import React, {Component} from "react";
import PaymentModal from "./PaymentModal";

class GlobalModals extends Component {
    render() {
        return (
            <React.Fragment>
                <PaymentModal />
            </React.Fragment>
        );
    }
}

export default GlobalModals;
