import {libraryItemTypes} from "consts/library";

const {video, image, audio, document} = libraryItemTypes;

export const ITEM_FILTERS = [
    {name: "all", id: "all"},
    {name: image, id: image},
    {name: video, id: video},
    {name: audio, id: audio},
    {name: document, id: document}
];

export const ITEM_SORTS = [
    {name: "name", id: "name"},
    {name: "date", id: "date"},
    {name: "author", id: "author"},
    {name: "format", id: "format"},
    {name: "size", id: "size"}
];
