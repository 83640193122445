import React, {Component} from "react";
import styled from "styled-components";
import WindowRectContext from "contexts/WindowRect";
import CurrentUser from "contexts/CurrentUser";
import {withContextConsumer} from "utils/contexts";
import {mediaQuery} from "components/common/styled/mediaQuery";
import Folder from "./Folder";
import Scrollbars from "components/common/Scrollbars";
import Controls from "./Controls";
import FoldersDropdown from "./FoldersDropdown";
import {isTeacher} from "consts/userTypes";

const FoldersContainer = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column;
    min-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
    margin-bottom: 1rem;
    ${mediaQuery.phone`
        box-shadow: none; 
        border-radius: none;
        height: 100%;
        background-color: white;
    `}
`;

const FolderTreeWrapper = styled.div`
    display: inline-flex;
    flex-flow: column;
    min-width: 100%;
`;

const FolderTree = styled.div`
    flex: 1;
`;
const ControlSection = styled.div``;

@withContextConsumer(WindowRectContext.Consumer)
@withContextConsumer(CurrentUser.Consumer)
class Folders extends Component {
    renderFolder(folder, deepLvl) {
        const {t, selectedFolderId, onSelectFolder, expendedFolderIds, toggleExpended} = this.props;
        return (
            <Folder
                t={t}
                key={folder.id}
                folder={folder}
                deepLvl={deepLvl}
                onSelect={onSelectFolder.bind(null, folder.id)}
                isSelected={selectedFolderId === folder.id}
                isExpended={folder.isRoot || expendedFolderIds.includes(folder.id)}
                toggleExpended={() => toggleExpended(folder.id)}>
                {folder.items.map(i => this.renderFolder(i, deepLvl + 1))}
            </Folder>
        );
    }

    renderDesktop() {
        const {
            t,
            folderTree,
            canDeleteFolder,
            onDeleteFolder,
            inSharingModal,
            extraFolderTrees,
            currentUser,
            viewOnly
        } = this.props;
        const isSchoolTeacher =
            isTeacher(currentUser) && Boolean(currentUser.schoolSettings && currentUser.schoolSettings.school);
        return (
            <FoldersContainer>
                <FolderTree>
                    <Scrollbars>
                        {!isSchoolTeacher && <FolderTreeWrapper>{this.renderFolder(folderTree, 0)}</FolderTreeWrapper>}
                        {extraFolderTrees &&
                            extraFolderTrees.map(extraFolder => (
                                <FolderTreeWrapper>{this.renderFolder(extraFolder, 0)}</FolderTreeWrapper>
                            ))}
                    </Scrollbars>
                </FolderTree>
                <ControlSection>
                    {!inSharingModal && <Controls t={t} canDeleteFolder={canDeleteFolder} onDeleteFolder={onDeleteFolder} />}
                </ControlSection>
            </FoldersContainer>
        );
    }

    renderMobile() {
        const {
            t,
            selectedFolderId,
            folderTree,
            canDeleteFolder,
            onDeleteFolder,
            inSharingModal,
            extraFolderTrees = [],
            findFolder
        } = this.props;

        const folder = findFolder(selectedFolderId);

        return (
            <FoldersDropdown
                selectedFolderName={folder.isRoot && folder.name === "default" ? t("library.defaultFolder") : folder.name}
                content={
                    <FoldersContainer>
                        <FolderTree>
                            <Scrollbars>
                                <FolderTreeWrapper>{this.renderFolder(folderTree, 0)}</FolderTreeWrapper>
                                {extraFolderTrees &&
                                    extraFolderTrees.map(extraFolder => (
                                        <FolderTreeWrapper>{this.renderFolder(extraFolder, 0)}</FolderTreeWrapper>
                                    ))}
                            </Scrollbars>
                        </FolderTree>
                        <ControlSection>
                            {!inSharingModal && (
                                <Controls t={t} canDeleteFolder={canDeleteFolder} onDeleteFolder={onDeleteFolder} />
                            )}
                        </ControlSection>
                    </FoldersContainer>
                }
            />
        );
    }

    render() {
        const {selectedFolderId, isPhoneResolution} = this.props;
        if (!selectedFolderId) {
            return null;
        }
        return isPhoneResolution ? this.renderMobile() : this.renderDesktop();
    }
}

export default Folders;
