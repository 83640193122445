import React, {Component} from "react";
import styled from "styled-components";
import {withRouter} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import NotificationDot from "components/common/NotificationDot";
import MenuItemsData from "./MenuItemsData";

const NavigationLinkWrapper = styled.div`
    > span {
        ${props => (props.dir === "rtl" ? "margin-right:" : "margin-left:")} 1rem;
        cursor: pointer;

        @media (min-width: 1033px) and (max-width: 1258px) {
            ${props => (props.dir === "rtl" ? "margin-right:" : "margin-left:")} 0.1rem;
        }
    }

    ${mediaQuery.phone`display: none;`}
`;

const NavigationLinkContainer = styled.span`
    padding: 0.5rem;
    border-radius: 4px;
    ${props => props.active && "background: white; color: var(--main-theme-color);"};
    position: relative;
`;

@withRouter
@withNamespaces("translation")
class MainNavigationLinks extends Component {
    render() {
        const {t, i18n, history, currentUser, myCoursesNotification} = this.props;
        return (
            <NavigationLinkWrapper dir={i18n.dir()}>
                {currentUser &&
                    MenuItemsData({currentUser}).map((d, i) => {
                        const isAvailable = d.availableForUsers.includes(currentUser.role);
                        const isActive = history.location.pathname.indexOf(d.link) === 0;
                        if (!isAvailable) {
                            return null;
                        }
                        const isCourseLink = d.link === "/courses";
                        return (
                            <NavigationLinkContainer id={d.id} active={isActive} key={i} onClick={() => history.push(d.link)}>
                                {t(d.title)}
                                <NotificationDot visible={isCourseLink && myCoursesNotification} reversePadding />
                            </NavigationLinkContainer>
                        );
                    })}
            </NavigationLinkWrapper>
        );
    }
}

export default MainNavigationLinks;
