import React from "react";
import stubImageSrc from "assets/icons/profilePlaceholder.png";

export default props => {
    const {src, placeholder, ...rest} = props;
    const imgSrc = src || placeholder || stubImageSrc;
    function handleError(e) {
        if (e.target.src !== stubImageSrc) e.target.src = stubImageSrc;
    }

    return <img src={imgSrc} onError={handleError} {...rest} />;
};
