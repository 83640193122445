import React, {useState, useContext, useEffect, useRef} from "react";
import {withRouter} from "react-router-dom";
import Scrollbar from "react-custom-scrollbars-rtl";
import SearchIcon from "components/common/icons/Search";
import OutsideClickHandler from "react-outside-click-handler";
import LandingContext from "contexts/Landing";
import {Container, IconContainer, Links, SearchInput, LinkContainer, Gradient, SearchResult} from "./styles";
import SearchedItem from "./SearchedItem";
import landingHeaderLinks from "consts/landingHeaderLinks";
import routes from "consts/routes";

const FETCH_EDGE_PX = 100;

function MarketSearch({history, t, scrollbarRef, ...props}) {
    const {withSearch} = props;
    const [searchValue, setSearchValue] = useState("");
    const [searchActive, setSearchActive] = useState(false);
    const headerRef = useRef(null);
    const {search: searchItems, onSearch, onScrollToAnchor, onSetHeaderRef} = useContext(LandingContext.Context);
    onSetHeaderRef(headerRef);

    const onInputChange = ({target: {value}}) => setSearchValue(value);

    useEffect(() => {
        onSearch(searchValue);
    }, [searchValue]);

    const onScroll = e => {
        const {
            target: {offsetHeight, scrollHeight, scrollTop}
        } = e;
        const offset = scrollHeight - offsetHeight - scrollTop;
        const needToFetch = offset < FETCH_EDGE_PX;
        if (needToFetch) onSearch(searchValue, true);
    };

    const {pathname} = history.location;

    return (
        <Container ref={headerRef}>
            <OutsideClickHandler onOutsideClick={() => setSearchActive(false)}>
                {withSearch && (
                    <IconContainer onClick={() => setSearchActive(a => !a)}>
                        <SearchIcon />
                    </IconContainer>
                )}
                <Links>
                    <SearchInput
                        value={searchValue}
                        onChange={onInputChange}
                        placeholder={t("landing.header.searchPlaceholder")}
                        visible={searchActive}
                    />
                    {landingHeaderLinks.map((link, i) => {
                        const onLinkClick =
                            pathname === routes.home.path
                                ? () => onScrollToAnchor({link, scrollbarRef, headerRef})
                                : () => history.push({pathname: routes.home.path, state: {link}});

                        return (
                            <LinkContainer key={i} onClick={onLinkClick}>
                                {t(link.label)}
                            </LinkContainer>
                        );
                    })}
                    <LinkContainer onClick={() => history.push(routes.market.path)}>
                        {t("landing.header.tabs.market")}
                    </LinkContainer>
                </Links>
                <SearchResult visible={searchActive}>
                    <Scrollbar autoHeight autoHeightMax={"15rem"} onScroll={onScroll}>
                        {searchItems.list.map(SearchedItem)}
                    </Scrollbar>
                    <Gradient />
                </SearchResult>
            </OutsideClickHandler>
        </Container>
    );
}

export default withRouter(MarketSearch);
