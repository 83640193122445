import React, {Component} from "react";
import styled, {css} from "styled-components";
import CrossIcon from "components/common/icons/Cross";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {withNamespaces} from "react-i18next";
import dropdownThemes from "consts/dropdownThemes";

const Container = styled.div`
    padding: ${props => (props.isRow ? 0 : "0.5rem 0 0 0")};
    ${props => props.disabled && "pointer-events: none;"};
`;

const Placeholder = styled.div`
    padding: 0.75rem 0;
    border-bottom: 1px solid #d8d8d8;
    color: #9e9e9e;
    ${mediaQuery.phone`
        padding: 0.5rem 0;
    `}
`;

const getWrapperThemeCss = style =>
    ({
        [dropdownThemes.whiteBordered]: css`
            border-radius: 4px;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
            background-color: var(--white);
            padding: 0.85rem 0.8rem;
        `,
        [dropdownThemes.dark]: css`
            padding: 0.375rem 0.875rem;
            font-size: 0.875rem;
            color: #fff !important;
            border-radius: 0.375rem;
            background-color: var(--light-black);
        `,
        [dropdownThemes.rounded]: css`
            border-radius: 1rem;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
            background-color: var(--white);
            padding: 0.4rem;
        `,
        [dropdownThemes.lightGray]: css`
            height: 2.5rem;
            padding: 0.375rem 0.875rem;
            border-radius: 0.375rem;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
            font-size: 0.875rem;
            background-color: var(--white);
        `,
        [dropdownThemes.default]: css`
            margin-bottom: 0.5rem;
        `
    }[style]);

const ChipItem = styled.div`
    display: inline-flex;
    align-items: center;

    border-radius: 2px;
    background-color: #f4f4f4;
    font-weight: 600;
    color: #6f6f6f;

    padding: 0.3rem 0.5rem;
    ${props => (props.dir === "rtl" ? "margin-left:" : "margin-right:")}0.5rem;
    svg {
        width: 1rem;
        margin-left: 0.5rem;
        cursor: pointer;
    }
    ${mediaQuery.phone`font-weight: normal;`}
    ${props => getWrapperThemeCss(props.theme || dropdownThemes.default)}
`;

@withNamespaces("translation")
class Chips extends Component {
    onDeleteChip(id) {
        const {data, onChange} = this.props;
        const newDataIds = data.filter(d => d.id !== id).map(d => d.id);
        onChange(newDataIds);
    }

    render() {
        const {data, style, dir, isCrossHidden, hasData, disabled, theme, isRow, placeholder} = this.props;
        if (!hasData && !data.length) {
            return <Placeholder>{placeholder}</Placeholder>;
        }
        return (
            <Container disabled={disabled} theme={theme} isRow={isRow}>
                {data.map((d, i) => (
                    <ChipItem style={style} key={i} dir={dir} theme={theme}>
                        {d.name}
                        {!isCrossHidden && <CrossIcon onClick={this.onDeleteChip.bind(this, d.id)} />}
                    </ChipItem>
                ))}
            </Container>
        );
    }
}

export default Chips;
