import React from "react";
import NetworkController from "controllers/Network";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import throttle from "lodash/throttle";
import debounce from "lodash/debounce";
import landingAnchors from "consts/landingAnchors";
import {landingContentPictureTabsList} from "consts/landingContentTabs";
import {withRouter} from "react-router-dom";

export const LandingContext = React.createContext("landing");

@withRouter
class LandingProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.loadSchoolsThrottled = throttle(this.loadSchools.bind(this), 500);
        this.state = {
            sendForm: this.sendForm,
            onSearch: debounce(this.searchMarketItems.bind(this), 400),
            onScrollToAnchor: this.scrollToAnchor,
            onSetAnchorRef: this.setAnchorRef,
            onSetActiveContentTab: this.setActiveContentTab,
            onSetHeaderRef: headerRef => (this.headerRef = headerRef),
            onGetHeaderRef: () => this.headerRef,
            schools: [],
            countSchools: 0,
            tutors: [],
            countTutors: 0,
            courses: {},
            content: {},
            search: {
                list: [],
                offset: 0,
                limit: 10,
                count: 0
            },
            activeContentTab: landingContentPictureTabsList[0],
            offsetTop: 0,
            onSetOffsetTop: offsetTop => this.setState({offsetTop})
        };
        this.anchorRefs = this.initAnchorRefs();
    }

    async componentDidMount() {
        await this.loadSchools();
        await this.loadTutors();
        await this.loadCourses();
        await this.loadContent();
        await this.searchMarketItems("");
    }

    async loadSchools() {
        const {response} = await NetworkController.get("/landing/schools", {});
        this.setState({schools: response?.schools, countSchools: response?.count});
    }

    async loadTutors() {
        const {response} = await NetworkController.get("/landing/tutors", {});
        this.setState({tutors: response?.tutors, countTutors: response?.count});
    }

    async loadCourses() {
        const {response} = await NetworkController.get("/landing/courses", {});
        this.setState({courses: response?.courses, countCourses: response?.count});
    }

    async loadContent() {
        const {response} = await NetworkController.get("/landing/content", {});
        this.setState({content: response});
    }

    async searchMarketItems(searchRow, isMore) {
        const {limit, offset, count, list} = this.state.search;
        if (isMore && count <= offset) return;
        const newOffset = isMore ? offset + limit : 0;
        const {response} = await NetworkController.get("/landing/marketSearch", {searchRow, limit, offset: newOffset});
        const newList = isMore ? [...list, ...response.list] : response.list;
        this.setState({search: {list: newList, offset: newOffset, count: response.count, limit}});
    }

    async sendForm({name, email, message}) {
        await NetworkController.get("/landing/sendForm", {name, email, message});
    }

    setActiveContentTab = tab => {
        this.setState({
            activeContentTab: tab
        });
    };

    initAnchorRefs() {
        const anchorRefs = {};
        for (const anchor of Object.values(landingAnchors)) {
            anchorRefs[anchor] = React.createRef();
        }
        return anchorRefs;
    }

    setAnchorRef = (ref, anchorName) => {
        if (!ref) {
            return;
        }
        this.anchorRefs = {
            ...this.anchorRefs,
            [anchorName]: {current: ref}
        };
    };

    scrollToAnchor = ({link, scrollbarRef, headerRef}) => {
        const contentRef = this.anchorRefs[link.anchor];
        const isContentRefSet = contentRef && contentRef.current;
        const isScrollbarReady = scrollbarRef && scrollbarRef.current && scrollbarRef.current.view;

        if (!isContentRefSet || !headerRef || !isScrollbarReady) {
            return;
        }

        const headerHeight = headerRef.current.clientHeight;
        const offsetTop = contentRef.current.offsetTop - headerHeight;
        scrollbarRef.current.view.scroll({top: offsetTop, behavior: "smooth"});

        if (link.newContentTab) {
            const newPictureTab = landingContentPictureTabsList.find(el => el.value === link.newContentTab);
            this.setActiveContentTab(newPictureTab);
        }
    };

    render() {
        return <LandingContext.Provider value={this.state}>{this.props.children}</LandingContext.Provider>;
    }
}

export default {
    Provider: LandingProvider,
    Consumer: LandingContext.Consumer,
    Context: LandingContext
};
