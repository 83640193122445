const lectureModes = {
    schedule: "schedule",
    self: "self"
};

export default lectureModes;

export function isScheduleMode(mode) {
    return lectureModes.schedule === mode;
}

export function isSelfMode(mode) {
    return lectureModes.self === mode;
}
