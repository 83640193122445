import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import {withContextConsumer} from "utils/contexts";
import WalletContext from "contexts/Wallet";
import WalletPaymentModal from "components/wallet/modals/payment/PaymentModal";

@withNamespaces("translation")
@withContextConsumer(WalletContext.Consumer)
class PaymentModal extends Component {
    render() {
        const {
            t,
            i18n,
            wallet,
            paymentModalOpenParams,
            setPaymentModalOpenParams,
            onPaypalPaymentApproved,
            loadPaypalLibrary,
            openTelrContent,
            openWaafiContent,
            telrContentIsLoading,
            onTelrPaymentApproved,
            waafiContentIsLoading,
            onWaafiPaymentApproved,
            onUnknownTransactionError
        } = this.props;
        if (!paymentModalOpenParams) {
            return null;
        }

        return (
            <WalletPaymentModal
                t={t}
                i18n={i18n}
                params={paymentModalOpenParams}
                currency={wallet.account.currency}
                onPaypalPaymentApproved={onPaypalPaymentApproved}
                loadPaypalLibrary={loadPaypalLibrary}
                onRequestClose={setPaymentModalOpenParams.bind(this, null)}
                openTelrContent={openTelrContent}
                openWaafiContent={openWaafiContent}
                telrContentIsLoading={telrContentIsLoading}
                onTelrPaymentApproved={onTelrPaymentApproved}
                waafiContentIsLoading={waafiContentIsLoading}
                onWaafiPaymentApproved={onWaafiPaymentApproved}
                onUnknownTransactionError={onUnknownTransactionError}
            />
        );
    }
}

export default PaymentModal;
