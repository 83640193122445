import styled from "styled-components";
import React, {Component} from "react";
import {withContextConsumer} from "utils/contexts";
import {withNamespaces} from "react-i18next";
import WalletContext from "contexts/Wallet";
import SubscriptionContext from "contexts/Subscription";
import CurrentUserContext from "contexts/CurrentUser";
import LocaleContext from "contexts/common/LocaleContext";
import userTypes from "consts/userTypes";
import PageLoadingSpinner from "components/common/PageLoadingSpinner";
import WalletIcon from "components/common/icons/notifications/WalletNotifications";
import {dateDiffInDays} from "utils/date";

const HeaderNotifications = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ebebeb;
    padding: 0.5rem;
`;

const ContentRow = styled.div`
    width: 100%;
    height: 19px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.2rem;
`;

const Text = styled.div`
    font-weight: ${props => (props.value ? "bold" : "normal")};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: ${props => (props.color ? props.color : "#8b8b8b")};
    margin-left: 0.2rem;
`;

const BalanceValue = styled.div`
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: #8b8b8b;
    margin-left: 1rem;
    margin-right: 1rem;
`;

@withContextConsumer(LocaleContext.Consumer)
@withContextConsumer(WalletContext.Consumer)
@withContextConsumer(SubscriptionContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
@withNamespaces("translation")
class HeaderWalletPreview extends Component {
    async componentDidMount() {
        await this.props.getWallet();
        if (this.props.currentUser.role === userTypes.teacher) {
            await this.props.getCurrentSubscription();
        }
    }

    render() {
        const {currentSubscription, wallet, moneyFormatter, numberFormatter, currentUser, i18n, t} = this.props;
        const isTeacher = currentUser.role === userTypes.teacher;
        const isSchoolTeacher = isTeacher && currentUser.isSchoolAttendee;
        if (!wallet || !currentSubscription || isSchoolTeacher) return null;

        const currency = wallet.account.currency;
        const balance = wallet.account.value;
        let daysLeftText, endDate, plan, used, availableWhiteboardUnits;

        if (isTeacher) {
            endDate = currentSubscription ? new Date(currentSubscription.period.end).toLocaleDateString(i18n.language) : null;

            plan = currentSubscription ? currentSubscription.plan : null;
            used = currentSubscription ? currentSubscription.used : null;
            availableWhiteboardUnits = plan && used ? numberFormatter.format(plan.whiteboardUnits - used.whiteboardUnits) : null;
            if (endDate) {
                const daysLeft = dateDiffInDays(new Date(), new Date(currentSubscription.period.end));
                const count = daysLeft > 10 && daysLeft < 20 ? daysLeft : daysLeft % 20;

                daysLeftText = numberFormatter.format(daysLeft);
                daysLeftText += ` ${t("notifications.walletHeader.daysLeft_interval", {
                    postProcess: "interval",
                    count
                })}`;
            }
        }
        const loaded = isTeacher ? endDate && availableWhiteboardUnits && balance : balance;
        if (!loaded) {
            return (
                <HeaderNotifications>
                    <PageLoadingSpinner />
                </HeaderNotifications>
            );
        }
        return (
            <HeaderNotifications>
                <React.Fragment>
                    <ContentRow>
                        <WalletIcon color={"#9A9898"} />
                        <BalanceValue>
                            {moneyFormatter.format(balance)}
                            {currency.sign}
                        </BalanceValue>
                    </ContentRow>
                    {isTeacher && (
                        <ContentRow>
                            <Text>{t("notifications.walletHeader.currentSubscriptionUntil")}</Text>
                            <Text value>{endDate}</Text>
                            <Text value color={"var(--red)"}>
                                ({daysLeftText})
                            </Text>
                        </ContentRow>
                    )}
                    {isTeacher && (
                        <ContentRow>
                            <Text value>{availableWhiteboardUnits}</Text>
                            <Text>{t("notifications.walletHeader.whiteboardUnitsLeft")}</Text>
                        </ContentRow>
                    )}
                </React.Fragment>
            </HeaderNotifications>
        );
    }
}

export default HeaderWalletPreview;
