import React, {Component, Suspense} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import {withContextProvider} from "utils/contexts";
import routes from "consts/routes";
import FileUploadProgressContext from "contexts/common/FileUploadProgress";
import NotificationsContext from "contexts/Notifications";
import SubscriptionContext from "contexts/Subscription";
import CurrentUserContext from "contexts/CurrentUser";
import CustomURLContext from "contexts/CustomURL";
import ChildrenContext from "contexts/Children";
import Enrollments from "contexts/Enrollments";
import ChatContext from "contexts/chat/Chat";
import WalletContext from "contexts/Wallet";
import MetaContext from "contexts/Meta";
import AppContext from "contexts/App";
import GlobalModals from "components/globalModals/GlobalModals";
import PageContainer from "./contents/PageContainer";
import CallbackContent from "./contents/Callback";
import Loading from "./contents/Loading";

const LecturesShowLoadable = React.lazy(() => import("./contents/lectures/LecturesShow"));

@withContextProvider(MetaContext.Provider)
class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <AppContext.Provider>
                    <CurrentUserContext.Provider>
                        <WalletContext.Provider>
                            <NotificationsContext.Provider>
                                <FileUploadProgressContext.Provider>
                                    <ChatContext.Provider>
                                        <SubscriptionContext.Provider>
                                            <ChildrenContext.Provider>
                                                <Enrollments.Provider>
                                                    <CustomURLContext.Provider>
                                                        <Switch>
                                                            <Route
                                                                path={routes.lecture.path}
                                                                component={props => (
                                                                    <Suspense fallback={<Loading />}>
                                                                        <LecturesShowLoadable {...props} />
                                                                        <GlobalModals />
                                                                    </Suspense>
                                                                )}
                                                                exact
                                                            />
                                                            <Route path={routes.callback.path} component={CallbackContent} />
                                                            <Route
                                                                path={routes.home.path}
                                                                component={props => <PageContainer {...props} />}
                                                            />
                                                            <Route component={() => <Redirect to={routes.home.path} />} />
                                                        </Switch>
                                                    </CustomURLContext.Provider>
                                                </Enrollments.Provider>
                                            </ChildrenContext.Provider>
                                        </SubscriptionContext.Provider>
                                    </ChatContext.Provider>
                                </FileUploadProgressContext.Provider>
                            </NotificationsContext.Provider>
                        </WalletContext.Provider>
                    </CurrentUserContext.Provider>
                </AppContext.Provider>
            </BrowserRouter>
        );
    }
}

export default Router;
