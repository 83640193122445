import React, {Component} from "react";
import styled from "styled-components";
import CrossIcon from "components/common/icons/chatWidget/Cross";
import ChatIcon from "components/common/icons/chatWidget/Chat";

const Container = styled.div`
    position: relative;
    margin-bottom: 1rem;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 3.125rem;
    background-color: #5c6977;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    fill: white;
`;

const MessageExistDot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    border: 0.2rem white solid;
    background-color: var(--main-theme-color);
    border-radius: 1rem;
`;

class ChatButton extends Component {
    render() {
        const {isOpen, isUnreadMessagesExist, onToggle} = this.props;
        return (
            <Container onClick={onToggle}>
                {isOpen ? <CrossIcon width="2rem" /> : <ChatIcon width="2rem" />}
                {isUnreadMessagesExist && <MessageExistDot />}
            </Container>
        );
    }
}

export default ChatButton;
