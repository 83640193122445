import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import FlexCenter from "./styled/FlexCenter";

const DropdownContainer = styled.div`
    position: relative;
    height: 100%;
    cursor: pointer;
`;

const MenuButton = styled.div`
    ${FlexCenter}
`;

const DropdownContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: ${props => (props.isOpen ? "block" : "none")};
    z-index: 100;
    background-color: white;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

@withNamespaces("translation")
class DropdownMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };
    }

    setExpanded(isExpanded) {
        this.setState({isExpanded});
    }

    render() {
        const {buttonContent, titleItem, items, menuStyle} = this.props;
        const {isExpanded} = this.state;

        return (
            <DropdownContainer onClick={() => this.setExpanded(!isExpanded)}>
                <MenuButton>{buttonContent}</MenuButton>
                <OutsideClickHandler onOutsideClick={() => this.setExpanded(false)} disabled={!isExpanded}>
                    <DropdownContent style={menuStyle} isOpen={isExpanded}>
                        {titleItem}
                        {items}
                    </DropdownContent>
                </OutsideClickHandler>
            </DropdownContainer>
        );
    }
}

export default DropdownMenu;
