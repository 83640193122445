import React, {Component} from "react";
import styled from "styled-components";
import Modal from "components/common/modals/Modal";

import LibraryItemModal from "components/library/modals/LibraryItemsModal";
import MaterialTabs from "components/common/tabs/MaterialTabs";
import {mediaQuery} from "components/common/styled/mediaQuery";
import FileIconDefault from "components/common/icons/library/FileIconDefault";
import Button from "components/form/Button";
import {ASSIGNMENT_ALLOW_FILE_TYPES, isFileTypeAllowed} from "utils/file";

const PageWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    justify-content: left;
`;

const TabsContainer = styled.div`
    width: 50rem;
    max-width: 100%;
    padding: 1rem 0;
    ${mediaQuery.phone`
        font-size: 0.65rem;
        padding: 1rem 0.8rem;    
    `}
`;

const InputFileButton = styled.div`
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8a8a8a;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    width: ${props => props.width || "14rem"};
    min-width: ${props => props.minWidth || "unset"};
    height: ${props => props.height || "2.5rem"};
    font-size: ${props => props.fontSize || "1rem"};
    font-weight: ${props => props.fontWeight || "normal"};
    padding: ${props => props.padding || "0 0 0 0"};
    margin: ${props => props.margin || "0 0 0 0"};
`;

const FileIconContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-flow: column;
    align-items: center;
    font-weight: 600;
    font-size: 0.8125rem;
    color: #bcc3ca;
    text-transform: uppercase;
    max-width: 2.25rem;
    min-width: 2.25rem;
    svg {
        width: 0.875rem;
        margin-bottom: 0.35rem;
    }
`;

const UploadFilesContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-flow: column;
    justify-content: left;
    align-items: center;
`;

const ShowFilesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

class LibraryAssignmentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedContent: [],
            assignmentFiles: [],
            libraryFiles: [],
            selectedPage: "upload",
            openmodel: false,
            error: ""
        };
    }

    addContentItems(items) {
        let libraryFiles = [];
        items.map(x => {
            libraryFiles.push(x);
        });
        this.setState({libraryFiles: libraryFiles});

        this.closeLectureModal();
    }

    checkFileType = event => {
        const {uploadType} = this.props;
        if (uploadType) {
            return true;
        }
        let error = "";
        const fileList = event.target.files;

        for (const file of fileList) {
            // check if there is file type if not its not supportes
            const type = file.type ? file.type : "NOT SUPPORTED";

            if (isFileTypeAllowed(type)) {
                error += file.type + " is not supported format\n";
            }
        }

        if (error !== "") {
            // if message not same old that mean has error
            event.target.value = null; // discard selected file

            this.setState({error: error});
            return false;
        }

        return true;
    };

    handleFileUploadChange = event => {
        const fileList = event.target.files;

        if (this.checkFileType(event)) {
            let assignmentFiles = [];

            for (const file of fileList) {
                assignmentFiles.push(file);
            }

            this.setState({assignmentFiles: assignmentFiles});
        }
    };

    getPagesData() {
        const {t} = this.props;
        return [
            {id: "upload", title: t(`assignment.addAssignment.documentUploading`)},
            {id: "library", title: t(`assignment.addAssignment.documentUploadingMylibrary`)}
        ];
    }

    closeLectureModal() {
        this.setState({openmodel: false});
    }

    getSelectedComponent() {
        const {selectedPage, assignmentFiles, libraryFiles} = this.state;
        const {uploadType} = this.props;

        if (selectedPage === "upload") {
            const {t} = this.props;
            return (
                <PageWrapper>
                    <UploadFilesContainer>
                        <label htmlFor={"assignmentFile"}>
                            <InputFileButton>{t("assignment.addAssignment.uploadFiles")}</InputFileButton>
                        </label>
                        <br />
                        {uploadType && uploadType === "Audio" ? (
                            <input
                                type="file"
                                placeholder={t("assignment.addAssignment.selectDocument")}
                                accept="audio/*"
                                name="assignmentFile"
                                id="assignmentFile"
                                multiple
                                style={{display: "none"}}
                                onChange={this.handleFileUploadChange}
                            />
                        ) : uploadType && uploadType === "Video" ? (
                            <input
                                type="file"
                                placeholder={t("assignment.addAssignment.selectDocument")}
                                accept="video/mp4,video/x-m4v,video/*"
                                name="assignmentFile"
                                id="assignmentFile"
                                multiple
                                style={{display: "none"}}
                                onChange={this.handleFileUploadChange}
                            />
                        ) : (
                            <input
                                type="file"
                                placeholder={t("assignment.addAssignment.selectDocument")}
                                accept={ASSIGNMENT_ALLOW_FILE_TYPES}
                                name="assignmentFile"
                                id="assignmentFile"
                                multiple
                                style={{display: "none"}}
                                onChange={this.handleFileUploadChange}
                            />
                        )}
                        {/* <input
                            type="file"
                            placeholder={t("assignment.addAssignment.selectDocument")}
                            accept={ASSIGNMENT_ALLOW_FILE_TYPES}
                            name="assignmentFile"
                            id="assignmentFile"
                            multiple
                            style={{display: "none"}}
                            onChange={this.handleFileUploadChange}
                        /> */}
                    </UploadFilesContainer>
                    {assignmentFiles ? (
                        <ShowFilesContainer>
                            <FileIconContainer>
                                {assignmentFiles.map(file => (
                                    <>
                                        <FileIconDefault />
                                        <p>{file.name}</p>
                                    </>
                                ))}
                            </FileIconContainer>
                        </ShowFilesContainer>
                    ) : null}
                </PageWrapper>
            );
        }

        if (selectedPage === "library") {
            const {selectedContent} = this.props;
            const {openmodel} = this.state;

            return (
                <PageWrapper>
                    <LibraryItemModal
                        isOpen={openmodel}
                        onRequestClose={this.closeLectureModal.bind(this)}
                        addedItems={selectedContent}
                        onConfirm={this.addContentItems.bind(this)}
                        hideAddButton={true}
                    />

                    <ShowFilesContainer>
                        <FileIconContainer>
                            {libraryFiles.map(file => (
                                <>
                                    <FileIconDefault />
                                    <p>{file.name}</p>
                                </>
                            ))}
                        </FileIconContainer>
                    </ShowFilesContainer>
                </PageWrapper>
            );
        }
    }

    closeModal = () => {
        //clear any attach file
        this.setState({assignmentFiles: []});
        this.props.closeAttachModal();
    };

    handleAttachFile = () => {
        this.props.onAddFileFromDevice(this.state.assignmentFiles);
        if (this.state.libraryFiles.length > 0) {
            this.props.onAddFileFromLibrary(this.state.libraryFiles);
        }
        this.closeModal();
    };

    render() {
        const {isOpen, title} = this.props;
        const {assignmentFiles, libraryFiles} = this.state;

        return (
            <Modal
                isOpen={isOpen}
                title={title}
                style={{borderRadius: "4px", width: "50rem"}}
                contentWrapperStyle={{padding: "2rem"}}
                onRequestClose={this.closeModal}
                fullHeightContent>
                <p>{this.state.error}</p>
                <TabsContainer>
                    <MaterialTabs
                        data={this.getPagesData()}
                        selected={this.state.selectedPage}
                        onChange={selectedPage => this.setState({selectedPage, openmodel: true})}
                    />
                </TabsContainer>
                {this.getSelectedComponent()}
                <UploadFilesContainer>
                    {assignmentFiles.length > 0 || libraryFiles.length > 0 ? (
                        <Button height="2.75rem" onClick={this.handleAttachFile}>
                            Attach
                        </Button>
                    ) : null}
                </UploadFilesContainer>
            </Modal>
        );
    }
}

export default LibraryAssignmentModal;
