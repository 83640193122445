import React, {Component} from "react";
import Modal from "components/common/modals/Modal";
import OwnersContent from "components/common/ownersQuizModalContent/OwnersContent";
import styled from "styled-components";
import TextButton from "components/form/TextButton";
import {withNamespaces} from "react-i18next";
import {compose} from "recompose";

const headerStyle = {fontWeight: "bold", fontSize: "1.5rem", padding: "0 0 1.5 0"};
const textButtonStyle = {fontSize: "1rem", margin: "1rem"};

const QuizHeaderButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 1rem 1rem 0 2rem;
    margin: 0 0 1rem 0;
`;

const AddButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Title = styled.div`
    line-height: 3rem;
    font-size: 1.75rem;
    font-weight: 600;
    color: #363636;
`;

class AddQuizModal extends Component {
    render() {
        const {
            isOpen,
            onClose,
            lectureData,
            closeTitle,
            title,
            course,
            attachEmbeddedQuizOnly,
            attachEmbeddedQuiz,
            attachSelfStudyQuiz,
            attachViaSelfStudyStructure,
            history,
            t
        } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                title={title}
                titleStyle={{padding: "0.5rem 0"}}
                headerStyle={headerStyle}
                //style={{borderRadius: "4px", width: "50rem"}}
                style={{
                    width: "50rem",
                    color: "var(--light-black)",
                    borderRadius: "10px"
                }}
                header={
                    <QuizHeaderButton>
                        <Title>{title || t("quiz.attachQuiz.attachQuizTitle")}</Title>
                        <AddButtonsContainer>
                            <TextButton
                                title={t("lecture.quizModal.createNewQuiz")}
                                style={textButtonStyle}
                                onClick={() => this.props.history.push(`/quizzes/add`)}
                                // disabled={!hasCoursesToAttach}
                                withPlus
                            />
                        </AddButtonsContainer>
                    </QuizHeaderButton>
                }
                overflow={"hidden"}
                contentWrapperStyle={{padding: 0}}
                onRequestClose={onClose}
                fullHeightContent>
                <OwnersContent
                    onClose={onClose}
                    lectureData={lectureData}
                    closeTitle={closeTitle}
                    course={course}
                    attachViaSelfStudyStructure={attachViaSelfStudyStructure}
                    attachSelfStudyQuiz={attachSelfStudyQuiz}
                    attachEmbeddedQuizOnly={attachEmbeddedQuizOnly}
                    attachEmbeddedQuiz={attachEmbeddedQuiz}
                    isListTitleHidden
                    hideAttached
                />
            </Modal>
        );
    }
}

export default compose(withNamespaces("translation"))(AddQuizModal);
