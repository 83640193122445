import React from "react";
import assignmentFormats from "consts/assignmentFormats";

import Modal from "components/common/modals/Modal";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
`;

const ModalWrapper = props => {
    const {isOpen, onClose, title, children} = props;

    return (
        <Container>
            <Modal
                isOpen={isOpen}
                title={title}
                style={{borderRadius: "4px", diplay: "flex", justifyContent: "center"}}
                contentWrapperStyle={{padding: 0}}
                onRequestClose={onClose}
                fullHeightContent>
                <Container>{children}</Container>
            </Modal>
        </Container>
    );
};

export default function AssignmentViewer(props) {
    const {viewer, handleClose} = props;
    const {url, format, open} = viewer;

    if (format === assignmentFormats["Image"]) {
        return (
            <ModalWrapper title={"View Image"} onClose={handleClose} isOpen={open}>
                <img src={url} width="420" height="320" alt="student submitted image" />
            </ModalWrapper>
        );
    }

    if (format === assignmentFormats["Audio"]) {
        return (
            <ModalWrapper title={"Audio Player"} onClose={handleClose} isOpen={open}>
                <audio controls>
                    <source src={url} type="audio/webm" />
                </audio>
            </ModalWrapper>
        );
    }

    if (format === assignmentFormats["Video"]) {
        return (
            <ModalWrapper title={"Video Player"} onClose={handleClose} isOpen={open}>
                <video width="350" height="240" controls>
                    <source src={url} type="video/webm" />
                </video>
            </ModalWrapper>
        );
    }

    return null;
}
