export const findFolderInTree = function*(root, folderId) {
    if (root.id === folderId) {
        yield root;
    }
    for (let i = 0; i < root.items.length; i++) {
        yield* findFolderInTree(root.items[i], folderId);
    }
};

export const flattenFolders = function(folderTree) {
    const result = [];
    const flatten = function(tree) {
        result.push(tree);
        tree.items.forEach(i => flatten(i));
    };
    flatten(folderTree);
    return result.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
};

export const filterTree = function(root, filterFunc) {
    root.items = root.items.filter(filterFunc);
    for (let i = 0; i < root.items.length; i++) {
        filterTree(root.items[i], filterFunc);
    }
};
