import React from "react";

export default ({active, height = "32", ...props}) => (
    <svg width={height} height={height} viewBox="0 0 32 32" {...props}>
        <path
            fill={active ? "#2479EF" : "#7B8A9D"}
            opacity={active ? "1" : "0.4"}
            fillRule="evenodd"
            d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm1.78 8.5c-2.156 0-3.549 1.449-3.549 3.692v1.702h-1.93A.304.304 0 0 0 12 14.2v2.466c0 .169.135.305.302.305h1.929v6.223c0 .17.135.306.302.306h2.517a.304.304 0 0 0 .302-.306v-6.223h2.255a.304.304 0 0 0 .302-.305l.001-2.466a.308.308 0 0 0-.088-.217.3.3 0 0 0-.214-.09h-2.256v-1.442c0-.694.163-1.046 1.054-1.046h1.292a.304.304 0 0 0 .302-.306v-2.29a.304.304 0 0 0-.301-.306l-1.92-.003z"
        />
    </svg>
);
