import React from "react";
import NetworkController from "controllers/Network";
import AsyncStateComponent from "./common/AsyncStateComponent";
import {withContextConsumer} from "utils/contexts";
import WalletContext from "./Wallet";
import errors from "consts/errors";
import CurrentUserContext from "./CurrentUser";
import userTypes from "consts/userTypes";
import {getAmountData, isPlanError} from "utils/subscriptionHelper";
import GlobalHints from "./GlobalHints";
const {NotEnoughMoney} = errors;

const SubscriptionContext = React.createContext("subscription");

const teacherPrincipalTypes = [userTypes.teacher, userTypes.principal];

const planSettingsForAdmin = {
    isThisPlanToProlong: false,
    isWithPlannedBadge: false,
    isUpgrade: false,
    currency: null
};

@withContextConsumer(WalletContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
@withContextConsumer(GlobalHints.Consumer)
class SubscriptionProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            currentSubscription: null,
            getPlans: this.getPlans.bind(this),
            getCurrentSubscription: this.getCurrentSubscription.bind(this),
            replaceSubscription: this.replaceSubscription.bind(this),
            prolongSubscription: this.prolongSubscription.bind(this),
            isPending: false,
            studentsInSchool: null
        };
    }

    async componentDidMount() {
        const {currentUser} = this.props;
        const shouldGetSubscriptionData = currentUser && teacherPrincipalTypes.includes(currentUser.role);
        if (shouldGetSubscriptionData) {
            await this.getCurrentSubscription();
            await this.getPlans();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevUserRole = prevProps?.currentUser?.role;
        const newUserRole = this.props?.currentUser?.role;
        if (prevUserRole !== newUserRole && newUserRole === userTypes.principal) this.getCurrentSubscription();
    }

    async getCurrentSubscription() {
        const {response} = await NetworkController.get("/subscription");
        const state = {currentSubscription: response.subscription};
        const shouldUpdateStudentsCount = response.studentsCount !== null && !isNaN(parseInt(response.studentsCount));
        if (shouldUpdateStudentsCount) {
            state.studentsInSchool = response.studentsCount;
        }
        await this.setStatePromise(state);
    }

    async getPlans() {
        const {currentUser} = this.props;
        const url = currentUser.role === userTypes.principal ? "/subscription/plans/school" : "/subscription/plans";
        const {response} = await NetworkController.get(url);
        const plans = await this.setSettingsToPlans(response.plans);
        this.setState({plans});
    }

    async setSettingsToPlans(plans) {
        const {currentUser, wallet} = this.props;
        const {currentSubscription} = this.state;

        const currency = wallet && wallet.account && wallet.account.currency;
        const currentPlan = currentSubscription && currentSubscription.plan;
        const isAdmin = currentUser.role === userTypes.admin;

        const shouldReturnPlainPlans = !isAdmin && (!currency || !currentPlan);

        if (shouldReturnPlainPlans) {
            return plans;
        }

        const {planToProlongName} = currentSubscription || {};

        const result = [];
        for (const plan of plans) {
            const settings = await this.getPlanSettings({isAdmin, plan, currentPlan, planToProlongName, currency});
            result.push({...plan, ...settings});
        }

        return result;
    }

    async getPlanSettings({isAdmin, plan, currentPlan, planToProlongName, currency}) {
        if (isAdmin) {
            return planSettingsForAdmin;
        }

        const currentPlanName = currentPlan && currentPlan.name;

        const isThisPlanToProlong = planToProlongName === plan.name;
        const isWithPlannedBadge = currentPlanName !== plan.name && currentPlanName !== "free" && isThisPlanToProlong;

        const isUpgrade = await this.getIsUpgrade({currency, currentPlan, plan});

        return {isThisPlanToProlong, isWithPlannedBadge, isUpgrade};
    }

    async getIsUpgrade({currency, currentPlan, plan}) {
        if (plan.isCommissionBased) {
            return false;
        }

        const currentPlanData = getAmountData({plan: currentPlan, currency});
        const planData = getAmountData({plan, currency});

        if (currentPlanData.value === 0) {
            return true;
        }
        if (planData.value === 0) {
            return false;
        }
        const {convertMoney} = this.props;

        const currentPlanPrice = await convertMoney(currentPlanData.currencyName, currency.name, currentPlanData.value);
        const newPlanPrice = await convertMoney(planData.currencyName, currency.name, planData.value);

        return currentPlanPrice < newPlanPrice;
    }

    async replaceSubscription(planName) {
        this.setState({isPending: true});
        const {getWallet, setPaymentModalOpenParams, getBalanceRechargeAmount} = this.props;

        const {response, error} = await NetworkController.put("/subscription", {planName});
        if (error && response.account === NotEnoughMoney) {
            const plan = this.state.plans.find(p => p.name === planName);
            const balanceRechargeAmount = getBalanceRechargeAmount(plan.amount);
            this.setState({isPending: false});
            const topUpData = {
                reason: NotEnoughMoney,
                amount: balanceRechargeAmount,
                callback: this.replaceSubscription.bind(this, planName)
            };
            return setPaymentModalOpenParams(topUpData);
        }
        await getWallet();
        if (error && isPlanError(response.plan)) {
            const {addHint} = this.props;
            addHint({text: response.plan, background: "var(--red)", timeout: 3000});
            return this.setState({isPending: false});
        }
        this.setState({currentSubscription: response.subscription, isPending: false});
    }

    async prolongSubscription(planName) {
        const {response} = await NetworkController.put("/subscription/prolong", {planName});
        await this.setStatePromise({currentSubscription: response.subscription});

        const plans = await this.setSettingsToPlans(this.state.plans);
        await this.setStatePromise({plans});
    }

    render() {
        return <SubscriptionContext.Provider value={this.state}>{this.props.children}</SubscriptionContext.Provider>;
    }
}

export default {Provider: SubscriptionProvider, Consumer: SubscriptionContext.Consumer};
