import React from "react";

export default ({color, ...rest}) => (
    <svg viewBox="0 0 16 16" {...rest}>
        <path
            fill={color || "var(--main-theme-color)"}
            fillRule="nonzero"
            d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.398 6.398 0 0 1 1.6 8c0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 3.536-2.864 6.4-6.4 6.4zM8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
        />
    </svg>
);
