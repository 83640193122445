import React, {Component} from "react";
import {withContextConsumer} from "utils/contexts";
import FileUploadProgressContext from "contexts/common/FileUploadProgress";
import FileUploadProgressList from "./FileUploadProgressList";
import PopperMenu from "components/common/popperMenu/PopperMenu";
import UploadIcon from "components/common/icons/Upload";
import styled from "styled-components";
import RoundIndicator from "components/common/RoundIndicator";

const IconWrapper = styled.div`
    ${props => props.isInvisible && "visibility: hidden;"};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.2rem;
    height: 2.2rem;
    left: 0.4rem;
    position: relative;
    overflow: visible;
    > svg:first-child {
        width: 2.1rem;
        height: 2.1rem;
    }
    > svg:last-child {
        position: absolute;
        top: 7px;
        left: 3px;
        width: 1.6rem;
        height: 1.2rem;
    }
`;

const UploadIconWithIndicator = props => {
    const indicatorOptions = {
        radius: 20,
        strokeWidth: 1,
        backgroundStrokeWidth: 1,
        color: "#fff",
        background: "var(--main-theme-color)"
    };

    return (
        <IconWrapper>
            <RoundIndicator indicatorOptions={indicatorOptions} {...props} />
            <UploadIcon onClick={props.onClick} />
        </IconWrapper>
    );
};

@withContextConsumer(FileUploadProgressContext.Consumer)
class FileUploadProgress extends Component {
    calculateAverageProgress(progressArray) {
        return progressArray.reduce((acc, cur) => acc + cur.progress, 0) / progressArray.length;
    }

    render() {
        const {
            uploadItems,
            isProgressExpanded,
            expandProgressContainer,
            collapseProgressContainer,
            resetAllProgresses,
            cancelUpload
        } = this.props;

        const uploadItemValues = Object.values(uploadItems);
        if (!uploadItemValues.length) {
            return <IconWrapper isInvisible />;
        }
        const averageProgress = this.calculateAverageProgress(uploadItemValues);
        return (
            <PopperMenu
                refElement={<UploadIconWithIndicator percentage={averageProgress} onClick={expandProgressContainer} />}
                content={
                    <FileUploadProgressList
                        uploadItems={uploadItems}
                        resetAllProgresses={resetAllProgresses}
                        cancelUpload={cancelUpload}
                    />
                }
                isShown={isProgressExpanded}
                onRequestClose={collapseProgressContainer}
                placement="bottom-center"
            />
        );
    }
}

export default FileUploadProgress;
