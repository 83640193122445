import React from "react";

export default props => (
    <svg viewBox="0 0 20 20" {...props}>
        <g fill="#FF0C3E">
            <path
                fillRule="nonzero"
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
            <path
                fillRule="evenodd"
                d="M11.282 9.985l3.24 3.241-1.296 1.297-3.24-3.241-3.242 3.24-1.296-1.296 3.24-3.24-3.24-3.242 1.296-1.296 3.241 3.24 3.241-3.24 1.297 1.296-3.241 3.241z"
            />
        </g>
    </svg>
);
