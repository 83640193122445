import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import DropdownPopper from "components/form/DropdownPopper";
import {mediaQuery} from "components/common/styled/mediaQuery";
import Chips from "components/form/Chips";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";

const Details = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    padding: 1rem 0 0;
    ${FlexMarginBetween(1)}
    ${mediaQuery.phone`
        ${FlexMarginBetween(0)};
        padding: 0;
        justify-content: flex-start;
        flex-direction:column;
    `}
`;

const Field = styled.div`
    flex: 1;
    color: #9e9e9e;
    font-size: 0.9rem;
    min-width: 12rem;
    padding: 0 1rem 0 0;
    ${mediaQuery.phone`
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0 0 1rem;
    `}
`;

const Property = styled.div``;

const Value = styled.div`
    line-height: 2rem;
    ${mediaQuery.phone`
        border-bottom: 1px solid #d8d8d8;
    `}
`;

const DropdownWrapper = styled.div`
    flex: 1;
    ${mediaQuery.phone`
        padding: 0 0 1rem;
    `}
`;

@withNamespaces("translation")
class CourseLectureForm extends Component {
    render() {
        const {
            course,
            courses = [],
            lectureId,
            lecturesList = [],
            competencies,
            onFieldChange,
            onCourseChange,
            error,
            i18n,
            weightageCategories,
            weightage,
            onChangeCourseWeightageCategories,
            t
        } = this.props;
        let coursesData;
        if (courses.length) {
            coursesData = courses.map(c => ({name: c.name, id: c.id}));
        } else if (course && course.name && course.id) {
            coursesData = [{name: course.name, id: course.id}];
        } else {
            return null;
        }

        let weightageCategoriesData = [];
        if (weightageCategories) {
            weightageCategoriesData = weightageCategories.map(c => ({name: c.name, id: c.id}));
        } else if (weightage && weightage.name && weightage.id) {
            weightageCategoriesData = [{name: weightage.name, id: weightage.id}];
        }

        return (
            <Details>
                <Row dir={i18n.dir()}>
                    <DropdownWrapper>
                        <DropdownPopper
                            title={t("quiz.addQuiz.linkedCourse")}
                            data={coursesData}
                            current={course.id}
                            placeholder={t("quiz.addQuiz.linkedCourse")}
                            onChange={courseId => onCourseChange(courseId)}
                            style={{position: "absolute", background: "white"}}
                            disabled={!onCourseChange}
                        />
                    </DropdownWrapper>
                    <DropdownWrapper>
                        <DropdownPopper
                            title={t("quiz.addQuiz.linkedLecture")}
                            data={lecturesList}
                            current={lectureId}
                            error={error}
                            placeholder={t(error ? `errors.${error}` : "quiz.attachQuiz.lecture")}
                            onChange={value => onFieldChange("lectureId", value)}
                            disabled={lecturesList.length <= 1}
                        />
                    </DropdownWrapper>
                </Row>
                <Row dir={i18n.dir()}>
                    <Field>
                        <Property>{t("addCourses.form.subjectTitle")}</Property>
                        <Value>{course.subject || t("quiz.attachQuiz.noSubject")}</Value>
                    </Field>
                    <Field>
                        <Property>{t("addCourses.form.gradeTitle")}</Property>
                        <Value>{course.grade || t("quiz.attachQuiz.noGrade")}</Value>
                    </Field>
                </Row>
                <Row dir={i18n.dir()}>
                    <Field>
                        <Property>{t("addCourses.form.competenciesTitle")}</Property>
                        <Chips data={competencies} dir={i18n.dir()} isCrossHidden />
                    </Field>
                    <Field>
                        <DropdownWrapper>
                            <DropdownPopper
                                title={t("assessment.addAssessment.linkedWeightage")}
                                data={weightageCategoriesData}
                                current={weightage?.id}
                                placeholder={t("assessment.addAssessment.linkedWeightage")}
                                onChange={weightage => {
                                    onChangeCourseWeightageCategories(weightage);
                                }}
                                style={{position: "absolute", background: "white"}}
                                disabled={!onCourseChange}
                            />
                        </DropdownWrapper>
                    </Field>
                </Row>
            </Details>
        );
    }
}

export default CourseLectureForm;
