import React from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";

const getBorderRadius = (tooltipAlignLeft, tooltipAlignTop) => {
    const position = `${tooltipAlignTop ? "top" : "bottom"}-${tooltipAlignLeft ? "left" : "right"}`;

    switch (position) {
        case "top-right":
            return "6px 6px 6px 0";
        case "top-left":
            return "6px 6px 0 6px";
        case "bottom-right":
            return "0 6px 6px 6px";
        case "bottom-left":
            return "6px 0 6px 6px";
        default:
            return "6px";
    }
};

const Tooltip = styled.div`
    display: ${props => (props.tooltipIsOpen ? "block" : "none")};
    padding: 5px 7px 5px 7px;
    z-index: 10000;
    background-color: #5c6977;
    border-radius: ${props => getBorderRadius(props.tooltipAlignLeft, props.tooltipAlignTop)};
    ${props => props.tooltipAlignLeft && "transform: translateX(-100%);"};
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--white);
    margin-top: 3px;
    ${mediaQuery.phone`${props => props.hideOnPhoneResolution && `display: none !important;`}`}
`;

export default Tooltip;
