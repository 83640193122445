import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import Avatar from "components/common/Avatar";
import SupportIcon from "components/common/icons/chatWidget/Support";
import userTypes from "consts/userTypes";

const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const MainContainer = styled.div`
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
`;

const RoundAvatar = styled(Avatar)`
    min-width: 1.8rem;
    max-width: 1.8rem;
    height: 1.8rem;
`;

const Name = styled.div`
    line-height: 1rem;
    max-height: 2rem;
    display: inline-block;
    font-size: 0.875rem;
    color: #5c6977;
    margin: 0 0.7rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
`;

const UnreadMessageCount = styled.div`
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 1rem;
    font-size: 0.8rem;
    text-align: center;
    color: var(--white);
    border-radius: 10px;
    background-color: var(--main-theme-color);
`;

const Highlight = styled.b`
    color: #ffa900;
`;

@withNamespaces("translation")
class ContactItem extends Component {
    getNameWithHighlight(name, highlight) {
        const parts = name.split(new RegExp(`(${highlight})`, "gi"));
        return parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? <Highlight key={i}>{part}</Highlight> : part
        );
    }

    render() {
        const {receiver, unreadMessageCount, onClick, searchQuery} = this.props;
        const unreadMessageCountVisible = !!unreadMessageCount;
        const {id, name, userPic} = receiver;
        const isAdmin = receiver && receiver.role === userTypes.admin;
        return (
            <Container onClick={() => onClick(id)}>
                <MainContainer>
                    {isAdmin ? <SupportIcon /> : <RoundAvatar src={userPic} />}
                    <Name>{this.getNameWithHighlight(name, searchQuery)}</Name>
                </MainContainer>
                {unreadMessageCountVisible && <UnreadMessageCount>{unreadMessageCount}</UnreadMessageCount>}
            </Container>
        );
    }
}

export default ContactItem;
