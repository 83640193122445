import {SocketController} from "./Socket";

const SOCKET_FOR_CHAT = "SOCKET_FOR_CHAT";
class ChatSocketController extends SocketController {
    constructor() {
        super();
    }

    async connect(url, token) {
        await super.connect(SOCKET_FOR_CHAT, url, token, "/chatsignal");
    }

    async emit(method, data) {
        return await super.emit(SOCKET_FOR_CHAT, method, data);
    }

    on(method, listener) {
        super.on(SOCKET_FOR_CHAT, method, listener);
    }

    removeAllListeners(method) {
        super.removeAllListeners(SOCKET_FOR_CHAT, method);
    }

    disconnect() {
        super.disconnect(SOCKET_FOR_CHAT);
    }
}

export default new ChatSocketController();
