import React, {Component} from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";
import FooterMenu from "./FooterMenu";
import Facebook from "components/common/icons/social/Facebook";
import Visa from "components/common/icons/Visa";
import PayPal from "components/common/icons/PayPal";
import Youtube from "components/common/icons/social/Youtube";
import {DesktopView, MobileView} from "components/teachers/common/styled";

const COPYRIGHT = "© Tabsera, 2020 ";
const webSite = "info@tabsera.com";

const SocialIconHeightPx = 24;
const PaymentIconHeightPx = 40;

const FACEBOOK_URL = "https://www.facebook.com/tabseraEDU/";
const YOUTUBE_URL = "https://www.youtube.com/channel/UCMo4Gr4UbAUo9_2oMU_JRPg/";

const FooterWrapper = styled.div`
    background-color: #363636;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;

    padding: 0 1rem;

    width: 100%;
    height: auto;
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.55);

    color: #323232;

    ${mediaQuery.phone`
        flex-direction: column;
    `}
`;

const Copyright = styled.div`
    padding: 1rem 0 0;
    height: 3.6rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.4);
    cursor: default;
    flex: 1;
    min-width: 8rem;
    ${mediaQuery.phone`
        order: 3;
        text-align: center;    
    `}
`;

const SupportEmail = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    cursor: default;
    font-size: 0.875rem;
    line-height: 1.5;
    justify-content: flex-end;
    color: rgba(255, 255, 255, 0.8);
    svg {
        margin: 0.5rem;
    }

    ${mediaQuery.phone`
        align-items: center;    
        justify-content: center;
    `}
`;

const SpaceForChat = styled.div`
    width: 80px;
    height: 60px;
`;

const WebSite = styled.a`
    font-size: 0.875rem;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    padding: 0 0.5rem 0;
`;

const IconLink = styled.a`
    display: flex;
    align-items: center;
    > svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

class Footer extends Component {
    render() {
        return (
            <FooterWrapper>
                <Copyright>{COPYRIGHT}</Copyright>
                <FooterMenu isLanding />
                <MobileView>
                    <WebSite href={`mailto:${webSite}`}>{webSite}</WebSite>
                </MobileView>
                <SupportEmail>
                    <DesktopView>
                        <WebSite href={`mailto:${webSite}`}>{webSite}</WebSite>
                    </DesktopView>
                    <IconLink href={FACEBOOK_URL} target={"_blank"} rel="noopener noreferrer">
                        <Facebook height={SocialIconHeightPx} active />
                    </IconLink>
                    <IconLink href={YOUTUBE_URL} target={"_blank"} rel="noopener noreferrer">
                        <Youtube height={SocialIconHeightPx} />
                    </IconLink>
                    <DesktopView style={{width: "max-content"}}>
                        <Visa height={PaymentIconHeightPx} />
                        <PayPal height={PaymentIconHeightPx} />
                        <SpaceForChat style={{display: "inline-block"}} />
                    </DesktopView>
                </SupportEmail>
                <MobileView>
                    <Visa height={PaymentIconHeightPx} />
                    <PayPal height={PaymentIconHeightPx} />
                </MobileView>
            </FooterWrapper>
        );
    }
}

export default Footer;
