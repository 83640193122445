import "whatwg-fetch";
import "./i18n";

import React from "react";
import ReactDOM from "react-dom";
import App from "containers/App";
import moment from "moment";
import "moment/locale/ar-sa";
import vhHack from "./domain/hacks/vh";
import style from "assets/root.css";

// require("./assets/**/*.png");
// require("./assets/**/*.svg");

vhHack();

moment.defineLocale("custom", {
    parentLocale: "en",
    week: {
        dow: 1
    }
});
moment.locale("custom");

ReactDOM.render(<App />, document.getElementById("app"));

if (module.hot) {
    module.hot.accept();
}
