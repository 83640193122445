import React from "react";

export default props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" {...props}>
        <g fill="none" fillRule="evenodd">
            <g>
                <circle cx="16.5" cy="16.5" r="16.5" fill="var(--main-theme-color)" />
                <path fill="#FFF" d="M10.6 22.98l14.987-6.487L10.6 10.007v5.045l10.705 1.441L10.6 17.935z" />
            </g>
        </g>
    </svg>
);
