import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import DropdownPopper from "components/form/DropdownPopper";
import DropdownSelectablePopper from "components/form/DropdownSelectablePopper";
import {mediaQuery} from "components/common/styled/mediaQuery";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";

const Details = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    ${FlexMarginBetween(1)}
    ${mediaQuery.phone`
        flex-direction:column;
        ${FlexMarginBetween(0)}
    `}
`;

const DropdownWrapper = styled.div`
    padding: 1rem 0 0 0;
    width: 100%;
    ${mediaQuery.phone`
        padding: 1rem 1rem 0 1rem;
    `}
`;

@withNamespaces("translation")
class AdHocForm extends Component {
    getGameName(game) {
        if (game.isPublisherQuiz) {
            return game.name;
        }
        return this.props.t(`quizGames.${game.name}.name`);
    }

    render() {
        const {
            t,
            i18n,
            quizMeta,
            quizGame,
            quizGames,
            lecturesList = [],
            onFieldChange,
            lectureId,
            subject,
            competencies,
            error
        } = this.props;

        const {subjects} = quizMeta;
        const subjectData = subject ? subjects.find(s => s.name === subject) : {competencies: []};

        return (
            <Details dir={i18n.dir()}>
                <Row dir={i18n.dir()}>
                    <DropdownWrapper>
                        <DropdownPopper
                            title={t("quiz.addQuiz.linkedLecture")}
                            data={lecturesList}
                            current={lectureId}
                            placeholder={t(error ? `errors.${error}` : "quiz.attachQuiz.lecture")}
                            error={error}
                            onChange={value => onFieldChange("lectureId", value)}
                        />
                    </DropdownWrapper>
                    <DropdownWrapper>
                        <DropdownPopper
                            title={t("addCourses.form.subjectTitle")}
                            data={subjects.map(s => ({name: s.name, id: s.name}))}
                            current={subject}
                            placeholder={t("addCourses.form.subjectTitle")}
                            onChange={value => onFieldChange("subject", value)}
                        />
                    </DropdownWrapper>
                </Row>
                <Row dir={i18n.dir()}>
                    <DropdownWrapper>
                        <DropdownSelectablePopper
                            title={t("addCourses.form.competenciesTitle")}
                            disabled={!subject}
                            data={subjectData.competencies.map(c => ({
                                name: t(`common.subjects.${subject}.competencies.${c}`),
                                id: c
                            }))}
                            selected={competencies}
                            placeholder={t("addCourses.form.competenciesPlaceholder")}
                            style={{marginLeft: "10px"}}
                            onChange={c => onFieldChange("competencies", c)}
                        />
                    </DropdownWrapper>
                    <DropdownWrapper>
                        <DropdownPopper
                            title={t("addCourses.form.quizGameTitle")}
                            current={quizGame}
                            data={quizGames.map(game => ({name: this.getGameName(game), id: game.id}))}
                            placeholder={t("addCourses.form.quizGamePlaceholder")}
                            onChange={value => onFieldChange("quizGame", value)}
                        />
                    </DropdownWrapper>
                </Row>
            </Details>
        );
    }
}

export default AdHocForm;
