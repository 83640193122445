import React from "react";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import {withRouter} from "react-router-dom";
import ChatContext from "contexts/chat/Chat";
import {withContextConsumer} from "utils/contexts";

const MessageInputContext = React.createContext("chat");

@withContextConsumer(ChatContext.Consumer)
class MessageInput extends AsyncStateComponent {
    constructor(props) {
        super(props);

        this.state = {
            message: {
                text: ""
            },
            libraryFiles: [],
            attachedFiles: [],
            inputCorrect: false,
            fileLibraryIsOpen: false,
            optionsPanelIsOpen: false,
            confirmClearMessagesIsOpen: false,
            confirmBlockUserIsOpen: false,
            fileSizeError: false,

            clearInput: this.clearInput.bind(this),
            handleChangeMessage: this.handleChangeMessage.bind(this),
            handleFileLibraryRequestClose: this.handleFileLibraryRequestClose.bind(this),
            handleInputLibraryFiles: this.handleInputLibraryFiles.bind(this),
            handleInputAttachedFiles: this.handleInputAttachedFiles.bind(this),
            handleFileLibraryRequestOpen: this.handleFileLibraryRequestOpen.bind(this),
            handleLibraryFileClick: this.handleLibraryFileClick.bind(this),
            handleAttachedFileClick: this.handleAttachedFileClick.bind(this)
        };
    }

    clearInput() {
        this.setState({message: {text: ""}, libraryFiles: [], attachedFiles: [], inputCorrect: false});
    }

    handleChangeMessage(value) {
        const verifyTextContent = text => !!text && text.trim().length;
        this.setState({message: {text: value}, inputCorrect: verifyTextContent(value)});
    }

    handleInputLibraryFiles(files) {
        const {libraryFiles} = this.state;
        const {stateFiles, messageIsNotEmpty} = this.addFilesToStateFiles(files, libraryFiles);

        this.setState({
            libraryFiles: stateFiles,
            fileLibraryIsOpen: false,
            inputCorrect: messageIsNotEmpty
        });
    }

    handleInputAttachedFiles(files) {
        if (!files) return;
        files.forEach(file => (file.id = file.name));

        const {attachedFiles} = this.state;
        const {stateFiles, messageIsNotEmpty, fileSizeError} = this.addFilesToStateFiles(files, attachedFiles);

        this.setState({
            attachedFiles: stateFiles,
            fileLibraryIsOpen: false,
            inputCorrect: messageIsNotEmpty,
            fileSizeError
        });
    }

    handleFileLibraryRequestClose() {
        this.setState({fileLibraryIsOpen: false});
    }

    handleFileLibraryRequestOpen() {
        this.setState({fileLibraryIsOpen: true});
    }

    addFilesToStateFiles(files, oldStateFiles) {
        const originalFilesLength = files.length;

        const {validationFileSize, maxFilesInMessage} = this.props.meta;
        const validationFileSizeInBytes = validationFileSize * 1024 * 1024;

        files = files.filter(({size}) => size < validationFileSizeInBytes);
        const newFilesLength = files.length;
        const fileSizeError = originalFilesLength !== newFilesLength;

        const oldFilesIds = new Set(oldStateFiles.map(d => d.id));
        const newStateFiles = [...oldStateFiles, ...files.filter(d => !oldFilesIds.has(d.id))];
        const stateFiles = newStateFiles.slice(0, maxFilesInMessage);

        const messageIsNotEmpty = stateFiles.length !== 0;
        return {stateFiles, messageIsNotEmpty, fileSizeError};
    }

    handleLibraryFileClick(fileId) {
        const libraryFiles = this.state.libraryFiles.filter(file => file.id !== fileId);
        const messageIsNotEmpty = libraryFiles.length !== 0;
        this.setState({libraryFiles, inputCorrect: messageIsNotEmpty});
    }

    handleAttachedFileClick(fileId) {
        const attachedFiles = this.state.attachedFiles.filter(file => file.id !== fileId);
        const messageIsNotEmpty = attachedFiles.length !== 0;
        this.setState({attachedFiles, inputCorrect: messageIsNotEmpty});
    }

    render() {
        return <MessageInputContext.Provider value={this.state}>{this.props.children}</MessageInputContext.Provider>;
    }
}

export default {Provider: withRouter(MessageInput), Consumer: MessageInputContext.Consumer};
