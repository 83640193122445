import React, {PureComponent} from "react";
import ReactDOM from "react-dom";
import styled, {css} from "styled-components";
import Scrollbars from "components/common/Scrollbars";
import OutsideClickHandler from "react-outside-click-handler";
import dropdownArrow from "assets/icons/dropdownArrow.svg";
import {mediaQuery} from "components/common/styled/mediaQuery";
import EllipsisText from "components/common/styled/EllipsisText";
import dropdownThemes from "consts/dropdownThemes";
import {withNamespaces} from "react-i18next";
import DropdownDoubleArrowIcon from "components/common/icons/DropdownDoubleArrowIcon";

const DropdownTitle = styled.div`
    color: #9e9e9e;
    font-size: 0.9rem;
    ${mediaQuery.phone`margin-bottom: 0;`}
`;

const getWrapperThemeCss = style =>
    ({
        [dropdownThemes.whiteBordered]: css`
            border-radius: 4px;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
            background-color: var(--white);
            padding: 0.85rem 0.8rem;
        `,
        [dropdownThemes.dark]: css`
            padding: 0.375rem 0.875rem;
            font-size: 0.875rem;
            color: #fff !important;
            border-radius: 0.375rem;
            background-color: var(--light-black);
            > span {
                color: var(--white) !important;
                opacity: 1;
            }
            div {
                border-radius: 0.375rem;
            }
            > div {
                border-radius: 0.375rem;
                background-color: var(--light-black);
            }
            > ${ImgContainer} {
                width: 1.75rem !important;
            }
        `,
        [dropdownThemes.rounded]: css`
            border-radius: 1rem;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
            background-color: var(--white);
            padding: 0.4rem;
        `,
        [dropdownThemes.lightGray]: css`
            height: 2.5rem;
            padding: 0.375rem 0.875rem;
            border-radius: 0.375rem;
            font-size: 0.875rem;
            background-color: var(--lighter-gray);
            > span {
                color: var(--light-black) !important;
                opacity: 1;
            }
            div {
                border-radius: 0.375rem;
            }
            > div {
                background-color: var(--lighter-gray);
            }
            > ${ImgContainer} {
                width: 1.75rem !important;
            }
            ${SearchBarItem} {
                input {
                    height: 2.5rem;
                    padding: 0.375rem 0.875rem;
                    font-size: 0.875rem;
                    background-color: var(--lighter-gray);
                }
            }
            ${Name} {
                padding: 0 0.3rem;
            }
            ${ArrowIcon} {
                svg {
                    fill: #5c6977;
                }
            }
        `,
        [dropdownThemes.default]: css`
            border-bottom: 1px solid ${props => (props.error ? "#fd0f0f" : "#cbcbcb")};
            padding: 0.7rem 0rem;
        `
    }[style]);

const ImgContainer = styled.div`
    transform: ${props => (props.isContentOpenUp ? "rotate(180deg)" : "none")};
`;

const DropdownWrapper = styled.div`
    position: relative;
    ${props => getWrapperThemeCss(props.theme)};
    color: #212931;
    width: 100%;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    opacity: ${props => (props.invisible ? 0 : 1)};
    pointer-events: ${props => (props.invisible ? "none" : "all")};

    ${props => props.disabled && `opacity: 0.5; pointer-events: none;`}
    ${ImgContainer} {
        width: 10px;
        ${props => props.isOpen && "transform: rotate(180deg);"}
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    ${props => (props.isContentOpenUp ? "bottom: 0" : " top: 0")};
    left: 0;
    right: 0;
    z-index: 100;

    background-color: white;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled.div`
    display: flex;
    padding: 0.7rem 0.2rem;
    ${props => props.selected && `background-color: ${props.isDark ? "var(--black)" : "#eeeeee"};`}
    &:hover {
        background-color: ${props => (props.isDark ? "var(--black)" : "#eeeeee")};
    }
`;

const SearchBarItem = styled.div`
    input {
        color: #212931;
        width: 100%;
        border: none;
        outline: none;
        padding: 0.7rem 0.2rem;
        font-size: 1rem;
        box-sizing: border-box;
    }
`;

const Placeholder = styled.span`
    opacity: 0.2;
    align-content: center;
    color: var(--light-black);
`;

const Name = styled.div`
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
    ${props => props.withIcon && "margin: 0 0.5rem;"}
`;

const ErrorElement = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 0.8rem;
    color: #fd0f0f;
    ${EllipsisText}
`;

const ArrowIcon = styled.div`
    svg {
        fill: var(--white);
    }
`;

@withNamespaces("translation")
class Dropdown extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            isOpen: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: state.isOpen ? props.data.length !== 0 : state.isOpen
        };
    }

    toggleOpened(e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({isOpen: !this.state.isOpen, filter: ""}, () => {
            if (this.selectedElement) {
                const offset = ReactDOM.findDOMNode(this.selectedElement).offsetTop;
                this.contentScrollbar.scrollTop(offset);
            }
        });
    }

    onSelectItem(id) {
        this.props.onChange(id);
        this.toggleOpened();
    }

    renderSearchBar() {
        const {placeholder, withNoValueItem} = this.props;
        const {filter} = this.state;
        return (
            <SearchBarItem>
                <input
                    type="text"
                    placeholder={withNoValueItem ? "" : placeholder}
                    value={filter}
                    onChange={e => this.setState({filter: e.target.value})}
                    autoFocus
                />
            </SearchBarItem>
        );
    }

    render() {
        const {
            t,
            id,
            theme,
            data,
            current,
            withNullOption,
            invisible,
            title,
            titleStyle,
            placeholder,
            error,
            errorNumber,
            errorTranslationPrefix,
            forceSearchBar,
            disabled,
            withNoValueItem,
            placeholderStyle,
            isContentOpenUp,
            withDoubleArrow
        } = this.props;
        const {isOpen, filter} = this.state;

        const selectedItem = (current || withNullOption) && data.find(d => d.id === current);
        const showSearchBar = forceSearchBar || data.length > 20;

        const filteredData = data.filter(d => d.name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
        const isNoValueItemShown = withNoValueItem && placeholder.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        const errorPrefix = errorTranslationPrefix || "errors";

        return (
            <OutsideClickHandler onOutsideClick={() => isOpen && this.toggleOpened()}>
                {title && <DropdownTitle style={titleStyle}>{title}</DropdownTitle>}
                <DropdownWrapper
                    id={id}
                    theme={theme || dropdownThemes.default}
                    isOpen={isOpen}
                    onClick={!disabled ? this.toggleOpened.bind(this) : () => true}
                    invisible={invisible}
                    error={error}
                    disabled={disabled}
                    style={placeholderStyle}>
                    {selectedItem && selectedItem.icon}
                    {selectedItem ? (
                        <Name withIcon={selectedItem.icon}>{selectedItem.name}</Name>
                    ) : (
                        <Placeholder>{placeholder}&nbsp;</Placeholder>
                    )}
                    <ImgContainer isContentOpenUp={isContentOpenUp}>
                        {withDoubleArrow ? (
                            <ArrowIcon>
                                <DropdownDoubleArrowIcon />
                            </ArrowIcon>
                        ) : (
                            <img src={dropdownArrow} alt={"arrow"} />
                        )}
                    </ImgContainer>
                    {isOpen && (
                        <DropdownContent isContentOpenUp={isContentOpenUp}>
                            {showSearchBar && this.renderSearchBar()}
                            <Scrollbars scrollRef={ref => (this.contentScrollbar = ref)} autoHeight autoHeightMax={200}>
                                {isNoValueItemShown && (
                                    <DropdownItem
                                        id={id && `${id}-item-noValue`}
                                        selected={current === ""}
                                        ref={ref => (current === "" ? (this.selectedElement = ref) : null)}
                                        onClick={this.onSelectItem.bind(this, "")}>
                                        {placeholder}
                                    </DropdownItem>
                                )}
                                {filteredData.map((d, i) => {
                                    const selected = d.id === current;
                                    return (
                                        <DropdownItem
                                            id={id && `${id}-item-${i}`}
                                            onClick={this.onSelectItem.bind(this, d.id)}
                                            selected={selected}
                                            isDark={theme === dropdownThemes.dark}
                                            ref={ref => (selected ? (this.selectedElement = ref) : null)}
                                            key={i}>
                                            {d.icon}
                                            <Name withIcon={d.icon}>{d.name}</Name>
                                        </DropdownItem>
                                    );
                                })}
                            </Scrollbars>
                        </DropdownContent>
                    )}
                    {error && <ErrorElement>{t(`${errorPrefix}.${error}`, {count: errorNumber})}</ErrorElement>}
                </DropdownWrapper>
            </OutsideClickHandler>
        );
    }
}

export default Dropdown;
