import React, {Component} from "react";
import styled from "styled-components";
import RadioSwitchButton from "./RadioSwitchButton";

const RadioWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

class RadioSwitch extends Component {
    render() {
        const {onClick, data, style, buttonsStyle, idPrefix} = this.props;

        return (
            <RadioWrapper style={style}>
                {data.map(item => (
                    <RadioSwitchButton
                        id={idPrefix && `${idPrefix}-${item.id}-button`}
                        style={buttonsStyle}
                        key={item.id}
                        title={item.title}
                        selected={item.selected}
                        onClick={onClick.bind(null, item.id)}
                    />
                ))}
            </RadioWrapper>
        );
    }
}

export default RadioSwitch;
