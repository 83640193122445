import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import TextButton from "components/form/TextButton";
import Arrow from "components/common/icons/Arrow";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    ${props => props.isInvisible && "visibility: hidden;"};
    svg {
        padding: 0.1rem 0 0 0;
        width: 1rem;
        height: 1rem;
        fill: var(--main-theme-color);
        cursor: pointer;
        ${props => props.dir === "rtl" && "transform: scaleX(-1);"}
    }
`;

@withNamespaces("translation")
class BackTextButton extends Component {
    render() {
        const {style, titleStyle, onClick, isInvisible, title, i18n} = this.props;

        return (
            <Container dir={i18n.dir()} onClick={onClick} isInvisible={isInvisible}>
                <Arrow />
                <TextButton title={title} style={style} titleStyle={titleStyle} />
            </Container>
        );
    }
}

export default BackTextButton;
