import React, {Component} from "react";
import styled from "styled-components";
import Scrollbars from "components/common/Scrollbars";
import {scrollbarLineFix} from "components/common/styled/scrollbarFix";
import {withContextConsumer} from "utils/contexts";
import LocaleContext from "contexts/common/LocaleContext";
import Message from "./Message";
import WidgetMessage from "../chatwidget/Message";

const ContentContainer = styled.div`
    flex: 1;
    ${scrollbarLineFix}
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    min-height: 100%;
    padding: 0.8rem;
`;

const SCROLL_AVAILABLE_RANGE = 100;
const SCROLL_TOP_LIMIT_WITHOUT_UPDATE = 100;

@withContextConsumer(LocaleContext.Consumer)
class MessageList extends Component {
    constructor(props) {
        super(props);
        this.scrollOnNewMessage = true;
        this.messagesLoading = false;
        this.state = {
            messageCount: props.messages.length
        };
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const scrollRange = this.scrollbar.getScrollHeight() - this.scrollbar.getScrollTop() - this.scrollbar.getClientHeight();
        this.scrollOnNewMessage = scrollRange < SCROLL_AVAILABLE_RANGE;

        if (
            !this.props.allMessagesLoaded &&
            !this.messagesLoading &&
            this.scrollbar.getScrollTop() < SCROLL_TOP_LIMIT_WITHOUT_UPDATE
        ) {
            this.props.loadNextMessages();
            this.messagesLoading = true;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.scrollbar && !this.initScrolled) {
            this.initScrolled = true;
            this.scrollbar.scrollToBottom();
        }

        const {messageCount} = this.state;
        this.messagesLoading = false;
        if (messageCount !== this.props.messages.length) {
            setTimeout(() => {
                if (this.scrollOnNewMessage) {
                    this.scrollbar.scrollToBottom();
                    this.setState({messageCount: this.props.messages.length});
                }
            }, 0);
        }
    }

    handleFileDownload(file) {
        this.props.downloadFile(file.file, `${file.name}.${file.extension}`);
    }

    renderAuthorName(message) {
        const {users, user} = this.props;
        const author = users.find(({id}) => id === message.from);
        return author ? author : user;
    }

    renderMessage(message, index) {
        const {t, user, messages, group, shortDateAndTimeFormatter, timeFormatter, type, onMessageClick} = this.props;
        const {text, from, createdAt, additionalInfo, libraryFiles, attachedFiles} = message;
        const authorName = this.renderAuthorName(message);
        const files = [...libraryFiles, ...attachedFiles];
        const isOwnMessage = from === user.id;
        const creationTime = new Date(createdAt);

        if (type === "widget") {
            const isLastMessageInList = index === 0;
            const isFirstMessageInBlock = index === messages.length - 1 || from !== messages[index + 1].from;
            const isLastMessageInBlock = index !== 0 && from === messages[index - 1].from;
            return (
                <WidgetMessage
                    t={t}
                    key={index}
                    author={authorName}
                    text={text}
                    additionalInfo={additionalInfo}
                    files={files}
                    onFileClick={this.handleFileDownload}
                    createdAt={timeFormatter.format(creationTime)}
                    isGroup={group}
                    isLastMessageInList={isLastMessageInList}
                    isFirstMessageInBlock={isFirstMessageInBlock}
                    isLastMessageInBlock={isLastMessageInBlock}
                    isOwnMessage={isOwnMessage}
                    onMessageClick={onMessageClick}
                />
            );
        }

        return (
            <Message
                t={t}
                key={index}
                author={authorName}
                text={text}
                additionalInfo={additionalInfo}
                isOwnMessage={isOwnMessage}
                files={files}
                onFileClick={this.handleFileDownload}
                createdAt={shortDateAndTimeFormatter.format(creationTime)}
            />
        );
    }

    render() {
        const {messages} = this.props;
        return (
            <ContentContainer>
                <Scrollbars scrollRef={ref => (this.scrollbar = ref)} onScroll={this.handleScroll.bind(this)}>
                    <MessageContainer>{messages.map((m, index) => this.renderMessage(m, index))}</MessageContainer>
                </Scrollbars>
            </ContentContainer>
        );
    }
}

export default MessageList;
