// @ts-nocheck
import React, {useState, useEffect, useRef} from "react";
// import Button from "@mui/material/Button";
import Button from "components/form/Button.js";

import {saveAs} from "file-saver";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import assignmentFormats from "consts/assignmentFormats";
import "video.js/dist/video-js.css";

// Required imports when recording audio-only using the videojs-wavesurfer plugin
import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";

WaveSurfer.microphone = MicrophonePlugin;

// Register videojs-wavesurfer plugin
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import "videojs-wavesurfer/dist/videojs.wavesurfer.js";

// register videojs-record plugin with this import
import "videojs-record/dist/css/videojs.record.css";
import "videojs-record/dist/videojs.record.js";

import styled from "styled-components";

import {dataURItoBlob} from "utils/file";

const ControlContainer = styled.div`
    display: flex;
`;

const PLUGINS = {
    Image: {
        record: {
            debug: true,
            imageOutputType: "dataURL",
            imageOutputFormat: "image/png",
            imageOutputQuality: 1,
            image: {
                // image media constraints: set resolution of camera
                facingMode: "user"
            }
        }
    },
    Audio: {
        wavesurfer: {
            backend: "WebAudio",
            waveColor: "#3d7cff",
            progressColor: "black",
            displayMilliseconds: true,
            debug: true,
            cursorWidth: 1,
            hideScrollbar: true,
            barWidth: 2,
            barHeight: 1,
            plugins: [
                // enable microphone plugin
                WaveSurfer.microphone.create({
                    bufferSize: 4096,
                    numberOfInputChannels: 1,
                    numberOfOutputChannels: 1,
                    constraints: {
                        video: false,
                        audio: true
                    }
                })
            ]
        },
        record: {
            audio: true,
            video: false,
            maxLength: 1 * 60 * 20, // maximum 20 hour recording
            displayMilliseconds: true,
            debug: true,
            audioMimeType: "audio/webm"
        }
    },
    Video: {
        record: {
            audio: true,
            video: {
                facingMode: "user"
            },
            maxLength: 1 * 60 * 20, // maximum 20 hour recording
            debug: true,
            videoMimeType: "video/webm;codecs=vp8,opus"
        }
    }
};

// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

const convertIntoSeconds = duration => {
    return 1 * 60 * duration;
};

let player = null;

const MediaRecorder = props => {
    const size = useWindowSize();
    const {format, handleMedia, t, mediaDuration} = props;

    let refElement = useRef(null);
    // options for device
    const [devices, setDevices] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(0);

    // option for UI
    const [isRecording, setIsRecording] = useState(false);
    const [isRecordingPaused, setIsRecordingPaused] = useState(false);
    const [isPlayingRecording, setIsPlayingRecording] = useState(false);
    const [isRecorded, setIsRecorded] = useState(false);
    const [isImageTaken, setIsImageTaken] = useState(false);

    // option for video player
    const [options, setOptions] = useState({
        controls: false,
        fluid: false,
        bigPlayButton: false,
        controlBar: {
            volumePanel: false,
            fullscreenToggle: false
        },

        width: size.width > 400 ? 520 : 400,
        height: 480,
        plugins: PLUGINS[format]
    });

    const isAudio = format === assignmentFormats.Audio;
    const isImage = format === assignmentFormats.Image;

    useEffect(() => {
        const videoJsOptions = {...options};

        if (format === assignmentFormats.Video || isAudio) {
            videoJsOptions.plugins.record.maxLength = mediaDuration ? convertIntoSeconds(mediaDuration) : convertIntoSeconds(10);
        }

        player = videojs(refElement, videoJsOptions, () => {
            // print version information at startup
            var msg =
                "Using video.js " +
                videojs.VERSION +
                " with videojs-record " +
                videojs.getPluginVersion("record") +
                " and recordrtc " +
                RecordRTC.version +
                " wavesurfer.js " +
                WaveSurfer.VERSION;
            videojs.log(msg);

            // auto start the audio/video device
            if (format !== assignmentFormats["Audio"]) {
                player.record().getDevice();
            }
        });

        player.on("deviceReady", () => {
            player.record().enumerateDevices();
        });

        player.on("enumerateReady", () => {
            const devices = player.record().devices;
            const cameraDevices = devices.filter(d => d.kind === "videoinput");
            setDevices(cameraDevices);
        });

        player.on("enumerateError", () => {
            console.log("Devices ", player.enumerateErrorCode);
        });

        // // snapshot is available
        player.on("finishRecord", async () => {
            if (format === assignmentFormats.Video) {
                player.record().saveAs({video: "my-video-file-name.mp4"});
                handleMedia(player.recordedData);
            }

            if (format === assignmentFormats.Audio) {
                player.record().saveAs({audio: "my-audio-file-name.mp3"});
                handleMedia(player.recordedData);
            }

            if (format === assignmentFormats.Image) {
                const blobImage = new Blob([dataURItoBlob(player.recordedData)], {type: "image/png"});
                saveAs(blobImage, "my-image-file-name.png");
                handleMedia(blobImage);
            }

            setIsRecorded(true);
        });

        return () => {
            player.record().destroy();
        };
    }, []);

    const resetStates = () => {
        setIsRecording(false);
        setIsRecordingPaused(false);
        setIsPlayingRecording(false);
        setIsRecorded(false);
        setIsImageTaken(false);
    };

    const takePicture = () => {
        player.record().start();
        setIsImageTaken(true);
    };

    const startRecording = () => {
        if (devices && player.record()?.start) {
            player.record().start();
            setIsRecording(true);
        } else {
            player.record().getDevice();
        }
    };

    const stopRecording = () => {
        player.record().stop();
        setIsRecording(false);
    };
    const resumeRecording = () => {
        player.record().resume();
        setIsRecording(true);
        setIsRecordingPaused(false);
    };

    const pauseRecording = () => {
        player.record().pause();
        setIsRecording(false);
        setIsRecordingPaused(true);
    };

    // play recorded
    const playRecording = () => {
        if (isAudio) {
            player.wavesurfer().play();
        } else {
            player.play();
        }
        setIsPlayingRecording(true);
    };

    // pause
    const pausePlaying = () => {
        if (isAudio) {
            player.wavesurfer().pause();
        } else {
            player.pause();
        }
        setIsPlayingRecording(false);
    };

    const retry = () => {
        if (isImage) {
            setIsImageTaken(false);
        }
        player.record().getDevice();
    };

    const handleReset = () => {
        player.record().reset();
        retry();
        resetStates();
    };

    const handleRecording = () => {
        if (!isRecordingPaused && !isRecorded) {
            startRecording();
        } else {
            resumeRecording();
        }
    };

    const handleCameraChange = event => {
        try {
            const deviceId = event.target.value;

            if (format === assignmentFormats["Video"]) {
                // change video input device
                player.record().setVideoInput(deviceId);
            } else if (format === assignmentFormats["Image"]) {
                player.record().recordImage = {deviceId: {exact: deviceId}};
                player.record().stopDevice();
                player.record().getDevice();
            }

            setSelectedDevice(deviceId);
        } catch (error) {
            console.error(error);
        }
    };

    const renderRetryButton = () => {
        return (
            <Button onClick={handleReset} variant="contained" width="8rem">
                {t(`lecture.digest.assignment.retry`)}
            </Button>
        );
    };

    const renderControl = () => {
        let renderCameraOption = null;

        if (devices) {
            renderCameraOption = (
                <>
                    <label htmlFor="camera">{t(`lecture.digest.assignment.selectCamera`)} </label>
                    <select value={selectedDevice} onChange={handleCameraChange} id={"camera"}>
                        {devices?.map(device => {
                            return (
                                <option key={device.deviceId} value={device.deviceId}>
                                    {device.label}
                                </option>
                            );
                        })}
                    </select>
                </>
            );
        }

        if (format === assignmentFormats.Image) {
            return (
                <>
                    {renderCameraOption}

                    <ControlContainer>
                        {isImageTaken ? (
                            <Button onClick={retry} width="8rem">
                                {t(`lecture.digest.assignment.retry`)}
                            </Button>
                        ) : (
                            <Button onClick={takePicture} variant="contained" width="8rem">
                                {t(`lecture.digest.assignment.takePicture`)}
                            </Button>
                        )}
                    </ControlContainer>
                </>
            );
        }

        if (format === assignmentFormats.Audio) {
            return (
                <ControlContainer>
                    {!devices ? (
                        <Button onClick={startRecording} variant="contained" width="8rem">
                            {t(`lecture.digest.assignment.enableRecording`)}
                        </Button>
                    ) : isRecorded ? (
                        isPlayingRecording ? (
                            <>
                                <Button onClick={pausePlaying} variant="contained" width="8rem">
                                    {t(`lecture.digest.assignment.pause`)}
                                </Button>
                                {renderRetryButton()}
                            </>
                        ) : (
                            <>
                                <Button onClick={playRecording} variant="contained" width="8rem">
                                    {t(`lecture.digest.assignment.play`)}
                                </Button>
                                {renderRetryButton()}
                            </>
                        )
                    ) : isRecording ? (
                        <>
                            <Button onClick={stopRecording} variant="contained" width="8rem">
                                {t(`lecture.digest.assignment.stopRecording`)}
                            </Button>
                        </>
                    ) : (
                        <Button onClick={startRecording} variant="contained" width="8rem">
                            {isRecordingPaused
                                ? t(`lecture.digest.assignment.resumeRecording`)
                                : t(`lecture.digest.assignment.startRecording`)}
                        </Button>
                    )}
                </ControlContainer>
            );
        }

        if (format === assignmentFormats.Video) {
            return (
                <>
                    {renderCameraOption}
                    <ControlContainer>
                        {isRecorded ? (
                            isPlayingRecording ? (
                                <>
                                    <Button onClick={pausePlaying} variant="contained" width="8rem">
                                        {t(`lecture.digest.assignment.pause`)}
                                    </Button>
                                    {renderRetryButton()}
                                </>
                            ) : (
                                <>
                                    <Button onClick={playRecording} variant="contained" width="8rem">
                                        {t(`lecture.digest.assignment.play`)}
                                    </Button>
                                    {renderRetryButton()}
                                </>
                            )
                        ) : isRecording ? (
                            <>
                                <Button onClick={pauseRecording} variant="contained" width="8rem" style={{marginRight: 8}}>
                                    {t(`lecture.digest.assignment.pauseRecording`)}
                                </Button>

                                <Button onClick={stopRecording} variant="contained" width="8rem">
                                    {t(`lecture.digest.assignment.stopRecording`)}
                                </Button>
                            </>
                        ) : (
                            <Button onClick={handleRecording} variant="contained" width="8rem">
                                {isRecordingPaused
                                    ? t(`lecture.digest.assignment.resumeRecording`)
                                    : t(`lecture.digest.assignment.startRecording`)}
                            </Button>
                        )}
                    </ControlContainer>
                </>
            );
        }

        return null;
    };

    return (
        <div>
            {format === "Audio" ? (
                <audio id="myAudio" ref={node => (refElement = node)} class="video-js vjs-default-skin" />
            ) : (
                <video id="myVideo" ref={node => (refElement = node)} className="video-js vjs-default-skin" />
            )}
            {renderControl()}
        </div>
    );
};

export default MediaRecorder;
