// calculate and set css vh variable to fix vh in safari
export default () => {
    function fixVH() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    fixVH();
    window.addEventListener("resize", () => fixVH());
};
