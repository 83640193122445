import React from "react";
import Circle from "./Circle";

export default props => {
    const {active, ...rest} = props;
    if (active) {
        return (
            <div>
                <Circle />
                <svg viewBox="0 0 20 20" {...rest}>
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill="#FFF"
                            d="M10 20c1.265 0 2.3-1.035 2.3-2.3H7.7c0 1.265 1.035 2.3 2.3 2.3zm6.5-7V9.5c0-3.1-2.1-5.6-5-6.3v-.7c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v.7c-2.9.7-5 3.2-5 6.3V13l-1.293 1.293A2.414 2.414 0 0 0 1.5 16h17c0-.64-.254-1.254-.707-1.707L16.5 13z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
    return (
        <div>
            <svg viewBox="0 0 20 20" {...rest}>
                <path
                    fill="#FFF"
                    fillRule="evenodd"
                    d="M10 20c1.265 0 2.3-1.035 2.3-2.3H7.7c0 1.265 1.035 2.3 2.3 2.3zm6.5-7V9.5c0-3.1-2.1-5.6-5-6.3v-.7c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v.7c-2.9.7-5 3.2-5 6.3V13l-1.293 1.293A2.414 2.414 0 0 0 1.5 16h17c0-.64-.254-1.254-.707-1.707L16.5 13z"
                />
            </svg>
        </div>
    );
};
