import React, {Component} from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";
import Rating from "components/common/Rating";
import Paginator from "components/common/Paginator";

const ReviewsContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ReviewWrapper = styled.div`
    flex: 1;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid #ebebeb;
    ${mediaQuery.phone`
         width: 100%;      
         padding: 0.9rem 1rem;
    `}
`;

const Review = styled.div`
    display: flex;
    flex-flow: wrap;
    flex: 1;
`;

const ReviewerName = styled.div`
    font-weight: 600;
    width: 50%;
    margin: 0.7rem 0;
    ${mediaQuery.phone`
         width: 100%;      
         margin: 0;
    `}
`;

const RatingContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 50%;
    margin: 0.7rem 0;
    ${mediaQuery.phone`
        width: 100%;  
         margin: 0.5rem 0;
        justify-content: flex-start;
    `}
`;

const ReviewText = styled.div`
    font-size: 0.8rem;
    max-width: 80%;
    ${mediaQuery.phone`
         max-width: unset;    
         width: 100%;    
    `}
`;

const REVIEWS_PER_PAGE = 10;

class ReviewsList extends Component {
    constructor(props) {
        super(props);
        this.state = {page: 1};
    }

    loadReviews(page) {
        const {lectureId, getLectureReviews} = this.props;
        getLectureReviews({lectureId, offset: REVIEWS_PER_PAGE * (page - 1), limit: REVIEWS_PER_PAGE});
    }

    onPageChange(page) {
        this.loadReviews(page);
        this.setState({page});
    }

    render() {
        const {reviews, reviewsCount} = this.props;
        const {page} = this.state;
        return (
            <div>
                <ReviewsContainer>
                    {reviews &&
                        reviews.map((review, index) => {
                            return (
                                <ReviewWrapper key={index}>
                                    <Review>
                                        <ReviewerName>{`${review.author.firstName} ${review.author.lastName}`}</ReviewerName>
                                        <RatingContainer>
                                            <Rating ratedItem={{rating: review.rating}} />
                                        </RatingContainer>
                                        <ReviewText>{review.text}</ReviewText>
                                    </Review>
                                </ReviewWrapper>
                            );
                        })}
                </ReviewsContainer>
                <Paginator
                    pageSize={REVIEWS_PER_PAGE}
                    current={page}
                    total={reviewsCount}
                    onChange={this.onPageChange.bind(this)}
                />
            </div>
        );
    }
}

export default ReviewsList;
