import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import LibraryModal from "./LibraryModal";
import LibraryView from "../common/LibraryView";
import LibraryContext from "contexts/Library";
import CurrentUserContext from "contexts/CurrentUser";
import PageLoadingSpinner from "components/common/PageLoadingSpinner";

@withNamespaces("translation")
@withContextProvider(LibraryContext.Provider)
@withContextConsumer(LibraryContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
class LibraryItemsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isIndicatorOpen: false,
            errors: {}
        };

        this.attachLibraryContentItems = this.attachLibraryContentItems.bind(this);
    }

    attachLibraryContentItems() {
        let items = [];

        const {selectedItems, originalItems, onConfirm} = this.props;

        for (let i = 0; i < selectedItems.length; i++) {
            const id = selectedItems[i];

            for (let j = 0; j < originalItems.length; j++) {
                const element = originalItems[j];

                if (element.id === id) {
                    items.push(element);
                    break;
                }
            }
        }
        onConfirm(items);
    }

    render() {
        const {
            t,
            isOpen,
            meta,
            gotFolders,
            gotExtraFolders,
            currentUser,
            isPhoneResolution,
            onRequestClose,
            addedItems,
            hideAddButton
        } = this.props;

        const {isIndicatorOpen} = this.state;

        if (!isOpen) {
            return null;
        }

        if (!gotFolders || !gotExtraFolders) {
            return <PageLoadingSpinner />;
        }

        const {totalLibrarySize, availableLibrarySize} = meta;
        const percentage = Math.round((100 * availableLibrarySize) / totalLibrarySize);

        return (
            <LibraryModal width={"60rem"} onRequestClose={onRequestClose} title={"My Library"} isOpen={isOpen}>
                <LibraryView
                    currentUser={currentUser}
                    isIndicatorOpen={!isPhoneResolution || isIndicatorOpen}
                    total={totalLibrarySize || 0}
                    totalAvailable={availableLibrarySize || 0}
                    percentage={percentage}
                    viewOnly
                    inSharingModal
                    filterType={"all"}
                    inCourseAttachment
                    addedItems={addedItems}
                    onAttach={this.attachLibraryContentItems}
                    hideAddButton={hideAddButton}
                />
            </LibraryModal>
        );
    }
}

export default LibraryItemsModal;
