import React from "react";
import styled from "styled-components";
import {compose} from "recompose";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import AssignmentSubmissionContext from "contexts/assignment/AssignmentSubmission";

import {withContextConsumer, withContextProvider} from "utils/contexts";
import EnrollmentsContext from "contexts/Enrollments";
import LecturesDigestContext from "contexts/LecturesDigest";

import userTypes from "consts/userTypes";
import StudentAssessmentList from "./assessment/StudentAssessmentList";
import AdminAssessmentList from "./assessment/AdminAssessmentList";

const {student, child, principal, teacher} = userTypes;

const AssessmentContainer = styled.div`
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const NoAssessmentMessage = styled.div`
    padding: 1rem;
    color: #8b8b8b;
`;

function AssessmentList(props) {
    const {
        assessments,
        isLectureOwner,
        // setOpenedAssignment,
        currentUser,
        // enterAssignment,
        // showPersonalResult,
        lecture,
        getLectureData,
        usersAttendance,
        history,
        updateAssignments,
        t,
        updateAssessment,
        updateSubmission,
        deleteSubmission,
        replaceSubmissionFromCache
    } = props;

    const isStudentOrChild = currentUser.role == student || currentUser.role == child;
    const isTeacherOrPrincipal = currentUser.role == teacher || currentUser.role === principal;

    if (isStudentOrChild) {
        return (
            <AssessmentContainer>
                <ListContainer>
                    {assessments && assessments.length > 0 ? (
                        assessments.map((assessment, index) => {
                            const submittedAssessment = assessment?.results.find(
                                submission => submission.student === currentUser.id && submission.status !== "deleted"
                            );

                            return (
                                <StudentAssessmentList
                                    key={assessment.id}
                                    submittedAssessment={submittedAssessment}
                                    assessment={assessment}
                                    // setOpenedAssignment={setOpenedAssignment}
                                    currentUser={currentUser}
                                    // enterAssignment={enterAssignment}
                                    // showPersonalResult={showPersonalResult}
                                    lecture={lecture}
                                    getLectureData={getLectureData}
                                    usersAttendance={usersAttendance}
                                    history={history}
                                    index={index}
                                    updateSubmission={updateSubmission}
                                    deleteSubmission={deleteSubmission}
                                    replaceSubmissionFromCache={replaceSubmissionFromCache}
                                />
                            );
                        })
                    ) : (
                        <NoAssessmentMessage>{t("lecture.digest.noAssessmentsAssigned")}</NoAssessmentMessage>
                    )}
                </ListContainer>
            </AssessmentContainer>
        );
    }

    // Teacher or Principal
    if (isTeacherOrPrincipal) {
        return (
            <AssessmentContainer>
                <ListContainer>
                    <AdminAssessmentList
                        assessments={assessments}
                        updateAssignments={updateAssignments}
                        isLectureOwner={isLectureOwner}
                        // setOpenedAssignment={setOpenedAssignment}
                        currentUser={currentUser}
                        // showPersonalResult={showPersonalResult}
                        lecture={lecture}
                        getLectureData={getLectureData}
                        usersAttendance={usersAttendance}
                        history={history}
                        updateAssessment={updateAssessment}
                    />
                </ListContainer>
            </AssessmentContainer>
        );
    }

    return null;
}

export default compose(
    withNamespaces("translation"),
    withContextConsumer(EnrollmentsContext.Consumer),
    withContextConsumer(LecturesDigestContext.Consumer),
    withContextProvider(AssignmentSubmissionContext.Provider)
)(AssessmentList);
