import React from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";

const VersionContainer = styled.div`
    position: absolute;
    pointer-events: none;
    font-size: 0.8rem;
    padding: 0.1rem 0.4rem;
    left: 1rem;
    bottom: 1.5rem;
    ${mediaQuery.phone`
        left: 0.6rem;
        bottom: 0rem;
    `}
`;

export default () => {
    if (!process.env.TABSERA_WITH_BUILD_INFO) {
        return null;
    }
    return (
        <VersionContainer>
            {process.env.TABSERA_VERSION}-{process.env.TABSERA_BUILD_NUMBER}
        </VersionContainer>
    );
};
