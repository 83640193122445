import React, {Component} from "react";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import ChatWidgetContent from "components/chatwidget/ChatWidget";
import CurrentUserContext from "contexts/CurrentUser";
import WindowRectContext from "contexts/WindowRect";
import ChatContext from "contexts/chat/Chat";

@withContextProvider(WindowRectContext.Provider)
@withContextConsumer(CurrentUserContext.Consumer)
@withContextConsumer(ChatContext.Consumer)
class ChatWidget extends Component {
    render() {
        const {currentUser} = this.props;
        return currentUser ? <ChatWidgetContent {...this.props} /> : null;
    }
}

export default ChatWidget;
