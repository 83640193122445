import React from "react";

export default props => (
    <svg viewBox="0 0 102 102" {...props}>
        <g fill="none" fillRule="nonzero">
            <path
                fill="#BCC3CA"
                d="M51.95 20.757L36.875.405A1 1 0 0 0 36.072 0H4a4 4 0 0 0-4 4v94a4 4 0 0 0 4 4h94a4 4 0 0 0 4-4V24.757a4 4 0 0 0-4-4H51.95z"
            />
            <path
                fill="#D5DADF"
                d="M71.95 40.757L36.875.345A1 1 0 0 0 36.119 0H4a4 4 0 0 0-4 4v94a4 4 0 0 0 4 4h94a4 4 0 0 0 4-4V40.757H71.95z"
            />
        </g>
    </svg>
);
