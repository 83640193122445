import React, {PureComponent} from "react";
import styled from "styled-components";
import formatChatTitle from "utils/formatChatTitle";
import Avatar from "../common/Avatar";
import File from "../library/files/FilesGrid/File";
import {Link} from "react-router-dom";

const Container = styled.div`
    display: flex;
    margin: 1.25rem;
`;

const MessageAvatar = styled(Avatar)`
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const MessageDateAndOwner = styled.div`
    font-family: Almarai, OpenSans, sans-serif;
    font-size: 0.625rem;
    margin-bottom: 0.375rem;
    color: #5d5d5d;
`;

const Text = styled.div`
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.8px;
    color: #1e1e1e;
    word-break: break-word;
`;

const FilesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem 0 1rem;
`;

const AdditionalInfoContainer = styled.span`
    font-size: 0.8rem;
    border-radius: 0.5rem;
    margin-bottom: 0.25rem;
    padding: 0.5rem;
    color: #5c6977;
    background-color: #ebecee;
`;

class Message extends PureComponent {
    renderAdditionalInfo() {
        const {t, additionalInfo} = this.props;
        if (!additionalInfo) return null;
        return (
            <AdditionalInfoContainer>
                {`${t("chatWidget.messageAdditionalInfo.questionAbout")} `}
                <Link to={`/content/${additionalInfo.id}`}>{t(`chatWidget.messageAdditionalInfo.${additionalInfo.type}`)}</Link>
            </AdditionalInfoContainer>
        );
    }

    render() {
        const {author, text, createdAt, files, onFileClick, isOwnMessage, t} = this.props;
        const isFilesExist = files && files.length !== 0;
        const owner = isOwnMessage ? t("chatsList.message.you") : formatChatTitle(author, t);
        return (
            <Container>
                <div>
                    <MessageAvatar src={author.userPic} />
                </div>
                <Content>
                    <MessageDateAndOwner>{`${owner} ${t("chatsList.message.at")} ${createdAt}`}</MessageDateAndOwner>
                    {this.renderAdditionalInfo()}
                    <Text>{text}</Text>
                    {isFilesExist && (
                        <FilesContainer>
                            {files.map(file => (
                                <File key={file?.id} item={file} selectItem={() => onFileClick(file)} />
                            ))}
                        </FilesContainer>
                    )}
                </Content>
            </Container>
        );
    }
}

export default Message;
