import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import styled from "styled-components";
import ReactModal from "react-modal";
import screenfull from "screenfull";
import WindowRectContext from "contexts/WindowRect";
import {withContextConsumer} from "utils/contexts";
import Scrollbars from "components/common/Scrollbars";
import TawkChat from "components/TawkChat";
import {scrollbarLineFix} from "../styled/scrollbarFix";
import FullScreenIcon from "../icons/FullScreenIcon";
import CloseIcon from "../icons/CloseModal";

const Waafipay = "/images/waafipay3.jpg";
const DEFAULT_FULL_SCREEN_AUTO_HEIGHT_BOTTOM_MARGIN = "49px";

const toggleFullScreen = () => {
    try {
        return screenfull.enabled && screenfull.toggle();
    } catch (e) {}
};

const ModalHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2.5rem;
    font-weight: 600;
    margin: 0.5rem 0;
    font-size: 1.5rem;
    flex-shrink: 0;
`;

const ModalFooter = styled.div`
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2.5rem;

    margin: 0.5rem 0;
    flex: 0.2;
    flex-shrink: 0;
`;

const ModalContentWrapper = styled.div`
    ${props => (props.withoutPadding ? "padding: 0" : "padding: 1rem 1rem 1px")};
    ${props => props.fullHeight && "flex: 1;"};
    ${props => props.isBrowserFullScreenMode && "padding:0"};
`;

const ModalHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 1rem;
`;

const ModalCloseIcon = styled.div`
    cursor: pointer;
    padding: 1rem;
    svg {
        width: 1rem;
        height: 1rem;
    }
`;

const ModalFullScreenIcon = styled.div`
    cursor: pointer;
    padding: 1rem;
    svg {
        width: 1.25rem;
        height: 1.25rem;
    }
`;

const ScrollbarContainer = styled.div`
    ${scrollbarLineFix};
    overflow: hidden;
    ${p => p.customMargin && `margin: ${p.customMargin};`}
`;

const ControlBlock = styled.div`
    height: ${p => (p.withoutScroll ? "0px" : "1rem")};
`;

const Title = styled.div``;
const Img = styled.img`
    width: 150px;
    height: 48px;
`;

@withNamespaces("translation")
@withContextConsumer(WindowRectContext.Consumer)
class Modal extends Component {
    componentDidMount() {
        try {
            screenfull.on("change", () => {
                if (screenfull.isFullscreen) {
                    TawkChat.hide();
                } else {
                    TawkChat.show();
                }
            });
        } catch (e) {}
    }

    componentWillUnmount() {
        try {
            screenfull.off("change", () => {});
        } catch (e) {}
    }

    getModalHeader() {
        const {
            i18n,
            title,
            headerStyle,
            titleStyle,
            closeIconStyle,
            closeIcon,
            isFullScreen,
            onRequestClose,
            header,
            withoutHeader,
            currentStage
        } = this.props;

        const isBrowserFullScreenMode = isFullScreen && screenfull.element;
        if (isBrowserFullScreenMode || withoutHeader) return null;

        return header ? (
            <ModalHeader style={headerStyle}>{header}</ModalHeader>
        ) : (
            <ModalHeader style={headerStyle}>
                <ModalHeaderContainer>
                    {isFullScreen && (
                        <ModalFullScreenIcon onClick={toggleFullScreen} dir={i18n.dir()}>
                            <FullScreenIcon />
                        </ModalFullScreenIcon>
                    )}
                    {/* // TODO - Quick change showing logo on waafi pay. */}
                    {currentStage === "telr" ? (
                        <Img src={Waafipay} alt={"WaafiPay"} />
                    ) : (
                        <Title style={titleStyle}>{title}</Title>
                    )}
                    {/* <Title style={titleStyle}>{title}</Title> */}
                    <ModalCloseIcon onClick={onRequestClose} dir={i18n.dir()} style={closeIconStyle}>
                        {onRequestClose && closeIcon ? closeIcon : <CloseIcon />}
                    </ModalCloseIcon>
                </ModalHeaderContainer>
            </ModalHeader>
        );
    }

    getFooter() {
        const {footer, footerStyle} = this.props;
        return footer ? <ModalFooter style={footerStyle}>{footer}</ModalFooter> : null;
    }

    getContent() {
        const {
            isPhoneResolution,
            isFullScreen,
            fullHeightContent,
            contentWrapperStyle,
            customMargin,
            onScroll,
            onScrollStart,
            onScrollStop,
            footer,
            factorHeightContentWithFooter,
            customFullScreenAutoHeightBottomMargin,
            withoutPadding,
            withoutScroll
        } = this.props;

        const modalFullScreen = isFullScreen || isPhoneResolution;
        const isBrowserFullScreenMode = isFullScreen && screenfull.element;

        if (fullHeightContent) {
            return (
                <ModalContentWrapper style={contentWrapperStyle} withoutPadding={withoutPadding} fullHeight>
                    {this.props.children}
                </ModalContentWrapper>
            );
        }
        const autoHeightBottomMargin = customFullScreenAutoHeightBottomMargin || DEFAULT_FULL_SCREEN_AUTO_HEIGHT_BOTTOM_MARGIN;
        const autoHeightMax = modalFullScreen
            ? `calc(var(--vh, 1vh) * 100 - ${autoHeightBottomMargin})`
            : `calc(var(--vh, 1vh) * ${footer ? factorHeightContentWithFooter || 63 : 80})`;
        return (
            <ScrollbarContainer customMargin={customMargin}>
                <Scrollbars
                    onScroll={onScroll}
                    onScrollStart={onScrollStart}
                    onScrollStop={onScrollStop}
                    autoHeightMax={autoHeightMax}
                    autoHeight>
                    <ModalContentWrapper isBrowserFullScreenMode={isBrowserFullScreenMode} style={contentWrapperStyle}>
                        {this.props.children}
                    </ModalContentWrapper>
                    <ControlBlock withoutScroll={withoutScroll} />
                </Scrollbars>
            </ScrollbarContainer>
        );
    }

    render() {
        const {
            i18n,
            isOpen,
            onRequestClose,
            style,
            backgroundColor,
            borderRadius,
            isPhoneResolution,
            isFullScreen,
            shouldCloseOnOverlayClick,
            width
        } = this.props;

        const modalFullScreen = isFullScreen || isPhoneResolution;

        const overlayStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "1000",
            direction: i18n.dir()
        };
        const contentStyle = Object.assign(
            {
                position: "static",
                display: "flex",
                flexFlow: "column",

                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "auto",

                width: modalFullScreen ? "100%" : width || "30rem",
                height: modalFullScreen ? "calc(var(--vh, 1vh) * 100)" : "auto",
                maxWidth: "100%",
                maxHeight: modalFullScreen ? "calc(var(--vh, 1vh) * 100)" : "calc(var(--vh, 1vh) * 90)",

                border: "none",
                borderRadius: modalFullScreen ? "0" : borderRadius || "15px",
                padding: "0",
                backgroundColor: backgroundColor || "var(--white)",

                overflowX: "hidden"
            },
            modalFullScreen ? {} : style || {}
        );

        return (
            <ReactModal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={{overlay: overlayStyle, content: contentStyle}}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick !== undefined ? shouldCloseOnOverlayClick : true}>
                {this.getModalHeader()}
                {this.getContent()}
                {this.getFooter()}
            </ReactModal>
        );
    }
}

export default Modal;
