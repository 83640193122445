import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import RadioSwitch from "components/common/radioSwitch/RadioSwitch";

const constainerStyle = {
    padding: "0",
    flexDirection: "column",
    margin: "0 -0.5rem"
};

const buttonsStyle = {
    margin: "0.5rem 0"
};

@withNamespaces("translation")
class PaymentMethodChoice extends Component {
    render() {
        const {t, onSelectPaymentMethod, availablePaymentMethods, paymentMethod} = this.props;

        const radioSwitchData = availablePaymentMethods.map(method => ({
            id: method,
            title: t(`wallet.payment.${method}`),
            selected: method === paymentMethod
        }));

        return (
            <RadioSwitch
                buttonsStyle={buttonsStyle}
                style={constainerStyle}
                data={radioSwitchData}
                onClick={onSelectPaymentMethod}
            />
        );
    }
}

export default PaymentMethodChoice;
