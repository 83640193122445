import {css} from "styled-components";

export default (horizMargin, marginTop, marginBottom) =>
    css`
        > div:first-child {
            ${props => (props.dir === "rtl" ? "margin-left:" : "margin-right:")}${horizMargin}rem;
            ${marginTop && `margin-bottom:${marginTop}rem`};
            ${marginBottom && `margin-bottom:${marginBottom}rem`};
        }
        > div:last-child {
            ${props => (props.dir === "rtl" ? "margin-right:" : "margin-left:")}${horizMargin}rem;
            ${marginTop && `margin-bottom:${marginTop}rem`};
            ${marginBottom && `margin-bottom:${marginBottom}rem`};
        }
        > div:not(:first-child):not(:last-child) {
            margin-right: ${horizMargin}rem;
            margin-left: ${horizMargin}rem;
            ${marginTop && `margin-bottom:${marginTop}rem`};
            ${marginBottom && `margin-bottom:${marginBottom}rem`};
        }
    `;
