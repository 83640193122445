import React, {Component} from "react";
import styled from "styled-components";
const OptionsContainer = styled.div`
    position: relative;
`;

const OptionsPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    visibility: ${props => (props.visible ? "visible" : "hidden")};
    opacity: ${props => (props.visible ? "1" : "0")};
    padding: 1.25rem 1rem;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    position: absolute;
    right: -1rem;
    top: 1.2rem;
    z-index: 1;
`;

const OptionsPanelButton = styled.div`
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.875rem;
    color: var(--red);
    &:first-child {
        margin-bottom: 1rem;
    }
`;

class ChatOptionsPanel extends Component {
    render() {
        const {t, handlerClearMessages, handlerBlock, handlerUnblock, blocked, isOpen} = this.props;
        const blockOptionTitle = blocked ? t("chatsList.unblockUser") : t("chatsList.blockUser");
        const blockOptionFunc = blocked ? handlerUnblock : handlerBlock;
        return (
            <OptionsContainer>
                <OptionsPanel visible={isOpen}>
                    <OptionsPanelButton onClick={handlerClearMessages}>{t("chatsList.clearHistory")}</OptionsPanelButton>
                    <OptionsPanelButton onClick={blockOptionFunc}>{blockOptionTitle}</OptionsPanelButton>
                </OptionsPanel>
            </OptionsContainer>
        );
    }
}

export default ChatOptionsPanel;
