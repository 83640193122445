import React from "react";
import styled from "styled-components";
import {compose} from "recompose";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import TextButton from "components/form/TextButton";
import {AudioFile, VideoFile, InsertPhoto, InsertDriveFile, PictureAsPdf, YouTube} from "@mui/icons-material";
import {Chip, Paper, Stack, Box, TextField, Button} from "@mui/material";

import userTypes from "consts/userTypes";

const {student, child, principal, teacher} = userTypes;

const AssignmentContainer = styled.div`
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const NoAssignmentMessage = styled.div`
    padding: 1rem;
    color: red; //#8b8b8b;
`;

function DocumentList(props) {
    const {isLectureOwner, actions, currentUser, lecture, getLectureData, history, t} = props;

    const isStudentOrChild = currentUser.role === student || currentUser.role === child;
    const isTeacherOrPrincipal = currentUser.role === teacher || currentUser.role === principal;
    const isTeacher = currentUser.role === teacher;
    const lectureId = lecture?.id || lecture?._id;
    const courseId = lecture?.course?.id || lecture?.course?._id || lecture?.course;
    return (
        <AssignmentContainer>
            <Box sx={{width: "100%", maxWidth: "100%", pt: 2}}>
                {actions && actions.length > 0 ? (
                    actions.map((action, index) => {
                        // const submittedAssignment = action?.find(
                        //     submission => submission.student === currentUser.id && submission.status !== "deleted"
                        // );

                        const contentType = action?.contentType;
                        const url = action?.fileUrl;
                        const name = action?.name;
                        const topicName = action?.topicName || action?.name;
                        const topicObjectives = action?.topicObjectives;
                        const startPage = action?.startPage;
                        const endPage = action?.endPage;
                        const startTime = action?.startTime;
                        const youtubeLink = action?.youtubeId ? `https://youtu.be/${action?.youtubeId}` : null;
                        return (
                            <Paper elevation={4} sx={{p: 2, m: 2}} key={index}>
                                {/* {t("assessment.addnewassessment.score")} */}
                                <Box sx={{width: "100%", maxWidth: "100%", p: 0.5}}>
                                    <h4> Document: {topicName} </h4>
                                    <h4> Objectives: {topicObjectives} </h4>

                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        sx={{cursor: "pointer"}}
                                        spacing={2}>
                                        {contentType === "youtube" ? (
                                            <YouTube
                                                color="error"
                                                fontSize="large"
                                                onClick={() => {
                                                    window.open(youtubeLink, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        ) : contentType === "pdf" ? (
                                            <PictureAsPdf
                                                color="primary"
                                                fontSize="large"
                                                onClick={() => {
                                                    window.open(url, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        ) : contentType === "audio" || contentType === "mp3" ? (
                                            <AudioFile
                                                color="primary"
                                                fontSize="large"
                                                onClick={() => {
                                                    window.open(url, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        ) : contentType === "video" || contentType === "mp4" ? (
                                            <VideoFile
                                                color="primary"
                                                fontSize="large"
                                                onClick={() => {
                                                    window.open(url, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        ) : contentType === "image" ||
                                          contentType === "png" ||
                                          contentType === "jpg" ||
                                          contentType === "jpeg" ? (
                                            <InsertPhoto
                                                color="primary"
                                                fontSize="large"
                                                onClick={() => {
                                                    window.open(url, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        ) : (
                                            <InsertDriveFile
                                                color="primary"
                                                fontSize="large"
                                                onClick={() => {
                                                    window.open(url, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        )}
                                        <p
                                            onClick={() => {
                                                window.open(url || youtubeLink, "_blank", "noopener,noreferrer");
                                            }}>
                                            {name || youtubeLink}{" "}
                                        </p>
                                    </Stack>

                                    {contentType === "pdf" ? (
                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                            <h4> start Page </h4>
                                            <TextField
                                                id="action-startPage"
                                                label=""
                                                value={startPage}
                                                style={{marginLeft: 10, width: "10%"}}
                                                variant="standard"
                                                disabled
                                            />

                                            <h4> End Page </h4>
                                            <TextField
                                                id="action-endPage"
                                                label=""
                                                value={endPage}
                                                style={{marginLeft: 10, width: "10%"}}
                                                variant="standard"
                                                disabled
                                            />
                                        </Stack>
                                    ) : null}
                                    <br />
                                    <br />
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <Button
                                            // height="2.75rem"
                                            variant="contained"
                                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                                            size="large"
                                            onClick={() => {
                                                window.open(url, "_blank", "noopener,noreferrer");
                                            }}>
                                            Open now
                                        </Button>
                                        <Button
                                            // height="2.75rem"
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            onClick={() => {
                                                window.open(url, "_blank", "noopener,noreferrer");
                                            }}>
                                            Open in new tab
                                        </Button>
                                    </Stack>
                                </Box>
                            </Paper>
                        );
                    })
                ) : (
                    <Chip label={t("lecture.digest.noDocumentsAssigned")} color="error" />
                    // <NoAssignmentMessage>{t("lecture.digest.noDocumentsAssigned")}</NoAssignmentMessage>
                )}
                {isTeacher ? (
                    <TextButton
                        onClick={() => history.push(`/courses/edit/${courseId}`)}
                        style={{justifyContent: "left", alignItems: "left", paddingTop: "2rem"}}
                        title={t("lecture.digest.attachDocument")}
                        withPlus
                    />
                ) : null}
            </Box>
        </AssignmentContainer>
    );
}

export default compose(withNamespaces("translation"))(DocumentList);
