import React, {Component} from "react";
import styled from "styled-components";
import {mediaQuery} from "../styled/mediaQuery";

const TabsContainer = styled.div`
    display: flex;
    padding-bottom: 0;
    background-color: #f4f4f4;
    border-radius: 4px;
`;

const TabItem = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.7rem;
    border-radius: 4px;
    color: ${props => (props.isSelected ? "var(--main-theme-color)" : "#c6c6c6")};
    ${props => props.isSelected && "background-color: white;"}
    ${props => props.isSelected && "box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.1);"}
    font-weight: 600;
    cursor: ${props => (props.isClickable ? "pointer" : "auto")};
    text-align: center;
    ${mediaQuery.phone`height: 2.2rem;`}
`;

class MaterialTabs extends Component {
    render() {
        const {data, selected, onChange, itemStyle, idPrefix} = this.props;
        return (
            <TabsContainer>
                {data.map((d, i) => {
                    const isSelected = d.id === selected;
                    return (
                        <TabItem
                            id={idPrefix && `${idPrefix}-${d.id}`}
                            key={i}
                            isSelected={isSelected}
                            onClick={onChange.bind(null, d.id)}
                            isClickable={data.length > 1}
                            style={itemStyle}>
                            {d.title}
                        </TabItem>
                    );
                })}
            </TabsContainer>
        );
    }
}

export default MaterialTabs;
