import React from "react";
import styled from "styled-components";
import PageLoadingSpinner from "components/common/PageLoadingSpinner";

const SpinnerWrapper = styled.div`
    height: 100vh;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ({invisible}) => <SpinnerWrapper>{!invisible && <PageLoadingSpinner />}</SpinnerWrapper>;
