import React, {Component} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {ErrorContainer} from "../../common/styled";

const PaymentButtonContainer = styled.div`
    ${props => props.disabled && "opacity: 0.5; pointer-events: none;"}
`;

class PaypalPaymentButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentInProgress: false,
            error: false
        };
    }

    componentDidMount() {
        this.updatePaymentControls();
    }

    updatePaymentControls() {
        paypal
            .Buttons({
                funding: {disallowed: [paypal.FUNDING.CREDIT, paypal.FUNDING.CARD]},
                style: {color: "silver", tagline: false},
                onClick: () => this.setState({error: false, paymentInProgress: true}),
                createOrder: (data, actions) => {
                    return actions.order.create({purchase_units: [{amount: {value: this.props.amount}}]});
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then(details => this.props.onApproved(details.id));
                },
                onCancel: () => this.setState({paymentInProgress: false}),
                onError: () => this.setState({error: true, paymentInProgress: false})
            })
            .render(ReactDOM.findDOMNode(this.paymentButtons));
    }

    render() {
        const {t, disabled} = this.props;
        const {paymentInProgress, error} = this.state;
        return (
            <React.Fragment>
                <PaymentButtonContainer ref={ref => (this.paymentButtons = ref)} disabled={disabled || paymentInProgress} />
                {error && <ErrorContainer>{t("wallet.payment.topUpError")}</ErrorContainer>}
            </React.Fragment>
        );
    }
}

export default PaypalPaymentButton;
