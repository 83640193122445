import React from "react";

export default props => (
    <svg viewBox="0 0 12 12" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle cx="6" cy="6" r="6" fill="#F5F5F5" />
            <rect width="6" height="1" x="3" y="5.5" fill="#5C6977" rx=".5" />
            <rect width="6" height="1" x="3" y="5.5" fill="#5C6977" rx=".5" transform="rotate(90 6 6)" />
        </g>
    </svg>
);
