import React from "react";
import {withNamespaces} from "react-i18next";
import styled from "styled-components";
import Plus from "components/common/icons/Plus";
import Search from "components/common/icons/Search";
import FlexCenter from "components/common/styled/FlexCenter";
import HiddenFileInput from "components/common/styled/HiddenFileInput";

const TextButtonContainer = styled.div`
    ${FlexCenter};
    ${HiddenFileInput};
    color: ${props => (props.type === "danger" ? "#ff0c3e" : "var(--main-theme-color)")};
    font-weight: 600;
    cursor: pointer;
    align-items: baseline;
    position: relative;
    opacity: ${props => (props.disabled ? "0.5" : "1")};
    pointer-events: ${props => (props.disabled ? "none" : "all")};
    padding: 0 1rem;
    svg {
        cursor: pointer;
        width: ${props => props.iconwidth || "0.9rem"};
        ${props => (props.i18n.dir() === "rtl" ? "margin-left" : "margin-right")}: 0.3rem;
        fill: var(--main-theme-color);
    }
`;
const Title = styled.div``;

@withNamespaces("translation")
class TextButton extends React.Component {
    render() {
        const {onClick, ref, title, withPlus, titleStyle, type, disabled, children, iconWidth, withSearch, ...props} = this.props;
        return (
            <TextButtonContainer ref={ref} disabled={disabled} type={type} onClick={onClick} {...props}>
                {withPlus && <Plus iconwidth={iconWidth} />}
                {withSearch && <Search iconwidth={iconWidth} fill="var(--main-theme-color)" />}
                <Title style={titleStyle}>{title}</Title>
                {children}
            </TextButtonContainer>
        );
    }
}

export default React.forwardRef((props, ref) => <TextButton ref={ref} {...props} />);
