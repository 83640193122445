import React, {Component} from "react";

const CheckWhiteboardContext = React.createContext("");

class CheckWhiteBoardProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentId: localStorage.getItem("submissionId"),
            submissionId: localStorage.getItem("submissionId"),
            assignment: JSON.parse(localStorage.getItem("assignment")),
            submittedAssignment: JSON.parse(localStorage.getItem("submittedAssignment")),
            setStudentAndSubmission: this.setStudentAndSubmission.bind(this),
            setAssignment: this.setAssignment.bind(this),
            setStudentSubmission: this.setStudentSubmission.bind(this)
        };
    }

    setAssignment = assignment => {
        localStorage.setItem("assignment", JSON.stringify(assignment));
        this.setState({
            assignment: assignment
        });
    };
    setStudentSubmission = submittedAssignment => {
        localStorage.setItem("submittedAssignment", JSON.stringify(submittedAssignment));
        this.setState({
            submittedAssignment: submittedAssignment
        });
    };

    setStudentAndSubmission = (studentId, submissionId, callback) => {
        localStorage.setItem("studentId", this.state.studentId);
        localStorage.setItem("submissionId", this.state.submissionId);
        this.setState(
            {
                studentId,
                submissionId
            },
            () => {
                callback();
            }
        );
    };

    render() {
        return <CheckWhiteboardContext.Provider value={this.state}>{this.props.children}</CheckWhiteboardContext.Provider>;
    }
}

export default {Provider: CheckWhiteBoardProvider, Consumer: CheckWhiteboardContext.Consumer, Context: CheckWhiteboardContext};
