import React, {Component} from "react";
import styled from "styled-components";
import FooterMenu from "./FooterMenu";
import LanguageSelect from "./LanguageSelect";
import Version from "./Version";

import {mediaQuery} from "components/common/styled/mediaQuery";

const FooterWrapper = styled.div`
    position: relative;

    background-color: var(--white);

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 1rem;

    width: 100%;
    height: 4rem;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.55);

    color: #323232;

    ${mediaQuery.phone`
        display: block;
        color: #8b8b8b;
        box-shadow: none;
        background-color: #f8f8fa;
        padding: 1.2rem 1rem;
        height: 8rem;
    `}
`;

class Footer extends Component {
    render() {
        const {currentUser} = this.props;

        return (
            <FooterWrapper>
                <Version />
                <FooterMenu />
                {!currentUser && <LanguageSelect />}
            </FooterWrapper>
        );
    }
}

export default Footer;
