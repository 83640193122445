import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router-dom";
import Logo from "./LandingLogo";
import FlexCenter from "components/common/styled/FlexCenter";
import MarketSearch from "components/landing/MarketSearch/MarketSearch";
import MarketSearchMobile from "components/landing/MarketSearch/MarketSearchMobile";
import Button from "components/form/Button";
import {mediaQuery} from "components/common/styled/mediaQuery";
import LanguageDropDownContainer from "components/common/LanguageDropdown";
import routes from "consts/routes";
import {checkIfLocationPathStartsWith} from "utils/location";
import {withContextConsumer} from "utils/contexts";
import LandingContext from "contexts/Landing";

const SCROLL_EDGE = 100;

const routesAvailableForGuests = [
    routes.courses.path,
    routes.schools.path,
    routes.content.path,
    routes.market.path,
    routes.programs.path
];

const HeaderButtonContainer = styled.div`
    ${FlexCenter};
    width: 20rem;
    padding: 0 2rem;
    ${mediaQuery.phone`
        width: 10rem;
        padding: 0 1rem;
    `};
`;
const HeaderWrapper = styled.div`
    ${props => props.isHeaderFixed && "position: fixed"};
    ${props => !props.isHeaderFixed && "margin: 0 0 2rem"};
    ${props => !props.isHeaderFixed && "height: 4.5rem"};
    display: flex;
    width: 100vw;
    justify-content: space-between;
    opacity: 1;
    background: ${props => (props.scrolled ? "rgb(23,23,23)" : "rgba(0,0,0,0)")};
    box-shadow: ${props => (props.scrolled ? "0 10px 40px 4px rgba(46, 50, 55, 0.1)" : "0")};
    transition: 0.3s;
    z-index: 10;
`;

@withRouter
@withNamespaces("translation")
@withContextConsumer(LandingContext.Consumer)
class LandingHeader extends Component {
    componentDidMount() {
        this.props.forwardedRef(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.forwardedRef(this);
        }
    }

    onScroll(e) {
        const scrollbar = e.srcElement;
        this.props.onSetOffsetTop(scrollbar.scrollTop);
    }

    render() {
        const {t, i18n, history, scrollbarRef, location, offsetTop} = this.props;
        const filled = location.pathname && location.pathname !== routes.home.path;
        const isHeaderFixed = !routesAvailableForGuests.some(r => checkIfLocationPathStartsWith(r));
        return (
            <HeaderWrapper isHeaderFixed={isHeaderFixed} scrolled={filled || offsetTop > SCROLL_EDGE}>
                <Logo />
                <MarketSearch t={t} scrollbarRef={scrollbarRef} />
                <MarketSearchMobile t={t} history={history} scrollbarRef={scrollbarRef} />
                <HeaderButtonContainer>
                    <LanguageDropDownContainer i18n={i18n} isLanding />
                    <Button id="landingSigninButton" type="blue" dir={i18n.dir()} onClick={() => history.push("/signin")}>
                        {t("landing.header.signIn")}
                    </Button>
                </HeaderButtonContainer>
            </HeaderWrapper>
        );
    }
}

export default LandingHeader;
