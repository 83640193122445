import React from "react";

export default props => {
    return (
        <svg viewBox="0 0 20 20" {...props}>
            <path
                fill="#FFF"
                fillRule="evenodd"
                d="M2.5 2.5h15A1.5 1.5 0 0 1 19 4v10a1 1 0 0 1-1 1h-1.854v5l-4.155-5H2.5A1.5 1.5 0 0 1 1 13.5V4a1.5 1.5 0 0 1 1.5-1.5z"
            />
        </svg>
    );
};
