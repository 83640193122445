import React, {Component} from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";
import LectureInfoDesktop from "./LectureInfoDesktop";
import LectureInfoMobile from "./LectureInfoMobile";
import {withContextConsumer} from "utils/contexts";
import LocaleContext from "contexts/common/LocaleContext";
import {Value} from "./LectureInfoStyled";
import moment from "moment-timezone";
import {isSelfMode, isScheduleMode} from "consts/lectureMode";

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem 0;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const InfoContainer = styled.div``;

const Owner = styled.div`
    display: flex;
    visibility: ${props => (props.isLectureOwner ? "hidden" : "visible")};
    padding: 0.3rem 0 1.5rem 0;
    ${mediaQuery.phone`
        padding: 0 1rem 1rem;
    `}
`;

@withContextConsumer(LocaleContext.Consumer)
class LectureInfo extends Component {
    constructor(props) {
        super(props);
        const timeZone = props.currentUser.timezone || moment.tz.guess();
        this.timeFormatter = props.getCustomTimeFormatter({timeZone});
        this.dateFormatter = props.getCustomDateFormatter({timeZone});
    }

    render() {
        const {
            lecture,
            course,
            numberFormatter,
            currentUser,
            usersAttendance,
            totalUsers,
            loadAttendance,
            usersPage,
            pageSize
        } = this.props;

        const isSelfLecture = isSelfMode(lecture.mode);

        const startDate = isSelfLecture
            ? ""
            : `${this.dateFormatter.format(new Date(lecture.startDate))} ${this.timeFormatter.format(
                  new Date(lecture.startDate)
              )}`;

        const studentsNumber = lecture.studentsNumber
            ? numberFormatter.format(lecture.studentsNumber)
            : usersAttendance && usersAttendance.length > 0
            ? numberFormatter.format(usersAttendance.length)
            : "-";

        const isLectureOwner = currentUser.id === lecture.owner.id;

        return (
            <InfoWrapper>
                <Owner isLectureOwner={isLectureOwner}>
                    <Value>{`${lecture.owner.firstName} ${lecture.owner.lastName}`}</Value>
                </Owner>
                <InfoContainer>
                    <LectureInfoDesktop
                        lecture={lecture}
                        course={course}
                        startDate={startDate}
                        studentsNumber={studentsNumber}
                        usersAttendance={usersAttendance}
                        totalUsers={totalUsers}
                        loadAttendance={loadAttendance}
                        usersPage={usersPage}
                        pageSize={pageSize}
                    />
                    <LectureInfoMobile
                        lecture={lecture}
                        course={course}
                        startDate={startDate}
                        studentsNumber={studentsNumber}
                        usersAttendance={usersAttendance}
                        totalUsers={totalUsers}
                        loadAttendance={loadAttendance}
                        usersPage={usersPage}
                        pageSize={pageSize}
                    />
                </InfoContainer>
            </InfoWrapper>
        );
    }
}

export default LectureInfo;
