import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import QuizzesListContext from "contexts/quizes/QuizzesList";
import QuizzesList from "./QuizzesList";
import AttachForm from "./AttachForm";
import {mediaQuery} from "components/common/styled/mediaQuery";

const ModalContent = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 0 2rem 0;
    height: 100%;
    ${mediaQuery.phone`
        padding: 1rem 0 0 0;
    `}
`;

const AttachQuizTitle = styled.div`
    line-height: 1.5;
    padding: 1rem;
    width: 100%;
    ${mediaQuery.phone`
        padding: 1rem;
    `}
`;

const Bold = styled.span`
    font-weight: bold;
`;

@withNamespaces("translation")
@withContextProvider(QuizzesListContext.Provider)
@withContextConsumer(QuizzesListContext.Consumer)
class OwnersContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quiz: null,
            subject: null,
            competencies: [],
            quizGame: null,
            attachedQuiz: null
        };
    }

    async componentDidMount() {
        const {getQuizzes, getQuizMeta, getQuizGames} = this.props;
        await getQuizMeta();
        await getQuizzes();
        await getQuizGames();
    }

    onFieldChange(field, value) {
        this.setState({[field]: value});
    }

    setQuiz(quiz) {
        const {lectureData} = this.props;
        const {competencies = [], subject = null, grade = null} = lectureData.course || {};
        this.setState({
            quiz,
            competencies,
            subject,
            grade
        });
    }

    async attachQuiz(course) {
        const {
            attachQuiz,
            getQuizzes,
            lectureData,
            attachViaSelfStudyStructure,
            attachSelfStudyQuiz,
            attachEmbeddedQuizOnly,
            attachEmbeddedQuiz
        } = this.props;
        const {quiz, subject, competencies, quizGame} = this.state;

        const attachForm = [
            {
                courseId: course ? course.id : null,
                lectureId: lectureData.id,
                subject: course ? course.subject : subject,
                competencies,
                grade: course ? course.grade : null,
                quizGame
            }
        ];
        if (attachEmbeddedQuizOnly) {
            await attachEmbeddedQuiz(quiz);
        }

        if (attachViaSelfStudyStructure) {
            await attachSelfStudyQuiz(quiz);
        }

        await attachQuiz(quiz.id, attachForm);
        await getQuizzes();
        this.setState({quiz: null, attachedQuiz: quiz, subject: null, competencies: []});
    }

    renderContentTitle(course) {
        const {lectureData, isListTitleHidden, t} = this.props;
        const {quiz} = this.state;
        if (quiz) {
            const lectureName = course ? `${course.subject}, ${course.grade}, ${course.language}` : lectureData.name;
            return (
                <AttachQuizTitle>
                    {t("lecture.notifications.attachQuiz") + " "}
                    <Bold>{quiz.name}</Bold>
                    {t("lecture.notifications.toLecture")}
                    <Bold>{lectureName}</Bold>
                </AttachQuizTitle>
            );
        }
        if (isListTitleHidden) {
            return null;
        }
        return <AttachQuizTitle>{t("lecture.notifications.attachQuiz")}</AttachQuizTitle>;
    }

    render() {
        const {onClose, quizMeta, lectureData, quizzes, attachEmbeddedQuizOnly, quizGames, closeTitle, hideAttached} = this.props;
        const {quiz} = this.state;

        if (!quizMeta) {
            return null;
        }

        const course = this.props?.course || quizMeta?.courses.find(c => c.id === lectureData?.course);
        let filteredQuizzez = quizzes;
        if (attachEmbeddedQuizOnly) {
            let embeddedQuizzez = quizzes.filter(quiz => quiz.type === "embedded");

            filteredQuizzez = embeddedQuizzez;
        }

        return (
            <ModalContent>
                {this.renderContentTitle(course)}
                {quiz ? (
                    <AttachForm
                        quizMeta={quizMeta}
                        quizGames={quizGames}
                        lectureData={lectureData}
                        onFieldChange={this.onFieldChange.bind(this)}
                        attachQuiz={this.attachQuiz.bind(this)}
                        course={course}
                        {...this.state}
                    />
                ) : (
                    <QuizzesList
                        quizzes={filteredQuizzez}
                        setQuiz={this.setQuiz.bind(this)}
                        lectureData={lectureData}
                        onClose={onClose}
                        closeTitle={closeTitle}
                        hideAttached={hideAttached}
                    />
                )}
            </ModalContent>
        );
    }
}

export default OwnersContent;
