import React from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;

    /* background: var(--main-theme-gradient); */

    @media (max-width: 1220px) and (min-width: 1033px) {
        background: var(--main-theme-gradient);
    }

    width: 100%;
    height: 4rem;

    ${mediaQuery.phone`
       background: var(--main-theme-gradient);
    `}
`;

const HeaderContent = styled.div`
    height: 100%;
    /* width: 1032px; */
    max-width: 100%;
    margin: 0 0.3rem;
    width: 100%;
`;

export default props => (
    <HeaderWrapper>
        <HeaderContent>{props.children}</HeaderContent>
    </HeaderWrapper>
);
