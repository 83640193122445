import validator from "validator";
import {isAlphaAvailable} from "./common";
import moment from "moment-timezone";

export function validateEmail(data) {
    const errors = {};
    if (!validator.isEmail(data.email)) {
        errors.email = "Incorrect email";
    }
    return errors;
}
export function validatePasswordData(data) {
    const errors = {};
    if (data.newPassword.length < 8) {
        errors.newPassword = "From 8 characters long";
    }
    if (data.newPassword.length > 200) {
        errors.newPassword = "Up to 200 characters long";
    }
    if (data.newPassword !== data.confirmNewPassword) {
        errors.confirmNewPassword = "Password does not match";
    }
    return errors;
}

export function validateEditProfileData(data) {
    const errors = {};
    if (!isAlphaAvailable(data.firstName)) {
        errors.firstName = "First name can contain only letters";
    }
    if (!validator.isLength(data.firstName, {min: 2, max: 50})) {
        errors.firstName = "First name should be from 2 to 50 characters long";
    }
    if (!isAlphaAvailable(data.lastName)) {
        errors.lastName = "Last name can contain only letters";
    }
    if (!validator.isLength(data.lastName, {min: 2, max: 50})) {
        errors.lastName = "Last name should be from 2 to 50 characters long";
    }
    if (data.middleName && !isAlphaAvailable(data.middleName)) {
        errors.middleName = "Middle name can contain only letters";
    }
    if (data.middleName && !validator.isLength(data.middleName, {min: 2, max: 50})) {
        errors.middleName = "Middle name should be from 2 to 50 characters long";
    }
    if (data.bio && data.bio.length > 1000) {
        errors.bio = "Bio is too big";
    }
    if (data.dateOfBirth) {
        if (!validator.isISO8601(data.dateOfBirth)) {
            errors.dateOfBirth = "Invalid date of birth";
        }
        const age = moment().diff(moment(data.dateOfBirth), "years");
        if (age > 120 || age < 1) {
            errors.dateOfBirth = "Invalid date of birth";
        }
    }
    return errors;
}

export function validateEditCompanyData(data, currentUserPic) {
    const errors = {};

    if (!data.userPic && !currentUserPic) {
        errors.userPic = "Company logo is not set";
    }

    if (!data.name || data.name.trim().length === 0) {
        errors.name = "Company name can not be empty";
    }

    if (!validator.isLength(data.name, {min: 2, max: 50})) {
        errors.name = "Company name should be from 2 to 50 characters long";
    }

    if (!data.email || (data.email && data.email.trim().length !== 0 && !validator.isEmail(data.email))) {
        errors.email = "Incorrect email";
    }

    if (data.website && data.website.trim().length !== 0 && !validator.isURL(data.website)) {
        errors.website = "Incorrect website url";
    }

    if (!data.address || data.address.trim().length === 0) {
        errors.address = "Company address can not be empty";
    }

    return errors;
}

export function validatePhoneData(data) {
    const errors = {};
    // if (!validator.isMobilePhone(data.newPhone, "any", {strictMode: true})) {
    //     errors.newPhone = "Phone number should be in international format";
    // }
    return errors;
}

export function validateUserNameData(data) {
    const errors = {};
    if (!validator.isAlphanumeric(data.userName)) {
        errors.userName = "Only characters and numbers";
    }
    if (!validator.isLength(data.userName, {min: 2, max: 50})) {
        errors.userName = "From 2 to 50 characters long";
    }
    return errors;
}

export function validateEditTeacherSettings(data) {
    const errors = {};

    const rate = parseInt(data.rate);
    if (isNaN(rate) || rate < 1) {
        errors.rate = "Hourly rate minimum value is 1";
    } else if (rate > 10000) {
        errors.rate = "Hourly rate maximum value is 10000";
    }

    const achievementErrors = {};
    for (let i = 0; i < data.achievements.length; i++) {
        const achievement = data.achievements[i];
        if (achievement.isDeleted) {
            continue;
        }
        if (achievement.name.length === 0) {
            achievementErrors[i] = achievementErrors[i] || {};
            achievementErrors[i].name = "Name is empty";
        } else if (achievement.name.length > 1000) {
            achievementErrors[i] = achievementErrors[i] || {};
            achievementErrors[i].name = "Name must be less then 1000 chars long";
        }

        if (!achievement.date) {
            achievementErrors[i] = achievementErrors[i] || {};
            achievementErrors[i].date = "Date is empty";
        }
    }
    if (Object.keys(achievementErrors).length) {
        errors.achievements = achievementErrors;
    }

    const workExperienceErrors = {};
    for (let i = 0; i < data.workExperience.length; i++) {
        const exp = data.workExperience[i];
        if (exp.place.length === 0) {
            workExperienceErrors[i] = workExperienceErrors[i] || {};
            workExperienceErrors[i].place = "Place is empty";
        } else if (exp.place.length > 1000) {
            workExperienceErrors[i] = workExperienceErrors[i] || {};
            workExperienceErrors[i].place = "Place is too big";
        }

        if (exp.responsibilities.length === 0) {
            workExperienceErrors[i] = workExperienceErrors[i] || {};
            workExperienceErrors[i].responsibilities = "Responsibilities field is empty";
        } else if (exp.responsibilities.length > 1000) {
            workExperienceErrors[i] = workExperienceErrors[i] || {};
            workExperienceErrors[i].responsibilities = "Responsibilities string is too big";
        }

        const {startDateError, endDateError} = validateStartEndDates(moment(exp.startDate), moment(exp.endDate));
        if (startDateError) {
            workExperienceErrors[i] = workExperienceErrors[i] || {};
            workExperienceErrors[i].startDate = startDateError;
        }
        if (endDateError) {
            workExperienceErrors[i] = workExperienceErrors[i] || {};
            workExperienceErrors[i].endDate = endDateError;
        }
    }

    if (Object.keys(workExperienceErrors).length) {
        errors.workExperience = workExperienceErrors;
    }

    const educationErrors = {};
    for (let i = 0; i < data.education.length; i++) {
        const educationItem = data.education[i];
        if (educationItem.place.length === 0) {
            educationErrors[i] = educationErrors[i] || {};
            educationErrors[i].place = "Place is empty";
        } else if (educationItem.place.length > 1000) {
            educationErrors[i] = educationErrors[i] || {};
            educationErrors[i].place = "Place is too big";
        }

        if (educationItem.activity.length === 0) {
            educationErrors[i] = educationErrors[i] || {};
            educationErrors[i].activity = "Activity is empty";
        } else if (educationItem.activity.length > 1000) {
            educationErrors[i] = educationErrors[i] || {};
            educationErrors[i].activity = "Activity is too big";
        }
        const {startDateError, endDateError} = validateStartEndDates(
            moment(educationItem.startDate),
            moment(educationItem.endDate)
        );
        if (startDateError) {
            educationErrors[i] = educationErrors[i] || {};
            educationErrors[i].startDate = startDateError;
        }
        if (endDateError) {
            educationErrors[i] = educationErrors[i] || {};
            educationErrors[i].endDate = endDateError;
        }
    }

    if (Object.keys(educationErrors).length) {
        errors.education = educationErrors;
    }

    return errors;
}

function validateStartEndDates(startDate, endDate) {
    let startDateError;
    let endDateError;
    if (!startDate) {
        startDateError = "Start date is empty";
    }
    if (!endDate) {
        endDateError = "End date is empty";
    }
    if (startDateError || endDateError) {
        return {startDateError, endDateError};
    }

    if (startDate.isAfter(endDate)) {
        endDateError = "End date must be after start date";
        return {endDateError};
    }
    return {startDateError, endDateError};
}
