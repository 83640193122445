import React, {Component} from "react";
import styled from "styled-components";
import Button from "components/form/Button";
import HiddenFileInput from "components/common/styled/HiddenFileInput";
import {mediaQuery} from "../common/styled/mediaQuery";

const ButtonWrapper = styled.div`
    ${mediaQuery.phone`
        margin-bottom: 2rem;
        ${props => props.hideOnPhoneResolution && "display: none;"}
    `}
`;

const FileInputContainer = styled.div`
    ${HiddenFileInput}
`;

class AddFilesButton extends Component {
    onFileChange(e) {
        const {meta, onInputFiles} = this.props;
        const filesArray = Array.from(e.target.files);
        const files = filesArray.slice(0, meta.maxUploadInTime);
        const filesWasFiltered = files.length !== filesArray.length;
        onInputFiles(files, filesWasFiltered);
        e.target.value = "";
    }

    render() {
        const {t, meta, disabled, hideOnPhoneResolution, onInputFiles, inSharingModal, invisible} = this.props;
        if (invisible) return null;
        return (
            <ButtonWrapper hideOnPhoneResolution={hideOnPhoneResolution}>
                <Button
                    type="action"
                    width={"100%"}
                    fontWeight={"bold"}
                    disabled={disabled}
                    onClick={() => inSharingModal && onInputFiles()}>
                    {t("library.addFiles")}
                    {!inSharingModal && (
                        <FileInputContainer>
                            <input
                                title=" "
                                type="file"
                                accept={`.${meta.extensions.join(",.")}`}
                                onChange={this.onFileChange.bind(this)}
                                multiple
                            />
                        </FileInputContainer>
                    )}
                </Button>
            </ButtonWrapper>
        );
    }
}

export default AddFilesButton;
