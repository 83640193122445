import React from "react";

export default props => (
    <svg viewBox="0 0 20 20" {...props} fill={props.fill ? props.fill : "#FFF"}>
        <path
            fill="#5C6977"
            d="M13.701 6.299c1.732 1.732 1.732 4.54 0 6.272-1.532 1.532-3.907 1.708-5.634.529l-1.659 1.658c-.322.323-.844.323-1.166 0-.298-.297-.32-.765-.069-1.089l.069-.077L6.9 11.934c-1.18-1.727-1.004-4.102.528-5.635 1.732-1.732 4.54-1.732 6.272 0zm-1.167 1.167c-1.087-1.088-2.85-1.088-3.938 0-1.087 1.087-1.087 2.85 0 3.938 1.088 1.087 2.85 1.087 3.938 0 1.088-1.088 1.088-2.85 0-3.938z"
        />
    </svg>
);
