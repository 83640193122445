import React, {Component} from "react";
import styled from "styled-components";
import Chips from "./Chips";
import DropdownPopper from "./DropdownPopper";

const ComponentContainer = styled.div``;

const Title = styled.div`
    color: #9e9e9e;
    font-size: 0.9rem;
    ${props => props.selected.length && "margin-bottom: 0.5rem;"}
`;

class DropdownSelectablePopper extends Component {
    onAddItem(id) {
        const {selected, onChange} = this.props;
        if (!selected.includes(id)) {
            onChange([...selected, id]);
        }
    }

    render() {
        const {title, selected, data, placeholder, onChange, dir, style, ...rest} = this.props;
        return (
            <ComponentContainer>
                {title && <Title selected={selected}>{title}</Title>}
                <Chips data={selected} onChange={onChange} dir={dir} hasData={!!data.length} />
                {!!data.length && (
                    <DropdownPopper
                        data={data}
                        placeholder={placeholder}
                        onChange={this.onAddItem.bind(this)}
                        style={style}
                        forceSearchBar
                        {...rest}
                    />
                )}
            </ComponentContainer>
        );
    }
}

export default DropdownSelectablePopper;
