import React, {Component} from "react";
import get from "lodash/get";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import TextButton from "components/form/TextButton";
import {mediaQuery} from "components/common/styled/mediaQuery";
import AddQuizModal from "./AddQuizModal";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import userTypes from "consts/userTypes";
import {withContextConsumer} from "utils/contexts";
import EnrollmentsContext from "contexts/Enrollments";

const QuizzesContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const Title = styled.div`
    font-weight: bold;
    min-width: 0;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const NoQuizzesMessage = styled.div`
    padding: 1rem;
    color: #8b8b8b;
`;

const Quiz = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3rem;
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    min-width: 0;
    ${mediaQuery.phone`
        ${FlexMarginBetween(0.3)}
        padding: 0 0.7rem;
    `}
`;

const Info = styled.div`
    color: #8b8b8b;
    margin: 0 1rem;
`;

const AddQuizButtonWrapper = styled.div`
    padding: 0.8rem 0;
    min-width: 0;
    ${mediaQuery.phone`
        width: 100%;
        order: 1;
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
    `}
`;

@withNamespaces("translation")
@withContextConsumer(EnrollmentsContext.Consumer)
class QuizzesList extends Component {
    constructor(props) {
        super(props);
        this.state = {isAddQuizModalOpen: false};
    }

    onClick(quiz, isPassedByStudent) {
        const {currentUser, isLectureOwner, isParent, setOpenedQuiz, enterQuiz, showPersonalResult} = this.props;
        const hasSeveralResults = quiz.results.length > 1;
        if (isLectureOwner || this.isUserCourseOwner() || (isParent && hasSeveralResults)) {
            return setOpenedQuiz(quiz);
        }
        const isChildResults = isParent && quiz.results.length && currentUser.id !== get(quiz, "results[0].student.id", null);
        if (isPassedByStudent || isChildResults) {
            return showPersonalResult(quiz.results[0].id);
        }
        return enterQuiz(quiz.id);
    }

    isUserCourseOwner() {
        const {currentUser, lecture} = this.props;

        let owner = false;
        let courseOwner = get(lecture, "course.owner", "");
        if (currentUser.id === courseOwner) {
            owner = true;
        }

        return owner;
    }

    getDisabledQuizTitle(quiz) {
        const {t, getPrevQuiz} = this.props;
        const previousQuiz = getPrevQuiz(quiz);
        if (!previousQuiz) {
            return;
        }
        const prevQuizName = previousQuiz.name;
        if (quiz.passThreshold) {
            return t("lecture.digest.quizWillBeAvailable", {name: prevQuizName, threshold: previousQuiz.passThreshold});
        }
        return t("lecture.digest.quizWillBeAvailableWithoutThreshold", {name: prevQuizName});
    }

    toggleAddQuizModal() {
        this.setState({isAddQuizModalOpen: !this.state.isAddQuizModalOpen});
    }

    getParentData(quiz) {
        const {lecture, currentUser, isParent, isEnrolledToCourse, usersAttendance} = this.props;
        const isAttendee = usersAttendance.some(a => a.id === currentUser.id);
        if (!isParent || isAttendee) {
            return {isAttendee};
        }

        const isFinishedByChildren =
            quiz.results && quiz.results.some(el => el.status === "finished" && el.student.id !== currentUser.id);
        const lectureCourseId = get(lecture, "course.id", null);
        const isEnrolled = isEnrolledToCourse(lectureCourseId);
        const isButtonDisabled = !isEnrolled && !isFinishedByChildren;

        return {isFinishedByChildren, isEnrolled, isButtonDisabled};
    }

    async closeAddQuizModal() {
        const {getLectureData, lecture} = this.props;
        this.setState({isAddQuizModalOpen: !this.state.isAddQuizModalOpen}, () => getLectureData(lecture.id));
    }

    render() {
        const {quizzes, isParent, isLectureOwner, currentUser, lecture, isQuizActive, t} = this.props;
        const {isAddQuizModalOpen} = this.state;

        let isCourseOwner = this.isUserCourseOwner();

        const {child, parent, student} = userTypes;
        const isStudent = [child, parent, student].includes(currentUser.role);
        const showAddQuizButton = !isStudent && isLectureOwner;

        return (
            <QuizzesContainer>
                <ListContainer>
                    {quizzes && quizzes.length ? (
                        quizzes.map((quiz, index) => {
                            const isResultPassed = r => r.status === "finished" && r.student.id === currentUser.id;
                            const isPassedByStudent = quiz.results && !!quiz.results.find(isResultPassed);

                            const {isFinishedByChildren, isEnrolled, isButtonDisabled, isAttendee} = this.getParentData(quiz);
                            const isChildQuiz = isParent && !isEnrolled && !isAttendee;

                            const disabled = !isQuizActive(quiz) || isButtonDisabled;

                            const isResultButton =
                                isLectureOwner || isCourseOwner || isPassedByStudent || isFinishedByChildren || isChildQuiz;

                            const buttonType = isResultButton ? "results" : "start";
                            return (
                                <Quiz key={index}>
                                    <Title>{quiz.name}</Title>
                                    {disabled && <Info>{this.getDisabledQuizTitle(quiz)}</Info>}
                                    <TextButton
                                        disabled={disabled}
                                        titleStyle={{fontSize: "0.8rem"}}
                                        title={t(`lecture.digest.${buttonType}`)}
                                        onClick={this.onClick.bind(this, quiz, isPassedByStudent)}
                                    />
                                </Quiz>
                            );
                        })
                    ) : (
                        <NoQuizzesMessage>{t("lecture.digest.noQuizzesAssigned")}</NoQuizzesMessage>
                    )}
                </ListContainer>
                {showAddQuizButton && (
                    <AddQuizButtonWrapper>
                        <TextButton
                            onClick={this.toggleAddQuizModal.bind(this)}
                            title={t("lecture.digest.attachQuiz")}
                            withPlus
                        />
                        <AddQuizModal
                            isOpen={isAddQuizModalOpen}
                            onClose={this.closeAddQuizModal.bind(this)}
                            lectureData={lecture}
                            closeTitle={t("lecture.digest.ok")}
                            title={t("lecture.digest.attachQuiz")}
                            course={lecture.course}
                        />
                    </AddQuizButtonWrapper>
                )}
            </QuizzesContainer>
        );
    }
}

export default QuizzesList;
