import React from "react";

export default props => (
    <svg viewBox="0 0.5 14.45 14.45">
        <g fill="none" fillRule="evenodd" transform="translate(.333 .333)">
            <mask id="starHalfFilledB" fill="#fff">
                <path d="M6.667 10.18l4.12 2.487L9.693 7.98l3.64-3.153L8.54 4.42 6.667 0 4.793 4.42 0 4.827 3.64 7.98l-1.093 4.687z" />
            </mask>
            <path
                fill="var(--yellow)"
                fillRule="nonzero"
                d="M6.667 10.18l4.12 2.487L9.693 7.98l3.64-3.153L8.54 4.42 6.667 0 4.793 4.42 0 4.827 3.64 7.98l-1.093 4.687z"
            />
            <path fill="#757575" d="M6.667-9.333h13v28h-13z" mask="url(#starHalfFilledB)" />
        </g>
    </svg>
);
