import React from "react";
import HintsContext from "contexts/common/Hints";

const GlobalHintsContext = React.createContext("globalHints");

class GlobalHintsProvider extends HintsContext {
    render() {
        return <GlobalHintsContext.Provider value={this.state}>{this.props.children}</GlobalHintsContext.Provider>;
    }
}

export default {
    Provider: GlobalHintsProvider,
    Consumer: GlobalHintsContext.Consumer
};
