import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import LibraryModal from "./LibraryModal";
import LibraryView from "../common/LibraryView";
import AddFilesButton from "../AddFilesButton";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {withContextConsumer} from "utils/contexts";
import LibraryContext from "contexts/Library";
import AttachFilesButton from "../AttachFilesButton";
import {libraryItemTypes} from "consts/library";
import CurrentUserContext from "contexts/CurrentUser";

const MobileAddFileButton = styled.div`
    display: none;
    ${mediaQuery.phone`display: block;`}
`;

@withNamespaces("translation")
@withContextConsumer(LibraryContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
class ShareWithLibraryModal extends Component {
    componentDidUpdate(prevProps, prevState) {
        const {isOpen, clearSelectedItems, isSingleItemPickerMode, selectedItems} = this.props;
        if (prevProps.isOpen && !isOpen) {
            clearSelectedItems();
        }
        if (isSingleItemPickerMode && selectedItems.length) {
            this.handleInputFiles();
        }
    }

    handleInputFiles() {
        const {selectedItems, items, onInputFiles} = this.props;
        return onInputFiles(selectedItems.map(id => items.find(i => i.id === id)));
    }

    getTitle() {
        const {t, fileType} = this.props;
        switch (fileType) {
            case libraryItemTypes.image:
                return t("library.share.titleImage");
            case libraryItemTypes.document:
                return t("library.share.titleDocument");
            case libraryItemTypes.audio:
                return t("library.share.titleAudio");
            case libraryItemTypes.video:
                return t("library.share.titleVideo");
            default:
                return t("library.share.titleDocument");
        }
    }

    render() {
        const {
            t,
            isOpen,
            selectedItems,
            onRequestClose,
            fileType,
            onAttachFiles,
            isSingleItemPickerMode,
            currentUser
        } = this.props;

        if (!currentUser) {
            return null;
        }

        return (
            <LibraryModal width={"46rem"} onRequestClose={onRequestClose} title={this.getTitle()} isOpen={isOpen}>
                <LibraryView
                    filterType={fileType}
                    onInputFiles={this.handleInputFiles.bind(this)}
                    onAttachFiles={onAttachFiles}
                    isSingleItemPickerMode={isSingleItemPickerMode}
                    currentUser={currentUser}
                    inSharingModal
                />
                <MobileAddFileButton>
                    <AddFilesButton
                        t={t}
                        onInputFiles={this.handleInputFiles.bind(this)}
                        disabled={selectedItems.length === 0}
                        invisible={isSingleItemPickerMode}
                        inSharingModal
                    />
                    <AttachFilesButton t={t} onAttachFiles={onAttachFiles} />
                </MobileAddFileButton>
            </LibraryModal>
        );
    }
}

export default ShareWithLibraryModal;
