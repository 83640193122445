import {mediaQuery} from "components/common/styled/mediaQuery";
import AssessmentAddEditContext from "contexts/assessment/AssessmentAddEdit";
import AssignmentStudentProgressContext from "contexts/assignment/AssignmentStudentProgress";
import CheckWhiteboard from "contexts/CheckWhiteboard";
import CurrentUserContext from "contexts/CurrentUser";
import EnrollmentsContext from "contexts/Enrollments";
import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import styled from "styled-components";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import CheckAssessmentData from "./CheckAssessmentData";

const AssignmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const StudentProgressContainer = styled.div`
    /* padding: 2.8rem 0; */
`;

const Title = styled.div`
    font-weight: bold;
    min-width: 0;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

@withNamespaces("translation")
@withContextConsumer(CurrentUserContext.Consumer)
@withContextProvider(AssessmentAddEditContext.Provider)
@withContextConsumer(AssessmentAddEditContext.Consumer)
@withContextConsumer(AssignmentStudentProgressContext.Consumer)
@withContextConsumer(EnrollmentsContext.Consumer)
@withContextConsumer(CheckWhiteboard.Consumer)
class StudentAssessmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            feedback: "",
            grade: null
        };
    }

    render() {
        const {location, currentUser, assessment, students, mode, history, isLectureOwner, t, updateAssignment} = this.props;

        let studentAssessment = {};
        // render the ui based on assessment submitted or not
        let renderView = null;
        if (students && students.length !== 0) {
            renderView = students.map(student => {
                if (assessment.results) {
                    studentAssessment = assessment?.results.filter(result => result.studentId === student.id);
                }
                return (
                    <CheckAssessmentData
                        currentUser={currentUser}
                        location={location}
                        mode={mode}
                        isLectureOwner={isLectureOwner}
                        history={history}
                        student={student}
                        assessment={assessment}
                        studentSubmission={studentAssessment}
                        updateAssignment={updateAssignment}
                        {...this.props}
                    />
                );
            });
        }

        return (
            <AssignmentContainer>
                <ListContainer>
                    <StudentProgressContainer>
                        <Title>{t("lecture.digest.checkAssignment.StudentProgress")}</Title>

                        {renderView}
                    </StudentProgressContainer>
                </ListContainer>
            </AssignmentContainer>
        );
    }
}

export default StudentAssessmentList;
