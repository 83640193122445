import {keyframes} from "styled-components";

export default keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
`;
