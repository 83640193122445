import React from "react";

export default props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <path
                fill="var(--light-black)"
                d="M8.5 7.5h3a.5.5 0 1 1 0 1h-3v3a.5.5 0 1 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0v3z"
            />
            <circle cx="8" cy="8" r="8" stroke="var(--light-black)" />
        </g>
    </svg>
);
