import React, {Component} from "react";
import NetworkController from "controllers/Network";

const MetaContext = React.createContext("meta");

class MetaProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCurrency: null,
            globalCountries: [],
            globalLanguages: [],
            getCurrentCurrency: this.getCurrentCurrency.bind(this),
            getGlobalCountries: this.getGlobalCountries.bind(this),
            getCitiesAutocomplete: this.getCitiesAutocomplete.bind(this),
            getGlobalLanguages: this.getGlobalLanguages.bind(this)
        };
    }

    async getCurrentCurrency() {
        const {response} = await NetworkController.get("/wallet/currency");
        this.setState({currentCurrency: response.currency});
    }

    async getGlobalCountries() {
        if (this.state.globalCountries.length) {
            return;
        }
        const {response} = await NetworkController.get("/locations/countries");
        this.setState({globalCountries: response.countries});
    }

    async getCitiesAutocomplete(country, search) {
        const {response} = await NetworkController.get("/locations/cities/autocomplete", {country, search});
        return response.cities;
    }

    async getGlobalLanguages() {
        if (this.state.globalLanguages.length) {
            return;
        }
        const {response} = await NetworkController.get("/meta/languages");
        this.setState({globalLanguages: response.languages});
    }

    render() {
        return <MetaContext.Provider value={this.state}>{this.props.children}</MetaContext.Provider>;
    }
}

export default {Provider: MetaProvider, Consumer: MetaContext.Consumer};
