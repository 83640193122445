import React, {Component} from "react";
import styled from "styled-components";
import PaymentMethods from "consts/paymentMethods";

const Container = styled.div`
    font-size: 0.8rem;
    color: #8b8b8b;
`;

class TransferingInfo extends Component {
    render() {
        const {t, paymentMethod, currency} = this.props;

        const fee = currency.info[paymentMethod].fee;
        const minimumIncome = currency.info[paymentMethod].minimumIncome + currency.sign;
        return (
            <Container>
                {paymentMethod === PaymentMethods.paypal &&
                    t("wallet.payment.transferingInfoPaypal", {
                        percentage: fee.income.percentage,
                        perTransaction: fee.income.perTransaction,
                        minimumIncome
                    })}
                {paymentMethod === PaymentMethods.telr && t("wallet.payment.transferingInfoTelr", {minimumIncome})}
            </Container>
        );
    }
}

export default TransferingInfo;
