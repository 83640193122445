import React from "react";
import NetworkController from "controllers/Network";
import EventSourceController from "controllers/EventSource";
import AsyncStateComponent from "./common/AsyncStateComponent";
import uniqBy from "lodash/uniqBy";
import {withContextConsumer} from "../utils/contexts";
import CurrentUserContext from "./CurrentUser";
import userTypes from "consts/userTypes";
import notificationsType from "consts/notificationsType";

const {globalType, walletType, chatType, allTypes} = notificationsType;
const EVENT_NEW_NOTIFICATION = "newNotification";
const START_OFFSET = 0;
const START_LIMIT = 7;
const TIME_FOR_NOTIFICATION = 3000; // ms

const NotificationsContext = React.createContext("notifications");

@withContextConsumer(CurrentUserContext.Consumer)
class NotificationsProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            chatNotifications: {
                headerNotifications: [],
                notifications: [],
                totalNotifications: 0
            },
            allNotifications: {
                headerNotifications: [],
                notifications: [],
                totalNotifications: 0
            },
            newGlobalNotification: null,
            newChatNotification: null,
            loadNotifications: this.loadNotifications.bind(this),
            setViewed: this.setViewed.bind(this),
            getNotifications: this.getNotifications.bind(this),
            clearAllByType: this.clearAllByType.bind(this),
            clearByTypeAndIds: this.clearByTypeAndIds.bind(this),
            updateChatNotificationView: this.updateChatNotificationView.bind(this),
            hideChatNotification: this.hideChatNotification.bind(this)
        };
        this.listener = this.listener.bind(this);
        this.tmpState = null;
        this.timeouts = [];
    }

    async componentDidMount() {
        const {currentUser} = this.props;
        if (currentUser && currentUser.role !== userTypes.admin) {
            await this.loadNotifications();

            if (EventSourceController.supportEventSource) {
                this.notificationSource = EventSourceController;
                this.notificationSource.addEventListener(EVENT_NEW_NOTIFICATION, this.listener);
            }
        }
    }

    async componentDidUpdate() {
        const {currentUser} = this.props;
        if (currentUser && currentUser.role !== userTypes.admin) {
            if (!EventSourceController.source && EventSourceController.supportEventSource) {
                EventSourceController.init();
                await this.loadNotifications();

                this.notificationSource = EventSourceController;
                this.notificationSource.addEventListener(EVENT_NEW_NOTIFICATION, this.listener);
            }
        } else {
            EventSourceController.close();
        }
    }

    componentWillUnmount() {
        if (this.notificationSource) {
            this.notificationSource.removeEventListener(EVENT_NEW_NOTIFICATION, this.listener);
            this.notificationSource.close();
        }

        this.timeouts.forEach(id => clearTimeout(id));
    }

    hideChatNotification(notificationId) {
        const notificationsState = this.state.chatNotifications;
        const headerNotifications = notificationsState.headerNotifications.filter(({id}) => notificationId === id);
        const notifications = notificationsState.notifications.filter(({id}) => notificationId !== id) || [];
        const totalNotifications = notificationsState.totalNotifications - 1;

        this.setState({
            newChatNotification: null,
            chatNotifications: {headerNotifications, notifications, totalNotifications}
        });
    }

    listener(event) {
        const data = JSON.parse(event.data);
        this.createNotification(data);
    }

    createNotification(data) {
        const type = data.type === chatType ? data.type : allTypes;
        const notificationsState = this.state[type];

        const notifications = notificationsState.notifications || [];
        const totalNotifications = notificationsState.totalNotifications + 1;
        notifications.unshift(data);
        if (notifications.length > 5) {
            notifications.pop();
        }
        const headerNotifications = notifications.slice(0, 4);

        this.setState({
            newGlobalNotification: [globalType, walletType, allTypes].includes(type) ? data : null,
            newChatNotification: type === chatType ? data : null,
            [type]: {notifications, totalNotifications, headerNotifications}
        });
        this.removeNewNotification(type);
    }

    removeNewNotification(type) {
        let newState = {};

        if ([globalType, walletType, allTypes].includes(type)) newState = {newGlobalNotification: null};
        if (type === chatType) newState = {newChatNotification: null};

        this.timeouts.push(setTimeout(() => this.setState(newState), TIME_FOR_NOTIFICATION));
    }

    async clearAllByType(type) {
        const {error} = await NetworkController.delete("/notifications", {type});
        if (!error) {
            this.setState({
                [type]: {
                    headerNotifications: [],
                    notifications: [],
                    totalNotifications: 0
                }
            });
        }
    }

    async clearByTypeAndIds(type, ids) {
        const {error} = await NetworkController.delete("/notifications/ids", {ids});
        if (!error) {
            await this.getNotifications(type, START_OFFSET, START_LIMIT);
        }
    }

    async loadNotifications() {
        await this.getNotifications(chatType, START_OFFSET, START_LIMIT);
        await this.getNotifications(allTypes, START_OFFSET, START_LIMIT);
    }

    async updateChatNotificationView() {
        await this.getNotifications(chatType, START_OFFSET, START_LIMIT);
    }

    async getNotifications(type, offset, limit) {
        const notifications = this.state[type].notifications;
        const {error, response} = await NetworkController.get("/notifications", {type, offset, limit});
        if (!error) {
            let resNotifications = response.notifications;
            resNotifications.concat(notifications);
            resNotifications.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
            resNotifications = uniqBy(resNotifications, u => u.id);
            this.setState({
                [type]: {
                    headerNotifications: resNotifications.slice(0, 4),
                    notifications: resNotifications,
                    totalNotifications: response.count
                }
            });
        }
    }

    async setViewed(ids) {
        if (!ids || ids.length < 1) {
            return;
        }

        await NetworkController.post("/notifications", {ids});

        this.tmpState = this.state;
        await ids.forEach(id => this.setViewedNotification(id));

        this.setState(this.tmpState);
        this.tmpState = null;
    }

    setViewedNotification(id) {
        this.updateFieldInObjectById(id, "isViewed", true, chatType).updateFieldInObjectById(id, "isViewed", true, allTypes);
    }

    updateFieldInObjectById(id, field, value, type) {
        const notificationIndex = this.tmpState[type].notifications.findIndex(u => u.id === id);
        const notificationObject = this.tmpState[type];
        if (notificationIndex !== -1) {
            notificationObject.notifications[notificationIndex].isViewed = true;
            notificationObject.headerNotifications = notificationObject.notifications.slice(0, 4);
            this.tmpState[type] = notificationObject;
        }
        return this;
    }

    render() {
        return <NotificationsContext.Provider value={this.state}>{this.props.children}</NotificationsContext.Provider>;
    }
}

export default {Provider: NotificationsProvider, Consumer: NotificationsContext.Consumer};
