import moment from "moment-timezone";
import {convertTime24to12} from "./time";
import {isLiveMode, isSelfMode} from "../consts/courseMode";

export function getReviewResultHintData(reviewResult) {
    if (!reviewResult) {
        return null;
    }
    const reviewResultHintData = {timeout: 3000};
    switch (reviewResult) {
        case "reviewSuccess":
            reviewResultHintData["text"] = "Review was left successfully";
            break;
        case "reviewExists":
            reviewResultHintData["text"] = "You have already left review for this course";
            break;
        default:
            reviewResultHintData["text"] = "Couldn't save the review";
    }
    return reviewResultHintData;
}

export function getLectureDataForDropdown(lecture) {
    const startDate = moment(lecture.startDate);
    return {
        name: lecture.name
            ? lecture.name
            : startDate.format("DD MMMM, YYYY") + " " + convertTime24to12(startDate.format("HH:mm")),
        id: lecture.id
    };
}

export function getLectureDataForAssessmentDropdown(lecture) {
    const startDate = moment(lecture.startDate);
    return {
        name: lecture.name
            ? lecture.name
            : startDate.format("DD MMMM, YYYY") + " " + convertTime24to12(startDate.format("HH:mm")),
        id: lecture._id
    };
}
