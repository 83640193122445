import React, {PureComponent} from "react";
import styled from "styled-components";
import Button from "components/form/Button";
import {withNamespaces} from "react-i18next";
import GlobalHintsContext from "contexts/GlobalHints";
import {withContextConsumer} from "utils/contexts";
import {getReviewResultHintData} from "utils/lectureHelpers";
import LeaveReview from "components/common/inputs/LeaveReview";

const ModalContent = styled.div`
    display: flex;
    flex-flow: column;
    padding: 1rem;
`;

const Title = styled.div`
    margin: 1rem 0;
    font-size: 1.5rem;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Delimiter = styled.div`
    width: 100%;
    height: 1px;
    background: #ebebeb;
`;

@withNamespaces("translation")
@withContextConsumer(GlobalHintsContext.Consumer)
class StudentsContent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            rating: null,
            text: "",
            isCourseReview: false
        };
    }

    openCourseReviewModal() {
        this.setState({isCourseReview: true, text: ""}, () => this.leaveReviewComponent && this.leaveReviewComponent.resetText());
    }

    async onConfirm() {
        const {
            disconnectFromLecture,
            isAdHocLecture,
            isLastCourseLecture,
            onLeaveLectureReview,
            onLeaveCourseReview
        } = this.props;
        const {rating, text, isCourseReview} = this.state;

        if (isAdHocLecture) {
            return disconnectFromLecture("/meetings");
        }
        if (!isCourseReview) {
            const error = await onLeaveLectureReview({rating, text});

            const hintData = getReviewResultHintData(error ? "reviewError" : "reviewSuccess");
            this.props.addHint(hintData);

            if (isLastCourseLecture) {
                return this.openCourseReviewModal();
            }
            return disconnectFromLecture("/meetings");
        }
        await onLeaveCourseReview(text);
    }

    getTitleAndHint() {
        const {t} = this.props;
        const {isCourseReview} = this.state;
        return isCourseReview
            ? {
                  title: t("lecture.notifications.courseDidFinished"),
                  hint: t("lecture.review.courseFeedbackHint")
              }
            : {
                  hint: t("lecture.review.lectureFeedbackHint")
              };
    }

    render() {
        const {t, isAdHocLecture} = this.props;
        const {isCourseReview} = this.state;
        const {title, hint} = this.getTitleAndHint();
        return (
            <ModalContent>
                {title && <Title>{title}</Title>}
                <Delimiter />
                {!isAdHocLecture && (
                    <LeaveReview
                        getTextFieldRef={ref => (this.leaveReviewComponent = ref)}
                        onTextChange={text => this.setState({text})}
                        onRate={rating => this.setState({rating})}
                        hint={hint}
                        ratingHint={t(`lecture.review.ratingHint`)}
                        withoutRating={isCourseReview}
                    />
                )}
                <ButtonWrapper>
                    <Button onClick={this.onConfirm.bind(this)}>{t("lecture.notifications.ok")}</Button>
                </ButtonWrapper>
            </ModalContent>
        );
    }
}

export default StudentsContent;
