import React from "react";

export default props => (
    <svg viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <rect width="14" height="14" fill="#BCC3CA" fillRule="nonzero" rx="2" />
            <path
                fill="#FFF"
                d="M9.306 7.447L5.924 9.138a.5.5 0 0 1-.724-.447V5.309a.5.5 0 0 1 .724-.447l3.382 1.69a.5.5 0 0 1 0 .895z"
            />
        </g>
    </svg>
);
