import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import TextButton from "components/form/TextButton";
import Scrollbars from "components/common/Scrollbars";
import Button from "components/form/Button";
import {mediaQuery} from "components/common/styled/mediaQuery";
import FlexMarginBetween from "../styled/FlexMarginBetween";

const AssignmentsContainer = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: flex-start;
`;

const AssignmentsListContainer = styled.div`
    display: flex;
    flex-flow: column;
    padding: 0 0 1rem 0;
    width: 100%;
`;

const Assignment = styled.div`
    display: flex;
    padding: 0.7rem 0;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    min-width: 0;
    word-break: break-word;
    ${FlexMarginBetween(0.5)}
    ${mediaQuery.phone`
        padding: 1rem;
        flex-direction: column;
        justify-content: flex-start;
    `}
`;

const AssignmentName = styled.div``;

const Border = styled.div`
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
`;
const ButtonWrapper = styled.div`
    padding: 1.5rem 0 1rem 0;
    ${mediaQuery.phone`
        padding: 1.5rem 1rem 0         
        width: 100%;
        > div {
            width: 100%;
        }
    `}
`;

const TextButtonWrapper = styled.div`
    ${mediaQuery.phone`
        display: flex;
        justify-content: flex-start;
        padding: 1rem 0 0 0;
     `}
`;

const NoAssignmentsMessage = styled.div`
    padding: 1rem;
    color: #8b8b8b;
`;

@withNamespaces("translation")
class AssignmentsList extends Component {
    renderAssignments() {
        const {assignments, setAssignment, lectureData, hideAttached, t} = this.props;

        const filteredAssignments = [];

        assignments.forEach((q, i) => {
            const isAttached = q.lecturesAttachedTo?.find(l => l?.lectureId === lectureData?.id);
            const isExcluded = hideAttached && isAttached;

            if (!isExcluded) {
                const buttonStyle = isAttached ? {color: "#b4b4b4", fontWeight: "normal", cursor: "auto"} : {};

                filteredAssignments.push(
                    <Assignment key={i}>
                        <AssignmentName>{q?.name}</AssignmentName>
                        <TextButtonWrapper>
                            <TextButton
                                titleStyle={{...buttonStyle, wordBreak: "keep-all"}}
                                title={t(`lecture.notifications.${isAttached ? "attached" : "attach"}`)}
                                onClick={isAttached ? () => {} : () => setAssignment(q)}
                            />
                        </TextButtonWrapper>
                    </Assignment>
                );
            }
        });

        if (!filteredAssignments.length) {
            return <NoAssignmentsMessage>{t("lecture.digest.noAssignmentsAvailable")}</NoAssignmentsMessage>;
        }

        return (
            <AssignmentsListContainer>
                <Border />
                {filteredAssignments}
            </AssignmentsListContainer>
        );
    }

    render() {
        const {assignments, onClose, closeTitle} = this.props;

        if (!assignments) {
            return null;
        }

        return (
            <AssignmentsContainer>
                {!!assignments.length && (
                    <Scrollbars autoHeightMax="30rem" autoHeight autoHide>
                        <AssignmentsListContainer>{this.renderAssignments()}</AssignmentsListContainer>
                    </Scrollbars>
                )}
                <ButtonWrapper>
                    <Button onClick={onClose}>{closeTitle}</Button>
                </ButtonWrapper>
            </AssignmentsContainer>
        );
    }
}

export default AssignmentsList;
