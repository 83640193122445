import React from "react";

export default ({fillColor, opacity, ...props}) => (
    <svg viewBox="0 0 21 21" {...props}>
        <path
            fill={fillColor || "#FFF"}
            d="M10.5 3.15c4.06 0 7.35 3.29 7.35 7.35s-3.29 7.35-7.35 7.35-7.35-3.29-7.35-7.35 3.29-7.35 7.35-7.35zm1.64 10.48l-.298.011c-.428.013-.875.02-1.342.02-.577 0-1.124-.01-1.642-.03.374 1.822 1.033 3.036 1.642 3.036s1.268-1.214 1.64-3.036zm-7.207-.473l.04.082c.724 1.458 2.008 2.59 3.57 3.111-.4-.728-.71-1.687-.91-2.79-1.044-.084-1.943-.217-2.7-.403zm11.134 0c-.761.186-1.658.32-2.7.402-.2 1.104-.51 2.063-.91 2.792 1.591-.533 2.894-1.696 3.61-3.193zM10.5 7.938c-.62 0-1.204.011-1.75.035-.122.77-.193 1.625-.193 2.528 0 .678.04 1.33.112 1.938.569.027 1.18.04 1.831.04.652 0 1.262-.013 1.832-.04.07-.608.111-1.26.111-1.938 0-.903-.071-1.758-.193-2.528-.546-.024-1.13-.036-1.75-.036zm-2.964.114l-.223.02c-.923.09-1.705.223-2.345.4l-.209.06-.117.037c-.2.607-.31 1.257-.31 1.932 0 .444.048.877.137 1.294l.29.09c.71.217 1.613.375 2.706.474-.06-.594-.09-1.217-.09-1.858v-.257c.01-.765.066-1.502.161-2.192zm5.93 0l.002.032c.103.758.158 1.571.158 2.417 0 .641-.031 1.264-.092 1.858.99-.09 1.823-.228 2.498-.414l.209-.06.29-.09c.09-.417.136-.85.136-1.294 0-.675-.108-1.325-.309-1.932l-.117-.037c-.725-.222-1.651-.383-2.776-.48zM8.542 4.648l-.195.07c-1.26.469-2.326 1.339-3.042 2.454.704-.149 1.52-.258 2.447-.327.197-.854.464-1.602.79-2.197zm3.913 0l.029.052c.313.586.57 1.315.76 2.145.929.07 1.745.178 2.45.327C14.941 6 13.802 5.099 12.456 4.65zM10.5 4.334l-.079.006c-.517.088-1.06 1.022-1.426 2.44.476-.017.978-.026 1.505-.026.526 0 1.028.009 1.506.026-.386-1.49-.966-2.446-1.506-2.446z"
            opacity={opacity || ".3"}
        />
    </svg>
);
