import React from "react";
import styled from "styled-components";
import userPicPlaceholder from "assets/icons/profilePlaceholder.png";
import Img from "./Img";

const Avatar = styled(Img)`
    display: block;
    width: ${props => props.width || "5rem"};
    height: ${props => props.height || "5rem"};
    border-radius: 50%;
`;

export default ({src, placeholder, ...rest}) => <Avatar src={src || placeholder || userPicPlaceholder} {...rest} />;
