import React from "react";
import Circle from "./Circle";

export default props => {
    const {active} = props;
    const color = props.color || "var(--white)";
    if (active) {
        return (
            <div>
                <Circle />
                <svg viewBox="0 0 20 20" {...props}>
                    <g fill="none" fillRule="evenodd">
                        <g fill="#FFF">
                            <path d="M8.562 1.005c.075-.004-.076 0 0 0zM19.7 14.419c.381.73.388 1.638.017 2.375-.37.738-1.062 1.194-1.81 1.194H2.084c-.74 0-1.424-.445-1.798-1.169a2.643 2.643 0 0 1-.02-2.351L4.322 8.28a.599.599 0 0 1 .529-.351h10.115c.214 0 .413.128.522.337l4.212 6.153zM10 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
                            <path
                                stroke="#FFF"
                                strokeWidth=".6"
                                d="M19.187 6.846l-.463-.875c-.584-1.104-1.652-1.79-2.786-1.79H4.053c-1.134 0-2.202.686-2.786 1.79l-.463.875c-.193.364-.089.837.232 1.055.321.22.737.1.93-.263l.463-.876c.34-.644.963-1.044 1.624-1.044h11.884c.662 0 1.285.4 1.625 1.044l.463.876c.127.24.351.373.581.373a.615.615 0 0 0 .349-.11c.32-.218.424-.69.232-1.055z"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
    return (
        <div>
            <svg viewBox="0 0 20 20" {...props}>
                <g fill={color} fillRule="evenodd">
                    <path d="M8.562 1.005c.075-.004-.076 0 0 0zM19.7 14.419c.381.73.388 1.638.017 2.375-.37.738-1.062 1.194-1.81 1.194H2.084c-.74 0-1.424-.445-1.798-1.169a2.643 2.643 0 0 1-.02-2.351L4.322 8.28a.599.599 0 0 1 .529-.351h10.115c.214 0 .413.128.522.337l4.212 6.153zM10 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
                    <path
                        stroke="#FFF"
                        strokeWidth=".6"
                        d="M19.187 6.846l-.463-.875c-.584-1.104-1.652-1.79-2.786-1.79H4.053c-1.134 0-2.202.686-2.786 1.79l-.463.875c-.193.364-.089.837.232 1.055.321.22.737.1.93-.263l.463-.876c.34-.644.963-1.044 1.624-1.044h11.884c.662 0 1.285.4 1.625 1.044l.463.876c.127.24.351.373.581.373a.615.615 0 0 0 .349-.11c.32-.218.424-.69.232-1.055z"
                    />
                </g>
            </svg>
        </div>
    );
};
