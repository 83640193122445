import React from "react";

export default props => (
    <svg viewBox="0 0 32 33" {...props}>
        <path
            fill="#FFF"
            d="M22.4 7.4H9.6c-1.423 0-2.591 1.092-2.71 2.485l-.01.234v10.898c0 1.424 1.093 2.591 2.485 2.71l.235.01h10.248c.232 0 .462.04.68.115l.215.088 4.377 2.083V10.12c0-1.423-1.093-2.59-2.485-2.71L22.4 7.4z"
        />
    </svg>
);
