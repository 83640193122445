import React from "react";
import {withRouter} from "react-router-dom";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import NetworkController from "controllers/Network";
import {withContextConsumer} from "utils/contexts";
import CurrentUserContext from "./CurrentUser";

const CustomURLContext = React.createContext("course");

@withRouter
@withContextConsumer(CurrentUserContext.Consumer)
class CustomURLProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            getPageInfo: this.getPageInfo
        };
    }

    async getPageInfo(url) {
        const {response} = await NetworkController.get("/custom-url", {url});
        return response;
    }

    render() {
        return <CustomURLContext.Provider value={this.state}>{this.props.children}</CustomURLContext.Provider>;
    }
}

export default {
    Context: CustomURLContext,
    Provider: CustomURLProvider,
    Consumer: CustomURLContext.Consumer
};
