import {css} from "styled-components";
import getScrollbarWidth from "react-custom-scrollbars-rtl/lib/utils/getScrollbarWidth";

export const scrollbarLineFix = css`
    > div:first-child > div:first-child {
        margin-bottom: ${-getScrollbarWidth()}px !important;
    }
`;

export const hideDefaultScrollBar = css`
    > div > div > div::-webkit-scrollbar {
        width: 0;
    }
    > div > div > div {
        -ms-overflow-style: none;
    }
    > div > div > div {
        overflow: -moz-scrollbars-none;
    }
`;
