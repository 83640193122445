import React from "react";
import styled from "styled-components";

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1rem;
`;

export const Title = styled.div`
    font-size: 0.8rem;
    color: #8b8b8b;
    padding: 0.3rem 0;
`;

export const Value = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    color: #363636;
    padding: 0.3rem 0;
`;

export const Row = styled.div`
    display: flex;
`;
