import React, {Component} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Scrollbars from "components/common/Scrollbars";
import OutsideClickHandler from "react-outside-click-handler";
import dropdownArrow from "assets/icons/dropdownArrow.svg";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {withContextConsumer} from "utils/contexts";
import WindowRectContext from "contexts/WindowRect";
import {withNamespaces} from "react-i18next";
import {Manager, Popper, Reference} from "react-popper";
import {hideDefaultScrollBar} from "../common/styled/scrollbarFix";
import Tooltip from "components/common/Tooltip";

const DropdownTitle = styled.div`
    color: #9e9e9e;
    font-size: 0.9rem;
    ${mediaQuery.phone`margin-bottom: 0;`}
`;

const DropdownWrapper = styled.div`
    position: relative;
    border-bottom: 1px solid ${props => (props.error ? "#fd0f0f" : "#cbcbcb")};
    overflow: hidden;
    padding: 0.7rem 0;
    color: #212931;
    width: 100%;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    pointer-events: ${props => (props.invisible ? "none" : "all")};
    ${props => props.withoutPointer && "pointer-events: none;"}
    ${props => props.halfOpacity && "opacity: 0.5;"}
    opacity: ${props => props.invisible && 0};
    img {
        width: 10px;
        ${props => props.isExpanded && "transform: rotate(180deg);"}
    }
`;

const DropdownContent = styled.div`
    display: ${props => (props.isExpanded ? "block" : "none")};
    width: 100%;
    z-index: 1001;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    ${hideDefaultScrollBar}
`;

const DropdownItem = styled.div`
    padding: 0.7rem 0.2rem;
    ${props => props.selected && "background-color: #eeeeee"};
    ${props => props.isRTL && "direction: rtl"};
    cursor: pointer;
    &:hover {
        background-color: #eeeeee;
    }
`;

const SearchBarItem = styled.div`
    input {
        color: #212931;
        width: 100%;
        border: none;
        outline: none;
        padding: 0.7rem 0.2rem;
        font-size: 1rem;
        box-sizing: border-box;
    }
`;

const Placeholder = styled.span`
    opacity: 0.5;
`;

const CurrentItem = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
`;

@withNamespaces("translation")
@withContextConsumer(WindowRectContext.Consumer)
class DropdownPopper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            tooltipIsOpen: false,
            filter: ""
        };
        this.portal = document.createElement("div");
        document.body.appendChild(this.portal);
        document.querySelector("body").style.overflow = "hidden";
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isExpanded: state.isExpanded ? props.data.length !== 0 : state.isExpanded
        };
    }

    componentWillUnmount() {
        this.portal.remove();
        document.querySelector("body").style.overflow = "auto";
    }

    setIsExpanded(isExpanded, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({isExpanded, filter: ""}, () => {
            if (this.selectedElement) {
                const offset = ReactDOM.findDOMNode(this.selectedElement).offsetTop;
                this.contentScrollbar.scrollTop(offset);
            }
            this.scheduleUpdate && this.scheduleUpdate();
        });
    }

    setTooltipIsOpen(tooltipIsOpen) {
        if (this.state.isExpanded && tooltipIsOpen) {
            return;
        }
        this.setState({tooltipIsOpen});
    }

    onSelectItem(id) {
        this.props.onChange(id);
        this.setIsExpanded(false);
    }

    renderSearchBar() {
        const {placeholder} = this.props;
        const {filter} = this.state;
        return (
            <SearchBarItem>
                <input
                    type="text"
                    placeholder={placeholder}
                    value={filter}
                    onChange={e => this.setState({filter: e.target.value})}
                    autoFocus
                />
            </SearchBarItem>
        );
    }

    render() {
        const {
            title,
            data,
            current,
            invisible,
            placeholder,
            error,
            forceSearchBar,
            i18n,
            tooltip,
            disabled,
            tooltipAlignLeft,
            referenceStyle,
            popperStyle,
            containerStyle,
            withNoValueItem
        } = this.props;

        const {isExpanded, tooltipIsOpen, filter} = this.state;

        const selectedItem = current && data.find(d => d.id === current);
        const showSearchBar = forceSearchBar || data.length > 20;

        const filteredData = data.filter(d => d.name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
        const isEmptyValueShown = withNoValueItem && current;
        let popperPlacement = "bottom-start";
        return (
            <Manager>
                <Reference>
                    {({ref}) => (
                        <div style={containerStyle}>
                            {title && <DropdownTitle>{title}</DropdownTitle>}
                            <DropdownWrapper
                                ref={ref}
                                error={error}
                                isExpanded={isExpanded}
                                invisible={invisible}
                                withoutPointer={isExpanded || disabled}
                                halfOpacity={disabled}
                                onClick={e => this.setIsExpanded(true, e)}
                                onMouseEnter={this.setTooltipIsOpen.bind(this, true)}
                                onMouseLeave={this.setTooltipIsOpen.bind(this, false)}
                                style={referenceStyle}>
                                <CurrentItem>
                                    {selectedItem ? selectedItem.name : <Placeholder>{placeholder}&nbsp;</Placeholder>}
                                </CurrentItem>
                                <img src={dropdownArrow} alt={"arrow"} />
                            </DropdownWrapper>
                        </div>
                    )}
                </Reference>
                {ReactDOM.createPortal(
                    <Popper
                        placement={popperPlacement}
                        outOfBoundaries
                        modifiers={{
                            preventOverflow: {escapeWithReference: true},
                            computeStyle: {gpuAcceleration: false},
                            autoSizing: {
                                enabled: true,
                                fn: styles => {
                                    styles.styles.width = styles.offsets.reference.width;
                                    return styles;
                                }
                            },
                            placement: {popperPlacement},
                            flip: {enabled: false}
                        }}>
                        {props => {
                            const {style, ref, outOfBoundaries, scheduleUpdate} = props;
                            this.scheduleUpdate = scheduleUpdate;
                            return (
                                <DropdownContent
                                    style={{...style, ...popperStyle}}
                                    ref={ref}
                                    isExpanded={outOfBoundaries ? false : isExpanded}>
                                    <OutsideClickHandler
                                        disabled={!isExpanded}
                                        onOutsideClick={() => isExpanded && this.setIsExpanded(false)}>
                                        {showSearchBar && this.renderSearchBar()}
                                        <Scrollbars scrollRef={r => (this.contentScrollbar = r)} autoHeightMax={300} autoHeight>
                                            {isEmptyValueShown && (
                                                <DropdownItem
                                                    onClick={this.onSelectItem.bind(this, "")}
                                                    selected={current === ""}
                                                    ref={r => (current === "" ? (this.selectedElement = r) : null)}
                                                    isRTL={i18n.dir() === "rtl"}>
                                                    -
                                                </DropdownItem>
                                            )}
                                            {filteredData.map((d, i) => {
                                                const selected = d.id === current;
                                                return (
                                                    <DropdownItem
                                                        onClick={this.onSelectItem.bind(this, d.id)}
                                                        selected={selected}
                                                        ref={r => (selected ? (this.selectedElement = r) : null)}
                                                        key={i}
                                                        isRTL={i18n.dir() === "rtl"}>
                                                        {d.name}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </Scrollbars>
                                    </OutsideClickHandler>
                                </DropdownContent>
                            );
                        }}
                    </Popper>,
                    this.portal
                )}
                {tooltip && (
                    <Popper placement="bottom">
                        {props => (
                            <div ref={props.ref} style={props.style}>
                                <Tooltip
                                    tooltipIsOpen={tooltipIsOpen}
                                    tooltipAlignLeft={i18n.dir() === "rtl" ? !tooltipAlignLeft : tooltipAlignLeft}
                                    hideOnPhoneResolution>
                                    {tooltip}
                                </Tooltip>
                            </div>
                        )}
                    </Popper>
                )}
            </Manager>
        );
    }
}

export default DropdownPopper;
