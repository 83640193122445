import React from "react";

export default props => (
    <svg viewBox="0 0 14 11" {...props}>
        <g fill="#5C6977" fillRule="evenodd" transform="translate(4.5 1)">
            <rect width="4" height="4" rx=".7" />
            <rect width="4" height="4" y="5" rx=".7" />
            <rect width="4" height="4" x="5" y="5" rx=".7" />
            <rect width="4" height="4" x="5" rx=".7" />
        </g>
    </svg>
);
