import React, {Component} from "react";
import styled from "styled-components";
import {libraryItemTypes} from "consts/library";
import AbsoluteFill from "components/common/styled/AbsoluteFill";
import EllipsisText from "components/common/styled/EllipsisText";
import FolderBig from "components/common/icons/library/FolderBig";
import DocumentBig from "components/common/icons/library/DocumentBig";
import SelectGridCheckmark from "components/common/icons/library/SelectGridCheckmark";
import {mediaQuery} from "components/common/styled/mediaQuery";

const FileContainer = styled.div`
    position: relative;
    width: ${({size}) => (size === "small" ? "3rem" : "6.3rem")};
    margin: ${({size}) => (size === "small" ? "0.2rem 0.171rem" : "0 0.171rem 1rem 0.171rem")};
    cursor: pointer;
    ${props => props.disabled && "opacity: 0.5; pointer-events: none;"}
    ${mediaQuery.phone`width: 30%;`}
`;

const FileName = styled.div`
    font-size: 0.75rem;
    text-align: center;
    margin-top: 0.3rem;
    ${EllipsisText}
`;

const FileSign = styled.div`
    position: relative;
    padding-top: 100%;
    border-radius: 4px;
    overflow: hidden;
`;
const FolderBigStyled = styled(FolderBig)`
    ${AbsoluteFill}
`;
const DocumentBigStyled = styled(DocumentBig)`
    ${AbsoluteFill}
`;
const ImagePreview = styled.img`
    ${AbsoluteFill};
    width: 100%;
    height: 100%;
    user-drag: none;
`;
const ExtensionPreview = styled.div`
    position: absolute;
    left: 0;
    bottom: 1rem;
    width: 100%;
    font-size: ${({size}) => (size === "small" ? "0.9rem" : "1.2rem")};
    text-align: center;
    text-transform: uppercase;
    color: #bcc3ca;
    font-weight: 600;
`;

const SelectGridCheckmarkStyled = styled(SelectGridCheckmark)`
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
`;

class File extends Component {
    resolveFileBackground() {
        const {item, size} = this.props;

        if (item.type === libraryItemTypes.folder) {
            return <FolderBigStyled />;
        }
        if (item.type === libraryItemTypes.image) {
            return <ImagePreview src={item.preview} />;
        }
        return (
            <React.Fragment>
                <DocumentBigStyled />
                <ExtensionPreview size={size}>{item.extension}</ExtensionPreview>
            </React.Fragment>
        );
    }

    isSelected() {
        const {selectedItems, item} = this.props;
        return selectedItems && selectedItems.includes(item.id);
    }

    isFolder() {
        return this.props.item.type === "folder";
    }

    toggleSelection() {
        const {item, selectItem, unselectItem} = this.props;
        return this.isSelected() ? unselectItem(item.id) : selectItem(item.id);
    }

    onClick() {
        if (this.isSelected() || !this.isFolder()) {
            return this.toggleSelection();
        }
        clearTimeout(this.clickTimeout);
        this.clickTimeout = setTimeout(() => this.toggleSelection(), 300);
    }

    onDBClick() {
        if (!this.isFolder()) {
            return;
        }
        clearTimeout(this.clickTimeout);
        this.props.onSelectFolder(this.props.item.id);
    }

    render() {
        const {item, inSharingModal, size} = this.props;

        // check if item is null
        if (item === null) {
            return null;
        }

        if (inSharingModal && item.isMarkedForDeletion) {
            return null;
        }

        const disabled = !inSharingModal && item.isMarkedForDeletion;
        return (
            <FileContainer
                size={size}
                disabled={disabled}
                onClick={this.onClick.bind(this)}
                onDoubleClick={this.onDBClick.bind(this)}>
                <FileSign>{this.resolveFileBackground()}</FileSign>
                {this.isSelected() && <SelectGridCheckmarkStyled />}
                <FileName>{item.name}</FileName>
            </FileContainer>
        );
    }
}

export default File;
