import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import TextButton from "components/form/TextButton";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import AssignmentsAddEditContext from "contexts/assignment/AssignmentAddEdit";
import AssessmentAddEditContext from "contexts/assessment/AssessmentAddEdit";
import AssignmentsListContext from "contexts/assignment/AssignmentList";
import AssignmentStudentProgressContext from "contexts/assignment/AssignmentStudentProgress";
import CheckAssignmentData from "./CheckAssignmentData";
import CurrentUserContext from "contexts/CurrentUser";
import EnrollmentsContext from "contexts/Enrollments";
import CheckWhiteboard from "contexts/CheckWhiteboard";
import {Chip, Stack} from "@mui/material";
const AssignmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const StudentProgressContainer = styled.div`
    /* padding: 2.8rem 0; */
`;

const StudentProgressInnerContainerSubmitted = styled.div`
    padding: 1rem;
    min-width: 350px;
    margin-top: 1rem;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    color: var(--main-theme-color);
`;

const StudentProgressInnerContainer = styled.div`
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 4px;
    outline: none;
`;

const Title = styled.div`
    font-weight: bold;
    min-width: 0;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

@withNamespaces("translation")
@withContextConsumer(CurrentUserContext.Consumer)
@withContextProvider(AssignmentsAddEditContext.Provider)
@withContextConsumer(AssignmentsAddEditContext.Consumer)
@withContextProvider(AssessmentAddEditContext.Provider)
@withContextConsumer(AssessmentAddEditContext.Consumer)
@withContextConsumer(AssignmentStudentProgressContext.Consumer)
@withContextConsumer(AssignmentsListContext.Consumer)
@withContextConsumer(EnrollmentsContext.Consumer)
@withContextConsumer(CheckWhiteboard.Consumer)
class StudentAssignmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            feedback: "",
            grade: null
        };
    }

    async componentDidMount() {
        const {assignment, getStudentsWork, lecture} = this.props;

        const qs = {
            assessmentId: assignment?.id,
            lectureId: lecture?.id
        };

        await getStudentsWork(assignment, qs);
    }

    checkWithWhiteboard = (lectureId, assignmentId, submissionId, studentId) => {
        this.props.setStudentAndSubmission(studentId, submissionId, () => {
            this.props.history.push({
                pathname: `/lectures/${lectureId}/assignment/${assignmentId}`,
                search: `?submission=${submissionId}`
            });
        });
    };

    hasStudentSubmitted = studentId => {
        if (this.props.assignment?.results?.length > 0) {
            return this.props.assignment?.results?.findIndex(r => r?.student === studentId);
        }
        return -1;
    };

    render() {
        const {
            location,
            currentUser,
            lecture,
            assignment,
            students,
            mode,
            history,
            isLectureOwner,
            t,
            updateAssignment,
            setAssignment,
            setStudentSubmission
        } = this.props;
        // render the ui based on assignment submitted or not
        let renderView = null;
        if (students && students.length !== 0) {
            renderView = students.map(student => {
                const studentId = student?.id || student?._id;
                const index = this.hasStudentSubmitted(studentId);
                const assignmentId = assignment?.id || assignment?._id;
                if (index !== -1) {
                    const studentSubmission = assignment.results[index];

                    return (
                        <>
                            <StudentProgressInnerContainerSubmitted>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: `/assignments/check/${assignmentId}`,
                                            search: `?student=${studentId}&lecture=${lecture?.id || lecture?.id}`
                                        })
                                    }>
                                    {student?.firstName} {student?.lastName} {/* {t("assignment.result.Submission")}{" "} */}
                                    {studentSubmission?.status === "checked" ? (
                                        <Chip
                                            label={"Checked"}
                                            variant="filled"
                                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                                        />
                                    ) : (
                                        <Chip label={"Unchecked"} variant="filled" color="warning" />
                                    )}
                                </Stack>
                            </StudentProgressInnerContainerSubmitted>
                            {/* <CheckAssignmentData
                                checkAssignmentWithWhiteboard={() => {
                                    setAssignment(assignment);
                                    setStudentSubmission(studentSubmission);
                                    this.checkWithWhiteboard(
                                        lecture?.id,
                                        assignment?.id,
                                        studentSubmission?.id,
                                        studentSubmission?.student
                                    );
                                }}
                                currentUser={currentUser}
                                location={location}
                                mode={mode}
                                isLectureOwner={isLectureOwner}
                                history={history}
                                student={student}
                                assignment={assignment}
                                studentSubmission={studentSubmission}
                                updateAssignment={updateAssignment}
                                {...this.props}
                            /> */}
                        </>
                    );
                } else {
                    return (
                        <StudentProgressInnerContainer>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                {student?.firstName} {student?.lastName} {/* {t("assignment.result.noSubmission")}{" "} */}
                                <Chip label={"No Submission"} variant="filled" color="error" />
                            </Stack>
                        </StudentProgressInnerContainer>
                    );
                }
            });
        }

        return (
            <AssignmentContainer>
                <ListContainer>
                    <StudentProgressContainer>
                        <Title>{t("lecture.digest.checkAssignment.StudentProgress")}</Title>

                        {renderView}
                    </StudentProgressContainer>
                </ListContainer>
            </AssignmentContainer>
        );
    }
}

export default StudentAssignmentList;
