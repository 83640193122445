import React, {Component} from "react";
import get from "lodash/get";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
// import Button from "components/form/Button";
import {mediaQuery} from "components/common/styled/mediaQuery";
import ConfirmModal from "components/common/modals/ConfirmModal";
import {compose} from "recompose";
import TextButton from "components/form/TextButton";
import AddAssignmentModal from "./AddAssignmentModal";
import {Paper, Button, Stack} from "@mui/material";
// import {withContextConsumer, withContextProvider} from "utils/contexts";
// import EnrollmentsContext from "contexts/Enrollments";
import {getUserTime} from "utils/time";

import NetworkController from "controllers/Network";
// import LibraryContext from "contexts/Library";

import StudentAssignmentList from "components/assignment/check/StudentAssignmentList";
import AssignmentInfo from "./assignment/AssignmentInfo";

const AssignmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 1000px;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const MainContainer = styled.div`
    padding: ${props => props.padding || "0 0 0 0"};
    margin: ${props => props.margin || "0 0 0 0"};
    width: 75%;
`;

// const ButtonContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;

// const Title = styled.div`
//     font-weight: bold;
//     min-width: 0;
//     word-break: break-word;
//     height: fit-content;
//     ${mediaQuery.phone`
//         padding: 0.3 0.7;
//     `}
// `;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const NoAssignmentMessage = styled.div`
    padding: 1rem;
    color: #8b8b8b;
`;

const SpaceContainer = styled.div`
    padding: 1rem;
    margin-bottom: 0.5rem;
`;

// const InfoContainer = styled.div`
//     justify-content: center;
//     //align-items: left;
// `;

const AddAssignmentButtonWrapper = styled.div`
    padding: 0.8rem 0;
    max-width: 100%;
    ${mediaQuery.phone`
        width: 100%;
        order: 1;
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
    `}
`;

class AdminAssignmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddAssignmentModalOpen: false,
            isEditAssignmentModalOpen: false,
            isStudentAssignmentModalOpen: false,
            viewInPdf: null,
            pageNumber: 1,
            countPages: 1,
            pages: [],
            page: 1,
            scale: props.isPhoneResolution ? 2 : 1,

            showImages: false,
            showAudios: false,
            feedbackFiles: null,
            isOpen: false,
            onRequestClose: true,
            isDeleteOpen: false,
            onDeleteRequestClose: true,
            students: null,
            assignmentToDeleted: null,
            deleteAssignment: this.deleteAssignment.bind(this)
        };
    }

    async componentDidMount() {
        // get enroll student in the course.
        const {lecture} = this.props;
        const {course} = lecture;
        if (course) {
            await this.getCourseEnrollements(course);
        }
    }

    async getCourseEnrollements(course) {
        let courseId = course?.id || course?._id;
        const {response} = await NetworkController.get(`/courses/${courseId}/enrollments`);
        const {enrollments} = response;

        const students = enrollments?.map(e => e.user);
        this.setState({students});
    }

    onClick(assignment, isPassedByStudent) {
        const {currentUser, isLectureOwner, isParent, setOpenedAssignment, enterAssignment, showPersonalResult} = this.props;
        const hasSeveralResults = assignment.results.length > 1;
        if (isLectureOwner || (isParent && hasSeveralResults)) {
            return setOpenedAssignment(assignment);
        }
        const isChildResults =
            isParent && assignment.results.length && currentUser?.id !== get(assignment, "results[0].student.id", null);
        if (isPassedByStudent || isChildResults) {
            return showPersonalResult(assignment?.results[0]?.id);
        }
        return enterAssignment(assignment?.id);
    }

    toggleAddAssignmentModal() {
        this.setState({isAddAssignmentModalOpen: !this.state.isAddAssignmentModalOpen});
    }

    async closeAddAssignmentModal() {
        this.setState({isAddAssignmentModalOpen: !this.state.isAddAssignmentModalOpen});
    }

    async deleteAssignment(assignment) {
        const {lecture, updateAssignments} = this.props;
        // let qs = {
        //     lectureId: lecture.id
        // };
        await NetworkController.delete(`/assignments/${assignment?.id}`);
        updateAssignments(assignment?.id);
        this.handleDeleteDialogClose();
    }

    onFileDelete() {
        this.setState({showImages: !this.state.showImages});
    }

    handleDeleteDialogClose = () => this.setState({isDeleteOpen: false});

    renderAssignments = () => {
        const {assignments, isLectureOwner, currentUser, lecture, usersAttendance, t, history, updateAssignment} = this.props;

        const {students, isDeleteOpen} = this.state;

        return assignments.map((assignment, index) => {
            return (
                <Paper elevation={4}>
                    <SpaceContainer>
                        <AssignmentInfo assignment={assignment} />

                        <br />
                        {isLectureOwner ? (
                            // <ButtonContainer>
                            <Stack direction="row" justifyContent="left" alignItems="center" spacing={2}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#3d7cff",
                                        borderColor: "#3d7cff"
                                    }}
                                    // color="#3d7cff"
                                    onClick={() => {
                                        history.push(`/assignments/${assignment?.id}`);
                                    }}>
                                    {t("lecture.digest.editAssignment")}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                        this.setState({
                                            isDeleteOpen: !isDeleteOpen,
                                            assignmentToDeleted: assignment
                                        })
                                    }>
                                    {t("lecture.digest.deleteAssignment")}
                                </Button>
                                {/* <Button
                                type="light-black"
                                onClick={() =>
                                    this.setState({
                                        isDeleteOpen: !isDeleteOpen,
                                        assignmentToDeleted: assignment
                                    })
                                }>
                                {t("lecture.digest.deleteAssignment")}
                            </Button> */}
                                {/* </ButtonContainer> */}
                            </Stack>
                        ) : null}

                        <StudentAssignmentList
                            assignment={assignment}
                            currentUser={currentUser}
                            history={history}
                            usersAttendance={usersAttendance}
                            lecture={lecture}
                            students={students}
                            isLectureOwner={isLectureOwner}
                            updateAssignment={updateAssignment}
                        />
                    </SpaceContainer>
                </Paper>
            );
        });
    };

    render() {
        const {assignments, isLectureOwner, currentUser, lecture, getLectureData, t} = this.props;

        const {isAddAssignmentModalOpen, isDeleteOpen, assignmentToDeleted} = this.state;

        return (
            <AssignmentContainer>
                <ListContainer>
                    <MainContainer>
                        {assignments && assignments.length ? (
                            this.renderAssignments()
                        ) : (
                            <NoAssignmentMessage>{t("lecture.digest.noAssignmentsAssigned")}</NoAssignmentMessage>
                        )}
                    </MainContainer>
                </ListContainer>

                {/*Todo - Allow user to attach assignmetn from lecture*/}
                {isLectureOwner ? (
                    <AddAssignmentButtonWrapper>
                        <TextButton
                            onClick={this.toggleAddAssignmentModal.bind(this)}
                            title={t("lecture.digest.attachAssignment")}
                            withPlus
                        />
                    </AddAssignmentButtonWrapper>
                ) : null}

                {isAddAssignmentModalOpen ? (
                    <AddAssignmentModal
                        isOpen={isAddAssignmentModalOpen}
                        onClose={this.closeAddAssignmentModal.bind(this)}
                        lecture={lecture}
                        closeTitle={t("lecture.digest.ok")}
                        title={t("lecture.digest.attachAssignment")}
                        course={lecture.course}
                        getLectureData={getLectureData}
                    />
                ) : null}

                {isDeleteOpen ? (
                    <ConfirmModal
                        title={t("lecture.digest.deletemessage")}
                        headerStyle={{margin: "1rem 0 0"}}
                        onRequestClose={this.handleDeleteDialogClose}
                        buttonsContainerStyle={{type: "danger"}}
                        controlButtonsStyle={{flex: 1, width: "100%"}}
                        isOpen={isDeleteOpen}
                        confirmTitle={t("lecture.digest.confirmdeleteAssignment")}
                        cancelTitle={t("lecture.digest.canceldeleteAssignment")}
                        onConfirmClick={() => {
                            this.deleteAssignment(assignmentToDeleted);
                        }}
                        onCancelClick={this.handleDeleteDialogClose}
                        buttonsType={"danger"}
                    />
                ) : null}
            </AssignmentContainer>
        );
    }
}

export default compose(withNamespaces("translation"))(AdminAssignmentList);
