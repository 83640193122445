export default {
    allTypes: "allNotifications",
    globalType: "globalNotifications",
    walletType: "walletNotifications",
    chatType: "chatNotifications",
    allNotifications: "allNotifications",
    about: {
        attachQuizzes: "attachQuizzes"
    }
};
