import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {withContextConsumer} from "utils/contexts";
import CurrentUserContext from "contexts/CurrentUser";
import userTypes from "consts/userTypes";

const FooterMenuContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    ${mediaQuery.phone`
        height: 3.6rem;
        justify-content: space-between;
        ${p =>
            p.isLanding &&
            `
            min-width: unset;
            padding: 0.5rem;
            margin-right: unset;
        `}
        flex-direction: column;
        justify-content: space-around;
        &:first-child{margin-left: 0;}
    `}
    ${p =>
        p.isLanding &&
        `
            align-items: center;
            min-width: 26rem;
            font-size: 0.875rem;
            margin-right: 80px;
            line-height: 1.5;
            >a {
                color: var(--light-gray);
            }
            >a:visited{
                color: var(--gray);
            }
    `}
`;

const FooterItem = styled.a`
    padding: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    ${props => !props.withoutSeporator && "border-right: 1px solid #323232;"};
    &:visited {
        color: inherit;
    }
    ${mediaQuery.phone`
        padding: 0;
        border: none;
        ${props => props.alignRight && "text-align: right"}
    `}
`;

@withNamespaces("translation")
@withContextConsumer(CurrentUserContext.Consumer)
class FooterMenu extends Component {
    render() {
        const {t, currentUser, isLanding} = this.props;
        const isAdmin = currentUser && currentUser.role === userTypes.admin;

        if (isAdmin) {
            return null;
        }

        return (
            <FooterMenuContainer isLanding={isLanding}>
                <FooterItem target="_blank" href={"/privacy"} withoutSeporator>
                    {t("navigation.footer.privacy")}
                </FooterItem>
                <FooterItem target="_blank" href={"/terms"} withoutSeporator>
                    {t("navigation.footer.terms")}
                </FooterItem>
                <FooterItem target="_blank" href={"/payment-terms"} withoutSeporator>
                    {t("navigation.footer.payment")}
                </FooterItem>
            </FooterMenuContainer>
        );
    }
}

export default FooterMenu;
