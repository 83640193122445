import React from "react";

export default props => (
    <svg viewBox="0 0 32 33" {...props} fill={props.fill ? props.fill : "#FFF"}>
        <path
            fillRule="evenodd"
            d="M21.657 10.862c.542.543.542 1.422 0 1.965l-3.692 3.691 3.692 3.694c.542.542.542 1.422 0 1.964-.543.542-1.422.542-1.964 0L16 18.483l-3.693 3.693c-.542.542-1.421.542-1.964 0-.542-.542-.542-1.422 0-1.964l3.692-3.694-3.692-3.691c-.542-.543-.542-1.422 0-1.965.543-.542 1.422-.542 1.964 0L16 14.552l3.693-3.69c.542-.542 1.421-.542 1.964 0z"
        />
    </svg>
);
