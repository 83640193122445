import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import Recordings from "./Recordings";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import CurrentUserContext from "contexts/CurrentUser";
import BackTextButton from "components/common/BackTextButton";
import {withRouter} from "react-router-dom";
import DocumentList from "./DocumentList";
import QuizzesList from "./QuizzesList";
import AssignmentList from "./AssignmentList";
import AssessmentList from "./AssessmentList";
import MaterialTabs from "components/common/tabs/MaterialTabs";
import LectureReviewModal from "components/lectures/show/modals/modalLectureDidFinished/LectureDidFinishedModal";
import {mediaQuery} from "components/common/styled/mediaQuery";
import Button from "components/form/Button";
import ChatContext from "contexts/chat/Chat";
import ChecklistContext from "contexts/lectureReview/Checklist";
import QuizResultsModal from "./resultsList/QuizResultsModal";
import LectureInfo from "./lectureInfo/LectureInfo";
import userTypes, {isPrincipal} from "consts/userTypes";

import get from "lodash/get";

import ReviewsList from "./ReviewsList";

const {student, child, parent, principal, teacher} = userTypes;

const DigestContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50rem;
    margin: 3rem 0;
    ${mediaQuery.phone`
        margin: 0;
        width: 100%;
    `}
`;

const Title = styled.div`
    padding: 1rem 0 0 0;
    font-size: 2.1rem;
    font-weight: bold;
    ${mediaQuery.phone`
        padding: 1rem 0.8rem 0;
    `}
`;

const QuizzesTitle = styled.div`
    padding: 1rem 0 0 0;
    font-weight: 600;
    ${mediaQuery.phone`
        padding: 1rem 0.8rem 0;
    `}
`;

const BackButtonContainer = styled.div`
    ${mediaQuery.phone`
        padding: 1rem 0.8rem 0 0.8rem;
    `}
`;

const TabsContainer = styled.div`
    width: 50rem;
    max-width: 100%;
    padding: 1rem 0;
    ${mediaQuery.phone`
        font-size: 0.65rem;
        padding: 1rem 0.8rem;    
    `}
`;

const ReviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    //width: 100%;
    margin: 0.5rem 0;
    ${mediaQuery.phone`
        margin: 0;
        width: 100%;
    `}
`;

const ASSESSMENT_CATEGORY = {
    assignment: "assignment",
    quiz: "quiz"
};

@withRouter
@withNamespaces("translation")
@withContextConsumer(CurrentUserContext.Consumer)
@withContextConsumer(ChatContext.Consumer)
@withContextProvider(ChecklistContext.Provider)
@withContextConsumer(ChecklistContext.Consumer)
class LectureDigest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPage: "documents",
            showModal: false
        };
    }

    async componentDidMount() {
        const {hash} = window.location;
        const [tab] = hash.slice(1).split(":");

        if (tab) {
            let currentOpenedTab = tab.split("=")[1] || tab;
            this.setState({selectedPage: currentOpenedTab});
        }
    }

    async updateAssignments(assignmentId) {
        this.props.updateLectureAssignments(assignmentId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.quizzes.length !== this.props.quizzes.length) {
            this.setState({quizzes: this.sortQuizzes(this.props.quizzes)});
        }
    }

    hasNextLevel(quiz) {
        return Boolean(quiz.nextLevelQuiz);
    }

    hasPrevLevel(quiz) {
        const {quizzes} = this.props;
        return quizzes.some(el => el.nextLevelQuiz === quiz.id);
    }

    isQuizWithoutChain(quiz) {
        return !this.hasNextLevel(quiz) && !this.hasPrevLevel(quiz);
    }

    isQuizChainStart(quiz) {
        return this.hasNextLevel(quiz) && !this.hasPrevLevel(quiz);
    }

    getNextQuiz(quizzes, quiz) {
        const nextQuiz = quizzes.find(el => el.id === quiz.nextLevelQuiz);
        return nextQuiz ? {...nextQuiz, chainStart: quiz.chainStart} : null;
    }

    sortQuizzes(quizzes) {
        const quizzesWithChain = [];
        const quizzesWithoutChain = [];
        const quizzesChainStart = [];

        for (const q of quizzes) {
            if (this.isQuizWithoutChain(q)) {
                quizzesWithoutChain.push(q);
            } else if (this.isQuizChainStart(q)) {
                quizzesChainStart.push(q);
            } else {
                quizzesWithChain.push(q);
            }
        }

        let result = [...quizzesWithoutChain];
        for (const q of quizzesChainStart) {
            const quizStart = {...q, chainStart: q.id};
            const chain = [quizStart];

            let nextQuiz = this.getNextQuiz(quizzesWithChain, quizStart);
            while (nextQuiz) {
                chain.push(nextQuiz);
                nextQuiz = this.getNextQuiz(quizzesWithChain, nextQuiz);
            }
            result = [...result, ...chain];
        }
        return result;
    }

    getPrevQuiz(quiz) {
        const {quizzes} = this.props;
        return quizzes.find(el => el.nextLevelQuiz === quiz.id && el.chainStart === quiz.chainStart);
    }

    hasPassedThreshold(quiz, result) {
        return quiz.passThreshold <= result.rightAnswersPercent;
    }

    isPrevQuizPassed(quiz) {
        const prevQuiz = this.getPrevQuiz(quiz);
        if (!prevQuiz) {
            return true;
        }
        const result = prevQuiz.results[0];
        if (!result) {
            return false;
        }
        const hasPassedThreshold = this.hasPassedThreshold(prevQuiz, result);
        return hasPassedThreshold || quiz.results.length;
    }

    isQuizActive(quiz) {
        const {isLectureOwner} = this.props;
        return isLectureOwner || this.isQuizWithoutChain(quiz) || this.isQuizChainStart(quiz) || this.isPrevQuizPassed(quiz);
    }

    getPagesData() {
        const {t} = this.props;
        return [
            {id: "documents", title: t(`lecture.digest.documents`)},
            {id: "assignments", title: t(`lecture.digest.assignments`)},
            {id: "quizzes", title: t(`lecture.digest.quizzes`)},
            {id: "assessments", title: t(`lecture.digest.assessments`)},
            {id: "reviews", title: t(`lecture.digest.reviews`)}
        ];
    }

    getSelectedComponent() {
        const {selectedPage} = this.state;
        if (selectedPage === "documents") {
            const {currentUser, isLectureOwner, lecture, getLectureData} = this.props;

            const actions = lecture?.actions || [];
            return (
                <DocumentList
                    actions={actions}
                    isLectureOwner={isLectureOwner}
                    isParent={currentUser.role === userTypes.parent}
                    currentUser={currentUser}
                    lecture={lecture}
                    getLectureData={getLectureData}
                    history={this.props.history}
                />
            );
        }
        if (selectedPage === "quizzes") {
            const {
                currentUser,
                setOpenedQuiz,
                enterQuiz,
                showPersonalResult,
                isLectureOwner,
                lecture,
                getLectureData,
                usersAttendance
            } = this.props;

            const quizzes = this.sortQuizzes(this.props.quizzes);

            return (
                <QuizzesList
                    quizzes={quizzes}
                    isLectureOwner={isLectureOwner}
                    isParent={currentUser.role === userTypes.parent}
                    setOpenedQuiz={setOpenedQuiz}
                    currentUser={currentUser}
                    enterQuiz={enterQuiz}
                    showPersonalResult={showPersonalResult}
                    lecture={lecture}
                    getLectureData={getLectureData}
                    isQuizActive={this.isQuizActive.bind(this)}
                    getPrevQuiz={this.getPrevQuiz.bind(this)}
                    usersAttendance={usersAttendance}
                />
            );
        }
        if (selectedPage === "assignments") {
            const {
                currentUser,
                setOpenedQuiz,
                enterQuiz,
                showPersonalResult,
                isLectureOwner,
                lecture,
                getLectureData,
                usersAttendance,
                assignments,
                updateAssignment,
                updateSubmission
            } = this.props;

            return (
                <AssignmentList
                    assignments={assignments}
                    updateAssignment={updateAssignment}
                    updateSubmission={updateSubmission}
                    isLectureOwner={isLectureOwner}
                    isParent={currentUser.role === userTypes.parent}
                    setOpenedAssignment={setOpenedQuiz}
                    currentUser={currentUser}
                    enterAssignment={enterQuiz}
                    showPersonalResult={showPersonalResult}
                    lecture={lecture}
                    getLectureData={getLectureData}
                    usersAttendance={usersAttendance}
                    updateAssignments={this.updateAssignments.bind(this)}
                    history={this.props.history}
                />
            );
        }
        if (selectedPage === "assessments") {
            const {
                currentUser,
                isLectureOwner,
                lecture,
                getLectureData,
                usersAttendance,
                assessments,
                updateAssessment,
                updateAssessmentSubmission
            } = this.props;

            return (
                <AssessmentList
                    assessments={assessments}
                    assignments={assessments}
                    updateAssessment={updateAssessment}
                    updateAssessmentSubmission={updateAssessmentSubmission}
                    isLectureOwner={isLectureOwner}
                    isParent={currentUser.role === userTypes.parent}
                    currentUser={currentUser}
                    lecture={lecture}
                    getLectureData={getLectureData}
                    usersAttendance={usersAttendance}
                    updateAssignments={this.updateAssignments.bind(this)}
                    history={this.props.history}
                />
            );
        }
        if (selectedPage === "reviews") {
            const {lectureReviews, getLectureReviews, lecture} = this.props;
            return <ReviewsList reviews={lectureReviews} getLectureReviews={getLectureReviews} lectureId={lecture.id} />;
        }
    }

    submitReview = ({rating, text}) => {
        const {lecture, onLeaveLectureReview} = this.props;
        this.closeReviewModal();
        return onLeaveLectureReview({lectureId: lecture.id, rating, text});
    };

    openReviewModal = () => {
        this.setState({showModal: true});
    };

    closeReviewModal = () => {
        this.setState({showModal: false});
    };

    getLectureReview() {
        const {lecture} = this.props;
        const {showModal} = this.state;
        return (
            <LectureReviewModal
                isOpen={showModal}
                disconnectFromLecture={() => {}}
                isLectureOwner={false}
                lectureState={lecture}
                heading={"lecture.review.reviewLecture"}
                onLeaveLectureReview={this.submitReview}
                onRequestClose={this.closeReviewModal}
            />
        );
    }

    handleStartLecture = () => {
        this.props.history.push(`/lectures/${id || lectureToStart}?connectionType=${connectionType}`);
    };

    render() {
        const {
            t,
            lecture,
            usersAttendance,
            history,
            showOverallResult,
            showPersonalResult,
            openedQuiz,
            setOpenedQuiz,
            currentUser,
            totalUsers,
            loadAttendance,
            usersPage,
            pageSize,
            quizzes,
            quizzesToken,
            isLectureOwner
        } = this.props;

        const course = lecture.course || {subject: "-", grade: "-", language: "-"};
        const disableDownloadRecording = [student, child, parent].includes(currentUser.role);

        // for testing quizz games
        // TODO: remove
        quizzes.forEach(el => {
            // console.group(`Quizz - ${el.name}`);
            const origin = window.location.origin;
            const fakeUrl = `${origin}/games/${lecture.owner.id}/gameid/index.html?quizId=${el.id}&lectureId=${
                lecture.id
            }&quizzesToken=${quizzesToken}`;
        });

        // TODO - check the use of additionalInfo
        let additionalInfo = null;

        let receiverId = null;

        if (currentUser.role === teacher) {
            //  lecture belong to course.
            receiverId = lecture.course && lecture.course.owner;
        } else {
            receiverId = lecture.owner.id;
        }

        let isCourseOwner = false;
        let courseOwner = get(lecture, "course.owner", "");
        if (currentUser.id === courseOwner) {
            isCourseOwner = true;
        }

        let isSchoolLecture = true;

        if (currentUser.role === teacher && isCourseOwner) {
            isSchoolLecture = false;
        }
        return (
            <DigestContainer>
                <BackButtonContainer>
                    <BackTextButton
                        title={this.props.title == "Meeting" ? "Meeting" : t(`lecture.digest.backToLecturesList`)}
                        style={{padding: "0 0.3rem"}}
                        titleStyle={{fontSize: "0.8rem"}}
                        onClick={() => {
                            this.props.title == "Meeting"
                                ? history.push("/meetings")
                                : isPrincipal(currentUser.role)
                                ? history.push("/schools/lectures")
                                : history.push("/lectures");
                        }}
                    />
                </BackButtonContainer>
                <Title>{lecture.name || `${course.subject}, ${course.grade}, ${course.language}`}</Title>
                <LectureInfo
                    lecture={lecture}
                    course={course}
                    currentUser={currentUser}
                    usersAttendance={usersAttendance}
                    totalUsers={totalUsers}
                    loadAttendance={loadAttendance}
                    usersPage={usersPage}
                    pageSize={pageSize}
                />

                {isSchoolLecture &&
                lecture.course &&
                ((currentUser.role === principal && isCourseOwner) || (currentUser.role === teacher && isLectureOwner)) ? (
                    <ReviewContainer>
                        <Button
                            width="25%"
                            height="1.75rem"
                            style={{marginRight: 8}}
                            onClick={() => {
                                this.props.history.push(`/lectures/${lecture.id}/review`);
                            }}>
                            {t("common.review")}
                        </Button>
                        <Button
                            width="25%"
                            height="1.75rem"
                            onClick={() => this.props.openDialogWith(receiverId, additionalInfo, false, lecture.id)}>
                            {t("common.reviewDiscussion")}
                        </Button>
                    </ReviewContainer>
                ) : null}

                {lecture.course && currentUser.role === student ? (
                    <ReviewContainer>
                        <Button
                            width="25%"
                            height="1.75rem"
                            style={{marginRight: 8}}
                            onClick={() => {
                                this.openReviewModal();
                            }}>
                            {t("common.review")}
                        </Button>
                    </ReviewContainer>
                ) : null}

                <Recordings t={t} recordings={lecture.recordings} lecture={lecture} disableDownload={disableDownloadRecording} />
                {(currentUser.role === userTypes.teacher && isLectureOwner) ||
                currentUser.role === userTypes.student ||
                currentUser.role === userTypes.child ||
                (currentUser.role === principal && isCourseOwner) ? (
                    <TabsContainer>
                        <MaterialTabs
                            data={this.getPagesData()}
                            selected={this.state.selectedPage}
                            onChange={selectedPage => this.setState({selectedPage})}
                        />
                    </TabsContainer>
                ) : (
                    <QuizzesTitle>{t(`lecture.digest.quizzes`)}</QuizzesTitle>
                )}
                {currentUser.role === userTypes.student ? this.getLectureReview() : null}

                {this.getSelectedComponent()}
                <QuizResultsModal
                    quiz={openedQuiz}
                    showOverallResult={showOverallResult}
                    showPersonalResult={showPersonalResult}
                    isOpen={!!openedQuiz}
                    withoutOverallResult={currentUser.role === userTypes.parent}
                    onRequestClose={setOpenedQuiz.bind(null, null)}
                />
            </DigestContainer>
        );
    }
}

export default LectureDigest;
