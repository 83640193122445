import React, {PureComponent} from "react";
import styled, {css} from "styled-components";
import {withNamespaces} from "react-i18next";
import EllipsisText from "components/common/styled/EllipsisText";
import Cleave from "cleave.js/react";
import {mediaQuery} from "components/common/styled/mediaQuery";
import TextareaAutosize from "react-textarea-autosize";
import "cleave.js/dist/addons/cleave-phone.i18n.js";

const InputTitle = styled.label`
    color: #9e9e9e;
    font-size: 0.9rem;
    ${mediaQuery.phone`margin-bottom: 0;`}
`;

const InputWrapper = styled.div`
    position: relative;
`;

const inputElementStyle = css`
    outline: none;
    border: none;
    border-bottom: ${props => `${props.withoutunderline ? "0" : "1px"} ${props.error ? "#fd0f0f" : "#cbcbcb"}`} solid;
    background-color: transparent;
    border-radius: 0;

    padding: 0.7rem 0;
    color: ${props => (props.disabled ? "#9e9e9e" : "#212931")};
    font-size: 1rem;
    width: 100%;

    box-sizing: border-box;
    resize: none;
    ::placeholder {
        color: var(--light-black);
        opacity: 0.2;
    }
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    letter-spacing: 0.7px;
`;

const ErrorElement = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 0.8rem;
    color: #fd0f0f;
    ${EllipsisText}
`;

const HintElement = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.3);
    ${EllipsisText}
`;

const InputElement = styled.input`
    ${inputElementStyle}
`;
const CleaveElement = styled(Cleave)`
    ${inputElementStyle}
`;

const TextArea = styled(TextareaAutosize)`
    min-height: 2.75rem;
    ${inputElementStyle}
`;

@withNamespaces("translation")
class Input extends PureComponent {
    onKeyPressed(e) {
        const {onEnter} = this.props;
        if (onEnter && e.key === "Enter") {
            onEnter();
        }
    }

    onChange(e) {
        const {onChange, formatFunction} = this.props;
        if (formatFunction) {
            e.target.rawValue = formatFunction(e.target.value);
            e.target.value = e.target.rawValue;
        }
        onChange && onChange(e.target.value);
    }

    getInputElement() {
        const {
            id,
            formatOptions,
            error,
            withoutUnderline,
            type,
            multiline,
            placeholder,
            value,
            defaultValue,
            maxLength,
            onFocus,
            onBlur,
            pattern,
            max,
            minRows,
            maxRows,
            inputStyle,
            disabled
        } = this.props;

        const commonProps = {
            withoutunderline: withoutUnderline,
            onFocus: e => onFocus && onFocus(e.target.value),
            onKeyPress: this.onKeyPressed.bind(this),
            style: inputStyle,
            id,
            disabled,
            error,
            type,
            placeholder,
            onBlur
        };
        if (!formatOptions && !multiline) {
            return (
                <InputElement
                    value={value}
                    defaultValue={defaultValue}
                    maxLength={maxLength}
                    onChange={this.onChange.bind(this)}
                    pattern={pattern}
                    max={max}
                    {...commonProps}
                />
            );
        }
        if (multiline) {
            return (
                <TextArea
                    value={value}
                    defaultValue={defaultValue}
                    onChange={this.onChange.bind(this)}
                    minRows={minRows}
                    maxRows={maxRows || 4}
                    maxLength={maxLength}
                    {...commonProps}
                />
            );
        }
        return <CleaveElement options={formatOptions} value={defaultValue} onInput={this.onChange.bind(this)} {...commonProps} />;
    }

    getBottomElement() {
        const {t, hint, error, showErrorText, errorNumber, errorTranslationPrefix} = this.props;
        if ((!hint && !error) || !showErrorText) {
            return;
        }
        if (error) {
            const prefix = errorTranslationPrefix || "errors";
            return <ErrorElement>{t(`${prefix}.${error}`, {count: errorNumber})}</ErrorElement>;
        }
        if (hint) {
            return <HintElement>{hint}</HintElement>;
        }
    }

    render() {
        const {title, style, titleStyle, id} = this.props;

        return (
            <InputWrapper style={style}>
                {title && (
                    <InputTitle for={id} style={titleStyle}>
                        {title}
                    </InputTitle>
                )}
                {this.getInputElement()}
                {this.getBottomElement()}
            </InputWrapper>
        );
    }
}

export default Input;
