import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {toHHmmFromMillis, msToTime} from "utils/time";
import {Column, Value} from "./LectureInfoStyled";
import Paginator from "components/common/Paginator";
import LectureAttendanceTable from "./LectureAttendanceTable";
import moment from "moment-timezone";
import {withContextConsumer} from "utils/contexts";
import lectureDigestContext from "contexts/LecturesDigest";
const Container = styled.div`
    margin-top: 1.25rem;
`;

const Title = styled.div`
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Username = styled.div`
    font-size: 0.8rem;
    color: #8b8b8b;
    padding: 0.3rem 0;
`;

const AttendanceTable = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TableHeaders = styled.div`
    font-size: 1rem;
    font-weight: bold;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
    color: var(--main-theme-color);
`;

@withNamespaces("translation")
@withContextConsumer(lectureDigestContext.Consumer)
class LectureAttendance extends Component {
    renderAttendanceDuration(attendances) {
        const {startDate, endDate} = this.props;

        let joinTime = null;
        let leaveTime = null;
        const result = attendances.reduce((acc, elem) => {
            joinTime = moment(elem.joinLectureAt).format("h:mm A, D/MM/YYYY");
            leaveTime = moment(elem.leaveLectureAt).format("h:mm A,D/MM/YYYY");
            const joinAtDate = new Date(elem.joinLectureAt);
            const leaveAtDate = new Date(elem.leaveLectureAt);
            return acc + Math.max(leaveAtDate, startDate) - Math.max(joinAtDate, startDate);
        }, 0);
        const lectureDuration = endDate - startDate;
        //  return `  ${msToTime(result)} / ${msToTime(lectureDuration)}    (${Math.floor((result / lectureDuration) * 100)}%)`;
        return `${toHHmmFromMillis(result)} (${Math.floor((result / lectureDuration) * 100)}%) ||  ${msToTime(
            result
        )} / ${msToTime(lectureDuration)} `;
    }

    render() {
        const {t, usersAttendance, groupedUserenrolledUsers, total, loadAttendance, pageSize, page} = this.props;
        const {startDate, endDate, lecture, currentUser} = this.props;
        const needToShowPagination = total > usersAttendance?.length;

        // const meetingTime = moment
        //     ?.utc(moment(endDate, "DD/MM/YYYY HH:mm:ss")?.diff(moment(startDate, "DD/MM/YYYY HH:mm:ss")))
        //     ?.format("h:mm A,D/MM/YYYY");
        const lectureDuration = endDate - startDate;
        const meetingTime = msToTime(lectureDuration);
        const meetingTimeinHours = toHHmmFromMillis(lectureDuration);

        const lectureDurationInMinutes = moment
            ?.duration(moment(lectureDuration))
            ?.asMinutes()
            .toFixed(1);
        // const lectureDurationInMinutes = moment?.duration(moment(lectureDuration, "HH:mm:ss")?.format("HH:mm"))?.asMinutes();

        return (
            Boolean(usersAttendance.length) && (
                <Container>
                    <Title>{t("lecture.digest.attendance")}</Title>

                    <LectureAttendanceTable
                        startDate={startDate}
                        endDate={endDate}
                        lectureDuration={lectureDuration}
                        lectureDurationInMinutes={lectureDurationInMinutes}
                        meetingTime={meetingTime}
                        meetingTimeinHours={meetingTimeinHours}
                        groupedUserenrolledUsers={groupedUserenrolledUsers}
                        usersAttendance={usersAttendance}
                        lecture={lecture}
                    />
                    {/* {usersAttendance.map(elem => (
                        <Row key={elem.id}>
                            <Username>{`${elem.firstName} ${elem.lastName}`}</Username>
                            <Column />
                            <Value>{this.renderAttendanceDuration(elem.lectureAttendance)}</Value>
                        </Row>
                    ))} 
                     {needToShowPagination && (
                        <Paginator pageSize={pageSize} current={page} total={total} onChange={loadAttendance} />
                    )} */}
                </Container>
            )
        );
    }
}

export default LectureAttendance;
