import React, {Component} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class GlobalToast extends Component {
    render() {
        return (
            <React.Fragment>
                <ToastContainer />
            </React.Fragment>
        );
    }
}

export default GlobalToast;
