import React, {Component} from "react";
import styled from "styled-components";
import BlockedIcon from "components/common/icons/chatWidget/Blocked";
import Avatar from "components/common/Avatar";

const Container = styled.div`
    position: relative;
    svg {
        width: 1.1rem;
        height: 1.1rem;
    }
`;

const BlockedIconWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 1rem;
    border: white solid 0.2rem;
    right: 0;
    bottom: 0;
    background-color: #ff075e;
`;

const Picture = styled(Avatar)`
    width: ${({size}) => size};
    height: ${({size}) => size};
`;

class UserAvatar extends Component {
    render() {
        const {src, blocked, size} = this.props;
        return (
            <Container>
                <Picture size={size} src={src} />
                {blocked && (
                    <BlockedIconWrapper>
                        <BlockedIcon />
                    </BlockedIconWrapper>
                )}
            </Container>
        );
    }
}

export default UserAvatar;
