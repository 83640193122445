import styled from "styled-components";

export const HeaderButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    object-fit: contain;
    background-color: #f5f5f7;
    cursor: pointer;
    svg {
        fill: #596a78;
        width: ${props => props.width};
        height: ${props => props.height};
        transform: ${props => props.transform};
    }
    &:hover {
        background-color: #e2e4e9;
    }
`;
