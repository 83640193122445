import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import styled from "styled-components";
import FilesIcon from "components/common/FilesIcon";
import LinearProgressBar from "components/common/LinearProgressBar";
import CloseIcon from "components/common/icons/Cross";
import {Arrow} from "components/common/popperMenu/Arrow";
import TextButton from "components/form/TextButton";

const UNNAMED = "Unnamed";

const FileUploadProgressContainer = styled.div`
    width: 20rem;
    background: #fff;
    position: relative;
    > div:last-child {
        top: -1.9rem;
        left: 9.5rem;
        z-index: 10000;
    }
`;

const List = styled.div`
    display: flex;
    flex-flow: column;
    min-width: 0;
    flex: 4;
`;

const FileData = styled.div`
    display: flex;
    align-items: center;
`;

const UploadItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0.3rem 0;
    border-bottom: 1px solid #cbcbcb;
`;

const FileName = styled.div`
    min-width: 0;
    text-overflow: ellipsis;
    padding: 0 0.5rem;
    flex: 3;
    overflow: hidden;
    word-break: keep-all;
    white-space: pre;
    color: black;
    height: fit-content;
`;

const ProgressBarContainer = styled.div`
    width: 6rem;
    padding: 0 0.5rem;
    flex: 4;
    height: 2.65rem;
    display: flex;
    align-items: center;
`;

const IconContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const TextButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    padding: 0.5rem 0 0;
`;

const progressBarColors = {
    progressContainer: "#c7ccd3",
    mainColor: "var(--main-theme-color)"
};

@withNamespaces("translation")
class FileUploadProgressList extends Component {
    renderList(uploadId) {
        const {uploadItems, t} = this.props;
        return (
            <List>
                {Object.values(uploadItems[uploadId].files).map((file, j) => {
                    return (
                        <FileData key={j}>
                            <IconContainer>
                                <FilesIcon file={file} />
                            </IconContainer>
                            <FileName>{file.name || t("notifications.uploadProgress.unnamed")}</FileName>
                        </FileData>
                    );
                })}
            </List>
        );
    }

    render() {
        const {uploadItems, cancelUpload, resetAllProgresses, t} = this.props;

        const uploadItemIds = Object.keys(uploadItems);
        if (!uploadItemIds.length) {
            return null;
        }
        return (
            <FileUploadProgressContainer>
                <List>
                    {uploadItemIds.map(id => {
                        return (
                            <UploadItem key={id}>
                                {this.renderList(id)}
                                <ProgressBarContainer>
                                    <LinearProgressBar
                                        progress={uploadItems[id].progress}
                                        colors={progressBarColors}
                                        height="4px"
                                    />
                                </ProgressBarContainer>
                                <IconContainer onClick={cancelUpload.bind(null, id)}>
                                    <CloseIcon />
                                </IconContainer>
                            </UploadItem>
                        );
                    })}
                </List>
                <TextButtonContainer>
                    <TextButton title={t("notifications.uploadProgress.clearAll")} type="danger" onClick={resetAllProgresses} />
                </TextButtonContainer>
                <Arrow place={"start"} />
            </FileUploadProgressContainer>
        );
    }
}

export default FileUploadProgressList;
