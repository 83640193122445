import React from "react";
import styled from "styled-components";
import FlexCenter from "components/common/styled/FlexCenter";
import Star from "components/common/icons/Star";
import StarHalfFilled from "components/common/icons/StarHalfFilled";

const RatingWrapper = styled.div`
    display: flex;
    ${FlexCenter};
    ${props => props.canRate && "cursor: pointer;"};
    svg {
        padding: 0 ${props => props.marginBetweenStarsRem || "0.15"}rem;
        height: ${props => props.starHeight || "1.1rem"};
        width: ${props => props.starHeight || "1.1rem"};
        path {
            fill-rule: nonzero;
        }
    }
    svg:first-child {
        padding: 0 ${props => props.marginBetweenStarsRem || "0.15"}rem 0 0;
    }
    svg:last-child {
        padding: 0 0 0 ${props => props.marginBetweenStarsRem || "0.15"}rem;
    }
`;

class Rating extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hoveredRating: null};
    }

    onMouseEnterStar(starIndex) {
        this.setState({hoveredRating: starIndex + 1});
    }

    onMouseLeaveStar() {
        this.setState({hoveredRating: null});
    }

    calculateStarsColors(rating) {
        const {hoveredRating} = this.state;
        return [0, 1, 2, 3, 4].map(i => {
            if (hoveredRating) {
                return {
                    isFull: true,
                    color: i >= hoveredRating ? "#757575" : "#ffd700"
                };
            }
            if (rating <= i + 0.25) {
                return {isFull: true, color: "#757575"};
            }
            if (rating <= i + 0.75) {
                return {isFull: false};
            }
            return {isFull: true, color: "#ffd700"};
        });
    }

    render() {
        const {onRate, ratedItem, canRate, style, marginBetweenStarsRem, starHeight} = this.props;
        const starsProps = this.calculateStarsColors(ratedItem.rating);
        return (
            <RatingWrapper style={style} canRate={canRate} marginBetweenStarsRem={marginBetweenStarsRem} starHeight={starHeight}>
                {starsProps.map((starProp, index) => {
                    if (!starProp.isFull) {
                        return <StarHalfFilled key={index} />;
                    }
                    return (
                        <Star
                            key={index}
                            color={starProp.color}
                            onClick={() => canRate && onRate(ratedItem.id, index)}
                            onMouseEnter={() => canRate && this.onMouseEnterStar(index)}
                            onMouseLeave={() => canRate && this.onMouseLeaveStar()}
                        />
                    );
                })}
            </RatingWrapper>
        );
    }
}

export default Rating;
