import React from "react";
import styled, {css} from "styled-components";
import rotate from "components/common/styled/keyframes/rotate";

const animation = css`
    ${rotate} 1s linear infinite;
`;
const Icon = styled.svg`
    animation: ${animation};
`;

export default props => (
    <Icon viewBox="0 0 100 100" {...props}>
        <circle
            cx="50"
            cy="50"
            r="40"
            fill="none"
            stroke={props.stroke || "#5c6977"}
            strokeWidth="6"
            strokeDasharray="164.93361431346415 56.97787143782138"
        />
    </Icon>
);
