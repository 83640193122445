import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import LectureAttendance from "./LectureAttendance";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {Column, Title, Value, Row} from "./LectureInfoStyled";
import {Paper} from "@mui/material";
const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${mediaQuery.phone`
        display: none;
    `}
`;

export const BlankColumn = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 3rem;
`;

@withNamespaces("translation")
class LectureInfoDesktop extends Component {
    render() {
        const {
            lecture,
            course,
            startDate,
            studentsNumber,
            t,
            usersAttendance,
            totalUsers,
            loadAttendance,
            usersPage,
            pageSize
        } = this.props;

        return (
            <InfoContainer>
                <Paper elevation={3} sx={{width: "100%", maxWidth: "100%", p: 2}}>
                    <Row>
                        <Column>
                            <Title>{t("lecture.digest.subject")}</Title>
                            <Title>{t("lecture.digest.grade")}</Title>
                            <Title>{t("lecture.digest.language")}:</Title>
                        </Column>
                        <Column />
                        <Column>
                            <Value>{course.subject ? course.subject : "-"}</Value>
                            <Value>{course.grade ? course.grade : "-"}</Value>
                            <Value>{course.language ? course.language : "-"}</Value>
                        </Column>
                        <BlankColumn />
                        <Column>
                            <Title>{t("lecture.digest.dateTime")}</Title>
                            <Title>{t("lecture.digest.studentsNumber")}</Title>
                            <Title>{t("lecture.digest.type")}</Title>
                        </Column>
                        <Column />
                        <Column>
                            <Value>{startDate}</Value>
                            <Value>{studentsNumber}</Value>
                            <Value>{lecture.type}</Value>
                        </Column>
                    </Row>
                </Paper>
                <Row>
                    <LectureAttendance
                        usersAttendance={usersAttendance}
                        startDate={new Date(lecture.startDate)}
                        lecture={lecture}
                        endDate={new Date(lecture.endDate)}
                        total={totalUsers}
                        loadAttendance={loadAttendance}
                        page={usersPage}
                        pageSize={pageSize}
                    />
                </Row>
            </InfoContainer>
        );
    }
}

export default LectureInfoDesktop;
