import React, {Component} from "react";
import Modal from "./Modal";
import styled from "styled-components";
import AcceptDeclineButtonsGroup from "../AcceptDeclineButtonsGroup";

const ContentContainer = styled.div`
    padding: 0 0 1rem 0;
`;

class ConfirmModal extends Component {
    render() {
        const {
            buttonsContainerStyle,
            confirmTitle,
            cancelTitle,
            onConfirmClick,
            onCancelClick,
            controlButtonsStyle,
            buttonsType = "action"
        } = this.props;
        return (
            <Modal
                {...this.props}
                headerStyle={{margin: "1rem", wordBreak: "break-word"}}
                closeIconStyle={{visibility: "hidden"}}
                fullHeightContent>
                <ContentContainer>
                    {this.props.children}
                    <AcceptDeclineButtonsGroup
                        confirmTitle={confirmTitle}
                        cancelTitle={cancelTitle}
                        onConfirmClick={onConfirmClick}
                        onCancelClick={onCancelClick}
                        controlButtonsStyle={controlButtonsStyle}
                        style={buttonsContainerStyle}
                        type={buttonsType}
                    />
                </ContentContainer>
            </Modal>
        );
    }
}
export default ConfirmModal;
