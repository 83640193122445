import React from "react";

const STATUS_GOOD = "good";
const STATUS_BAD = "bad";
const STATUS_ATTENTION = "attention";

export default props => {
    const {status, viewed} = props;
    switch (status) {
        case STATUS_GOOD:
            return (
                <svg viewBox="0 0 15 15" {...props}>
                    <g fill="none" fillRule="evenodd">
                        <ellipse
                            cx="7.5"
                            cy="7.453"
                            fill={!viewed ? "var(--main-theme-color)" : "var(--gray)"}
                            rx="7.5"
                            ry="7.453"
                        />
                        <path fill="#FFF" d="M3.563 6.563h5.624v1.405H3.564z" />
                        <path stroke="#FFF" strokeWidth="1.406" d="M7.554 4.688l2.499 2.498-2.74 2.74" />
                    </g>
                </svg>
            );
        case STATUS_BAD:
            return (
                <svg viewBox="0 0 15 15" {...props}>
                    <g fill="none" fillRule="evenodd">
                        <ellipse
                            cx="7.5"
                            cy="7.453"
                            fill={!viewed ? "var(--main-theme-color)" : "var(--gray)"}
                            rx="7.5"
                            ry="7.453"
                        />
                        <g fill="#FFF">
                            <path d="M5.18 4.517l5.303 5.303-.994.994-5.303-5.303z" />
                            <path d="M10.483 5.511L5.18 10.815l-.994-.995 5.303-5.303z" />
                        </g>
                    </g>
                </svg>
            );
        case STATUS_ATTENTION:
            return (
                <svg viewBox="0 0 16 16" {...props}>
                    <path
                        fill={!viewed ? "var(--main-theme-color)" : "var(--gray)"}
                        fillRule="nonzero"
                        d="M8 15.9c-4.418 0-8-3.56-8-7.95C0 3.56 3.582 0 8 0s8 3.56 8 7.95c0 4.39-3.582 7.95-8 7.95zm0-5.838a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm-.914-6.868v5h1.828v-5H7.086z"
                    />
                </svg>
            );
    }
};
