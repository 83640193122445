import React from "react";
import {getUserTime} from "utils/time";
import styled from "styled-components";
import {mediaQuery} from "../../../common/styled/mediaQuery";
import assignmentFormats from "../../../../consts/assignmentFormats";
import AssignmentViewer from "../AssignmentViewer";
import TextField from "@mui/material/TextField";

const Title = styled.div`
    font-weight: bold;
    min-width: 0;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

const StudentAssignmentInfoContainer = styled.div`
    justify-content: center;
`;

const StudentAssignmentContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const StudentAssignmentTimeContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const FileContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 4px;
`;

const FileName = styled.div`
    font-weight: 500;
`;

const FileTime = styled.div`
    font-weight: 300;
`;

const AttachmentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
`;

const DownloadButton = styled.a`
    margin-left: 12px;
    padding: 4px;
    background-color: var(--main-theme-color);
    color: white;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
`;

function Attachment(props) {
    const {label, name, createdAt, url, t, format, openViewer} = props;

    return (
        <AttachmentContainer>
            - {label}: <FileTime> {getUserTime(createdAt)}</FileTime>
            <FileName> {name}</FileName>
            {format === assignmentFormats["File Upload"] || format === assignmentFormats["WorkSheet"] ? (
                <DownloadButton target={"_blank"} href={url} download={name}>
                    {t("lecture.digest.checkAssignment.downloaddoc")}
                </DownloadButton>
            ) : format === assignmentFormats["Video"] || format === assignmentFormats["Audio"] ? (
                <DownloadButton
                    target={"_blank"}
                    onClick={() => {
                        openViewer(url, format);
                    }}>
                    {t("lecture.digest.checkAssignment.play")}
                </DownloadButton>
            ) : (
                <DownloadButton
                    target={"_blank"}
                    onClick={() => {
                        openViewer(url, format);
                    }}>
                    {t("lecture.digest.checkAssignment.view")}
                </DownloadButton>
            )}
        </AttachmentContainer>
    );
}

function WorkHistory(props) {
    const {submittedAssignment, assignment, t} = props;
    const {format} = assignment;

    const [viewer, setViewer] = React.useState({url: "", format: "", open: true});

    const openViewer = (url, format) => {
        setViewer({url: url, format: format, open: true});
    };

    const closeViewer = () => {
        setViewer({url: "", format: "", open: false});
    };

    return (
        <>
            <StudentAssignmentInfoContainer>
                <Title>{t("lecture.digest.checkAssignment.workhistory")}</Title>

                <FileContainer>
                    {submittedAssignment.attachments.map(attachment => {
                        const {name, createdAt, url, status} = attachment;

                        if (status === "deleted") return null;

                        return (
                            <Attachment
                                label={"submitted on"}
                                name={name}
                                createdAt={createdAt}
                                url={url}
                                format={format}
                                openViewer={openViewer}
                                t={t}
                            />
                        );
                    })}
                </FileContainer>

                <FileContainer>
                    {submittedAssignment.feedbackFiles.map(attachment => {
                        const {name, createdAt, url} = attachment;
                        return (
                            <Attachment
                                label={"feedback on"}
                                name={name}
                                createdAt={createdAt}
                                url={url}
                                format={format}
                                openViewer={openViewer}
                                t={t}
                            />
                        );
                    })}
                </FileContainer>

                <StudentAssignmentContainer>
                    <h5>{t("lecture.digest.assignment.status")}: </h5>
                    <p style={{float: "left", padding: "10px"}}>{submittedAssignment?.status}</p>
                    <h5
                        style={{
                            float: "right",
                            "margin-left": "10px",
                            padding: "10px"
                        }}>
                        {t("lecture.digest.assignment.grade")}:{" "}
                    </h5>
                    <p style={{float: "right", padding: "10px"}}>
                        {submittedAssignment?.grade || "-"} / {assignment?.totalScores}
                    </p>
                </StudentAssignmentContainer>

                <StudentAssignmentTimeContainer>
                    <h5> {t("lecture.digest.assignment.checkTime")}: </h5>
                    <p
                        style={{
                            float: "right",
                            padding: "5px",
                            margin: "5px"
                        }}>
                        {submittedAssignment?.checkTime ? getUserTime(submittedAssignment?.checkTime) : null}
                    </p>
                </StudentAssignmentTimeContainer>
                <StudentAssignmentTimeContainer>
                    <h5>{t("lecture.digest.assignment.feedback")}: </h5>
                    <TextField
                        id="outlined-multiline-static"
                        label=""
                        multiline
                        key="assignment-feedback"
                        rows={2}
                        style={{width: "350px"}}
                        disabled
                        defaultValue={submittedAssignment?.feedback}>
                        {submittedAssignment?.feedback}
                    </TextField>
                    {/* <p style={{ float: "left", padding: "10px" }}>{submittedAssignment?.feedback}</p> */}
                </StudentAssignmentTimeContainer>
            </StudentAssignmentInfoContainer>
            <AssignmentViewer handleClose={closeViewer} viewer={viewer} />
        </>
    );
}

export default WorkHistory;
