import React, {Component} from "react";
import styled from "styled-components";
import Button from "components/form/Button";
import HiddenFileInput from "components/common/styled/HiddenFileInput";
import {mediaQuery} from "../common/styled/mediaQuery";

const ButtonWrapper = styled.div`
    ${mediaQuery.phone`
        ${props => props.hideOnPhoneResolution && "display: none;"}
    `}
`;

const FileInputContainer = styled.div`
    ${HiddenFileInput}
`;

class AttachFilesButton extends Component {
    onFileChange(e) {
        e.preventDefault();
        const {onAttachFiles} = this.props;
        const files = Array.from(e.target.files);
        onAttachFiles(files);
    }

    render() {
        const {t, hideOnPhoneResolution, onAttachFiles} = this.props;
        if (!onAttachFiles) return null;
        return (
            <ButtonWrapper hideOnPhoneResolution={hideOnPhoneResolution}>
                <Button type="light" width={"100%"} fontWeight={"bold"} onClick={() => onAttachFiles()}>
                    {t("library.attachFiles")}
                    <FileInputContainer>
                        <input title=" " type="file" onChange={this.onFileChange.bind(this)} multiple />
                    </FileInputContainer>
                </Button>
            </ButtonWrapper>
        );
    }
}

export default AttachFilesButton;
