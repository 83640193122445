import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import Modal from "components/common/modals/Modal";
import QuizResultsList from "./QuizResultsList";
import TextButton from "components/form/TextButton";
import {mediaQuery} from "components/common/styled/mediaQuery";

const Title = styled.div`
    padding: 1rem 0;
    display: flex;
    align-items: flex-end;
    ${mediaQuery.phone`
        padding: 0 1rem;
    `};
`;

const QuizName = styled.div`
    font-size: 1.1rem;
    font-weight: bold;
    white-space: pre;
`;

const ResultPrompt = styled.div`
    font-size: 0.9rem;
    color: #8b8b8b;
`;

const ButtonWrapper = styled.div`
    ${mediaQuery.phone`
        padding: 0 1rem;
    `};
`;

const modalStyle = {
    width: "fitContent",
    maxWidth: "54rem",
    borderRadius: "4px",
    padding: "0 2rem",
    alignItems: "flex-start",
    position: "relative"
};

const headerStyle = {
    width: "100%",
    justifyContent: "flex-start",
    position: "static",
    padding: "0.5rem 0"
};

const contentWrapperStyle = {
    padding: 0
};

const textButtonStyle = {
    width: "fit-content",
    padding: "1rem 0",
    fontSize: "0.9rem"
};

@withNamespaces("translation")
class QuizResultsModal extends Component {
    getTitle() {
        const {quiz, t} = this.props;

        return (
            <Title>
                <QuizName>{`${quiz.name} `}</QuizName>
                <ResultPrompt>{t("quiz.result.results")}</ResultPrompt>
            </Title>
        );
    }

    render() {
        const {quiz, isOpen, onRequestClose, showOverallResult, showPersonalResult, withoutOverallResult, i18n, t} = this.props;

        const closeIconStyle = i18n.dir() === "rtl" ? {top: "1rem", left: "1rem"} : {top: "1rem", right: "1rem"};

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                title={quiz && this.getTitle()}
                style={modalStyle}
                headerStyle={headerStyle}
                contentWrapperStyle={contentWrapperStyle}
                closeIconStyle={closeIconStyle}>
                <QuizResultsList quiz={quiz} showPersonalResult={showPersonalResult} />
                {!withoutOverallResult && (
                    <ButtonWrapper>
                        <TextButton
                            title={t("quiz.result.overallResult")}
                            onClick={quiz && showOverallResult.bind(null, quiz.id)}
                            style={textButtonStyle}
                        />
                    </ButtonWrapper>
                )}
            </Modal>
        );
    }
}

export default QuizResultsModal;
