import React from "react";

export default props => (
    <svg viewBox="0 0 16 16" {...props}>
        <g fill={props.color || "var(--light-black)"} fillRule="evenodd">
            <rect width="20" height="2" x="-2" y="7" rx="1" transform="rotate(45 8 8)" />
            <rect width="20" height="2" x="-2" y="7" rx="1" transform="rotate(-45 8 8)" />
        </g>
    </svg>
);
