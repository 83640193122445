import React from "react";

export default props => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            fill="#BCC3CA"
            fillRule="nonzero"
            d="M13.912 12.59L4.444 7.356a.892.892 0 0 0-1.14.24L0 11.884V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-.088.59zm-1.26 1.301c-.204.07-.424.109-.652.109H2c-.45 0-.866-.149-1.2-.4l3.382-4.354 8.47 4.645zM10.2 5.448a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
        />
    </svg>
);
