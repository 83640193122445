import React from "react";

export default props => (
    <svg viewBox="0 0 19 19" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle cx="9.5" cy="9.5" r="9.5" fill="#FFF" />
            <path fill="#2EBE5A" d="M6.2 9.6L5 10.8 8.2 14l7.2-6.8L14.2 6l-6 5.6z" />
        </g>
    </svg>
);
