import React from "react";

export default props => (
    <svg width="3" height="17" viewBox="0 0 3 17" {...props}>
        <g fill="#5D5D5D">
            <circle cx="1.5" cy="1.5" r="1.5" />
            <circle cx="1.5" cy="8.5" r="1.5" />
            <circle cx="1.5" cy="15.5" r="1.5" />
        </g>
    </svg>
);
