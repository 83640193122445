import {css} from "styled-components";
import resolutions from "consts/resolutions";

export const mediaQuery = Object.keys(resolutions).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${resolutions[label] / 16}em) {
            ${css(...args)}
        }
    `;

    return acc;
}, {});
