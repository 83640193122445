import React from "react";

export default props => (
    <svg viewBox="0 0 14 14" {...props}>
        <g fill="#BCC3CA" fillRule="evenodd">
            <path fillRule="nonzero" d="M7 0v6a1 1 0 0 0 1 1h6v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h6z" />
            <path d="M8.5 0L14 5.5H8.9a.4.4 0 0 1-.4-.4V0z" />
        </g>
    </svg>
);
