import styled from "styled-components";
import FlexCenter from "components/common/styled/FlexCenter";
import {mediaQuery} from "components/common/styled/mediaQuery";

export const Container = styled.div`
    ${FlexCenter};
    position: relative;
    height: 5rem;
    ${mediaQuery.phone`display: none;`}
`;
export const MobileContainer = styled.div`
    display: none;
    ${mediaQuery.phone`
         ${FlexCenter};
         position: relative;
         height: 5rem;
     `}
`;

export const LinkContainer = styled.div`
    height: 2.25rem;
    padding: 0.5rem;
    margin: 0 1rem;

    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.2s;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: #fefefe;
    white-space: nowrap;
    user-select: none;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
    ${mediaQuery.phone`
       margin-top: 1rem;
    `}
`;

export const IconContainer = styled.div`
    position: absolute;
    ${FlexCenter};
    height: 2.25rem;
    width: 2.25rem;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    border-radius: 50%;
    z-index: 5;
    left: -3rem;
    svg {
        height: 1rem;
        width: 1rem;
        fill: white;
    }
`;
export const SearchIconContainer = styled(IconContainer)`
    top: 1.375rem;
    left: 4rem;
`;
export const MenuIconContainer = styled(IconContainer)`
    top: 1.375rem;
    left: 1rem;
    svg {
        width: 2rem;
        height: 2rem;
    }
`;

export const SearchInput = styled.input`
    box-sizing: border-box;
    padding: 0 1rem;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 600;
    height: 2.25rem;
    z-index: 5;
    position: absolute;
    width: 100%;
    border-radius: 8px;
    caret-color: var(--main-theme-color);
    transition: 0.35s ease-in-out;
    transform: ${p => (p.visible ? "translateX(0)" : "translateX(-120%)")};
    transition-delay: ${p => (p.visible ? "0s" : "0.3s")};
    &::placeholder {
        color: rgba(0, 0, 0, 0.23);
    }
    ${mediaQuery.phone`
        top: 4.5rem;
        width: calc(100vw - 2rem);
    `}
`;

export const Links = styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    ${mediaQuery.phone`
        position: absolute;
        left: 7rem;
        top: 2.5rem;
        width: calc(100vw - 15.5rem)
    `}
`;

export const SearchResult = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    top: 4.5rem;
    background-color: white;
    border-radius: 0.5rem;
    max-height: ${p => (p.visible ? "15rem" : 0)};
    overflow: hidden;
    transition-duration: 0.2s;
    transition-delay: ${p => (p.visible ? "0.4s" : "0s")};
    box-shadow: 0 10px 40px -20px;
    ${mediaQuery.phone`
        top: 7.5rem;
        width: calc(100vw - 2rem);
        max-height: 80vh;
    `}
`;

export const Gradient = styled.div`
    background: linear-gradient(rgba(0, 0, 0, 0), white);
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2rem;
    position: absolute;
    z-index: 8;
`;

export const SearchedItemContainer = styled.div`
    cursor: pointer;
    background: white;
    padding: 0.75rem 1rem 0.75rem 0;
    width: calc(100% - 2rem);
    height: 4rem;
    display: grid;
    margin: 0 1rem;
    grid-column-gap: 1rem;
    border-bottom: 1px solid #eef2f5;
    grid-template:
        "avatar name   type" 1.25rem
        "avatar detail type" 1.25rem
        /2rem auto auto;
`;

export const Avatar = styled.div`
    grid-area: avatar;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: ${p => `url(${p.src})`};
    background-size: cover;
    display: flex;
    margin: auto;
`;
export const Name = styled.div`
    grid-area: name;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2;
    color: var(--absolute-black);
`;
export const Detail = styled.div`
    grid-area: detail;
    opacity: 0.56;
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
`;
export const Type = styled.div`
    grid-area: type;
    opacity: 0.56;
    font-size: 0.75rem;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    color: #b5bec5;
    div {
        align-self: center;
        padding: 0.375rem;
        border-radius: 0.25rem;
        background-color: #eef2f5;
    }
`;

export const MenuList = styled.div`
    display: flex;
    height: 100vh;
    width: 50vw;
    flex-direction: column;
    position: fixed;
    left: ${p => (p.active ? "0" : "-120%")};
    top: 0;
    transition: 0.5s ease;
    z-index: 15;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    padding: 4rem 1rem;
`;

export const SearchMenuList = styled(MenuList)`
    width: 100%;
`;

export const CloseButton = styled.div`
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    g {
        fill: white;
    }
`;
