import React from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";

export const Arrow = styled.div`
    position: absolute;
    border-style: solid;
    border-width: 0 12px 10px 12px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    color: #fff;
    left: calc(${p => (p.dir === "ltr" ? "90%" : "10%")});
    top: -10px;
    margin-top: 0px;
    z-index: 1;
    ${mediaQuery.phone`
        left: calc(${props => (props.dir === "ltr" ? "100% - " : "0.5rem + ")}${props => {
        if (props.place === "start") {
            return 13.1;
        }
        if (props.place === "end") {
            return 11.65;
        }
        return 9.25;
    }}rem);
    `}
`;
