import validator from "validator";
import {isAlphaAvailable} from "./common";

export function validateCreateChildData(data, isEdit) {
    const errors = {};
    if (!isAlphaAvailable(data.firstName)) {
        errors.firstName = "First name can contain only letters";
    }
    if (!validator.isLength(data.firstName, {min: 2, max: 50})) {
        errors.firstName = "First name should be from 2 to 50 characters long";
    }
    if (data.middleName && !isAlphaAvailable(data.middleName)) {
        errors.middleName = "Middle name can contain only letters";
    }
    if (data.middleName && !validator.isLength(data.middleName, {min: 2, max: 50})) {
        errors.middleName = "Middle name should be from 2 to 50 characters long";
    }
    if (!isAlphaAvailable(data.lastName)) {
        errors.lastName = "Last name can contain only letters";
    }
    if (!validator.isLength(data.lastName, {min: 2, max: 50})) {
        errors.lastName = "Last name should be from 2 to 50 characters long";
    }
    if (!validator.isAlphanumeric(data.userName)) {
        errors.userName = "Only characters and numbers";
    }
    if (!validator.isLength(data.userName, {min: 2, max: 50})) {
        errors.userName = "From 2 to 50 characters long";
    }
    if (!isEdit && data.password.length < 8) {
        errors.password = "From 8 characters long";
    }
    if (!isEdit && data.password.length > 200) {
        errors.password = "Up to 200 characters long";
    }
    if (!isEdit && data.password !== data.confirmPassword) {
        errors.confirmPassword = "Password does not match";
    }
    if (data.dateOfBirth && !validator.isISO8601(data.dateOfBirth)) {
        errors.dateOfBirth = "Invalid date of birth";
    }
    if (data.district && !isAlphaAvailable(data.district)) {
        errors.district = "District can contain only letters";
    }
    if (data.district && !validator.isLength(data.district, {min: 2, max: 100})) {
        errors.district = "District should be from 2 to 100 characters long";
    }
    if (data.school && !isAlphaAvailable(data.school)) {
        errors.school = "School can contain only letters";
    }
    if (data.school && !validator.isLength(data.school, {min: 2, max: 100})) {
        errors.school = "School should be from 2 to 100 characters long";
    }
    return errors;
}
