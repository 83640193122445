import React, {Component} from "react";
import PaymentMethods from "../../../../consts/paymentMethods";
import PaypalPaymentButton from "./PaypalPaymentButton";
// import TelrPaymentButton from "./TelrPaymentButton";
import WaafiPaymentButton from "./WaafiPaymentButton";
import WaafiCardPaymentButton from "./WaafiCardPaymentButton";

class PaymentButtons extends Component {
    render() {
        const {
            t,
            amount,
            paymentMethod,
            paymentButtonDisabled,
            onPaypalPaymentApproved,
            openTelrContent,
            openWaafiContent,
            telrContentIsLoading,
            waafiContentIsLoading
        } = this.props;

        if (paymentMethod === PaymentMethods.paypal) {
            return (
                <PaypalPaymentButton
                    t={t}
                    disabled={paymentButtonDisabled}
                    onApproved={onPaypalPaymentApproved}
                    amount={amount}
                />
            );
        }
        if (paymentMethod === PaymentMethods.telr) {
            return (
                <WaafiCardPaymentButton
                    t={t}
                    disabled={paymentButtonDisabled}
                    amount={amount}
                    loading={waafiContentIsLoading}
                    openWaafiContent={openWaafiContent}
                />
            );
        }
        if (paymentMethod === PaymentMethods.waafi) {
            return (
                <WaafiPaymentButton
                    t={t}
                    disabled={paymentButtonDisabled}
                    amount={amount}
                    loading={waafiContentIsLoading}
                    openWaafiContent={openWaafiContent}
                />
            );
        }
        return null;
    }
}

export default PaymentButtons;
