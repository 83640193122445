import React, {PureComponent} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import Rating from "components/common/Rating";
import Input from "components/form/Input";

const RatingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RatingHint = styled.div`
    margin: 1rem 0 0;
    font-size: 0.8rem;
    color: #444444;
`;

const Hint = styled.div`
    font-size: 0.8rem;
    margin: 1rem 0 0;
`;

const Review = styled.div`
    width: 100%;
    padding: 0.25rem 1rem;
    margin: 0.5rem 0 1rem;
    background: #f4f4f4;
    border-radius: 4px;
`;

@withNamespaces("translation")
class LeaveReview extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            rating: null,
            text: ""
        };
    }

    onRate(ratingItemIdMock, starIndex) {
        const shouldResetToNull = this.state.rating === 1 && starIndex === 0;
        const resultingRating = shouldResetToNull ? null : starIndex + 1;
        this.setState({rating: resultingRating}, () => this.props.onRate(resultingRating));
    }

    onTextChange(text) {
        this.setState({text}, () => this.props.onTextChange(text));
    }

    resetText() {
        if (this.textField) this.textField.resetText();
        this.setState({text: ""});
    }

    renderRatingSection() {
        const {ratingHint, starHeight} = this.props;
        const {rating} = this.state;
        return (
            <RatingSection>
                {ratingHint && <RatingHint>{ratingHint}</RatingHint>}
                <Rating
                    ratedItem={{rating: rating}}
                    onRate={this.onRate.bind(this)}
                    style={{margin: "1rem 0"}}
                    starHeight={starHeight}
                    canRate
                />
            </RatingSection>
        );
    }

    render() {
        const {t, hint, withoutRating} = this.props;
        const {text} = this.state;
        return (
            <React.Fragment>
                {!withoutRating && this.renderRatingSection()}
                {hint && <Hint>{hint}</Hint>}
                <Review>
                    <Input
                        placeholder={t("lecture.review.feedbackPlaceholder")}
                        defaultValue={text}
                        onChange={this.onTextChange.bind(this)}
                        minRows={5}
                        maxRows={5}
                        maxLength={1000}
                        multiline
                        withoutUnderline
                    />
                </Review>
            </React.Fragment>
        );
    }
}

export default LeaveReview;
