import React from "react";

export default ({color, ...rest}) => (
    <svg viewBox="0 0 20 20" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                stroke={color || "var(--main-theme-color)"}
                fillRule="nonzero"
                strokeWidth="2"
                d="M5.7 10.302L8.89 13.5l5.81-7"
            />
            <path
                fill={color || "var(--main-theme-color)"}
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
        </g>
    </svg>
);
