import {PDFDocument} from "pdf-lib";
import pdfjsLib from "pdfjs-dist";

pdfjsLib.GlobalWorkerOptions.workerSrc = "/workers/pdf.worker.js";

export async function loadPdf(url) {
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    return {pdfDoc};
}

export async function modifyPdf(pathToPdf, startPage, endPage) {
    // const existingPdfBytes = await fetch(pathToPdf).then(res => res.arrayBuffer());

    // const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const {pdfDoc} = await loadPdf(pathToPdf);

    // Create a new "sub" document
    const subDocument = await PDFDocument.create();

    for (let i = startPage - 1; i < endPage; i++) {
        // copy the page at current index
        const [copiedPage] = await subDocument.copyPages(pdfDoc, [i]);
        subDocument.addPage(copiedPage);
    }

    // const pdfBytes = await subDocument.save();

    const pdfDataUri = await subDocument.saveAsBase64({dataUri: true});

    return dataURItoBlob(pdfDataUri);
}

export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    let ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    let blob = new Blob([ab], {type: mimeString});
    return blob;
}

export function getFileExtension(filename) {
    // get file extension
    return filename.split(".").pop();
}

export const ASSIGNMENT_ALLOW_FILE_TYPES =
    "image/png,image/jpeg,image/gif,application/pdf,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const SIGNATURE_ALLOW_FILE_TYPES =
    "image/png,image/jpeg,image/gif,application/pdf,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export function isFileTypeAllowed(fileType) {
    const types = [
        "image/png",
        "image/jpeg",
        "image/gif",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];

    return types.every(type => type !== fileType);
}

let pdfDoc = null,
    scale = 1;

export class PdfRenderer {
    constructor(canvas, context) {
        this.pageRendering = false;
        this.pageNumPending = null;
        this.totalPages = 1;
        this.currentPage = 1;
        this.canvas = canvas;
        this.context = context;
        this.pages = [];
    }

    setCurrentPage(page) {
        this.currentPage = page;
    }

    getCurrentPage() {
        return this.currentPage;
    }

    getTotalPages() {
        return this.totalPages;
    }

    getPages() {
        return this.pages;
    }

    /**
     * Get page info from document, resize canvas accordingly, and render page.
     * @param num Page number.
     */
    renderPage(num) {
        const self = this;
        this.pageRendering = true;
        // Using promise to fetch the page
        pdfDoc.getPage(num).then(function(page) {
            let viewport = page.getViewport({scale: scale});
            self.canvas.height = viewport.height;
            self.canvas.width = viewport.width;

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: self.context,
                viewport: viewport
            };
            const renderTask = page.render(renderContext);

            // Wait for rendering to finish
            renderTask.promise.then(function() {
                self.pageRendering = false;
                if (self.pageNumPending !== null) {
                    // New page rendering is pending
                    self.renderPage(this.pageNumPending);
                    self.pageNumPending = null;
                }
            });
        });
    }

    /**
     * If another page rendering in progress, waits until the rendering is
     * finished. Otherwise, executes rendering immediately.
     */
    queueRenderPage(num) {
        if (this.pageRendering) {
            this.pageNumPending = num;
        } else {
            this.renderPage(num);
        }
    }

    loadPdf(url, callback) {
        const self = this;
        /**
         * Asynchronously downloads PDF.
         */
        pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
            pdfDoc = pdfDoc_;

            self.totalPages = pdfDoc.numPages;

            self.pages = [...Array(pdfDoc.numPages).keys()].map(i => i + 1);
            // Initial/first page rendering
            self.renderPage(self.currentPage);
            if (typeof callback === "function") {
                callback(self.pages);
            }
        });
    }
}
