import React from "react";
import {withRouter} from "react-router-dom";
import NetworkController from "controllers/Network";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import TawkChat from "components/TawkChat";

export const CurrentUserContext = React.createContext("currentUser");

class CurrentUserProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            isLoadingCurrentUser: true,
            setCurrentUser: this.setCurrentUser.bind(this),
            logout: this.logout.bind(this)
        };
    }

    async componentDidMount() {
        NetworkController.onUnauthorizedRequest = () => {
            this.setState({currentUser: null});
            this.props.history.push("/signin");
        };
        const {error, response} = await NetworkController.get("/users", {}, {withoutAuthorizationCheck: true});
        if (error) {
            return this.setState({isLoadingCurrentUser: false});
        }
        this.setState({
            currentUser: response?.user,
            isLoadingCurrentUser: false
        });
    }

    async setCurrentUser(currentUser) {
        await this.setStatePromise({currentUser});
    }

    async logout() {
        this.setState({currentUser: null});
        await NetworkController.post("/users/logout");
        TawkChat.logout();
        this.props.history.push("/signin");
    }

    render() {
        const {isLoadingCurrentUser} = this.state;
        return (
            <CurrentUserContext.Provider value={this.state}>
                {!isLoadingCurrentUser && this.props.children}
            </CurrentUserContext.Provider>
        );
    }
}

export default {
    Provider: withRouter(CurrentUserProvider),
    Consumer: CurrentUserContext.Consumer,
    Context: CurrentUserContext
};
