import React from "react";
import {withRouter} from "react-router-dom";
import AsyncStateComponent from "./common/AsyncStateComponent";

const AppContext = React.createContext("children");

class AppProvider extends AsyncStateComponent {
    render() {
        return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
    }
}

export default {Provider: withRouter(AppProvider), Consumer: AppContext.Consumer};
