import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const SpinnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default props => (
    <SpinnerContainer>
        <Spinner height="10rem" stroke="var(--main-theme-color)" {...props} />
    </SpinnerContainer>
);
