import React from "react";
import MetaTags from "react-meta-tags";

const defaultTitle = "Tabsera";
const defaultDescription =
    "Tabsera. Education online platform. Join online schools with best tutors, various courses, awesome multimedia materials and virtual labs ";
const defaultKeys = "tabsera, education, education platform, tutor, school, course, online course";

export default function({title, description, keywords, path = ""}) {
    return (
        <MetaTags>
            <title>{title || defaultTitle}</title>
            <meta id="metaDescriptionId" name="description" content={description || defaultDescription} />
            <meta id="metaTypeId" property="og:type" content="website" />
            <meta id="metaTitleId" property="og:title" content={defaultTitle} />
            <meta id="metaKeywordsId" property="og:title" content={keywords || defaultKeys} />
            <link id="metaCanonicalId" rel="canonical" href={`https://tabsera/com${path || ""}`} />
        </MetaTags>
    );
}
