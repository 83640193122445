import React, {Component} from "react";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";
import FileUploadContext from "../common/FileUploadProgress";
import {withContextConsumer} from "utils/contexts";
// import AddEditAssignment from "components/assignment/addEdit/addEditAssignment";

const AssignmentsAddEditContext = React.createContext("assignmentsList");

@withRouter
@withContextConsumer(FileUploadContext.Consumer)
class AssignmentsAddEditProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propsdata: null,
            createAssignment: this.createAssignment.bind(this),
            cancelSaveAssignment: this.cancelSaveAssignment.bind(this),
            getAssignment: this.getAssignment.bind(this),
            saveAssignment: this.saveAssignment.bind(this),
            PassAssignmentAddEditData: this.PassAssignmentAddEditData.bind(this)
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state == null) return true;

        if (this.state.propsdata !== null) return false;

        return true;
    }

    async getAssignment(assignmentId) {
        const {response} = await NetworkController.get(`/assignments/${assignmentId}`);
        return this.setState({assignment: response?.assignment});
    }

    async getAssignments() {
        const {response} = await NetworkController.get(`/assignments`);
        return this.setState({assignment: response?.assignment});
    }

    async createAssignment(assignment) {
        await this.sendAssignmentData("/assignments", assignment);
    }

    async saveAssignment(assignment) {
        await this.UpdateAssignmentData(`/assignments/edit/${assignment.id}`, assignment);
        // const config = {
        //     headers: {"content-type": "application/json"}
        // };
        // const {response} = await NetworkController.post(`/assignments/edit/${assignment.id}`, assignment, config);
        // return this.setState({assignment: response.assignment});
    }

    async sendAssignmentData(url, assignment) {
        const files = assignment.assignmentFile;
        const config = {
            headers: {"content-type": "multipart/form-data"}
        };
        const params = {url, body: assignment, files};
        const {error, response} = await NetworkController.formData("/assignments", assignment, files, config);

        if (error) {
            return error;
        } else {
            this.setState({assignment: response});
        }
        // const current = this.props.location.pathname;
        // this.props.history.replace(`/`);
        // setTimeout(() => {
        //     return this.props.history.replace(current);
        // });
        //const params = {url, body: assignment, files};
        //await this.props.uploadFilesWithProgressToApi(params);
        return this.props.history.goBack();
    }

    async UpdateAssignmentData(url, assignment) {
        const files = assignment.assignmentFile;
        const config = {
            headers: {"content-type": "multipart/form-data"}
        };
        const params = {url, body: assignment, files};
        // const {error, response} = await NetworkController.formData("/assignments", assignment, files, config);
        const {error, response} = await NetworkController.formData(url, assignment, files, config);
        if (error) {
            return error;
        } else {
            this.setState({assignment: response});
        }

        //const params = {url, body: assignment, files};
        //await this.props.uploadFilesWithProgressToApi(params);
        return this.props.history.goBack();
    }

    cancelSaveAssignment() {
        return this.props.history.goBack();
    }

    PassAssignmentAddEditData = async data => {
        // const { keyindex, lectureData, course, assignment, title } = data;
        //  this.setState({ propsdata: data })
        return this.setState({propsdata: data}, () => {
            this.props.history.push("/assignments/add");
        });

        // return (<AddEditAssignment lectureData={lectureData} course={course} />)
    };

    render() {
        return <AssignmentsAddEditContext.Provider value={this.state}>{this.props.children}</AssignmentsAddEditContext.Provider>;
    }
}

export default {
    Provider: withRouter(AssignmentsAddEditProvider),
    Consumer: AssignmentsAddEditContext.Consumer
};
