import React, {Component} from "react";
import styled from "styled-components";
import TextButton from "components/form/TextButton";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import Arrow from "components/common/icons/Arrow";
import {withContextConsumer} from "utils/contexts";
import LocaleContext from "contexts/common/LocaleContext";

const QuizResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50rem;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const Result = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 3rem;
    border-bottom: 1px solid #ebebeb;
    ${mediaQuery.phone`
        padding: 0 0.8rem;
    `}
`;

const Name = styled.div`
    display: flex;
    font-size: 0.8rem;
    font-weight: bold;
    flex: 4;
    ${mediaQuery.phone`flex: 8;`}
`;

const Grade = styled.div`
    color: #8b8b8b;
    font-size: 0.8rem;
    white-space: pre;
    flex: 3;
    ${mediaQuery.phone`flex: 1;`}
`;

const Details = styled.div`
    display: flex;
    font-size: 0.8rem;
    ${mediaQuery.phone`
        flex: unset;
        width: 1rem;
    `}
`;

const ButtonWrapper = styled.div`
    svg {
        display: none;
        transform: scale(${props => (props.isRtl ? 1 : -1)});
    }
    ${mediaQuery.phone`
        width: fit-content;
        padding: 0 0.2rem;
        svg {
            display: flex;
            height: 1rem;
            width: 1rem;
            fill: var(--main-theme-color);
            cursor: pointer;
        }
        div {
            display: none;
        }
    `};
`;

@withNamespaces("translation")
@withContextConsumer(LocaleContext.Consumer)
class QuizResultsList extends Component {
    render() {
        const {quiz, showPersonalResult, numberFormatter, i18n, t} = this.props;
        return (
            <QuizResultsContainer>
                {quiz &&
                    quiz.results &&
                    quiz.results.map((r, i) => (
                        <Result key={i}>
                            <Name>{`${r.student.firstName} ${r.student.lastName}`}</Name>
                            <Grade>
                                {r.status === "finished" ? numberFormatter.format(r.grade) + "%" : t("quiz.result.noAnswer")}
                            </Grade>
                            <Details>
                                {r.status === "finished" && (
                                    <ButtonWrapper isRtl={i18n.dir() === "rtl"} onClick={showPersonalResult.bind(null, r.id)}>
                                        <TextButton title={t("quiz.result.details")} />
                                        <Arrow />
                                    </ButtonWrapper>
                                )}
                            </Details>
                        </Result>
                    ))}
            </QuizResultsContainer>
        );
    }
}

export default QuizResultsList;
