import React, {Component} from "react";
import ModalLeftHeader from "components/common/modals/ModalLeftHeader";
import PaymentMethods from "consts/paymentMethods";
import TelrPaymentModalContent from "./TelrPaymentModalContent";
import DefaultPaymentModalContent from "./DefaultPaymentModalContent";
import WaafiPaymentModalContent from "./WaafiPaymentModalContent";

class PaymentModal extends Component {
    constructor(props) {
        super(props);
        const availablePaymentMethods = props.currency.info.paymentSystems;
        const paymentMethod = availablePaymentMethods[0];
        const amount = Math.max(props.currency.info[paymentMethod].minimumIncome, props.params.amount || 0);

        this.state = {
            amount,
            paymentMethod,
            availablePaymentMethods,
            loading: true,
            paymentButtonDisabled: false
        };
    }

    async componentDidMount() {
        const {availablePaymentMethods} = this.state;
        if (availablePaymentMethods.includes(PaymentMethods.paypal)) {
            await this.props.loadPaypalLibrary();
        }
        this.setState({loading: false});
    }

    selectPaymentMethod(paymentMethod) {
        this.setState({paymentMethod});
    }

    setAmount(amount) {
        const {currency} = this.props;
        const {paymentMethod} = this.state;
        const currencyInfo = currency.info[paymentMethod];
        const paymentButtonDisabled = !amount || amount < currencyInfo.minimumIncome || amount > currencyInfo.maximumIncome;
        this.setState({amount, paymentButtonDisabled});
    }

    render() {
        const {t, params, onTelrPaymentApproved, onWaafiPaymentApproved, onUnknownTransactionError, onRequestClose} = this.props;
        const {loading} = this.state;

        if (loading) {
            return null;
        }

        const currentStage = params.stage || "default";

        return (
            <ModalLeftHeader
                onRequestClose={onRequestClose}
                currentStage={currentStage}
                title={t("wallet.payment.title")}
                style={{width: "32rem"}}
                isOpen>
                {currentStage === "default" && (
                    <DefaultPaymentModalContent
                        {...this.props}
                        {...this.state}
                        onPaymentMethodChange={paymentMethod => this.setState({paymentMethod})}
                        setAmount={this.setAmount.bind(this)}
                    />
                )}
                {currentStage === "telr" && (
                    <TelrPaymentModalContent
                        params={this.props.params.data}
                        onRequestClose={onRequestClose}
                        onUnknownTransactionError={onUnknownTransactionError}
                        onTelrPaymentApproved={onTelrPaymentApproved}
                    />
                )}
                {currentStage === "waafi" && (
                    <WaafiPaymentModalContent
                        params={this.props.params.data}
                        onRequestClose={onRequestClose}
                        onUnknownTransactionError={onUnknownTransactionError}
                        onTelrPaymentApproved={onWaafiPaymentApproved}
                    />
                )}
            </ModalLeftHeader>
        );
    }
}

export default PaymentModal;
