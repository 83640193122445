import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import NetworkController from "controllers/Network";
import Input from "components/form/Input";
import Button from "components/form/Button";

import {getUserTime} from "utils/time";
import LibraryAssignmentModal from "components/assignment/addEdit/LibraryAssignmentModal.js";

import FileIconDefault from "../../common/icons/library/FileIconDefault";
import notify, {NOTIFY_TYPES} from "utils/notify";
import WorkHistory from "../../lectures/digest/assignment/WorkHistory";

const AssignmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const StudentProgressContainer = styled.div``;

const StudentProgressInnerContainer = styled.div`
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 4px;
`;

const ButtonContainer = styled.div`
    display: flex;
    //justify-content: center;
    align-items: center;
    margin-top: 1rem;
`;

const CheckViaWhiteboardButtonContainer = styled.div`
    margin-left: 1rem;
`;

const GradesContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const Name = styled.h5`
    text-transform: capitalize;
`;

const FileContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 4px;
`;

const DownloadButton = styled.a`
    margin-left: 12px;
    padding: 4px;
    background-color: var(--main-theme-color);
    color: white;
    border-radius: 5px;
    text-decoration: none;
`;

const FileName = styled.div`
    font-weight: 500;
`;

const FileTime = styled.div`
    font-weight: 300;
`;

const AttachmentContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
`;

const File = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.8125rem;
    color: #bcc3ca;
    text-transform: uppercase;
    margin-top: 3px;
    padding-left: 5rem;
    margin-left: 2rem;
    padding-right: 5rem;
    margin-right: 2rem;
    max-width: 2.25rem;
    min-width: 2.25rem;
    position: relative;

    svg {
        width: 0.875rem;
        margin-bottom: 0.35rem;
    }

    &:hover {
        cursor: pointer;
    }
`;

@withNamespaces("translation")
class CheckAssignmentData extends Component {
    constructor(props) {
        super(props);
        const {assignment, studentSubmission} = this.props;

        this.state = {
            page: 1,
            feedback: studentSubmission.feedback,
            assignmentId: studentSubmission.assignment,
            obtainScores: studentSubmission.grade,
            totalScores: assignment.totalScores,
            checkedAttachments: studentSubmission.attachments,
            assignmentFile: assignment.attachments,
            onAddFileFromLibrary: [],
            assignmentToAttach: null,
            renderFeedbackForm: this.renderFeedbackForm.bind(this),

            isOpen: false,
            feedbackFilesFromDevice: [],
            feedbackFilesFromLibrary: []
        };
    }

    openLibraryModal() {
        this.setState({isOpen: true});
        document.documentElement.setAttribute("overflow", "auto");
        document.body.setAttribute("overflow", "auto");
    }

    closeLibraryModal() {
        this.setState({isOpen: false});
        document.documentElement.setAttribute("overflow", "auto");
        document.body.setAttribute("overflow", "auto");
    }

    async setObtainedMarks(marks) {
        this.setState({obtainScores: marks});
    }

    onAddFileFromDevice(files) {
        this.setState({feedbackFilesFromDevice: files});
    }

    onAddFileFromLibrary(path) {
        this.setState({feedbackFilesFromLibrary: path});
    }

    async handleAssignmentFeedback() {
        // const {enrolled, assignment, lecture, getStudentsWork, studentSubmission} = this.props;
        // const nowMoment = moment();
        const {feedback, obtainScores, feedbackFilesFromDevice, feedbackFilesFromLibrary} = this.state;
        const {assignment, studentSubmission} = this.props;

        const config = {
            headers: {"content-type": "multipart/form-data"}
        };

        const data = {
            feedback: feedback,
            grade: obtainScores,
            libraryFeedbackFiles: feedbackFilesFromLibrary
        };

        const {error, response} = await NetworkController.formData(
            `/assignments/${assignment.id}/submission/${studentSubmission.id}/feedback`,
            data,
            feedbackFilesFromDevice,
            config
        );

        if (!error) {
            this.props.updateAssignment(response.assignment);
            notify("feedback submitted", NOTIFY_TYPES.success);
        }

        // if (error) {
        //     return error;
        // } else {
        //     const qs = {assessmentId: assignment?._id, lectureId: lecture?.id};
        //     await getStudentsWork(assignment, qs);
        //     this.setState({feedbackfile: []});
        // }
    }

    renderFeedbackForm() {
        const {t} = this.props;
        const {feedback, obtainScores, totalScores} = this.state;
        return (
            <>
                <GradesContainer>
                    <h5>Grade:</h5>
                    <Input
                        value={obtainScores}
                        placeholder={t("lecture.digest.checkAssignment.obtainScores")}
                        required
                        style={{marginLeft: 8, width: "40%"}}
                        minRows={1}
                        maxRows={1}
                        maxLength={3}
                        onChange={this.setObtainedMarks.bind(this)}
                        showErrorText
                    />

                    <h5>{t("lecture.digest.checkAssignment.totalScores")}:</h5>
                    <p style={{marginLeft: 8}}>{totalScores}</p>
                </GradesContainer>

                <h5>{t("lecture.digest.checkAssignment.feedback")}</h5>
                <Input
                    value={feedback}
                    placeholder={t("lecture.digest.checkAssignment.feedback")}
                    multiline
                    required
                    minRows={1}
                    maxLength={2000}
                    onChange={value => this.setState({feedback: value})}
                    showErrorText
                />
            </>
        );
    }

    render() {
        const {student, currentUser, studentSubmission, isLectureOwner, t, assignment} = this.props;
        const {assignmentToAttach, isOpen, feedbackFilesFromDevice, feedbackFilesFromLibrary} = this.state;

        const isWorksheet = assignment?.format === "WorkSheet";
        return (
            <AssignmentContainer>
                <ListContainer>
                    <StudentProgressContainer>
                        <>
                            <>
                                <StudentProgressInnerContainer>
                                    <Name>
                                        {student?.firstName} {student?.lastName}
                                    </Name>

                                    <WorkHistory submittedAssignment={studentSubmission} assignment={assignment} t={t} />

                                    {this.renderFeedbackForm()}

                                    <FileContainer>
                                        {feedbackFilesFromDevice.map(file => (
                                            <File>
                                                <FileIconDefault />
                                                <p>{file.name} </p>
                                            </File>
                                        ))}

                                        {feedbackFilesFromLibrary.map(file => (
                                            <File>
                                                <FileIconDefault />
                                                <p>{file.name} </p>
                                            </File>
                                        ))}
                                    </FileContainer>

                                    {isLectureOwner ? (
                                        <>
                                            <Button
                                                height="2.75rem"
                                                style={{marginTop: "1rem"}}
                                                onClick={this.openLibraryModal.bind(this)}>
                                                <h5>{t("lecture.digest.checkAssignment.uploaddoc")}</h5>
                                            </Button>

                                            <ButtonContainer>
                                                <Button type="gray" onClick={this.handleAssignmentFeedback.bind(this)}>
                                                    {t("lecture.digest.checkAssignment.submitGrade")}
                                                </Button>

                                                {isWorksheet ? (
                                                    <CheckViaWhiteboardButtonContainer>
                                                        <Button
                                                            type="light-black"
                                                            onClick={this.props.checkAssignmentWithWhiteboard}>
                                                            {t("lecture.digest.checkAssignment.checkviawhiteboard")}
                                                        </Button>
                                                    </CheckViaWhiteboardButtonContainer>
                                                ) : null}
                                            </ButtonContainer>
                                        </>
                                    ) : null}
                                </StudentProgressInnerContainer>
                            </>
                        </>

                        <LibraryAssignmentModal
                            isOpen={isOpen}
                            closeAttachModal={this.closeLibraryModal.bind(this)}
                            currentUser={currentUser}
                            onAddFileFromLibrary={this.onAddFileFromLibrary.bind(this)}
                            onAddFileFromDevice={this.onAddFileFromDevice.bind(this)}
                            title={t("assignment.addAssignment.fromLibrary")}
                            t={t}
                        />
                    </StudentProgressContainer>
                </ListContainer>
            </AssignmentContainer>
        );
    }
}

export default CheckAssignmentData;
