import React, {Component} from "react";
import {Field} from "../../common/styled";
import Input from "components/form/Input";
import TransferingInfo from "./TransferingInfo";
import PaymentButtons from "./PaymentButtons";
import PaymentMethodChoice from "./PaymentMethodChoice";

class DefaultPaymentModalContent extends Component {
    render() {
        const {
            t,
            currency,
            onPaypalPaymentApproved,
            openTelrContent,
            onPaymentMethodChange,
            paymentMethod,
            amount,
            paymentButtonDisabled,
            availablePaymentMethods,
            telrContentIsLoading,
            openWaafiContent,
            waafiContentIsLoading,
            setAmount
        } = this.props;

        return (
            <React.Fragment>
                <PaymentMethodChoice
                    t={t}
                    availablePaymentMethods={availablePaymentMethods}
                    paymentMethod={paymentMethod}
                    onSelectPaymentMethod={onPaymentMethodChange}
                />
                <Field>
                    <Input
                        placeholder={`${t("wallet.payment.amount")}, ${currency.sign}`}
                        defaultValue={currency.sign + amount}
                        onChange={amount => setAmount(parseInt(amount.replace(/[^0-9]+/g, "")))}
                        formatOptions={{numericOnly: true, prefix: currency.sign, noImmediatePrefix: true}}
                        showErrorText
                    />
                    <TransferingInfo t={t} paymentMethod={paymentMethod} currency={currency} />
                </Field>
                <PaymentButtons
                    t={t}
                    paymentMethod={paymentMethod}
                    paymentButtonDisabled={paymentButtonDisabled}
                    onPaypalPaymentApproved={id => onPaypalPaymentApproved(id)}
                    amount={amount}
                    openTelrContent={openTelrContent}
                    openWaafiContent={openWaafiContent}
                    telrContentIsLoading={telrContentIsLoading}
                    waafiContentIsLoading={waafiContentIsLoading}
                />
            </React.Fragment>
        );
    }
}

export default DefaultPaymentModalContent;
