import React, {Component} from "react";

class HintsContext extends Component {
    constructor(props) {
        super(props);
        this.timeouts = [];
        this.state = {
            hints: [],
            addHint: this.addHint.bind(this)
        };
    }

    componentWillUnmount() {
        this.timeouts.forEach(t => clearTimeout(t));
        this.timeouts = [];
    }

    addHint(options) {
        const {hints} = this.state;
        const hint = {...options, state: "shown"};
        this.setState({hints: [...hints, hint]}, () => {
            if (hint.timeout) {
                this.initRemoveLastHintTimeout(hints.length, hint.timeout);
            }
        });
    }

    initRemoveLastHintTimeout(index, timeoutTime) {
        const timeout = setTimeout(() => {
            const {hints} = this.state;
            hints[index].state = "removed";
            this.setState({hints});
        }, timeoutTime);
        this.timeouts.push(timeout);
    }
}

export default HintsContext;
