import React, {Component} from "react";
import styled from "styled-components";
import Logo from "./Logo";
import FlexCenter from "components/common/styled/FlexCenter";
import {withRouter} from "react-router-dom";
import HeaderContent from "./HeaderContent";
import HeaderWrapper from "./HeaderWrapper";
import HeaderButton from "./HeaderButton";
import {withNamespaces} from "react-i18next";
import LanguageDropDownContainer from "components/common/LanguageDropdown";
import ShowOnDesktop from "components/common/styled/ShowOnDesktop";

const TransparentWrapper = styled.div`
    width: 100vw;
    ${FlexCenter};
    position: fixed;
    background: transparent;
    padding-top: 0.5rem;
    z-index: 0;
`;

const HeaderButtonContainer = styled.div`
    ${FlexCenter}
`;

const LangWrapper = styled.div`
    position: fixed;
    height: 58px;
    ${FlexCenter};
    padding-top: 0.5rem;
    z-index: 10;
    transform: translateX(${p => (p.dir === "ltr" ? "" : "-")}460px);
`;

@withRouter
@withNamespaces("translation")
class NotRegistered extends Component {
    render() {
        const {t, i18n, history, location} = this.props;
        const isSharedCourseInPage =
            location.pathname
                .split("/")
                .slice(0, 3)
                .join("/") === "/shared/course";
        const isTermsPage = ["/terms", "/privacy", "/payment-terms"].includes(location.pathname);

        if (isSharedCourseInPage || isTermsPage) {
            return (
                <HeaderWrapper>
                    <HeaderContent>
                        <div>
                            <Logo />
                        </div>
                        <HeaderButtonContainer>
                            <ShowOnDesktop>
                                <LanguageDropDownContainer i18n={i18n} />
                            </ShowOnDesktop>
                            <HeaderButton dir={i18n.dir()} onClick={() => history.push("/signup")}>
                                {t("navigation.actions.signup")}
                            </HeaderButton>
                            <HeaderButton dir={i18n.dir()} onClick={() => history.push("/signin")}>
                                {t("navigation.actions.login")}
                            </HeaderButton>
                        </HeaderButtonContainer>
                    </HeaderContent>
                </HeaderWrapper>
            );
        }

        return (
            <>
                <TransparentWrapper>
                    <Logo />
                </TransparentWrapper>
                <LangWrapper dir={i18n.dir()}>
                    <LanguageDropDownContainer i18n={i18n} />
                </LangWrapper>
            </>
        );
    }
}

export default NotRegistered;
