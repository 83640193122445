import React, {PureComponent} from "react";
import styled from "styled-components";
import Modal from "components/common/modals/Modal";
import Button from "components/form/Button";

const titleStyle = {
    padding: "0.5rem 2.5rem",
    textAlign: "center"
};

const ConfirmMessageWrapper = styled.div`
    align-content: center;
    text-align: center;
`;

const ControlsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-content: center;
    margin-top: 1.5rem;
`;

class ConfirmClearMessages extends PureComponent {
    render() {
        const {t, isOpen, onRequestClose, onConfirm} = this.props;
        return (
            <Modal
                isOpen={isOpen}
                title={t("chatsList.clearMessageList.title")}
                titleStyle={titleStyle}
                onRequestClose={onRequestClose}>
                <ConfirmMessageWrapper>{t("chatsList.clearMessageList.message")}</ConfirmMessageWrapper>
                <ControlsWrapper>
                    <Button type="danger" onClick={onConfirm}>
                        {t(`chatsList.clearMessageList.yes`)}
                    </Button>
                    <Button type="light" onClick={onRequestClose}>
                        {t(`chatsList.clearMessageList.no`)}
                    </Button>
                </ControlsWrapper>
            </Modal>
        );
    }
}

export default ConfirmClearMessages;
