import React, {Component} from "react";
import get from "lodash/get";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import moment from "moment";
import TextButton from "components/form/TextButton";
import Button from "components/form/Button";
import {mediaQuery} from "components/common/styled/mediaQuery";
import ConfirmModal from "components/common/modals/ConfirmModal";
import {compose} from "recompose";

import {getUserTime} from "utils/time";

import NetworkController from "controllers/Network";

import StudentAssessmentList from "components/assessment/check/StudentAssessmentList";
import AssessmentInfo from "./AssessmentInfo";

const AssessmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    ${mediaQuery.phone`
        order: 2;
    `}
`;

const NoAssessmentMessage = styled.div`
    padding: 1rem;
    color: #8b8b8b;
`;

class AdminAssessmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddAssessmentModalOpen: false,
            isEditAssessmentModalOpen: false,
            isStudentAssessmentModalOpen: false,
            viewInPdf: null,
            pageNumber: 1,
            countPages: 1,
            pages: [],
            page: 1,
            scale: props.isPhoneResolution ? 2 : 1,

            showImages: false,
            showAudios: false,
            feedbackFiles: null,
            isOpen: false,
            onRequestClose: true,
            isDeleteOpen: false,
            onDeleteRequestClose: true,
            students: null,
            assessmentToDeleted: null,
            deleteAssessment: this.deleteAssessment.bind(this)
        };
    }

    async componentDidMount() {
        // get enroll student in the course.
        const {lecture} = this.props;
        await this.getLectureEnrollements(lecture);
    }

    async getLectureEnrollements(lecture) {
        const {response} = await NetworkController.get(`/lectures/attendances/${lecture?.id}`);
        const {usersAttendance} = response;
        this.setState({students: usersAttendance});
    }

    onClick(assessment, isPassedByStudent) {
        const {currentUser, isLectureOwner, isParent, setOpenedAssessment, enterAssessment, showPersonalResult} = this.props;
        const hasSeveralResults = assessment.results.length > 1;
        if (isLectureOwner || (isParent && hasSeveralResults)) {
            return setOpenedAssessment(assessment);
        }
        const isChildResults =
            isParent && assessment.results.length && currentUser.id !== get(assessment, "results[0].student.id", null);
        if (isPassedByStudent || isChildResults) {
            return showPersonalResult(assessment.results[0].id);
        }
        return enterAssessment(assessment.id);
    }

    async deleteAssessment(assessment) {
        const {lecture, updateAssessments} = this.props;
        await NetworkController.delete(`/assessments/${assessment.id}`);
        updateAssessments(assessment.id);
        this.handleDeleteDialogClose();
    }

    onFileDelete() {
        this.setState({showImages: !this.state.showImages});
    }

    handleDeleteDialogClose = () => this.setState({isDeleteOpen: false});

    renderAssessments = () => {
        const {assessments, isLectureOwner, currentUser, lecture, usersAttendance, t, history, updateAssessment} = this.props;

        const {students, isDeleteOpen} = this.state;

        return assessments.map((assessment, index) => {
            return (
                <>
                    <AssessmentInfo assessment={assessment} t={t} />

                    <StudentAssessmentList
                        assessment={assessment}
                        currentUser={currentUser}
                        history={history}
                        usersAttendance={usersAttendance}
                        lecture={lecture}
                        students={students}
                        isLectureOwner={isLectureOwner}
                        updateAssessment={updateAssessment}
                    />
                </>
            );
        });
    };

    render() {
        const {assessments, isLectureOwner, currentUser, lecture, t} = this.props;

        return (
            <AssessmentContainer>
                <ListContainer>
                    {assessments && assessments.length ? (
                        this.renderAssessments()
                    ) : (
                        <NoAssessmentMessage>{t("lecture.digest.noAssessmentsAssigned")}</NoAssessmentMessage>
                    )}
                </ListContainer>

                {/*Todo - Allow user to attach assignmetn from lecture*/}
                {/*{isLectureOwner ? (*/}
                {/*    <AddAssessmentButtonWrapper>*/}
                {/*        <TextButton*/}
                {/*            onClick={this.toggleAddAssessmentModal.bind(this)}*/}
                {/*            title={t("lecture.digest.attachAssessment")}*/}
                {/*            withPlus*/}
                {/*        />*/}
                {/*    </AddAssessmentButtonWrapper>*/}
                {/*) : null}*/}

                {/*{isAddAssessmentModalOpen ? (*/}
                {/*    <AddAssessmentModal*/}
                {/*        isOpen={isAddAssessmentModalOpen}*/}
                {/*        onClose={this.closeAddAssessmentModal.bind(this)}*/}
                {/*        lecture={lecture}*/}
                {/*        closeTitle={t("lecture.digest.ok")}*/}
                {/*        title={t("lecture.digest.attachAssessment")}*/}
                {/*        course={lecture.course}*/}
                {/*    />*/}
                {/*) : null}*/}
            </AssessmentContainer>
        );
    }
}

export default compose(withNamespaces("translation"))(AdminAssessmentList);
