import React from "react";

export default props => {
    return (
        <svg viewBox="0 0 20 20" {...props}>
            <g fill="none" fillRule="evenodd" stroke="rgb(0,0,0,0.75)" strokeLinecap="round" strokeWidth="2">
                <path strokeLinejoin="round" d="M6.7 1.7H3A1.3 1.3 0 0 0 1.7 3v3.7" />
                <path d="M6.7 18.2H3a1.3 1.3 0 0 1-1.3-1.3v-3.7M13.2 1.7h3.7A1.3 1.3 0 0 1 18.2 3v3.7M13.2 18.2h3.7a1.3 1.3 0 0 0 1.3-1.3v-3.7" />
            </g>
        </svg>
    );
};
