import React from "react";
import Modal from "components/common/modals/Modal";

export default props => (
    <Modal
        borderRadius={"5px"}
        style={{
            width: props.width || "32rem",
            height: props.height || "auto",
            color: "var(--light-black)"
        }}
        headerStyle={{
            padding: "1rem 1rem 0 1rem",
            margin: 0,
            justifyContent: "flex-start",
            fontSize: "1.5rem"
        }}
        titleStyle={{margin: 0}}
        closeIconStyle={{top: "10px"}}
        {...props}
    />
);
