import React from "react";

export default props => (
    <svg viewBox="0 0 5 8" {...props}>
        <path
            fill="#5C6977"
            fillRule="evenodd"
            d="M3.404 4L.54 1.136a.65.65 0 1 1 .92-.919L4.783 3.54a.65.65 0 0 1 0 .92L1.46 7.783a.65.65 0 1 1-.92-.92L3.404 4z"
        />
    </svg>
);
