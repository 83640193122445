import React from "react";
import {withNamespaces} from "react-i18next";
import _flattenDeep from "lodash/flattenDeep";
import NetworkController from "controllers/Network";
import {findFolderInTree} from "utils/folderTree";
import {libraryItemTypes} from "consts/library";
import AsyncStateComponent from "./common/AsyncStateComponent";
import FileUploadContext from "./common/FileUploadProgress";
import {withContextConsumer} from "utils/contexts";
import CurrentUserContext from "./CurrentUser";

const LibraryContext = React.createContext("library");

const LIBRARY_ITEMS_URL = "/library/items";

@withNamespaces("translation")
@withContextConsumer(CurrentUserContext.Consumer)
@withContextConsumer(FileUploadContext.Consumer)
class LibraryProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            meta: null,
            gotFolders: false,
            gotExtraFolders: false,
            folderTree: {},
            extraFolderTrees: [],
            gotItems: false,
            originalItems: [],
            items: [],
            itemsMode: localStorage.libraryItemsMode || "grid",
            setItemsMode: this.setItemsMode.bind(this),
            selectedFolderId: null,
            expendedFolderIds: [],
            canDeleteFolder: false,
            itemFilter: "all",
            setItemFilter: this.setItemFilter.bind(this),
            itemSort: "date",
            setItemSort: this.setItemSort.bind(this),
            selectedItems: [],
            clearSelectedItems: () => this.setState({selectedItems: []}),
            selectItem: this.selectItem.bind(this),
            unselectItem: this.unselectItem.bind(this),
            onSelectFolder: this.selectFolder.bind(this),
            toggleExpended: this.toggleExpended.bind(this),
            createNewFolder: this.createNewFolder.bind(this),
            deleteFolder: this.deleteFolder.bind(this),
            deleteItems: this.deleteItems.bind(this),
            updateFiles: this.updateFiles.bind(this),
            onEditFiles: this.onEditFiles.bind(this),
            moveItems: this.moveItems.bind(this),
            uploadFiles: this.uploadFiles.bind(this),
            validateFile: this.validateFile.bind(this),
            findFolder: this.findFolder
        };
    }

    async componentDidMount() {
        const {currentUser} = this.props;
        if (!currentUser) return;

        await this.getMeta();
        await this.getFolders();
        await this.getExtraFolders();
    }

    async getMeta() {
        const {response} = await NetworkController.get("/library/meta");
        await this.setStatePromise({meta: response.meta});
    }

    async getFolders() {
        const {response} = await NetworkController.get("/library/folders", {});
        await this.setStatePromise({folderTree: response.folderTree, gotFolders: true});
        await this.selectFolder(response.folderTree.id);
    }

    async getExtraFolders() {
        const {response} = await NetworkController.get("/library/extra-folders", {});
        await this.setStatePromise({extraFolderTrees: response.folderTrees, gotExtraFolders: true});
    }

    async createNewFolder(name) {
        const {selectedFolderId, expendedFolderIds} = this.state;
        const {error, response} = await NetworkController.post("/library/folders", {name, parent: selectedFolderId});
        if (error) {
            const {error: errorMessage} = response;
            return errorMessage;
        }
        await this.setStatePromise({folderTree: response.folderTree});
        await this.getExtraFolders();
        await this.selectFolder(response.newFolder.id);

        if (selectedFolderId !== response.folderTree.id && !expendedFolderIds.includes(selectedFolderId)) {
            await this.setStatePromise({expendedFolderIds: [...expendedFolderIds, selectedFolderId]});
        }
    }

    getFolderIds = folder => {
        return _flattenDeep([folder.id, folder.items.map(this.getFolderIds)]);
    };

    getIsFolderDeletable = selectedId => {
        const {extraFolderTrees = [], folderTree} = this.state;
        const ids = _flattenDeep([folderTree.id, ...extraFolderTrees.map(this.getFolderIds)]);
        return !ids.includes(selectedId);
    };

    async selectFolder(selectedFolderId) {
        const selectedFolderDidChange = selectedFolderId !== this.state.selectedFolderId;
        await this.setStatePromise({selectedFolderId, canDeleteFolder: this.getIsFolderDeletable(selectedFolderId)});
        if (selectedFolderDidChange) {
            await this.updateFiles();
        }
    }

    async updateFiles() {
        const {selectedFolderId} = this.state;
        const {error, response} = await NetworkController.get("/library/items", {folderId: selectedFolderId});
        if (error) {
            return await this.getFolders();
        }
        await this.setStatePromise({
            gotItems: true,
            originalItems: response.items,
            items: this.applySortAndFilterToItems(response.items),
            selectedItems: []
        });
    }

    async onEditFiles(data) {
        const {error, response} = await NetworkController.put("/library/items", {items: data});
        if (error) {
            return await this.getFolders();
        }
        await this.setStatePromise({folderTree: response.folderTree, selectedItems: []});
        await this.updateFiles();
    }

    async deleteFolder() {
        const {selectedFolderId} = this.state;
        const {error, response} = await NetworkController.delete("/library/items", {items: [{id: selectedFolderId}]});
        if (error) {
            return await this.getFolders();
        }
        await this.setStatePromise({folderTree: response.folderTree});
        await this.selectFolder(response.folderTree.id);
    }

    async deleteItems() {
        const {selectedItems} = this.state;
        const request = {items: selectedItems.map(id => ({id}))};
        const {error, response} = await NetworkController.delete("/library/items", request);
        if (error) {
            return await this.getFolders();
        }
        await this.setStatePromise({folderTree: response.folderTree, selectedItems: []});
        await this.updateFiles();
    }

    async moveItems(folderId) {
        const {selectedItems} = this.state;
        const request = {items: selectedItems.map(id => ({id})), folderId};
        const {error, response} = await NetworkController.put("/library/items/folder", request);
        if (error) {
            return await this.getFolders();
        }
        await this.setStatePromise({folderTree: response.folderTree, selectedItems: []});
        await this.updateFiles();
    }

    setItemFilter(itemFilter) {
        this.setState({itemFilter}, () => {
            this.setState({items: this.applySortAndFilterToItems(this.state.originalItems)});
        });
    }

    setItemSort(itemSort) {
        this.setState({itemSort}, () => {
            this.setState({items: this.applySortAndFilterToItems(this.state.originalItems)});
        });
    }

    applySortAndFilterToItems(originalItems) {
        const {itemFilter, itemSort} = this.state;
        let items = [...originalItems];
        if (itemFilter !== "all") {
            items = items.filter(i => i.type === itemFilter);
        }
        const typeOrder = [libraryItemTypes.folder, libraryItemTypes.image, libraryItemTypes.video, libraryItemTypes.document];
        items = items.sort((a, b) => {
            switch (itemSort) {
                case "name":
                    return a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase());
                case "date":
                    return new Date(b.createdAt) - new Date(a.createdAt);
                case "author":
                    return (a.author || "").toLocaleUpperCase().localeCompare((b.author || "").toLocaleUpperCase());
                case "format":
                    return typeOrder.indexOf(a.type) > typeOrder.indexOf(b.type) ? 1 : -1;
                case "size":
                    return (a.size || 0) - (b.size || 0);
            }
        });
        return items;
    }

    setItemsMode(itemsMode) {
        localStorage.libraryItemsMode = itemsMode;
        this.setState({itemsMode});
    }

    selectItem(id) {
        const {selectedItems, meta} = this.state;
        if (selectedItems.length + 1 <= meta.maxEditInTime) {
            this.setState({selectedItems: [...selectedItems, id]});
        }
    }

    unselectItem(id) {
        this.setState({selectedItems: this.state.selectedItems.filter(i => i !== id)});
    }

    findFolder = selectedFolderId => {
        const {folderTree, extraFolderTrees} = this.state;
        for (const tree of [folderTree, ...extraFolderTrees]) {
            const folder = findFolderInTree(tree, selectedFolderId).next().value;
            if (folder) return folder;
        }
        return {};
    };

    toggleExpended(folderId) {
        const {expendedFolderIds} = this.state;
        const isExpended = expendedFolderIds.includes(folderId);
        if (isExpended) {
            const root = this.findFolder(folderId);
            return this.setState({
                expendedFolderIds: expendedFolderIds.filter(id => !findFolderInTree(root, id).next().value)
            });
        }
        this.setState({expendedFolderIds: [...expendedFolderIds, folderId]});
    }

    async uploadFiles({files, author, folderId, isDataSeparated}) {
        const {addItemToUploadQueue, uploadQueuedFiles} = this.props;
        for (let i = 0; i < files.length; i++) {
            const error = this.validateFile(files[i].file);
            if (error) {
                continue;
            }
            const params = {
                url: LIBRARY_ITEMS_URL,
                body: {
                    name: files[i].name,
                    author: isDataSeparated ? files[i].author : author,
                    folderId: isDataSeparated ? files[i].folderId : folderId
                },
                files: {file: files[i].file}
            };

            await addItemToUploadQueue("api", params);
        }
        const response = await uploadQueuedFiles();
        return response && response.map(res => res && res.response && res.response.error);
    }

    validateFile(file) {
        const {t} = this.props;
        const {meta} = this.state;

        const hasFormatError = !meta.extensions.includes(file.name.split(".").slice(-1)[0]);
        if (hasFormatError) {
            return t(`errors.libraryFileFormatError`, {allowedFormats: meta.extensions.join(", ")});
        }

        const extension = file.name.split(".").slice(-1)[0];
        const restrictions = meta.restrictions.find(r => r.extensions.includes(extension));

        const hasSizeError = file.size > restrictions.size * 1024 * 1024;
        if (hasSizeError) {
            return t(`errors.libraryFileSizeError`, {allowedSize: restrictions.size});
        }
    }

    render() {
        return <LibraryContext.Provider value={this.state}>{this.props.children}</LibraryContext.Provider>;
    }
}

export default {Provider: LibraryProvider, Consumer: LibraryContext.Consumer};
