import userTypes from "consts/userTypes";

export default function(receiver, t, options, lecture) {
    if (!receiver) {
        return receiver;
    }
    if (options && options.parent) {
        return t("chatWidget.blockFamily");
    }
    if (receiver.isDeleted) {
        return t("chatsList.deletedName");
    }

    const isReceiverGroupChat = !receiver.role;
    if (isReceiverGroupChat) {
        return receiver.name;
    }
    if (receiver.role === userTypes.admin) {
        return t("chatsList.adminName");
    }

    if (lecture) {
        return `${lecture.name} (${lecture.course.name})`;
    }

    return `${receiver.firstName} ${receiver.lastName}`;
}
