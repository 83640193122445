import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import LectureAttendance from "./LectureAttendance";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {Column, Title, Value, Row} from "./LectureInfoStyled";

const InfoContainer = styled.div`
    display: none;
    width: 100%;
    padding: 0 1rem;
    ${mediaQuery.phone`
        display: flex;
        flex-direction: column;
    `}
`;

@withNamespaces("translation")
class LectureInfoMobile extends Component {
    render() {
        const {
            lecture,
            course,
            startDate,
            studentsNumber,
            t,
            usersAttendance,
            totalUsers,
            loadAttendance,
            usersPage,
            pageSize
        } = this.props;
        return (
            <InfoContainer>
                <Row>
                    <Column>
                        <Title>{t("lecture.digest.subject")}</Title>
                        <Title>{t("lecture.digest.grade")}</Title>
                        <Title>{t("lecture.digest.language")}</Title>
                        <Title>{t("lecture.digest.dateTime")}</Title>
                        <Title>{t("lecture.digest.studentsNumber")}</Title>
                        <Title>{t("lecture.digest.type")}</Title>
                    </Column>
                    <Column />
                    <Column>
                        <Value>{course.subject ? course.subject : "-"}</Value>
                        <Value>{course.grade ? course.grade : "-"}</Value>
                        <Value>{course.language ? course.language : "-"}</Value>
                        <Value>{startDate}</Value>
                        <Value>{studentsNumber}</Value>
                        <Value>{lecture.type}</Value>
                    </Column>
                </Row>
                <Row>
                    <LectureAttendance
                        usersAttendance={usersAttendance}
                        startDate={new Date(lecture.realStartDate || lecture.startDate)}
                        endDate={new Date(lecture.endDate)}
                        total={totalUsers}
                        loadAttendance={loadAttendance}
                        page={usersPage}
                        pageSize={pageSize}
                    />
                </Row>
            </InfoContainer>
        );
    }
}

export default LectureInfoMobile;
