import styled from "styled-components";
import UserPicContainer from "components/common/styled/UserPicContainer";
import {mediaQuery} from "components/common/styled/mediaQuery";
import FlexCenter from "components/common/styled/FlexCenter";
import AbsoluteFill from "components/common/styled/AbsoluteFill";

export const CardsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 5px;
    padding-right: 20px;

    ${mediaQuery.phone`
        width: 100%;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

export const CardWrapper = styled.div`
    position: relative;

    display: flex;
    justify-content: space-between;

    width: 48.5%;
    padding: 1.5rem;
    margin: 1rem 0;

    border-radius: 4px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
    background-color: var(--white);

    //cursor: pointer; commented down till principal can invite
    ${props => props.disabled && "cursor: auto; pointer-events: none;"};
    ${mediaQuery.phone`
        width: 95%;
        padding: 1.5rem;
        justify-content: unset;
        margin-left: auto;
        margin-right: auto;
    `}
`;

export const AvatarAndRating = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    height: 100%;
    ${mediaQuery.phone`
            width: 5rem;
            height: 7rem;
    `}
    > img {
        ${UserPicContainer};
        width: 8rem;
        height: 8rem;
        border: unset;

        align-self: center;
        object-fit: cover;
        ${mediaQuery.phone`
            width: 5rem;
            height: 5rem;
        `}
    }
`;

export const RightCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    ${mediaQuery.phone`
        width: auto;
        margin-left: 2rem;
    `}
`;

export const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const FullName = styled.div`
    display: flex;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.8;
    letter-spacing: normal;
    color: var(--black);
`;

export const Address = styled.div`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: normal;
    color: var(--black);

    ${mediaQuery.phone`
        display: none;
    `}
`;

export const Text = styled.div`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: normal;
    color: var(--black);
`;

export const LanguagesAndSkills = styled.div`
    margin-left: 0.5rem;
    font-size: 13px;
    line-height: 1.31;
    letter-spacing: normal;
    color: #8b8b8b;
`;

export const RowField = styled.div`
    display: flex;
    margin-top: 1rem;
`;

export const Controls = styled.div`
    display: flex;
    justify-content: space-between;

    margin-top: 1rem;
`;

export const DesktopView = styled.div`
    ${mediaQuery.phone`
        display: none;
   `}
`;

export const MobileView = styled.div`
    display: none;
    ${mediaQuery.phone`
        display: flex;
    `}
`;

export const SelectIcon = styled.div`
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    width: 1.5rem;
    height: 1.5rem;

    transition: opacity 150ms ease-out, background-color 150ms ease-out;
    background-color: ${props => (props.selected ? "var(--main-theme-color)" : "#f4f4f4")};
    border-radius: 50%;
    border: ${props => (props.selected ? "none" : "1px solid #fff")};

    ${props => props.disabled && "background-color: #8b8b8b;"};
    ${FlexCenter}
    > svg {
        visibility: ${props => (props.selected ? "visible" : "hidden")};
        margin-top: 0.1rem;
        height: 0.7rem;
    }
`;

export const Overlay = styled.div`
    ${AbsoluteFill}
    opacity: 0;
    border-radius: 4px;
    overflow: hidden;
    transition: opacity 200ms ease-in;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6));

    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1rem;
`;

export const SearchContainer = styled.div`
    margin-top: 2rem;
    padding-right: 1rem;
    ${mediaQuery.phone`
        padding: 0 2rem;
    `}
`;
