import React from "react";
import {CellOfRow, CreatedAt, Event, Link, NotificationRow, TitleOfNotification} from "./styled";
import Links from "../Links/Links";
import Status from "components/common/icons/notifications/statuses/status";
import userTypes from "consts/userTypes";
import routes, {getRoute} from "consts/routes";

const ONE_HOUR = 3600000; // in ms

export function renderDate(createdAt, t, i18n) {
    const nowDate = new Date();
    const yesterday = new Date(nowDate - 24 * ONE_HOUR);

    if (yesterday.getDate() === createdAt.getDate()) {
        return t("common.time.yesterday");
    }

    if (nowDate - createdAt < ONE_HOUR) {
        return createdAt.toLocaleString(i18n.language, {hour: "numeric", minute: "numeric"});
    }

    if (nowDate - createdAt < 24 * ONE_HOUR) {
        const hours = Math.floor((nowDate.getTime() - createdAt.getTime()) / ONE_HOUR);
        return `${hours} ${t("common.time.hour_interval", {postProcess: "interval", count: hours})} ${t("common.time.ago")}`;
    }

    return createdAt.toLocaleString(i18n.language, {day: "numeric", month: "long"});
}

export function renderNotificationsList(notifications, t, i18n, history, currentUser) {
    const styleStatus = i18n.dir() !== "rtl" ? {marginRight: "1rem"} : {marginLeft: "1rem"};

    Object.assign(styleStatus, {minWidth: "20px", height: "20px"});

    return notifications.map(item => {
        const {title, lectureId, contentId, courseId, programId, schoolId, schoolName} = item;
        const handlerClickLink = () => {
            const link = getLinkFromTitle(title, {lectureId, contentId, currentUser, courseId, programId, schoolId, schoolName});

            history.push(link);
        };

        return (
            <NotificationRow key={item.id}>
                <React.Fragment>
                    <div style={{display: "flex"}}>
                        <Status status={item.status} viewed={item.isViewed ? 1 : 0} style={styleStatus} />
                        <CellOfRow>
                            <TitleOfNotification>
                                {item.courseName}
                                {t(`notifications.titles.${item.title}`, {
                                    teacher: item.teacherName,
                                    quiz: item.quizName
                                })}
                                {item.lectureName}
                            </TitleOfNotification>
                            <CreatedAt>{renderDate(new Date(item.createdAt), t, i18n)}</CreatedAt>
                        </CellOfRow>
                    </div>
                    {item.subject !== "" && item.grade !== "" && (
                        <Event>
                            {t(`common.subjects.${item.courseSubject}.name`)}, {t(`common.grades.${item.grade}`)}
                        </Event>
                    )}
                    <Link onClick={handlerClickLink}>{t(`notifications.links.${Links[item.title]}`)}</Link>
                </React.Fragment>
            </NotificationRow>
        );
    });
}

export function getLinkFromTitle(title, {lectureId, contentId, programId, currentUser, courseId, schoolId: schoolIdOrNull}) {
    const schoolId = schoolIdOrNull || (currentUser && currentUser.schoolSettings && currentUser.schoolSettings.school.id);

    switch (Links[title]) {
        case "GO_TO_MARKET":
            return `/content/${contentId ? contentId : ""}`;
        case "GO_TO_COURSE":
            return (
                (courseId && `/courses/${courseId}`) ||
                (currentUser.role === userTypes.teacher ? {pathname: "/profile", state: {tab: "courses"}} : "/courses/search")
            );
        case "GO_TO_REQUEST":
            return "/courses#requestedCourses";
        case "GO_TO_ENROLLMENT_REQUEST":
            const isPrincipal = currentUser.role === userTypes.principal;
            const isTeacher = currentUser.role === userTypes.teacher;
            if (isPrincipal) return `/schools/${schoolId}#courses`;
            return isTeacher ? "/courses#enrollments" : "/courses";
        case "GO_TO_WALLET":
            return "/wallet/balance";
        case "GO_TO_LECTURES":
            if (!lectureId) return "/lectures";
            return `/lectures/${lectureId}/digest`;
        case "QUIZ_WAS_ATTACHED":
            return `/lectures/${lectureId}/digest`;
        case "GO_TO_LECTURE_REVIEW":
            return `/lectures/${lectureId}/review`;
        case "GO_TO_PROGRAM":
            return `/programs/${programId}`;
        case "GO_TO_SUBSCRIPTIONS":
            return `/wallet/subscriptions`;
        case "GO_TO_INVITATIONS":
            return routes.invitations.path;
        case "GO_TO_SCHOOL":
            if (!schoolId) return routes.home;
            return getRoute(routes.school.path, {id: schoolId});
        case "GO_TO_SCHOOL_PROGRAM_TAB":
            return getRoute(routes.school.path, {id: schoolId}, "#programs");
        default:
            return "";
    }
}
