import styled from "styled-components";
import FlexCenter from "components/common/styled/FlexCenter";

export const IconWrapper = styled.div`
    ${FlexCenter};
    border-radius: 2rem;
    /* background: var(--main-theme-color-semitransparent); */
    background: var(--main-theme-color);
    width: 2rem;
    height: 2rem;
    z-index: 10;
    transition: 0.15s;
    margin-right: 0.25rem;
    &:hover {
        background: var(--white);
        /* background: var(--main-theme-color); */

        g,
        path {
            fill: var(--main-theme-color) !important;
        }
    }
`;
