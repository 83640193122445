import React, {PureComponent} from "react";
import styled from "styled-components";
import MobileMenuIcon from "components/common/icons/MobileMenu";
import MobileMenuModal from "./MobileMenuModal";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {withContextConsumer} from "utils/contexts";
import WindowRectContext from "contexts/WindowRect";

const IconContainer = styled.div`
    display: none;
    padding: 1rem;
    margin: -1rem;
    svg {
        height: 1.2rem;
    }
    ${mediaQuery.phone`
        display: block;
        padding: 1rem 0rem 1rem 1rem;
        margin: -1rem 0rem -1rem -1rem;
    `}
`;

@withContextConsumer(WindowRectContext.Consumer)
class MobileMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        return props.isDesktopResolution && state.modalIsOpen ? {modalIsOpen: false} : {};
    }

    render() {
        const {currentUser, logout, myCoursesNotification} = this.props;
        const {modalIsOpen} = this.state;

        return (
            <React.Fragment>
                <IconContainer onClick={() => this.setState({modalIsOpen: true})}>
                    <MobileMenuIcon />
                </IconContainer>
                <MobileMenuModal
                    isOpen={modalIsOpen}
                    onRequestClose={() => this.setState({modalIsOpen: false})}
                    currentUser={currentUser}
                    logout={logout}
                    myCoursesNotification={myCoursesNotification}
                />
            </React.Fragment>
        );
    }
}

export default MobileMenu;
