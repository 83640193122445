export default {
    "New enrollment request to course": "GO_TO_ENROLLMENT_REQUEST",
    "New enrollment request to program": "GO_TO_SCHOOL_PROGRAM_TAB",
    "New request to course": "GO_TO_REQUEST",
    "New request to program": "GO_TO_SCHOOL_PROGRAM_TAB",
    "Status enroll on course was changed to declined": "GO_TO_COURSE",
    "Status enroll on course was changed to accepted": "GO_TO_COURSE",
    "New course request": "GO_TO_REQUEST",
    "Status of course request was changed to accepted": "GO_TO_COURSE",
    "Status of course request was changed to declined": "GO_TO_COURSE",
    "Status enroll on program was changed to accepted": "GO_TO_PROGRAM",
    "Status enroll on program was changed to declined": "GO_TO_PROGRAM",
    "Lecture Reviewed": "GO_TO_LECTURE_REVIEW",
    ANSWER_COUNT_100: "GO_TO_LECTURES",
    ANSWER_COUNT_50: "GO_TO_LECTURES",
    QUIZ_WAS_ATTACHED: "GO_TO_LECTURES",
    ONE_WEEK_UNTIL_SUBSCRIPTION_LEFT: "GO_TO_WALLET",
    SUBSCRIPTION_PAYMENT_WAS_CANCELED: "GO_TO_WALLET",
    SUBSCRIPTION_PAYMENT_WAS_PAID: "GO_TO_WALLET",
    CONTENT_SUBSCRIPTION_WAS_CANCELED_DUE_TO_MONEY_LACK: "GO_TO_WALLET",
    CONTENT_SUBSCRIPTION_NOT_ENOUGH_MONEY: "GO_TO_WALLET",
    CONTENT_SUBSCRIPTION_PAYMENT_WAS_PAID: "GO_TO_WALLET",
    ENROLLMENT_HAS_EXPIRED: "GO_TO_COURSE",
    CONTENT_SUBSCRIPTION_AUTO_RENEWAL_DISABLED: "GO_TO_MARKET",
    NOTIFICATION_COURSE_WAS_EDITED: "GO_TO_COURSE",
    SUBSCRIPTION_PLAN_CHANGED: "GO_TO_SUBSCRIPTIONS",
    TUTOR_INVITE_NEW: "GO_TO_INVITATIONS",
    TUTOR_INVITE_ACCEPTED: "GO_TO_INVITATIONS",
    TUTOR_INVITE_DECLINED: "GO_TO_INVITATIONS",
    FAILED_TO_PAY_TUTOR: "GO_TO_WALLET",
    CONTRACT_CANCELED: "GO_TO_SCHOOL"
};
