import styled from "styled-components";

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;

    color: white;
    font-weight: 600;
    font-size: 0.8rem;
`;
