import React, {Component} from "react";
import styled from "styled-components";
import Input from "components/form/Input";
import AttachFileIcon from "components/common/icons/chat/AttachFile";
import SendIcon from "components/common/icons/chat/Send";
import HiddenFileInput from "components/common/styled/HiddenFileInput";
import File from "components/library/files/FilesGrid/File";
import ShareWithLibraryModal from "components/library/modals/ShareWithLibraryModal";
import MessageInputContext from "contexts/chat/MessageInput";
import {withContextProvider, withContextConsumer} from "utils/contexts";
import userTypes from "consts/userTypes";
import messageTypes from "consts/messageAdditionalInfoTypes";
import {Link} from "react-router-dom";

const BlockStubContainer = styled.div`
    padding: 1rem;
    margin-top: 1px;
`;

const Container = styled.div`
    margin: 0.7rem 0;
`;

const Form = styled.form`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    flex: 1;
`;

const IconSendButton = styled.button`
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    user-select: none;
    transition: 0.2s;
    margin: 0 0.75rem;
    transition-timing-function: ease-out;
    transform: scale(${props => (props.visible ? 1 : 0)});
`;

const IconAttachFileButton = styled.div`
    position: relative;
    width: 1rem;
    height: 1rem;
    margin: 0 0.75rem;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    background: none;
`;

const AttachedFilesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
`;

const FileInputContainer = styled.div`
    ${HiddenFileInput}
`;

const FileSizeErrorContainer = styled.div`
    color: red;
    font-size: 0.9rem;
    padding: 0.5rem 1rem 0;
`;

const AdditionalInfoContainer = styled.div`
    padding: 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    background-color: #f5f5f5;
    color: #5c6977;
    margin-bottom: 0.5rem;
`;

@withContextProvider(MessageInputContext.Provider)
@withContextConsumer(MessageInputContext.Consumer)
class MessageInput extends Component {
    handleSendMessage(e) {
        e.preventDefault();
        const {inputCorrect, dialogState, message, libraryFiles, attachedFiles, onSubmit, clearInput} = this.props;

        if (inputCorrect) {
            const {dialogId} = dialogState;
            onSubmit({...message, dialogId}, libraryFiles, attachedFiles);
            clearInput();
        }
    }

    renderAttachFilesButton() {
        const {userRole, handleFileLibraryRequestOpen, handleInputAttachedFiles} = this.props;

        const handleFileChange = e => handleInputAttachedFiles(Array.from(e.target.files));

        if (userRole === userTypes.teacher) {
            return (
                <IconAttachFileButton onClick={handleFileLibraryRequestOpen}>
                    <AttachFileIcon />
                </IconAttachFileButton>
            );
        }
        return (
            <IconAttachFileButton>
                <AttachFileIcon />
                <FileInputContainer>
                    <input title=" " type="file" onChange={handleFileChange} multiple />
                </FileInputContainer>
            </IconAttachFileButton>
        );
    }

    renderAdditionalInfo() {
        const {t, additionalInfo} = this.props;
        if (!additionalInfo) return null;

        if (additionalInfo.type === messageTypes.schoolApplication) {
            return <AdditionalInfoContainer>{t("chatWidget.messageAdditionalInfo.applicationText")}</AdditionalInfoContainer>;
        }
        return (
            <AdditionalInfoContainer>
                {t("chatWidget.messageAdditionalInfo.questionAbout")}
                <Link to={`/content/${additionalInfo.id}`}>{t(`chatWidget.messageAdditionalInfo.${additionalInfo.type}`)}</Link>
            </AdditionalInfoContainer>
        );
    }

    render() {
        const {
            blocked,
            meta,
            t,
            message,
            fileLibraryIsOpen,
            inputCorrect,
            libraryFiles,
            attachedFiles,
            fileSizeError,
            handleInputLibraryFiles,
            handleInputAttachedFiles,
            handleFileLibraryRequestClose,
            handleChangeMessage,
            handleLibraryFileClick,
            handleAttachedFileClick
        } = this.props;

        const renderBlockStub = () => {
            return <BlockStubContainer>{t("chatsList.messageInputBlockStub")}</BlockStubContainer>;
        };

        const isLibraryFilesExist = libraryFiles && libraryFiles.length !== 0;
        const isAttachFilesExist = attachedFiles && attachedFiles.length !== 0;

        if (blocked) return renderBlockStub();
        return (
            <Container>
                {this.renderAdditionalInfo()}
                <ShareWithLibraryModal
                    isOpen={fileLibraryIsOpen}
                    fileType={"all"}
                    onInputFiles={handleInputLibraryFiles}
                    onAttachFiles={handleInputAttachedFiles}
                    onRequestClose={handleFileLibraryRequestClose}
                />
                <Form onSubmit={this.handleSendMessage.bind(this)}>
                    {this.renderAttachFilesButton()}
                    <Label
                        onClick={e => {
                            if (e.target === e.currentTarget) e.preventDefault();
                        }}>
                        <Input
                            style={{padding: "0.5rem 1rem", flex: 1, borderRadius: "0.5rem", backgroundColor: "#F5F5F7"}}
                            value={message.text}
                            onChange={handleChangeMessage}
                            type="text"
                            name="message"
                            maxLength={2000}
                            placeholder={t("chatsList.enterMessage")}
                            inputStyle={{border: "none", padding: "0", fontSize: "0.875rem", flex: 1}}
                        />
                        <IconSendButton visible={inputCorrect}>
                            <SendIcon />
                        </IconSendButton>
                    </Label>
                </Form>
                {fileSizeError && (
                    <FileSizeErrorContainer>
                        {t("errors.errorFileSize", {MAX_SIZE: meta.validationFileSize})}
                    </FileSizeErrorContainer>
                )}
                {isLibraryFilesExist && (
                    <AttachedFilesContainer>
                        {libraryFiles.map(file => (
                            <File size="small" key={file.id} item={file} selectItem={handleLibraryFileClick} />
                        ))}
                    </AttachedFilesContainer>
                )}
                {isAttachFilesExist && (
                    <AttachedFilesContainer>
                        {attachedFiles.map(file => (
                            <File size="small" key={file.id} item={file} selectItem={handleAttachedFileClick} />
                        ))}
                    </AttachedFilesContainer>
                )}
            </Container>
        );
    }
}

export default MessageInput;
