import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import {minutesToHumanDate, toHHmmFromMillis} from "utils/time";
import {styled} from "@mui/material/styles";
import {Stack, Box, Typography, Popover, Paper, Chip, Button, Grid} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const StyledTableHeaderCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "var(--main-theme-color)",
        color: theme.palette.common.white,
        fontSize: 18
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const attendanceHeaders = [
    {name: "Student"},
    {name: "Total time"},
    // {name:"Lecture start time"},
    // {name:"Lecture end time"},
    {name: "Join time"},
    {name: "Leave time"},
    {name: "Rejoins"},
    {name: "Active time"},
    {name: "Active (%)"}
];

const LectureAttendanceTable = props => {
    const {
        usersAttendance,
        groupedUserenrolledUsers,
        customClass,
        lectureDurationInMinutes,
        lectureDuration,
        meetingTime,
        meetingTimeinHours,
        startDate,
        lecture,
        endDate
    } = props;
    // const [currentLectureDate, setcurrentLectureDate] = useState(false);
    // const [lectureDates, setlectureDates] = useState([]);
    // const [currentIndex, setcurrentIndex] = useState(0);
    // const [rejoinData, setrejoinData] = useState([]);
    // const [totalIndex, settotalIndex] = useState(0);
    const [attendanceData, setattendanceData] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverId, setpopoverId] = React.useState(null);

    useEffect(() => {
        // let datesKeys = Object.keys(groupedUserenrolledUsers);
        setattendanceData(usersAttendance);
        // if (datesKeys?.length > 0) {
        //     let lastIndex = datesKeys.findLastIndex(item => item);
        //     setcurrentIndex(0);
        //     settotalIndex(lastIndex);
        //     setlectureDates(datesKeys);
        //     setcurrentLectureDate(datesKeys?.[0]);
        //     // setattendanceData(groupedUserenrolledUsers[(datesKeys?.[0])]);
        //     setattendanceData(usersAttendance);
        // }
    }, [usersAttendance]);

    const realStartDate = lecture?.realStartDate ? new Date(lecture?.realStartDate) : new Date(lecture?.startDate);
    const realEndDate = lecture?.realEndDate ? new Date(lecture?.realEndDate) : new Date(lecture?.endDate);
    const teacherStartDate = lecture?.realStartDate ? new Date(lecture?.realStartDate) : new Date(lecture?.startDate);
    const teacherEndDate = lecture?.realEndDate ? new Date(lecture.realEndDate) : new Date(lecture.endDate);
    const teacherLectureDuration = teacherEndDate - teacherStartDate;
    // const teacherLectureDurationTime = msToTime(teacherLectureDuration);
    const teacherLectureDurationTimeHours = toHHmmFromMillis(teacherLectureDuration);

    const teacherLectureDurationInMinutes = moment
        ?.duration(moment(teacherLectureDuration))
        ?.asMinutes()
        .toFixed(1);

    const handlePopoverOpen = (event, popoverId) => {
        setAnchorEl(event.currentTarget);
        setpopoverId(popoverId);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setpopoverId(null);
    };

    let rowData = [];

    attendanceData?.map(data => {
        let joinLectureAt = null;
        let leaveLectureAt = null;
        let activeTime = null;
        let timePercentage = null;
        let totalT = null;
        let activeT = null;
        let PercetageT = null;
        let rejoins = [];
        let timezone = props.currentUser?.timezone || moment.tz.guess();

        if (data?.lectureAttendance?.length > 0) {
            const joinTime = data?.lectureAttendance?.[0]?.joinLectureAt;

            if (joinTime) {
                joinLectureAt = moment.tz(joinTime, timezone).format("h:mm A,D/MM/YYYY");
            }

            const lastAttendance = data?.lectureAttendance?.findLast(element => element.leaveLectureAt);
            if (lastAttendance) {
                leaveLectureAt = moment.tz(lastAttendance?.leaveLectureAt, timezone).format("h:mm A,D/MM/YYYY");
            }
            let activeTimeinRejoins = 0;
            data?.lectureAttendance?.map(element => {
                let joinTime = moment(element?.joinLectureAt);
                let leaveTime = moment(element?.leaveLectureAt);
                let timeDifference = leaveTime?.diff(joinTime) || 0;
                activeTimeinRejoins = activeTimeinRejoins + timeDifference;

                rejoins.push({
                    joinLectureAt: moment.tz(joinTime, timezone).format("h:mm A,D/MM/YYYY"),
                    leaveLectureAt: moment.tz(leaveTime, timezone).format("h:mm A,D/MM/YYYY")
                });
            });

            if (activeTimeinRejoins > 0) {
                activeT = moment.duration(activeTimeinRejoins).minutes();
            }
            // activeTime = Math.max(new Date(leaveLectureAt), new Date(startDate)) - Math.max(new Date(joinLectureAt), new Date(startDate));
            totalT = teacherLectureDurationInMinutes;
            // activeT = moment?.duration(activeTime)?.asMinutes();
            PercetageT = (activeT / totalT) * 100;

            activeTime = minutesToHumanDate(activeT) || "-"; //activeT?.toString()?.substring(0, 4)
            timePercentage = PercetageT?.toString()?.substring(0, 4) || "-";
        }

        let row = {
            id: data.id,
            userId: data.userId,
            name: data?.firstName + " " + data?.lastName,
            meetingTime: teacherLectureDurationTimeHours || "-",
            startTime: moment(realStartDate)?.format("h:mm A,D/MM/YYYY") || "-",
            endTime: moment(realEndDate)?.format("h:mm A,D/MM/YYYY") || "-",
            joinLectureAt: joinLectureAt,
            leaveLectureAt: leaveLectureAt,
            rejoins: rejoins,
            activeTime: activeTime,
            activePercentage: timePercentage
        };
        rowData.push(row);
    });

    return (
        <Grid container spacing={2} sx={{pt: 1, mt: 1, pb: 1}}>
            {/* <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={2}>
                    {currentIndex > 0 ? (
                        <NavigateBeforeIcon color="primary" onClick={() => prevLectureDate()} />
                    ) : (
                        <NavigateBeforeIcon color="disabled" />
                    )}
                    <Chip label={moment(currentLectureDate)?.format("h:mm A,D/MM/YYYY")} variant="filled" color="primary" />

                    {totalIndex > currentIndex ? (
                        <NavigateNextIcon color="primary" onClick={() => nextLectureDate()} />
                    ) : (
                        <NavigateNextIcon
                            color="disabled"
                        />
                    )}
                </Stack>
            </Grid> */}
            <Grid item xs={12}>
                <Paper elevation={3} sx={{width: "100%", maxWidth: "100%", pt: 1, mt: 1, pb: 1}}>
                    <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                        <Typography sx={{p: 1}}>{"Lecture Time "}</Typography>
                        <Typography sx={{p: 1}}>{"Start Date:"}</Typography>
                        <Chip
                            label={moment(startDate)?.format("h:mm A,D/MM/YYYY")}
                            variant="filled"
                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                        />

                        <Typography sx={{p: 1}}>{"End Date:"}</Typography>
                        <Chip
                            label={moment(endDate)?.format("h:mm A,D/MM/YYYY")}
                            variant="filled"
                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                        />
                        <Typography sx={{p: 1}}>{"Duration:"}</Typography>
                        <Chip
                            label={meetingTimeinHours}
                            variant="filled"
                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                        <Typography sx={{p: 1}}>{"Teacher Time "}</Typography>
                        <Typography sx={{p: 1}}>{"Start Date:"}</Typography>
                        <Chip
                            label={moment(teacherStartDate)?.format("h:mm A,D/MM/YYYY")}
                            variant="filled"
                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                        />

                        <Typography sx={{p: 1}}>{"End Date:"}</Typography>
                        <Chip
                            label={moment(teacherEndDate)?.format("h:mm A,D/MM/YYYY")}
                            variant="filled"
                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                        />
                        <Typography sx={{p: 1}}>{"Duration:"}</Typography>
                        <Chip
                            label={teacherLectureDurationTimeHours}
                            variant="filled"
                            style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                        />
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper} sx={{maxHeight: 250}}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="Attendance table">
                        <TableHead>
                            <TableRow>
                                {attendanceHeaders?.map(header => {
                                    return <StyledTableHeaderCell key={header?.name}>{header?.name}</StyledTableHeaderCell>;
                                })}
                                {/* <TableCell>Name</TableCell>
                                <TableCell align="center">Lecture total time</TableCell>
                                <TableCell align="center">Lecture start Date</TableCell>
                                <TableCell align="center">Lecture end Date</TableCell>
                                <TableCell align="center">Join time</TableCell>
                                <TableCell align="center">Leave time</TableCell>
                                <TableCell align="center">Rejoins</TableCell>
                                <TableCell align="center">Active time &nbsp; (min)</TableCell>
                                <TableCell align="center">Active &nbsp;(%)</TableCell> */}
                            </TableRow>
                        </TableHead>

                        <TableBody sx={{"min-height": "100px"}}>
                            {rowData?.map(row => (
                                <TableRow key={row?.id} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell component="th" scope="row">
                                        {row?.name}
                                    </TableCell>
                                    <TableCell align="left">{`${row?.meetingTime}`}</TableCell>
                                    {/* <TableCell align="center">{`${row?.startTime}`}</TableCell> */}
                                    {/* <TableCell align="center">{`${row?.endTime}`}</TableCell> */}
                                    <TableCell align="center">
                                        {row?.joinLectureAt ? (
                                            `${row?.joinLectureAt}`
                                        ) : (
                                            <Chip label={"Absent"} variant="filled" color="warning" />
                                        )}
                                    </TableCell>
                                    <TableCell align="center">{row?.leaveLectureAt ? `${row?.leaveLectureAt}` : "-"}</TableCell>
                                    <TableCell align="center">
                                        <div>
                                            {row?.rejoins?.length > 0 ? (
                                                <div>
                                                    <Stack
                                                        aria-owns={open ? row?.id : undefined}
                                                        aria-haspopup="true"
                                                        onMouseEnter={e => handlePopoverOpen(e, row.id)}
                                                        onMouseLeave={handlePopoverClose}
                                                        direction="row"
                                                        justifyContent="space-around" //space-around
                                                        alignItems="center"
                                                        spacing={2}>
                                                        <Chip label={"Show rejoins"} variant="filled" color="secondary" />
                                                    </Stack>

                                                    <Popover
                                                        id={row?.id}
                                                        sx={{
                                                            pointerEvents: "none"
                                                        }}
                                                        open={popoverId === row.id}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            vertical: "center", //bottom
                                                            horizontal: "center" //left
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "center" //center
                                                        }}
                                                        onClose={handlePopoverClose}
                                                        disableRestoreFocus>
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-around" //space-around
                                                            alignItems="center"
                                                            spacing={2}>
                                                            <Typography sx={{p: 1}}>{"Join at"}</Typography>
                                                            <Typography sx={{p: 1}}>{"Left at"}</Typography>
                                                        </Stack>
                                                        {row?.rejoins?.map(rejoin => (
                                                            <Box sx={{width: "100%", maxWidth: 360, bgcolor: "background.paper"}}>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-around" //space-around
                                                                    alignItems="center"
                                                                    spacing={2}>
                                                                    <Typography sx={{p: 1}}>{`${
                                                                        rejoin?.joinLectureAt
                                                                    }`}</Typography>
                                                                    <Typography sx={{p: 1}}>{`${
                                                                        rejoin?.leaveLectureAt
                                                                    }`}</Typography>
                                                                </Stack>
                                                            </Box>
                                                        ))}
                                                    </Popover>
                                                </div>
                                            ) : (
                                                <Chip
                                                    label={"No rejoins"}
                                                    variant="filled"
                                                    style={{backgroundColor: "#3d7cff", color: "white", borderColor: "#3d7cff"}}
                                                />
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">{`${row?.activeTime}`}</TableCell>
                                    <TableCell align="left">{`${row?.activePercentage}`}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default LectureAttendanceTable;
