import React from "react";
import {cloneDeep} from "lodash";
import AsyncStateComponent from "./AsyncStateComponent";
import NetworkController from "controllers/Network";
import ChatController from "controllers/ChatNetwork";
import CurrentUserContext from "../CurrentUser";
import {withContextConsumer} from "utils/contexts";
import uuid from "uuid";

const FileUploadProgressContext = React.createContext("fileUploadProgress");

const API_CONTROLLER_TYPE = "api";
const CHAT_CONTROLLER_TYPE = "chat";
const CANCEL_MESSAGE = "cancelled";

const controllers = {
    [API_CONTROLLER_TYPE]: NetworkController,
    [CHAT_CONTROLLER_TYPE]: ChatController
};

@withContextConsumer(CurrentUserContext.Consumer)
class FileUploadProgressContextProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            isProgressExpanded: true,
            expandProgressContainer: this.expandProgressContainer.bind(this),
            collapseProgressContainer: this.collapseProgressContainer.bind(this),
            uploadItems: {},
            uploadFilesWithProgressToApi: this.uploadFilesWithProgressToApi.bind(this),
            uploadFilesWithProgressToChat: this.uploadFilesWithProgressToChat.bind(this),
            resetAllProgresses: this.resetAllProgresses.bind(this),
            addItemToUploadQueue: this.addItemToUploadQueue.bind(this),
            uploadQueuedFiles: this.uploadQueuedFiles.bind(this),
            cancelUpload: this.cancelUpload.bind(this)
        };
        this.cancelUploadData = {};
    }

    componentDidUpdate(prevProps) {
        const {currentUser} = this.props;
        const shouldResetAllProgress = (currentUser && !prevProps.currentUser) || (prevProps.currentUser && !currentUser);
        if (shouldResetAllProgress) {
            this.resetAllProgresses();
        }
    }

    updateUploadProgress(id, progressEvent) {
        const uploadProgressItem = cloneDeep(this.state.uploadItems[id]);
        if (!uploadProgressItem) {
            return;
        }
        const totalFilesSize = this.sumFileSizes(uploadProgressItem.files);
        uploadProgressItem.progress = Math.floor((progressEvent.loaded / totalFilesSize) * 100);
        this.setState({uploadItems: {...this.state.uploadItems, [id]: uploadProgressItem}});
    }

    sumFileSizes(fileArray) {
        return Object.values(fileArray).reduce((acc, cur) => acc + cur.size, 0);
    }

    async removeUploadProgress(id, cancelFunc) {
        if (!this.cancelUploadData[id]) {
            return;
        }
        cancelFunc(CANCEL_MESSAGE);
        delete this.cancelUploadData[id];

        const uploadItems = cloneDeep(this.state.uploadItems);
        delete uploadItems[id];

        await this.setStatePromise({uploadItems});
    }

    async uploadFilesWithProgressToApi(data) {
        return this.uploadFilesWithProgress(API_CONTROLLER_TYPE, data);
    }

    async uploadFilesWithProgressToChat(data) {
        return this.uploadFilesWithProgress(CHAT_CONTROLLER_TYPE, data);
    }

    async uploadFilesWithProgress(controllerType, {url, body, files}) {
        const checkedFiles = {};
        const fileIds = Object.keys(files);
        fileIds.forEach(k => {
            if (!!files[k]) {
                checkedFiles[k] = files[k];
            }
        });
        if (!Object.keys(checkedFiles).length) {
            if (controllerType === CHAT_CONTROLLER_TYPE) return {response: {files: []}};
            return await controllers[controllerType].formData(url, body);
        }
        await this.addItemToUploadQueue(controllerType, {url, body, files: checkedFiles});
        const result = await this.uploadQueuedFiles();
        return result[0];
    }

    async addItemToUploadQueue(controllerType, {url, body, files}) {
        const uploadId = uuid();
        const {uploadItems} = this.state;
        await this.setStatePromise({
            isProgressExpanded: true,
            uploadItems: {
                ...uploadItems,
                [uploadId]: {controllerType, url, body, files, progress: 0}
            }
        });
    }

    uploadQueuedFiles() {
        const {uploadItems} = this.state;
        const itemsToUpload = {};

        Object.keys(uploadItems).forEach(k => {
            if (!uploadItems[k].isProcessed) {
                itemsToUpload[k] = uploadItems[k];
                itemsToUpload[k].isProcessed = true;
            }
        });

        let uploadPromises = [];

        const params = {
            onUploadProgress: this.updateUploadProgress.bind(this),
            saveCancelFunc: this.saveCancelFunc.bind(this),
            cancelUploadData: this.cancelUploadData,
            itemsToUpload
        };
        this.setState({uploadItems: {...uploadItems, ...itemsToUpload}}, () => {
            uploadPromises = this.uploadFilesPromise(params);
        });
        return Promise.all(uploadPromises);
    }

    uploadFilesPromise({itemsToUpload, cancelUploadData, onUploadProgress, saveCancelFunc}) {
        const itemsToUploadIds = Object.keys(itemsToUpload);
        const uploadPromises = [];
        for (const uploadId of itemsToUploadIds) {
            const requestOptions = {
                onUploadProgress: onUploadProgress.bind(null, uploadId),
                saveCancelFunc: saveCancelFunc.bind(null, uploadId)
            };
            const {controllerType, url, body, files} = itemsToUpload[uploadId];
            const uploadPromise = controllers[controllerType].formData(url, body, files, requestOptions).then(async response => {
                const isCancelled = response.message === CANCEL_MESSAGE;
                !isCancelled && (await cancelUploadData[uploadId].removeFunc());
                return response;
            });
            uploadPromises.push(uploadPromise);
        }
        return uploadPromises;
    }

    async saveCancelFunc(uploadId, cancelFunc) {
        this.cancelUploadData[uploadId] = {removeFunc: this.removeUploadProgress.bind(this, uploadId, cancelFunc)};
    }

    resetAllProgresses() {
        this.cancelUploadData = {};
        this.setState({uploadItems: {}});
    }

    expandProgressContainer() {
        this.setState({isProgressExpanded: true});
    }

    collapseProgressContainer() {
        this.setState({isProgressExpanded: false});
    }

    cancelUpload(uploadId) {
        this.cancelUploadData[uploadId] && this.cancelUploadData[uploadId].removeFunc(CANCEL_MESSAGE);
    }

    render() {
        return <FileUploadProgressContext.Provider value={this.state}>{this.props.children}</FileUploadProgressContext.Provider>;
    }
}

export default {Provider: FileUploadProgressContextProvider, Consumer: FileUploadProgressContext.Consumer};
