import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";

export default styled.div`
    position: absolute;
    width: ${props => (props.isPopUp ? 25 : 33)}rem;
    top: 0.5rem;
    left: 0.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    color: #000;

    border-radius: 4px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
    margin: 1rem 0;

    will-change: transform;
    transform: translate3d(${p => (p.isPopUp ? (p.dir === "ltr" ? -362 : -45) : p.dir === "ltr" ? -478 : -56)}px, 22px, 0px);
    z-index: 2;
    ${mediaQuery.phone`
        position: fixed;
        left: 0;
        top: 3rem;
        width: 100%;
        max-width: 100%;
        transform: unset;
        border-radius: 0px;
    `}
`;
