import React, {Component} from "react";
import styled from "styled-components";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import {withNamespaces} from "react-i18next";
import Chevron from "components/common/icons/Chevron";
import Search from "components/common/icons/chatWidget/Search";
import Input from "components/form/Input";
import {HeaderButton} from "./styled";

const Container = styled.div`
    font-size: 0.875rem;
    font-weight: 600;
    color: #5c6977;
    display: flex;
    flex-direction: column;
    margin: 0.1rem 0 1rem;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SearchContainer = styled.div`
    display: ${({visible}) => (visible ? "block" : "none")};
    margin-top: 0.9rem;
`;

const Counter = styled.div`
    opacity: 0.3;
`;

const InfoContainer = styled.div`
    display: flex;
    ${FlexMarginBetween(0.5)};
`;

const ButtonsContainer = styled.div`
    display: flex;
    ${FlexMarginBetween(0.3)};
`;

@withNamespaces("translation")
class SearchHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false
        };
    }

    handleToggleSearch() {
        const {onChangeSearchQuery} = this.props;
        const {searchOpen} = this.state;
        if (searchOpen) onChangeSearchQuery("");
        this.setState({searchOpen: !searchOpen});
    }

    render() {
        const {t, contactListLength, onToggle, onChangeSearchQuery, searchQuery} = this.props;
        const {searchOpen} = this.state;
        return (
            <Container>
                <HeaderContainer>
                    <InfoContainer>
                        {t("chatWidget.title")}
                        <Counter>{contactListLength}</Counter>
                    </InfoContainer>
                    <ButtonsContainer>
                        <HeaderButton onClick={this.handleToggleSearch.bind(this)} height="1.2rem">
                            <Search />
                        </HeaderButton>
                        <HeaderButton onClick={onToggle} height="0.5rem" transform="rotate(90deg)">
                            <Chevron />
                        </HeaderButton>
                    </ButtonsContainer>
                </HeaderContainer>
                <SearchContainer visible={searchOpen}>
                    <Input
                        type="text"
                        name="chatSearch"
                        maxLength={100}
                        onChange={onChangeSearchQuery}
                        value={searchQuery}
                        placeholder={t("chatWidget.enterSearchQuery")}
                        style={{padding: "0.5rem 1rem", flex: 1, borderRadius: "0.5rem", backgroundColor: "#F5F5F7"}}
                        inputStyle={{border: "none", padding: "0", fontSize: "0.875rem", flex: 1}}
                    />
                </SearchContainer>
            </Container>
        );
    }
}

export default SearchHeader;
