import React from "react";

export default props => (
    <svg viewBox="0 0 28 28" {...props}>
        <path
            fillRule="evenodd"
            d="M16.1 16.059c.387 0 .7.369.7.823 0 .179-.05.352-.14.494l-2.1 3.295c-.232.363-.67.437-.98.164-.053-.047-.1-.102-.14-.164l-2.1-3.295c-.232-.363-.17-.88.14-1.152.121-.107.269-.165.42-.165h4.2zm-1.68-8.894c.053.047.1.102.14.164l2.1 3.295c.232.363.17.88-.14 1.152-.121.107-.269.165-.42.165h-4.2c-.387 0-.7-.369-.7-.823 0-.179.05-.352.14-.494l2.1-3.295c.232-.363.67-.437.98-.164z"
        />
    </svg>
);
