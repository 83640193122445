import React, {Component} from "react";
import styled from "styled-components";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import SupportIcon from "components/common/icons/chatWidget/Support";
import {withNamespaces} from "react-i18next";

const SupportContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    ${FlexMarginBetween(0.75)};
    cursor: pointer;
    user-select: none;
    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
`;

const SupportTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    color: #5c6977;
`;

const UnreadMessageCount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--white);
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 1.3rem;
    margin: 0 0.5rem;
    background-color: var(--main-theme-color);
`;

@withNamespaces("translation")
class SupportContact extends Component {
    render() {
        const {t, chatState, supportUser, onContactClick} = this.props;
        if (!supportUser) return null;

        const supportUserId = supportUser.id;
        const supportUserChat = chatState.find(({users}) => users.find(({id}) => supportUserId === id));
        const unreadMessageCount = supportUserChat && supportUserChat.unreadMessageCount;
        const unreadMessageCountVisible = !!unreadMessageCount;

        return (
            <SupportContainer onClick={() => onContactClick(supportUserId)}>
                <SupportIcon />
                <SupportTitle>
                    {t("chatWidget.messageToSupport")}
                    {unreadMessageCountVisible && <UnreadMessageCount>{unreadMessageCount}</UnreadMessageCount>}
                </SupportTitle>
            </SupportContainer>
        );
    }
}

export default SupportContact;
