import {keyframes} from "styled-components";

export default keyframes`
    from {
        transform: rotate(0);
    }
    
    to {
        transform: rotate(360deg);
    }
`;
