import React, {Component} from "react";
import styled from "styled-components";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import TextButton from "components/form/TextButton";
import Dropdown from "components/form/Dropdown";
import GridViewIcon from "components/common/icons/library/GridView";
import ListViewIcon from "components/common/icons/library/ListView";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {ITEM_FILTERS, ITEM_SORTS} from "../common/consts";
import WithTooltip from "components/common/WithTooltip";
import AddFilesButton from "components/library/AddFilesButton";
const HeaderContainer = styled.div`
    display: flex;
    font-size: 0.875rem;
    ${mediaQuery.phone`margin-top: 1.5rem; height: 16px;`}
`;

const FilterSection = styled.div`
    display: flex;
    ${FlexMarginBetween(0.5)}
    ${mediaQuery.phone`display: none;`}
`;

const ControlSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 2rem;
    ${FlexMarginBetween(0.5)}
    ${mediaQuery.phone`
       justify-content: flex-start;
       margin: 0;
    `}
`;

const ViewSection = styled.div`
    display: flex;
    align-items: center;
    svg {
        height: 0.7rem;
        cursor: pointer;
    }
    ${mediaQuery.phone`svg {height: 0.9rem; margin: 0 0.5rem;}`}
`;

const DropdownContainer = styled.div`
    width: 9rem;
`;

const Tooltip = styled.div`
    width: 12rem;
`;

const ButtonsContainer = styled.div`
    display: flex;
`;

const AddFileButtonsContainer = styled.div`
    width: 9rem;
`;

const ShareSort = styled.span`
    font-size: 0.75rem;
    font-weight: 600;
    color: ${props => (props.selected ? "#5c6977" : "#b4b4b4")};
    cursor: pointer;
    margin-${props => (props.dir === "rtl" ? "left" : "right")}: 1rem;
`;

class Header extends Component {
    getControlsSection() {
        const {
            t,
            showEditItemsControls,
            onCreateNewFolder,
            onMoveItems,
            onEditItems,
            onDeleteItems,
            inSharingModal,
            hasExtraneousSelected,
            inCourseAttachment,
            onAttach,
            viewOnly,
            meta,
            onInputFiles,
            invisible,
            hideAddButton,
            hideOnPhoneResolution
        } = this.props;

        if (inCourseAttachment) {
            return <TextButton onClick={onAttach} title={t("library.files.attach")} withPlus />;
        }

        if (inSharingModal) {
            return null;
        }
        if (showEditItemsControls) {
            return (
                <WithTooltip
                    tooltip={<Tooltip>{t("library.files.extraneousFilesSelectedMessage")}</Tooltip>}
                    isHidden={!hasExtraneousSelected}>
                    <ButtonsContainer>
                        <TextButton onClick={onMoveItems} title={t("library.files.move")} disabled={hasExtraneousSelected} />
                        <TextButton onClick={onEditItems} title={t("library.files.edit")} disabled={hasExtraneousSelected} />
                        <TextButton
                            onClick={onDeleteItems}
                            type="danger"
                            title={t("library.files.delete")}
                            disabled={hasExtraneousSelected}
                        />
                    </ButtonsContainer>
                </WithTooltip>
            );
        }

        return (
            <>
                <TextButton onClick={onCreateNewFolder} title={t("library.files.newFolder")} withPlus />
                {viewOnly ? null : (
                    <AddFileButtonsContainer>
                        <AddFilesButton
                            t={t}
                            meta={meta}
                            inSharingModal={inSharingModal}
                            onInputFiles={onInputFiles}
                            invisible={invisible}
                            hideOnPhoneResolution
                        />
                    </AddFileButtonsContainer>
                )}
            </>
        );
    }

    render() {
        const {
            t,
            i18n,
            itemsMode,
            setItemsMode,
            showEditItemsControls,
            itemFilter,
            setItemFilter,
            itemSort,
            setItemSort,
            inSharingModal,
            meta,
            onInputFiles,
            invisible,
            hideAddButton,
            hideOnPhoneResolution
        } = this.props;

        return (
            <HeaderContainer>
                {!inSharingModal && (
                    <FilterSection dir={i18n.dir()}>
                        <DropdownContainer>
                            <Dropdown
                                data={ITEM_SORTS.map(f => ({name: t(`library.sort.${f.name}`), id: f.id}))}
                                current={itemSort}
                                onChange={setItemSort}
                                disabled={showEditItemsControls}
                            />
                        </DropdownContainer>
                        <DropdownContainer>
                            <Dropdown
                                data={ITEM_FILTERS.map(f => ({name: t(`library.filter.${f.name}`), id: f.id}))}
                                current={itemFilter}
                                onChange={setItemFilter}
                                disabled={showEditItemsControls}
                            />
                        </DropdownContainer>
                    </FilterSection>
                )}
                {inSharingModal && (
                    <React.Fragment>
                        <ShareSort dir={i18n.dir()} selected={itemSort === "name"} onClick={setItemSort.bind(null, "name")}>
                            {t("library.sort.sortByName")}
                        </ShareSort>
                        <ShareSort dir={i18n.dir()} selected={itemSort === "date"} onClick={setItemSort.bind(null, "date")}>
                            {t("library.sort.sortByDate")}
                        </ShareSort>
                        {hideAddButton ? null : (
                            <AddFileButtonsContainer>
                                <AddFilesButton
                                    t={t}
                                    meta={meta}
                                    inSharingModal={inSharingModal}
                                    onInputFiles={onInputFiles}
                                    invisible={invisible}
                                    hideOnPhoneResolution
                                />
                            </AddFileButtonsContainer>
                        )}
                    </React.Fragment>
                )}
                <ControlSection dir={i18n.dir()}>{this.getControlsSection()}</ControlSection>
                <ViewSection>
                    <ListViewIcon style={{opacity: itemsMode === "list" ? 1 : 0.5}} onClick={setItemsMode.bind(null, "list")} />
                    <GridViewIcon
                        style={{
                            opacity: itemsMode === "grid" ? 1 : 0.5,
                            transform: `scale(${i18n.dir() === "rtl" ? -1 : 1})`
                        }}
                        onClick={setItemsMode.bind(null, "grid")}
                    />
                </ViewSection>
            </HeaderContainer>
        );
    }
}

export default Header;
