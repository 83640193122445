import React, {Component} from "react";
import styled, {css} from "styled-components";
import FolderSmallIcon from "components/common/icons/library/FolderSmall";
import FolderPlus from "components/common/icons/library/FolderPlus";
import FolderMinus from "components/common/icons/library/FolderMinus";

const FolderElement = styled.div`
    position: relative;
    display: inline-flex;
    min-width: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
    ${props => props.isSelected && "background-color: #f5f5f5;"}
`;

const FolderSmallIconStyled = styled(FolderSmallIcon)`
    height: 0.875rem;
`;

const FolderTitle = styled.div`
    color: var(--light-black);
    font-size: 0.875rem;
    margin: 0 0.75rem;
`;

const ExpendStyle = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0.75rem;
`;
const PlusIconStyled = styled(FolderPlus)`
    ${ExpendStyle}
`;
const MinusIconStyled = styled(FolderMinus)`
    ${ExpendStyle}
`;

class Folder extends Component {
    trimName(name) {
        const maxNameLength = 50;
        if (name.length < maxNameLength) {
            return name;
        }
        return name.slice(maxNameLength) + "...";
    }

    render() {
        const {t, folder, isSelected, onSelect, deepLvl, isExpended, toggleExpended, children} = this.props;
        const showExpendedIcon = !folder.isRoot && folder.items.length > 0;
        const isDefaultName = folder.isRoot && folder.name === "default";

        const expendIconLeft = {left: `${deepLvl - 0.4}rem`};
        return (
            <React.Fragment>
                <FolderElement isSelected={isSelected} onClick={onSelect}>
                    {showExpendedIcon &&
                        (isExpended ? (
                            <MinusIconStyled style={expendIconLeft} onClick={toggleExpended} />
                        ) : (
                            <PlusIconStyled style={expendIconLeft} onClick={toggleExpended} />
                        ))}

                    <FolderSmallIconStyled style={{marginLeft: `${deepLvl}rem`}} />
                    <FolderTitle>{isDefaultName ? t("library.defaultFolder") : this.trimName(folder.name)}</FolderTitle>
                </FolderElement>
                {isExpended && children}
            </React.Fragment>
        );
    }
}

export default Folder;
