import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import LogoHeader from "assets/logo/logo.png";
import {mediaQuery} from "components/common/styled/mediaQuery";
import routes from "consts/routes";

const LogoContainer = styled.div`
    width: 20rem;
    padding: 0 2rem;
    img {
        width: 7rem;
        margin-top: 0.875rem;
    }
    ${mediaQuery.phone`display: none;`}
`;

export default () => (
    <LogoContainer>
        <Link to={routes.home.path}>
            <img src={LogoHeader} alt={"logo"} />
        </Link>
    </LogoContainer>
);
