import React, {Component} from "react";
import styled from "styled-components";
import {compose} from "recompose";
import {withNamespaces} from "react-i18next";
import moment from "moment";
import {Button} from "@mui/material";
// import Button from "components/form/Button.js";
import {mediaQuery} from "components/common/styled/mediaQuery";
import ConfirmModal from "components/common/modals/ConfirmModal";
import FileIconDefault from "components/common/icons/library/FileIconDefault";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import EnrollmentsContext from "contexts/Enrollments";
import LecturesDigestContext from "contexts/LecturesDigest";
import AssignmentSubmissionContext from "contexts/assignment/AssignmentSubmission";
import NetworkController from "controllers/Network";
import assignmentFormats from "consts/assignmentFormats";
import IconButton from "@mui/material/IconButton";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {ASSIGNMENT_ALLOW_FILE_TYPES, isFileTypeAllowed} from "utils/file";
import {Chip, Stack} from "@mui/material";
import MediaRecorder from "./MediaRecorder";
import WorkHistory from "./assignment/WorkHistory";

import AssignmentInfo from "./assignment/AssignmentInfo";
import CheckWhiteboard from "contexts/CheckWhiteboard";

const AssignmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: left;
    margin: 0.5rem 0;
    padding: 0.1rem;
`;

const FileIconContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    font-weight: 600;
    font-size: 0.8125rem;
    color: #bcc3ca;
    padding: 1rem;
    margin: 3rem;
    text-transform: uppercase;
    max-width: 2.38rem;
    min-width: 2.28rem;

    svg {
        width: 1.725rem;
        margin-bottom: 0.35rem;
    }

    cursor: pointer;
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const NoAssignmentMessage = styled.div`
    padding: 1rem;
    color: #8b8b8b;
`;

const MainContainer = styled.div`
    padding: ${props => props.padding || "0 0 0 0"};
    margin: ${props => props.margin || "0 0 0 0"};
`;

const MainAssignmentSubmissionContainer = styled.div`
    justify-content: center;
`;

const ShowFilesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;

const ASSIGNMENT_SUBMISSION_STATUS = {
    initial: "initial",
    checked: "checked"
};

class StudentAssignmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddAssignmentModalOpen: false,
            editAssignmentOpen: false,
            viewInPdf: null,
            pageNumber: 1,
            countPages: 1,
            pages: [],
            page: 1,
            scale: props.isPhoneResolution ? 2 : 1,
            studentFiles: null,
            studentfileUploaded: null,
            isOpen: false,
            onRequestClose: true,
            isDeleteOpen: false,
            onDeleteRequestClose: true,
            submittedFiles: [],
            mediaFile: null,
            replaceAssignment: false,
            loading: false
        };
        this.inputOpenFileRef = React.createRef();
    }

    showLoader() {
        this.setState({loading: true});
    }

    hideLoader() {
        this.setState({loading: false});
    }

    openDeleteConfirmationModal() {
        this.setState({isDeleteOpen: true});
    }

    toggleReplaceAssignment = () => {
        this.setState(({replaceAssignment}) => ({replaceAssignment: !replaceAssignment}));
    };

    toggleConfirmUpdateModal = () => {
        this.setState(({isOpen}) => ({isOpen: !isOpen}));
    };

    checkFileType = event => {
        let error = "";
        const fileList = event.target.files;

        for (const file of fileList) {
            // check if there is file type if not its not supportes
            const type = file.type ? file.type : "NOT SUPPORTED";

            if (isFileTypeAllowed(type)) {
                error += file.type + " is not supported format\n";
            }
        }

        if (error !== "") {
            // if message not same old that mean has error
            event.target.value = null; // discard selected file

            this.setState({error: error});
            return false;
        }

        return true;
    };

    handleFileUploadChange = event => {
        const fileList = event.target.files;

        if (this.checkFileType(event)) {
            let assignmentFiles = [];

            for (const file of fileList) {
                assignmentFiles.push(file);
            }

            this.setState({submittedFiles: assignmentFiles});
        }
    };

    handleMedia = file => {
        const {name, type} = file;

        let tempFile = new File([file], name, {
            type: type
        });
        this.setState({mediaFile: tempFile});
    };

    handleStudentAssignmentSubmission = async () => {
        this.showLoader();
        const {
            submittedAssignment,
            assignment,
            lecture,
            submitStudentAssignment,
            updateSubmission,
            replaceSubmissionFromCache,
            replaceStudentAssignment
        } = this.props;

        const {submittedFiles, mediaFile, replaceAssignment} = this.state;

        let data = {
            lecture: lecture.id,
            assignment: assignment.id,
            format: assignment.format
        };

        let files = [];

        if (assignment.format === assignmentFormats["File Upload"]) {
            files = submittedFiles;
        } else {
            files.push(mediaFile);
        }

        // replace assignment for student
        if (replaceAssignment) {
            data.submission = submittedAssignment.id;
            const submission = await replaceStudentAssignment(data, files);
            this.toggleReplaceAssignment();
            replaceSubmissionFromCache(submission);
        } else {
            // create new assignment
            const submission = await submitStudentAssignment(data, files);
            updateSubmission(submission);
        }

        this.hideLoader();
    };

    async handleSubmitAssignmentDeletion() {
        const {deleteSubmission, submittedAssignment} = this.props;

        const {response, error} = await NetworkController.delete(`/assignments/submission/${submittedAssignment.id}`);

        deleteSubmission(submittedAssignment);
        this.setState({isDeleteOpen: false});
    }

    canAssignmentBeSubmitted = assignment => {
        const format = "YYYY-MM-DD";
        let availableFrom = moment(assignment.availableFrom).format(format);
        let dueDate = moment(assignment.dueDate).format(format);
        const today = moment().format(format);

        const hasTime = moment(today).isBetween(availableFrom, dueDate, undefined, "[]");

        return hasTime;
    };

    renderAssignmentSubmitOptions(assignment) {
        const {t} = this.props;
        const {format, mediaDuration} = assignment;
        if (assignmentFormats["File Upload"] === format) {
            return (
                <ButtonContainer>
                    <p style={{marginRight: 8}}> {t(`lecture.digest.assignment.uploadAssignment`)}: </p>

                    <IconButton
                        size={"large"}
                        onClick={() => {
                            this.inputOpenFileRef.current.click();
                        }}>
                        <DriveFolderUploadIcon />
                    </IconButton>

                    <input
                        type="file"
                        style={{display: "none"}}
                        accept={ASSIGNMENT_ALLOW_FILE_TYPES}
                        id="uploadSubmission-button"
                        ref={this.inputOpenFileRef}
                        multiple
                        onChange={this.handleFileUploadChange}
                    />
                </ButtonContainer>
            );
        }

        if (assignmentFormats.Image === format || assignmentFormats.Audio === format || assignmentFormats.Video === format) {
            return <MediaRecorder format={format} handleMedia={this.handleMedia} t={t} mediaDuration={mediaDuration} />;
        }

        if (assignmentFormats["WorkSheet"] === format) {
            const {submittedAssignment, assignment, lecture} = this.props;

            const lectureId = lecture.id;
            const assignmentId = assignment.id;
            const submissionId = submittedAssignment?.id;

            let path = `/lectures/${lectureId}/assignment/${assignmentId}`;
            let search = ``;

            return (
                <ButtonContainer>
                    <Button
                        variant="contained"
                        style={{backgroundColor: "#3d7cff", borderColor: "#3d7cff"}}
                        size="small"
                        onClick={() => {
                            if (submissionId) {
                                search += `?submission=${submissionId}`;
                            }
                            this.props.history.push({
                                pathname: path,
                                search: search
                            });
                        }}>
                        {t(`lecture.digest.checkAssignment.attemptViaWhiteboard`)}
                    </Button>
                </ButtonContainer>
            );
        }

        return null;
    }

    ReplaceOrDeleteAssignmentOptions(t) {
        return (
            <ButtonContainer>
                <ButtonContainer>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "#3d7cff",
                            borderColor: "#3d7cff",
                            color: "white"
                        }}
                        size="small"
                        onClick={this.toggleReplaceAssignment}>
                        {t("lecture.digest.replaceAssignment")}
                    </Button>
                </ButtonContainer>

                <ButtonContainer>
                    <Button variant="contained" color="error" size="small" onClick={this.openDeleteConfirmationModal.bind(this)}>
                        {t("lecture.digest.deleteWork")}
                    </Button>
                </ButtonContainer>
            </ButtonContainer>
        );
    }

    render() {
        const {submittedAssignment, assignment, t} = this.props;
        const {submittedFiles, mediaFile, isOpen, isDeleteOpen, onDeleteRequestClose, replaceAssignment, loading} = this.state;

        let hasSubmissionTime = this.canAssignmentBeSubmitted(assignment);

        const {format} = assignment;

        const isAssignmentChecked = submittedAssignment?.status == "checked";

        let disableSubmitButton = true;
        if (assignmentFormats.Image === format || assignmentFormats.Audio === format || assignmentFormats.Video === format) {
            disableSubmitButton = loading || (mediaFile === null || mediaFile === undefined);
        } else if (assignmentFormats["WorkSheet"] === format) {
            disableSubmitButton = loading || (!submittedAssignment && submittedFiles?.length === 0);
        } else {
            disableSubmitButton = loading || (!submittedAssignment && submittedFiles?.length === 0);
        }

        return (
            <AssignmentContainer>
                <ListContainer>
                    <MainContainer>
                        <AssignmentInfo assignment={assignment} />

                        <br />
                        <MainAssignmentSubmissionContainer>
                            {/*student work history*/}
                            {submittedAssignment?.id ? (
                                <>
                                    <WorkHistory submittedAssignment={submittedAssignment} assignment={assignment} t={t} />

                                    {!isAssignmentChecked && hasSubmissionTime && !replaceAssignment
                                        ? this.ReplaceOrDeleteAssignmentOptions(t, submittedAssignment)
                                        : null}
                                </>
                            ) : (
                                <Chip label={t("lecture.digest.noAssignmentsUploaded")} variant="filled" color="error" />
                                // <NoAssignmentMessage>{t("lecture.digest.noAssignmentsUploaded")}</NoAssignmentMessage>
                            )}
                            <br />
                            {hasSubmissionTime &&
                            !isAssignmentChecked &&
                            (submittedAssignment?.status !== "initial" || replaceAssignment) ? (
                                <>
                                    {this.renderAssignmentSubmitOptions(assignment)}

                                    <ShowFilesContainer>
                                        {submittedFiles.map(file => (
                                            <FileIconContainer>
                                                <FileIconDefault />
                                                <p>{file.name}</p>
                                            </FileIconContainer>
                                        ))}
                                    </ShowFilesContainer>
                                    <ButtonContainer>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: disableSubmitButton ? "grey" : "#3d7cff",
                                                borderColor: disableSubmitButton ? "grey" : "#3d7cff",
                                                color: "white"
                                            }}
                                            size="small"
                                            disabled={disableSubmitButton}
                                            // <Button
                                            //     type="gray"
                                            //     disabled={loading}
                                            onClick={() => {
                                                if (replaceAssignment) {
                                                    this.toggleConfirmUpdateModal();
                                                } else {
                                                    this.handleStudentAssignmentSubmission();
                                                }
                                            }}>
                                            {t("lecture.digest.submitStudentAssignment")}
                                        </Button>

                                        {replaceAssignment ? (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                disabled={loading}
                                                style={{marginLeft: 4}}
                                                onClick={this.toggleReplaceAssignment}>
                                                {t("lecture.digest.cancel")}
                                            </Button>
                                        ) : null}
                                    </ButtonContainer>
                                </>
                            ) : null}
                        </MainAssignmentSubmissionContainer>
                    </MainContainer>
                </ListContainer>

                <ConfirmModal
                    title={t("lecture.digest.replace")}
                    headerStyle={{margin: "1rem 0 0"}}
                    onCancelClick={this.toggleConfirmUpdateModal}
                    buttonsContainerStyle={{type: "danger"}}
                    controlButtonsStyle={{flex: 1, width: "100%"}}
                    isOpen={isOpen}
                    confirmTitle={t("lecture.digest.confirmUpdateAssignment")}
                    cancelTitle={t("lecture.digest.cancelUpdateAssignment")}
                    onConfirmClick={() => {
                        this.toggleConfirmUpdateModal();
                        this.handleStudentAssignmentSubmission();
                    }}
                    buttonsType={"danger"}
                />
                <ConfirmModal
                    title={t("lecture.digest.deletemessage")}
                    headerStyle={{margin: "1rem 0 0"}}
                    onRequestClose={onDeleteRequestClose}
                    buttonsContainerStyle={{type: "danger"}}
                    controlButtonsStyle={{flex: 1, width: "100%"}}
                    isOpen={isDeleteOpen}
                    confirmTitle={t("lecture.digest.confirmdeleteAssignment")}
                    cancelTitle={t("lecture.digest.canceldeleteAssignment")}
                    onConfirmClick={this.handleSubmitAssignmentDeletion.bind(this)}
                    onCancelClick={() => this.setState({isDeleteOpen: false})}
                    buttonsType={"danger"}
                />
            </AssignmentContainer>
        );
    }
}

export default compose(
    withNamespaces("translation"),
    withContextConsumer(EnrollmentsContext.Consumer),
    withContextProvider(AssignmentSubmissionContext.Provider),
    withContextConsumer(AssignmentSubmissionContext.Consumer),
    withContextConsumer(LecturesDigestContext.Consumer),
    withContextConsumer(CheckWhiteboard.Consumer)
)(StudentAssignmentList);
