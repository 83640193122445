import React from "react";

export default props => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            fill="#FFF"
            d="M8.534 3.466c1.412 1.41 1.423 3.69.023 5.09-1.4 1.401-3.68 1.39-5.091-.022-1.412-1.41-1.423-3.69-.023-5.09 1.4-1.401 3.68-1.39 5.091.022zm-1.26.28c-.997-.573-2.283-.44-3.125.403-.938.938-.998 2.427-.18 3.454l3.306-3.857zm.757.651L4.725 8.254c.998.573 2.284.44 3.126-.403.938-.938.998-2.427.18-3.454z"
        />
    </svg>
);
