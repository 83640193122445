import io from "socket.io-client";
import Flow from "@flowjs/flow.js";
import once from "lodash/once";

export class SocketController {
    constructor() {
        this.sockets = {};
    }

    async connect(lectureId, url, token, path = "/socket.io") {
        if (url.slice(-1) === "/") {
            url = url.slice(0, -1);
        }
        const urlParsed = new URL(url);
        if (urlParsed.pathname && urlParsed.pathname !== "/") {
            path = urlParsed.pathname + "/socket";
        }
        this.sockets[lectureId] = io(url, {
            transports: ["websocket"],
            query: {token},
            forceNew: true,
            path
        });
    }

    async emit(lectureId, method, data) {
        return new Promise(resolve => {
            this.sockets[lectureId].emit(method, data, (error, response) => resolve({error, response}));
        });
    }

    on(lectureId, method, listener) {
        this.sockets[lectureId].on(method, listener);
    }

    removeAllListeners(lectureId, method) {
        this.sockets[lectureId].removeAllListeners(method);
    }

    disconnect(lectureId) {
        const socket = this.sockets[lectureId];
        if (socket) {
            socket.disconnect();
            socket.removeAllListeners();
        }
    }

    async uploadFile(connectionInfo, method, files, form) {
        const {token, connectionUrl} = connectionInfo;
        const url = `${connectionUrl}/signal/v1${method}?token=${token}`;
        const data = new FormData();
        data.append("data", JSON.stringify(form));
        Object.keys(files).forEach(f => data.append(f, files[f]));
        const response = await fetch(url, {method: "post", body: data});
        return {
            error: response.status !== 200,
            response: await response.json()
        };
    }

    chunkedUpload(connectionInfo, method, file, form) {
        return new Promise(resolve => {
            const resolveOnce = once(resolve);
            const {token, connectionUrl} = connectionInfo;
            const url = `${connectionUrl}/signal/v1${method}?token=${token}`;

            const flow = new Flow({
                target: url,
                query: form,
                testChunks: false
            });
            flow.on("fileSuccess", (file, message) => resolveOnce({}));
            flow.on("fileError", (file, message) => resolveOnce({error: message}));
            flow.addFile(file);
            flow.upload();
        });
    }
}

export default new SocketController();
