import React, {Component} from "react";
import styled from "styled-components";
import ContactItem from "./ContactItem";
import {withNamespaces} from "react-i18next";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import SearchHeader from "./SearchHeader";
import Scrollbars from "components/common/Scrollbars";
import formatChatTitle from "utils/formatChatTitle";
import SupportContact from "../chat/SupportContact";
import userTypes from "consts/userTypes";

const Container = styled.div`
    width: 300px;
    padding: 1rem 1.3rem 0;
    border-radius: 8px;
    box-shadow: 0 6px 34px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--white);
`;

const Delimiter = styled.div`
    height: 1px;
    background: #f5f5f5;
    margin: 0 -1.3rem 1rem;
`;

const ListHeader = styled.div`
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--main-theme-color);
    margin: 0 0 1rem;
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 50vh;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    ${FlexMarginBetween(0, 1)}
`;

const invalidCharactersForSearch = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;

@withNamespaces("translation")
class ContactList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: ""
        };
    }

    async handleChangeSearchQuery(searchQuery) {
        searchQuery = searchQuery.replace(invalidCharactersForSearch, "");
        await this.props.searchInMessages(searchQuery);
        this.setState({searchQuery});
    }

    renderHeader() {
        const {chatState, onToggle} = this.props;
        const {searchQuery} = this.state;
        return (
            <SearchHeader
                searchQuery={searchQuery}
                contactListLength={chatState.length}
                onToggle={onToggle}
                onChangeSearchQuery={this.handleChangeSearchQuery.bind(this)}
            />
        );
    }

    renderSupportContact() {
        const {currentUser, chatState, supportUser, onContactClick} = this.props;
        if (currentUser.role === userTypes.admin) return null;
        return (
            <React.Fragment>
                <Delimiter />
                <SupportContact chatState={chatState} supportUser={supportUser} onContactClick={onContactClick} />
            </React.Fragment>
        );
    }

    renderGroupContactList(chatState) {
        return this.renderContactList("chatWidget.blockGroup", chatState.filter(({course, parent}) => course || parent));
    }

    renderIndividualContactList(chatState) {
        return this.renderContactList(
            "chatWidget.blockConversations",
            chatState.filter(({course, parent}) => !course && !parent)
        );
    }

    renderContactList(headerTitleKey, contacts) {
        const {t, onContactClick} = this.props;
        const {searchQuery} = this.state;
        return (
            <React.Fragment>
                <ListHeader> {t(headerTitleKey)} </ListHeader>
                <Scrollbars autoHeightMax="20vh" autoHeight>
                    <List>
                        {contacts.map(item => (
                            <ContactItem key={item.id} onClick={onContactClick} searchQuery={searchQuery} {...item} />
                        ))}
                    </List>
                </Scrollbars>
            </React.Fragment>
        );
    }

    getFilteredContactList() {
        const {t, currentUser, chatState} = this.props;
        const {searchQuery} = this.state;
        return chatState.reduce((filtered, item) => {
            const receiver = item.course ? item.course : item.users.find(({id}) => currentUser.id !== id);
            const name = formatChatTitle(receiver, t, item);
            receiver.name = name;
            const isMatch = name.match(new RegExp(`(${searchQuery})`, "gi"));
            const receiverVisible = receiver.role !== userTypes.admin;
            const {messageSearch} = item;
            if ((isMatch || messageSearch) && receiverVisible) filtered.push({...item, receiver});
            return filtered;
        }, []);
    }

    render() {
        const contactList = this.getFilteredContactList();
        return (
            <Container>
                {this.renderHeader()}
                {this.renderSupportContact()}
                <ListContainer>
                    <Delimiter />
                    {this.renderGroupContactList(contactList)}
                    <Delimiter />
                    {this.renderIndividualContactList(contactList)}
                </ListContainer>
            </Container>
        );
    }
}

export default ContactList;
