import React, {Component} from "react";
import i18next from "i18next";
import {withNamespaces} from "react-i18next";
import NetworkController from "controllers/Network";
import moment from "moment";
import momentTZ from "moment-timezone";

export const LocaleContext = React.createContext("locale");

const shortDateOptions = {month: "long", day: "numeric"};
const dateOptions = {year: "numeric", month: "long", day: "numeric"};
const dateTimeOptions = {month: "short", day: "numeric", hour: "numeric", minute: "numeric"};
const compactDateOptions = {year: "numeric", month: "short", day: "numeric"};
const timeOptions = {hour: "numeric", minute: "numeric"};
const timeOptionsUTC = {...timeOptions, timeZone: "UTC"};
const durationOptions = {hour: "numeric", minute: "numeric", hourCycle: "h23", timeZone: "UTC"};
const minutesSecondsOptions = {minute: "2-digit", second: "2-digit", timeZone: "UTC"};

const getFormatters = language => ({
    language,
    numberFormatter: new Intl.NumberFormat(language),
    numberFormatter2Digits: new Intl.NumberFormat(language, {minimumIntegerDigits: 2}),
    moneyFormatter: new Intl.NumberFormat(language, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
    shortDateFormatter: new Intl.DateTimeFormat(language, shortDateOptions),
    dateFormatter: new Intl.DateTimeFormat(language, dateOptions),
    compactDateFormatter: new Intl.DateTimeFormat(language, compactDateOptions),
    timeFormatter: new Intl.DateTimeFormat(language, timeOptions),
    shortDateAndTimeFormatter: new Intl.DateTimeFormat(language, dateTimeOptions),
    timeFormatterUTC: new Intl.DateTimeFormat(language, timeOptionsUTC),
    durationFormatter: new Intl.DateTimeFormat(language, durationOptions),
    minutesSecondsFormatter: new Intl.DateTimeFormat(language, minutesSecondsOptions)
});

@withNamespaces("translation")
class LocaleProvider extends Component {
    constructor(props) {
        super(props);
        const language = i18next.language;
        this.state = {
            ...getFormatters(language),
            serverTimeDiff: 0,
            adjustToServerTime: this.adjustToServerTime.bind(this),
            timezones: momentTZ.tz.names(),
            getCustomTimeFormatter: this.getCustomTimeFormatter.bind(this),
            getCustomDateFormatter: this.getCustomDateFormatter.bind(this),
            getCustomShortDateFormatter: this.getCustomShortDateFormatter.bind(this)
        };
    }

    async componentDidMount() {
        const userTime = new Date().toISOString();
        const {response} = await NetworkController.get(`/meta/time-diff?userTime=${userTime}`);
        this.setState({serverTimeDiff: response?.serverTimeDiff});
    }

    static getDerivedStateFromProps(props, state) {
        const oldLanguage = state.language;
        const newLanguage = props.i18n.language;
        if (oldLanguage !== newLanguage) {
            return getFormatters(newLanguage);
        }
        return null;
    }

    adjustToServerTime(date) {
        const res = moment(date);
        res.add(this.state.serverTimeDiff, "ms");
        return res;
    }

    getCustomTimeFormatter(options) {
        const language = i18next.language;
        return new Intl.DateTimeFormat(language, {...timeOptions, ...options});
    }

    getCustomDateFormatter(options) {
        const language = i18next.language;
        return new Intl.DateTimeFormat(language, {...dateOptions, ...options});
    }

    getCustomShortDateFormatter(options) {
        const language = i18next.language;
        return new Intl.DateTimeFormat(language, {...shortDateOptions, ...options});
    }

    render() {
        return <LocaleContext.Provider value={this.state}>{this.props.children}</LocaleContext.Provider>;
    }
}

export default {Provider: LocaleProvider, Consumer: LocaleContext.Consumer};
