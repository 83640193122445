import React from "react";
import {getUserTime} from "utils/time";
import styled from "styled-components";
import {mediaQuery} from "../../../common/styled/mediaQuery";
import DOMPurify from "dompurify";
const InfoContainer = styled.div`
    justify-content: center;
`;

const MainAssignmentInfoContainer = styled.div`
    justify-content: center;
`;

const Title = styled.div`
    font-weight: bold;
    font-family: "Raleway , Almarai, sans-serif";
    font-size: 1.5rem;
    min-width: 0;
    word-break: break-word;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

const Description = styled.div`
    font-family: "Raleway , Almarai, sans-serif";
    font-size: 1.2rem;
    min-width: 0;
    padding-bottom: 0.5rem;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

const Info = styled.div`
    font-weight: bold;
    font-family: "Raleway , Almarai, sans-serif";
    font-size: 1.2rem;
    min-width: 0;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

const Files = styled.div`
    font-family: "Raleway , Almarai, sans-serif";
    font-size: 1.2rem;
    min-width: 0;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

const FileContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0.5rem;
`;

const File = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
`;

const DownloadButton = styled.a`
    margin-left: 12px;
    padding: 4px;
    background-color: var(--main-theme-color);
    color: white;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
`;

function AssignmentInfo(props) {
    const {assignment} = props;
    const descriptionHtml = assignment?.description || `<p></p>`;
    // const cleanDescription = DOMPurify.sanitize();

    const sanitize = descHtml => ({
        __html: DOMPurify.sanitize(descHtml, {
            allowedTags: ["b", "i", "u", "s", "em", "option", "div", "svg", "h1", "h2", "strong", "p", "img", "a"],
            allowedAttributes: {
                a: ["href", "target"]
            }
        })
    });

    return (
        <MainAssignmentInfoContainer>
            <InfoContainer>
                <Title>{assignment?.name}</Title>
                <div dangerouslySetInnerHTML={sanitize(descriptionHtml)} />
                {/* <div dangerouslySetInnerHTML={{ __html: cleanDescription }} /> */}
                {/* <Description>{assignment?.description}</Description> */}
                {/* <p>Attached: {getUserTime(assignment.availableFrom)}</p> */}
                <Description>Due Date: {getUserTime(assignment?.dueDate)}</Description>
                {/* <p>Due Date: {getUserTime(assignment.dueDate)}</p> */}
            </InfoContainer>
            <InfoContainer>
                <Info>Attachments:</Info>
            </InfoContainer>
            <FileContainer>
                {assignment?.attachments.map((attachment, i) => {
                    return (
                        <File>
                            <Files>
                                {i + 1}. {attachment?.name}
                            </Files>
                            <DownloadButton target={"_blank"} href={attachment?.url} download={attachment?.name}>
                                Download
                            </DownloadButton>
                        </File>
                    );
                })}
            </FileContainer>
        </MainAssignmentInfoContainer>
    );
}

export default AssignmentInfo;
