import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import AdHocForm from "./forms/AdHocForm";
import CourseLectureForm from "./forms/CourseLectureForm";
import AcceptDeclineButtonsGroup from "components/common/AcceptDeclineButtonsGroup";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {getLectureDataForDropdown} from "utils/lectureHelpers";

const FormContainer = styled.div`
    flex-direction: column;
    width: 100%;
    ${mediaQuery.phone`
        padding: 1rem 0;
    `}
`;

const ButtonsWrapper = styled.div`
    ${mediaQuery.phone`padding: 0 0.8rem`}
`;

@withNamespaces("translation")
class AttachForm extends Component {
    render() {
        const {
            quizMeta,
            quizGames,
            onFieldChange,
            subject,
            competencies,
            quizGame,
            attachQuiz,
            course,
            lectureData,
            i18n,
            t
        } = this.props;

        const commonFormProps = {
            competencies,
            quizGame,
            quizMeta,
            quizGames,
            subject,
            onFieldChange,
            lectureId: lectureData.id,
            lecturesList: [getLectureDataForDropdown(lectureData)]
        };
        return (
            <FormContainer dir={i18n.dir()}>
                {course ? <CourseLectureForm course={course} {...commonFormProps} /> : <AdHocForm {...commonFormProps} />}
                <ButtonsWrapper>
                    <AcceptDeclineButtonsGroup
                        style={{padding: "2rem 0"}}
                        controlButtonsStyle={{flex: 1}}
                        confirmTitle={t("lecture.notifications.attach")}
                        cancelTitle={t("lecture.notifications.back")}
                        onConfirmClick={attachQuiz.bind(null, course)}
                        onCancelClick={onFieldChange.bind(null, "quiz", null)}
                    />
                </ButtonsWrapper>
            </FormContainer>
        );
    }
}

export default AttachForm;
