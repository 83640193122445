import React, {Component} from "react";
import styled from "styled-components";
import Button from "components/form/Button";
import {withNamespaces} from "react-i18next";

const WaafiContent = styled.div`
    outline: none;
    border: none;
    width: 100%;
    height: 3rem;

    div {
        width: 100%;
    }

    #form-submit {
        display: none;
    }
`;

@withNamespaces("translation")
class WaafiPaymentModalContent extends Component {
    constructor(props) {
        super(props);
    }

    submitForm() {
        document.getElementById("form-submit").click();
    }

    render() {
        const {params, onUnknownTransactionError, onRequestClose, t} = this.props;
        if (!params) {
            onUnknownTransactionError();
            onRequestClose();
            return null;
        }

        return (
            <WaafiContent>
                <form method="POST" action={params.hppUrl}>
                    <input type="hidden" name="hppRequestId" id="hppRequestId" value={params.hppRequestId} />
                    <input type="hidden" name="referenceId" id="referenceId" value={params.referenceId} />
                    <input type="hidden" name="srcAccountNo" id="srcAccountNo" />
                    <input type="hidden" name="srcAccountName" id="srcAccountName" />
                    <input type="submit" id="form-submit" />
                </form>
                <Button onClick={this.submitForm}>{t("wallet.payment.waafiRedirectToPaymentPage")}</Button>
            </WaafiContent>
        );
    }
}

export default WaafiPaymentModalContent;
