import React from "react";

export default props => (
    <svg viewBox="0 0 30 27" {...props}>
        <defs>
            <filter id="supportFilter" width="161.5%" height="161.5%" x="-30.8%" y="-30.8%" filterUnits="objectBoundingBox">
                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#supportFilter)" transform="translate(2)">
            <path
                fill="#5C6977"
                d="M13 0c7.18 0 13 5.82 13 13s-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0zm0 10c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"
            />
            <path
                fill="#EC5E53"
                fillRule="nonzero"
                d="M16.93 10.483c.012-.01.023-.022.034-.033l4.477-4.524c1.6 1.908 2.564 4.377 2.564 7.074s-.965 5.166-2.564 7.074l-4.477-4.524-.034-.033c.474-.722.75-1.587.75-2.517 0-.93-.276-1.795-.75-2.517zm-7.86 5.034c-.012.01-.023.022-.034.033l-4.477 4.524c-1.6-1.908-2.564-4.377-2.564-7.074S2.96 7.834 4.56 5.926l4.477 4.524.034.033c-.474.722-.75 1.587-.75 2.517 0 .93.276 1.795.75 2.517z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M15.487 16.9c.01.012.021.023.033.034l4.524 4.477c-1.909 1.6-4.378 2.563-7.074 2.563-2.697 0-5.166-.964-7.075-2.563l4.525-4.477.033-.034c.722.474 1.587.75 2.517.75.93 0 1.795-.276 2.517-.75zm-4.974-7.8c-.01-.012-.021-.023-.033-.034L5.956 4.589c1.909-1.6 4.378-2.563 7.074-2.563 2.697 0 5.166.964 7.075 2.563L15.58 9.066l-.033.034c-.722-.474-1.587-.75-2.517-.75-.93 0-1.795.276-2.517.75z"
            />
        </g>
    </svg>
);
