import React from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {withContextConsumer} from "utils/contexts";
import Pagination from "rc-pagination";
import LocaleContext from "contexts/common/LocaleContext";
import "rc-pagination/assets/index.css";
import {mediaQuery} from "./styled/mediaQuery";

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    font-weight: 600;
    .rc-pagination-item {
        outline: none;
        border: none;
        color: var(--black) !important;
        ${mediaQuery.phone`
            margin-right: 0px;
        `}
    }
    .rc-pagination-item-active {
        color: #8f8f8f !important;
        background-color: transparent;
    }
    .rc-pagination-prev,
    .rc-pagination-next {
        border: none;
        outline: none;
        font-size: 0.825rem;
        color: var(--black);
        font-weight: normal;
    }
    .rc-pagination-disabled {
        opacity: 0.5;
    }
`;

const itemRender = (current, type, element, numberFormatter) => {
    if (type !== "page") {
        return element;
    }
    return <span>{numberFormatter.format(current)}</span>;
};

const Paginator = ({numberFormatter, t, style, ...props}) => (
    <PaginationWrapper style={style}>
        <Pagination
            showTitle={false}
            prevIcon={<div>{t("pagination.prev")}</div>}
            nextIcon={<div>{t("pagination.next")}</div>}
            itemRender={(current, type, element) => itemRender(current, type, element, numberFormatter)}
            {...props}
        />
    </PaginationWrapper>
);

export default withNamespaces("translation")(withContextConsumer(LocaleContext.Consumer)(Paginator));
