import React, {Component} from "react";
import styled from "styled-components";
import {BalanceButton, ErrorContainer} from "../../common/styled";
import {withRouter} from "react-router-dom";

export const WaafiButton = styled(BalanceButton)`
    margin-top: 0rem;
    height: 3.1rem;
    ${props => props.disabled && "opacity: 0.5; pointer-events: none;"}
`;

@withRouter
class WaafiCardPaymentButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }

    render() {
        const {t, disabled, openWaafiContent, amount, loading, location} = this.props;
        const {error} = this.state;

        return (
            <React.Fragment>
                <WaafiButton
                    disabled={disabled || loading}
                    onClick={() => openWaafiContent(amount, location.pathname, "card")}
                    loading={loading}>
                    {t("wallet.payment.topUpWithTelr")}
                </WaafiButton>
                {error && <ErrorContainer>{t("wallet.payment.topUpError")}</ErrorContainer>}
            </React.Fragment>
        );
    }
}

export default WaafiCardPaymentButton;
