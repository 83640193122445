import React, {Component} from "react";

class AsyncStateComponent extends Component {
    setStatePromise(state, callback) {
        return new Promise(resolve =>
            this.setState(state, () => {
                resolve();
                callback && callback();
            })
        );
    }
}

export default AsyncStateComponent;
