import moment from "moment-timezone";

export function leadZeros(value) {
    if (value === undefined) {
        return value;
    }
    return (value + "").length < 2 ? `0${value}` : value;
}

const MS_PER_MINUTE = 1000 * 60;
const MS_PER_DAY = MS_PER_MINUTE * 60 * 24;

function getUTCDif(a, b) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes());
    return utc2 - utc1;
}

export function dateDiffInMinutes(a, b) {
    return Math.floor(getUTCDif(a, b) / MS_PER_MINUTE);
}

export function dateDiffInDays(a, b) {
    return Math.max(Math.floor(getUTCDif(a, b) / MS_PER_DAY), 0);
}

export function diffInMonths(d1, d2) {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return Math.max(months, 0);
}

export const diffInMonthsWithFraction = (d1, d2) => {
    const date1 = moment(d1);
    const date2 = moment(d2);
    return date2.diff(date1, "months", true);
};

export function HHmmToSeconds(HHmm) {
    const part = HHmm.split(":");
    return 60 * 60 * part[0] + 60 * part[1];
}

export function MMssToSeconds(HHmm) {
    const part = HHmm.split(":");
    let mm = parseFloat(60 * part[0]);
    let ss = parseFloat(part[1]);
    let mmPlusss = mm + ss;
    // return 60 * part[0] +  part[1]
    return mmPlusss;
}
