import {toast} from "react-toastify";

const NOTIFY_TYPES = {
    default: "default",
    success: "success",
    warning: "warning",
    error: "error",
    info: "info"
};

const notify = (message = "", type = NOTIFY_TYPES.default) =>
    toast(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        type: type
    });

export default notify;
export {NOTIFY_TYPES};
