import Network from "./Network";

class EventSourceController {
    constructor() {
        this.url = `${Network.getApiRoot()}/notifications/connection`;
        this.source = null;
        this.supportEventSource = typeof EventSource === "function";
    }

    init() {
        if (!this.source && this.supportEventSource) {
            this.source = new EventSource(this.url, {withCredentials: true});

            this.source.onopen = () => {
                console.log("Open EventSource");
            };

            this.source.onerror = () => {
                if (this.source.readyState !== EventSource.CONNECTING) {
                    console.log("Error connection");
                }
            };
        }
    }

    addEventListener(event, listener) {
        if (!this.source) {
            return;
        }
        return this.source.addEventListener(event, listener);
    }

    removeEventListener(event, listener) {
        if (!this.source) {
            return;
        }
        this.source.removeEventListener(event, listener);
    }

    close() {
        if (!this.source) {
            return;
        }
        this.source.close();
        this.source = null;
    }
}

export default new EventSourceController();
