import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";

const LanguageSelectWrapper = styled.div`
    position: absolute;
    display: none;
    align-items: center;

    height: 100%;
    right: 1rem;

    font-size: 0.8rem;
    span {
        margin: 0 0.4rem;
    }
    cursor: default;

    ${mediaQuery.phone`
        display: flex;
        position: relative;
        font-size: 0.9rem;
        height: auto;
        right: auto;
        justify-content: flex-end;
        margin-top: 0.9rem;
    `}
`;

const LanguageItem = styled.span`
    ${props => props.selected && "font-weight: 600;"};
    cursor: pointer;
`;

const SpaceForChat = styled.div`
    width: 80px;
    height: 60px;
`;

@withNamespaces("translation")
class LanguageSelect extends Component {
    render() {
        const {i18n} = this.props;

        return (
            <LanguageSelectWrapper>
                <LanguageItem onClick={() => i18n.changeLanguage("en")} selected={i18n.language === "en"}>
                    EN
                </LanguageItem>
                |
                <LanguageItem onClick={() => i18n.changeLanguage("ru")} selected={i18n.language === "ru"}>
                    RU
                </LanguageItem>
                |
                <LanguageItem onClick={() => i18n.changeLanguage("ar-SA")} selected={i18n.language === "ar-SA"}>
                    AR
                </LanguageItem>
                |
                <LanguageItem onClick={() => i18n.changeLanguage("tr")} selected={i18n.language === "tr"}>
                    TR
                </LanguageItem>
                |
                <LanguageItem onClick={() => i18n.changeLanguage("so")} selected={i18n.language === "so"}>
                    SO
                </LanguageItem>
                |
                <LanguageItem onClick={() => i18n.changeLanguage("fr")} selected={i18n.language === "fr"}>
                    FR
                </LanguageItem>
                <SpaceForChat />
            </LanguageSelectWrapper>
        );
    }
}

export default LanguageSelect;
