import React from "react";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import NetworkController from "controllers/Network";
import {withContextConsumer} from "utils/contexts";
import CurrentUserContext from "contexts/CurrentUser";
import userTypes from "consts/userTypes";
import enrollStatus from "consts/enrollStatus";
import WalletContext from "./Wallet";
import GlobalHintsContext from "./GlobalHints";
import ChatContext from "./chat/Chat";
import {diffInMonths} from "utils/date";
import enrollPaymentTypes from "consts/enrollPaymentTypes";
import errors from "consts/errors";
import {withRouter} from "react-router-dom";
const {NotEnoughMoney} = errors;

export const EnrollmentsContext = React.createContext("enrollments");

@withContextConsumer(WalletContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
@withContextConsumer(GlobalHintsContext.Consumer)
@withContextConsumer(ChatContext.Consumer)
class EnrollmentsProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            enrollmentsAcceptedLoading: true,
            enrollments: [],
            enrollmentsAccepted: [],
            enrollRequestsCount: 0,
            enrollmentError: null,
            enrollmentErrors: [],
            isEnrollmentErrorModalOpen: false,
            closeEnrollmentErrorModal: this.closeEnrollmentErrorModal.bind(this),
            getCurrentUserEnrollments: this.getCurrentUserEnrollments.bind(this),
            getEnrollRequestsToCourses: this.getEnrollRequestsToCourses.bind(this),
            onEnrollToCourse: this.enrollToCourse.bind(this),
            onEnrollToProgram: this.enrollToProgram.bind(this),
            isEnrolledToCourse: this.isEnrolledToCourse.bind(this),
            isEnrolledToCourseAndWaiting: this.isEnrolledToCourseAndWaiting.bind(this),
            cancelEnrollmentByCourse: this.cancelEnrollmentByCourse.bind(this),
            onSetEnrollmentStatus: this.setEnrollmentStatus.bind(this),
            revokeEnrollment: this.revokeEnrollment.bind(this),
            enrollModalParams: false,
            setEnrollModalParams: this.setEnrollModalParams,
            fullyUsedContent: null,
            isNotEnoughLicencesModalOpen: false,
            closeNotEnoughLicencesModalOpen: this.closeNotEnoughLicencesModalOpen.bind(this),
            transactionInProgress: props.transactionInProgress
        };
        window.ff = this;
    }

    setEnrollModalParams = async enrollModalParams => {
        await this.setStatePromise({enrollModalParams});
    };

    componentDidMount() {
        const {currentUser} = this.props;
        currentUser && this.getEnrollRequestsToCourses();
        currentUser && this.getCurrentUserEnrollments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentUser} = this.props;
        if (prevProps.transactionInProgress !== this.props.transactionInProgress) {
            this.setState({transactionInProgress: this.props.transactionInProgress});
        }
        if (!currentUser) {
            return;
        }
        const didUserChanged = !prevProps.currentUser || prevProps.currentUser.id !== currentUser.id;
        if (didUserChanged) {
            this.getEnrollRequestsToCourses();
            this.getCurrentUserEnrollments();

            const query = new URLSearchParams(window.location.search);
            const courseOrProgramId = query.get("courseOrProgramId");
            courseOrProgramId && this.setState({enrollModalParams: {id: courseOrProgramId, isRedirected: true}});
        }
    }

    async getCurrentUserEnrollments() {
        const {currentUser} = this.props;
        const isTeacher = currentUser && currentUser.role === userTypes.teacher;
        if (!currentUser) {
            this.setState({enrollmentsAcceptedLoading: false});
        } else if (!isTeacher) {
            const {error, response} = await NetworkController.get("/users/enrollments");
            if (!error) {
                const {enrollments} = response;
                const enrollmentsAccepted = enrollments.filter(e => e.status === enrollStatus.accepted);
                this.setState({enrollmentsAccepted, enrollmentsAcceptedLoading: false});
            }
        }
    }

    async getEnrollRequestsToCourses(data) {
        const {error, response} = await NetworkController.get("/courses/enrollments", data);
        if (!error) {
            this.setState({enrollments: response.enrollments, enrollRequestsCount: response.count});
        }
    }

    async enrollToCourse(course, voucher, paymentType, childrenIds = null) {
        const {setPaymentModalOpenParams, getBalanceRechargeAmount, currentUser, getWallet} = this.props;
        const isChild = currentUser.role === userTypes.child;
        const {error, response} = await NetworkController.post(`/courses/${course.id}/enrollments`, {
            voucherId: voucher && voucher.id,
            paymentType,
            childrenIds
        });
        if (error && response.account === NotEnoughMoney) {
            this.state.setEnrollModalParams(false);
            if (isChild) {
                this.props.addHint({text: "Please contact you parent for recharging balance", timeout: 3000});
                return {error};
            }
            const amount = await this.getPlainEnrollmentPrice(course, voucher, paymentType);
            const balanceRechargeAmount = getBalanceRechargeAmount(amount);
            const topUpData = {
                reason: NotEnoughMoney,
                amount: balanceRechargeAmount,
                callback: this.enrollToCourse.bind(this, course, voucher, paymentType, childrenIds)
            };
            setPaymentModalOpenParams(topUpData);
            return {error};
        }
        if (!error) {
            const callback = () => {
                getWallet();
                this.getEnrollRequestsToCourses();
            };
            this.setState({enrollModalParams: false}, callback);
            return {error: false};
        }
    }

    async enrollToProgram(program, voucher, paymentType, childrenIds = null) {
        const {setPaymentModalOpenParams, getBalanceRechargeAmount, currentUser, getWallet} = this.props;
        const isChild = currentUser.role === userTypes.child;
        const {error, response} = await NetworkController.post(`/programs/${program.id}/enrollments`, {
            voucherId: voucher && voucher.id,
            paymentType,
            childrenIds
        });
        if (error && response.account === NotEnoughMoney) {
            this.state.setEnrollModalParams(false);
            if (isChild) {
                this.props.addHint({text: "Please contact you parent for recharging balance", timeout: 3000});
                return {error};
            }
            const amount = await this.getPlainEnrollmentPrice(program, voucher, paymentType);
            const balanceRechargeAmount = getBalanceRechargeAmount(amount);
            const topUpData = {
                reason: NotEnoughMoney,
                amount: balanceRechargeAmount,
                callback: this.enrollToProgram.bind(this, program, voucher, paymentType, childrenIds)
            };
            setPaymentModalOpenParams(topUpData);
            return {error};
        }
        if (!error) {
            const callback = () => {
                getWallet();
                this.getEnrollRequestsToCourses();
            };
            this.setState({enrollModalParams: false}, callback);
            return {error: false};
        }
    }

    async cancelEnrollmentByCourse(courseId) {
        const {enrollmentsAccepted} = this.state;
        const request = {status: enrollStatus.declined};
        const enrollment = enrollmentsAccepted.find(enrollment => enrollment.course.id === courseId);
        const {error} = await NetworkController.put(`/courses/enrollments/${enrollment.id}`, request);
        if (!error) {
            this.setState({enrollmentsAccepted: enrollmentsAccepted.filter(({id}) => id !== enrollment.id)});
        }
    }

    getPriceForMonth(discountedFullPrice, price, priceForMonth) {
        const discountedPriceForMonth = (discountedFullPrice / price) * priceForMonth;
        return discountedPriceForMonth.toFixed(2);
    }

    async getPlainEnrollmentPrice(course, voucher, typeOfEnrollment) {
        const {convertMoney, wallet} = this.props;

        const courseCurrencyName = course.currency.name;
        const userCurrencyName = wallet.account.currency.name;

        const coursePrice = await convertMoney(courseCurrencyName, userCurrencyName, course.price);
        if (typeOfEnrollment === enrollPaymentTypes.monthly && course.canPayMonthly) {
            const courseMonthPrice = await convertMoney(courseCurrencyName, userCurrencyName, course.priceForMonth);
            const discountedFullPrice = voucher ? coursePrice - voucher.convertedAmount : parseFloat(coursePrice);

            let diffMonth = diffInMonths(new Date(course.startDate), new Date());
            if (new Date(course.startDate).getDate() > new Date().getDate() && diffMonth > 0) {
                diffMonth--;
            }
            let needPay = diffMonth * courseMonthPrice;
            if (needPay + parseFloat(courseMonthPrice) > coursePrice) {
                needPay = coursePrice - courseMonthPrice;
            }
            const discountedMonthPrice = this.getPriceForMonth(discountedFullPrice, coursePrice, courseMonthPrice);
            return parseFloat(discountedMonthPrice) + needPay;
        }

        if (!voucher) {
            return parseFloat(coursePrice);
        }
        return parseFloat(coursePrice - voucher.convertedAmount);
    }

    isEnrolledToCourse(courseId) {
        const {enrollmentsAccepted} = this.state;
        return enrollmentsAccepted.some(({course, program}) => (course || program)?.id === courseId);
    }

    isEnrolledToCourseAndWaiting(courseId) {
        const {enrollmentsAccepted} = this.state;
        return enrollmentsAccepted.some(
            enrollment => enrollment.courseId === courseId && enrollment.status !== enrollStatus.accepted
        );
    }

    async setEnrollmentStatus(enrollmentId, status, data, isProgram) {
        const {enrollmentErrors} = this.state;
        const request = {...data, status, isProgram};
        const apiPrefix = isProgram ? "programs" : "courses";
        const {error, response} = await NetworkController.put(`/${apiPrefix}/enrollments/${enrollmentId}`, request);
        if (!error) {
            if (status === enrollStatus.accepted) await this.props.reloadChatState();
            enrollmentErrors[enrollmentId] = null;
            const successState = {enrollments: response.enrollments, enrollRequestsCount: response.count, enrollmentErrors};
            this.setState(successState);
            return {...successState, error: false};
        }
        if (error) {
            if (response.error === "Not enough attached content subscription licences") {
                const errorState = {fullyUsedContent: response.fullyUsedContent, isNotEnoughLicencesModalOpen: true};
                this.setState(errorState);
                return {...errorState, error: true};
            }

            if (
                [
                    "ERROR_ENROLLMENT_NOT_FOUND",
                    "ERROR_MAX_STUDENTS_TO_COURSE",
                    "Subscription students count limit is reached"
                ].includes(response.error)
            ) {
                enrollmentErrors[enrollmentId] = response.error;
                const errorState = {isEnrollmentErrorModalOpen: true, enrollmentError: response.error, enrollmentErrors};
                this.setState(errorState, () => this.getEnrollRequestsToCourses(data));
                return {...errorState, error: true};
            }
        }
        return {};
    }

    async revokeEnrollment(enrollmentId, childId) {
        const request = {childId, enrollmentId};
        const {error} = await NetworkController.put(`/courses/revoke/${enrollmentId}`, request);
        this.getEnrollRequestsToCourses();
        this.getCurrentUserEnrollments();
        return error;
    }

    closeNotEnoughLicencesModalOpen() {
        this.setState({isNotEnoughLicencesModalOpen: false});
    }

    closeEnrollmentErrorModal() {
        this.setState({isEnrollmentErrorModalOpen: false});
    }

    render() {
        return <EnrollmentsContext.Provider value={this.state}>{this.props.children}</EnrollmentsContext.Provider>;
    }
}

export default {
    Provider: withRouter(EnrollmentsProvider),
    Consumer: EnrollmentsContext.Consumer
};
