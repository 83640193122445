import React from "react";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";

const AssignmentSubmissionContext = React.createContext("");

const CONFIG = {
    headers: {"content-type": "multipart/form-data"}
};
class AssignmentSubmissionProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            submitStudentAssignment: this.submitStudentAssignment.bind(this),
            replaceStudentAssignment: this.replaceStudentAssignment.bind(this),
            checkStudentAssignment: this.checkStudentAssignment.bind(this),
            getStudentSubmission: this.getStudentSubmission.bind(this)
        };
    }

    async getStudentSubmission(assignmentId, studentId) {
        let data = {
            studentId: studentId
        };
        const {response} = await NetworkController.get(`/assignments/submission/${assignmentId}`, data);
        const {submission} = response;

        return submission;
    }

    async submitStudentAssignment(data, files) {
        const {response} = await NetworkController.formData(`/assignments/submission`, data, files, CONFIG);

        const {submission} = response;

        return submission;
    }

    async replaceStudentAssignment(data, files) {
        const {response} = await NetworkController.formData(`/assignments/submission/${data.submission}`, data, files, CONFIG);

        const {submission} = response;

        return submission;
    }

    async checkStudentAssignment(data, files) {
        const {response} = await NetworkController.formData(`/assignments/check/${data.submission}`, data, files, CONFIG);

        const {submission} = response;

        return submission;
    }

    render() {
        return (
            <AssignmentSubmissionContext.Provider value={this.state}>{this.props.children}</AssignmentSubmissionContext.Provider>
        );
    }
}

export default {Provider: withRouter(AssignmentSubmissionProvider), Consumer: AssignmentSubmissionContext.Consumer};
