import React, {Component} from "react";
import styled from "styled-components";
import Status from "components/common/icons/notifications/statuses/status";
import {Manager, Reference, Popper} from "react-popper";
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router-dom";
import Popover from "./Popover";
import PopoverRow from "./PopoverRow";
import {IconWrapper} from "./style";

const Content = styled.div`
    display: flex;
    align-items: center;
    justify: flex-start;
`;

@withRouter
@withNamespaces("translation")
class PopUpChatNotification extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {newNotification, children, i18n, t} = this.props;
        const statusStyle = i18n.dir() === "rtl" ? {marginLeft: "1rem"} : {marginRight: "1rem"};
        return (
            <div style={{position: "relative"}}>
                <Manager>
                    <Reference>{({ref}) => <IconWrapper ref={ref}>{children}</IconWrapper>}</Reference>
                    <Popper placement="bottom-end" modifiers={{offset: {offset: "15", enable: true}}}>
                        {({ref}) =>
                            newNotification ? (
                                <div>
                                    <Popover ref={ref} isPopUp>
                                        <PopoverRow isPopUp>
                                            <Content>
                                                <Status status={newNotification.status} style={statusStyle} />
                                                <div>{t("notifications.newMessage")}</div>
                                            </Content>
                                        </PopoverRow>
                                    </Popover>
                                </div>
                            ) : null
                        }
                    </Popper>
                </Manager>
            </div>
        );
    }
}

export default PopUpChatNotification;
