import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import FooterContent from "components/footer/Footer";
import LandingFooterContent from "components/footer/FooterLanding";
import {withContextConsumer} from "utils/contexts";
import CurrentUserContext from "contexts/CurrentUser";

@withRouter
@withContextConsumer(CurrentUserContext.Consumer)
class Footer extends Component {
    render() {
        const {history, currentUser} = this.props;
        const isLanding = history.location.pathname === "/";
        return isLanding ? <LandingFooterContent /> : <FooterContent currentUser={currentUser} />;
    }
}

export default Footer;
