import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import NetworkController from "controllers/Network";
import Input from "components/form/Input";
import Button from "components/form/Button";

import notify, {NOTIFY_TYPES} from "utils/notify";

const AssessmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const StudentProgressContainer = styled.div``;

const StudentProgressInnerContainer = styled.div`
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 4px;
`;

const ButtonContainer = styled.div`
    display: flex;
    //justify-content: center;
    align-items: center;
    margin-top: 1rem;
`;

const GradesContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const Name = styled.h5`
    text-transform: capitalize;
`;

@withNamespaces("translation")
class assessmentsCheckAssessmentData extends Component {
    constructor(props) {
        super(props);
        const {assessment, studentSubmission} = this.props;

        this.state = {
            feedback: studentSubmission?.[0]?.feedback || "",
            assessmentId: assessment._id,
            obtainScores: studentSubmission?.[0]?.results?.grade || studentSubmission?.[0]?.results?.obtainScores || 0,
            totalScores: assessment?.totalScores || 0,
            renderFeedbackForm: this.renderFeedbackForm.bind(this)
        };
    }

    async setObtainedMarks(marks) {
        this.setState({obtainScores: marks});
    }

    async handleAssessmentFeedback() {
        const {feedback, obtainScores, totalScores} = this.state;
        const {assessment, student} = this.props;

        const config = {
            headers: {"content-type": "multipart/form-data"}
        };

        const data = {
            feedback: feedback,
            grade: obtainScores,
            totalScores: totalScores
        };

        const {error, response} = await NetworkController.formData(
            `/assessments/${assessment.id}/student/${student.id}/feedback`,
            data,
            config
        );

        if (!error) {
            this.props.updateAssessment(response.assessment);
            notify("feedback submitted", NOTIFY_TYPES.success);
        }
    }

    renderFeedbackForm() {
        const {t} = this.props;
        const {feedback, obtainScores, totalScores} = this.state;
        return (
            <>
                <GradesContainer>
                    <h5>Obtained Marks:</h5>
                    <Input
                        value={obtainScores}
                        placeholder={t("lecture.digest.checkAssignment.obtainScores")}
                        required
                        style={{marginLeft: 8, width: "40%"}}
                        minRows={1}
                        maxRows={1}
                        maxLength={3}
                        onChange={this.setObtainedMarks.bind(this)}
                        showErrorText
                    />

                    <h5>{t("lecture.digest.checkAssignment.totalScores")}:</h5>
                    <p style={{marginLeft: 8}}>{totalScores}</p>
                </GradesContainer>

                <h5>{t("lecture.digest.checkAssignment.feedback")}</h5>
                <Input
                    value={feedback}
                    placeholder={t("lecture.digest.checkAssignment.feedback")}
                    multiline
                    required
                    minRows={1}
                    maxLength={2000}
                    onChange={value => this.setState({feedback: value})}
                    showErrorText
                />
            </>
        );
    }

    render() {
        const {student, isLectureOwner, t} = this.props;

        return (
            <AssessmentContainer>
                <ListContainer>
                    <StudentProgressContainer>
                        <>
                            <>
                                <StudentProgressInnerContainer>
                                    <Name>
                                        {student?.firstName} {student?.lastName}
                                    </Name>

                                    {this.renderFeedbackForm()}

                                    {isLectureOwner ? (
                                        <>
                                            <ButtonContainer>
                                                <Button type="gray" onClick={this.handleAssessmentFeedback.bind(this)}>
                                                    {t("lecture.digest.checkAssignment.Submit")}
                                                </Button>
                                            </ButtonContainer>
                                        </>
                                    ) : null}
                                </StudentProgressInnerContainer>
                            </>
                        </>
                    </StudentProgressContainer>
                </ListContainer>
            </AssessmentContainer>
        );
    }
}

export default assessmentsCheckAssessmentData;
