import React, {Component} from "react";
import styled from "styled-components";

const TelrIframe = styled.iframe`
    outline: none;
    border: none;
    width: 100%;
    height: 30rem;
`;

class TelrPaymentModalContent extends Component {
    constructor(props) {
        super(props);
        this.onWindowMessage = this.onWindowMessage.bind(this);
    }

    componentDidMount() {
        window.addEventListener("message", this.onWindowMessage, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onWindowMessage);
    }

    async onWindowMessage(event) {
        if (event.origin === location.origin) {
            if (event.data === "callback-success") {
                await this.props.onTelrPaymentApproved();
            } else if (event.data === "callback-failed") {
                this.props.onUnknownTransactionError();
                this.props.onRequestClose();
            }
        }
    }

    render() {
        const {params} = this.props;
        return (
            <React.Fragment>
                <TelrIframe src={params.url} />
            </React.Fragment>
        );
    }
}

export default TelrPaymentModalContent;
