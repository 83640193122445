import React, {Component} from "react";
import styled from "styled-components";
import ListCheckmarkInactive from "components/common/icons/library/ListCheckmarkInactive";
import ListCheckmarkActive from "components/common/icons/library/ListCheckmarkActive";

const CheckmarkContainer = styled.div`
    cursor: pointer;
    svg {
        width: 16px;
        height: 16px;
    }
`;

class Checkmark extends Component {
    render() {
        const {checked, onClick} = this.props;
        return (
            <CheckmarkContainer onClick={onClick}>
                {checked ? <ListCheckmarkActive /> : <ListCheckmarkInactive />}
            </CheckmarkContainer>
        );
    }
}

export default Checkmark;
