import React from "react";

export default props => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            fill="#BCC3CA"
            fillRule="nonzero"
            d="M7.13 2.85L5.32.404A1 1 0 0 0 4.516 0H1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3.85a1 1 0 0 0-1-1H7.13z"
        />
    </svg>
);
