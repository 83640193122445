import React, {Component} from "react";
import styled from "styled-components";

const ControlsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.25rem;
`;

const DeleteFolderButton = styled.div`
    font-size: 0.75rem;
    font-weight: 600;
    color: #5d6a77;
    cursor: pointer;
    ${props => props.disabled && "pointer-events: none; opacity: 0.5;"}
`;

class Controls extends Component {
    render() {
        const {t, canDeleteFolder, onDeleteFolder} = this.props;
        return (
            <ControlsContainer>
                <DeleteFolderButton disabled={!canDeleteFolder} onClick={onDeleteFolder}>
                    {t("library.deleteFolder.button")}
                </DeleteFolderButton>
            </ControlsContainer>
        );
    }
}

export default Controls;
