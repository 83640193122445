import React, {Component} from "react";
import BrowserContext from "contexts/Browser";
import GlobalHintsContext from "contexts/GlobalHints";
import WindowRectContext from "contexts/WindowRect";
import {withNamespaces} from "react-i18next";
import {withContextProvider} from "utils/contexts";
import {QueryClient, QueryClientProvider} from "react-query";
import Router from "./Router";
import GlobalHints from "components/globalHints/GlobalHints";
import LocaleContext from "contexts/common/LocaleContext";

// Create a client
const queryClient = new QueryClient();

@withNamespaces("translation")
@withContextProvider(LocaleContext.Provider)
@withContextProvider(BrowserContext.Provider)
@withContextProvider(GlobalHintsContext.Provider)
@withContextProvider(WindowRectContext.Provider)
class App extends Component {
    render() {
        const {i18n} = this.props;
        return (
            <QueryClientProvider client={queryClient}>
                <div style={{direction: i18n.dir()}}>
                    <Router />
                    <GlobalHints />
                </div>
            </QueryClientProvider>
        );
    }
}

export default App;
