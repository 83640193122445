import React from "react";

export default props => (
    <svg viewBox="0 0 24 24" {...props}>
        <defs>
            <path
                id="cross_a"
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="cross_b" fill="#fff">
                <use xlinkHref="#cross_a" />
            </mask>
            <g fill="#000" fillOpacity=".54" mask="url(#cross_b)">
                <path d="M0 0h24v24H0z" />
            </g>
        </g>
    </svg>
);
