import React, {Component} from "react";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";
import FileUploadContext from "../common/FileUploadProgress";
import {withContextConsumer} from "utils/contexts";

const AssignmentsAddEditContext = React.createContext("assignmentsList");

@withRouter
@withContextConsumer(FileUploadContext.Consumer)
class AssignmentStudentProgressProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propsdata: null,
            assigmentWhiteboard: this.assigmentWhiteboard.bind(this),
            PassAssignmentAddEditData: this.PassAssignmentAddEditData.bind(this)
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state == null) return true;

        if (this.state.propsdata !== null) return false;

        return true;
    }

    async assigmentWhiteboard(assignment) {
        this.props.history.push({
            pathname: `/assignments/check/${studentAssignments[0]._id}`,
            search: `?assignmentId=${studentAssignments[0]._id}`,
            state: {isAssignmentSandboxView: true, studentAssignments, currentUser, key}
        });
    }

    PassAssignmentAddEditData = async data => {
        return this.setState({propsdata: data}, () => {
            this.props.history.push("/assignments/add");
        });
    };

    render() {
        return <AssignmentsAddEditContext.Provider value={this.state}>{this.props.children}</AssignmentsAddEditContext.Provider>;
    }
}

export default {
    Provider: withRouter(AssignmentStudentProgressProvider),
    Consumer: AssignmentsAddEditContext.Consumer
};
