const roles = {
    student: "student",
    teacher: "teacher",
    parent: "parent",
    child: "child",
    publisher: "publisher",
    admin: "admin",
    principal: "principal",
    temporary: "temporary",
    vendor: "vendor"
};
export default roles;

const checkRole = (value, role) => {
    const userRole = typeof value === "object" ? value && value.role : value;
    return userRole === role;
};

export const isStudent = value => {
    return checkRole(value, roles.student);
};
export const isTeacher = value => {
    return checkRole(value, roles.teacher);
};
export const isParent = value => {
    return checkRole(value, roles.parent);
};
export const isChild = value => {
    return checkRole(value, roles.child);
};
export const isPublisher = value => {
    return checkRole(value, roles.publisher);
};
export const isAdmin = value => {
    return checkRole(value, roles.admin);
};
export const isPrincipal = value => {
    return checkRole(value, roles.principal);
};
export const isTemporary = value => {
    return checkRole(value, roles.temporary);
};
