import React, {useEffect} from "react";
import Modal from "components/common/modals/Modal";
import OwnersContent from "components/common/ownersAssignmentModalContent/OwnersContent";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import {compose} from "recompose";
import {withNamespaces} from "react-i18next";
import styled from "styled-components";
import TextButton from "components/form/TextButton";
import AssignmentListContext from "contexts/assignment/AssignmentList";
import {withRouter} from "react-router-dom";

const headerStyle = {fontWeight: "bold", fontSize: "1.5rem", padding: "0 0 1.5 0"};
const textButtonStyle = {fontSize: "1rem", margin: "1rem"};
const AssignmentHeaderButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 1rem 1rem 0 2rem;
    margin: 0 0 1rem 0;
`;

const AddButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Title = styled.div`
    line-height: 3rem;
    font-size: 1.75rem;
    font-weight: 600;
    color: #363636;
`;

function AddAssignmentModal(props) {
    useEffect(async () => {
        const {getAssignments, getAssignmentMeta} = props;
        await getAssignmentMeta();
        await getAssignments();
    }, []);

    const {
        isOpen,
        onClose,
        lecture,
        getLectureData,
        closeTitle,
        title,
        course,
        assignmentMeta,
        assignments,
        t,
        attachViaSelfStudyStructure,
        attachSelfStudyAssignment
    } = props;

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            titleStyle={{padding: "0.5rem 0"}}
            headerStyle={headerStyle}
            // style={{borderRadius: "4px", width: "50rem"}}
            style={{
                position: "relative",
                width: "50rem",
                color: "var(--light-black)",
                borderRadius: "10px"
            }}
            header={
                <AssignmentHeaderButton>
                    <Title>{title || t("assignment.attachAssignment.attachAssignmentTitle")}</Title>
                    <AddButtonsContainer>
                        <TextButton
                            title={t("assignment.createNewAssignment")}
                            style={textButtonStyle}
                            onClick={() =>
                                props.history.push({
                                    pathname: `/assignments/create`,
                                    state: {isCreatingAssignment: true}
                                })
                            }
                            // disabled={!hasCoursesToAttach}
                            withPlus
                        />
                    </AddButtonsContainer>
                </AssignmentHeaderButton>
            }
            overflow={"hidden"}
            contentWrapperStyle={{padding: 0}}
            onRequestClose={onClose}
            fullHeightContent>
            <OwnersContent
                onClose={onClose}
                assignmentMeta={assignmentMeta}
                assignments={assignments}
                lectureData={lecture}
                closeTitle={closeTitle}
                course={course}
                getLectureData={getLectureData}
                attachViaSelfStudyStructure={attachViaSelfStudyStructure}
                attachSelfStudyAssignment={attachSelfStudyAssignment}
                isListTitleHidden
                hideAttached
            />
        </Modal>
    );
}

export default compose(
    withRouter,
    withNamespaces("translation"),
    withContextProvider(AssignmentListContext.Provider),
    withContextConsumer(AssignmentListContext.Consumer)
)(AddAssignmentModal);
