const courseModes = {
    live: "live",
    self: "self"
    // hybrid: "hybrid"
};

export default courseModes;

export function isLiveMode(mode) {
    return mode === courseModes.live;
}

export function isSelfMode(mode) {
    return mode === courseModes.self;
}

export function isHybridMode(mode) {
    // return mode === courseModes.hybrid;
}
