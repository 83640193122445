import React from "react";

export default props => (
    <svg viewBox="0 0 66 40" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H66V40H0z" />
            <g fill="#FFF" fillRule="nonzero">
                <path
                    d="M31.788 0c1.523 0 2.754.34 3.53.648l-.535 3.287-.356-.162c-.712-.308-1.652-.615-2.932-.583-1.553 0-2.25.664-2.266 1.311 0 .713.843 1.183 2.219 1.879 2.267 1.085 3.32 2.412 3.303 4.145-.032 3.157-2.72 5.198-6.85 5.198-1.765-.017-3.465-.39-4.388-.81l.55-3.417.519.243c1.28.568 2.121.81 3.692.81 1.133 0 2.348-.47 2.364-1.49 0-.664-.518-1.15-2.04-1.895-1.49-.728-3.482-1.943-3.45-4.129C25.165 2.072 27.902 0 31.788 0zm-8.275.276l-2.461 15.22h-3.936L19.578.276h3.935zm21.893 0l3.175 15.22h-3.644s-.356-1.75-.47-2.283h-5.052l-.826 2.283h-4.13l5.846-13.957C40.71.55 41.423.276 42.362.276h3.044zm-27.415 0L11.805 15.48H7.643L4.113 2.17c2.494 1.312 4.729 3.95 5.441 6.38l.421 2.104L13.83.276h4.162zm24.646 4.112c-.194.567-.534 1.49-.517 1.458 0 0-1.247 3.384-1.572 4.258h3.272c-.162-.761-.908-4.404-.908-4.404z"
                    transform="translate(7.843 11.765)"
                />
                <path
                    d="M6.396.276H.065L0 .583C4.94 1.846 8.21 4.891 9.554 8.55L8.178 1.555C7.95.583 7.255.308 6.396.275z"
                    opacity=".2"
                    transform="translate(7.843 11.765)"
                />
            </g>
        </g>
    </svg>
);
