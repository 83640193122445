import React from "react";
import styled from "styled-components";
import {compose} from "recompose";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import AssignmentSubmissionContext from "contexts/assignment/AssignmentSubmission";

import {withContextConsumer, withContextProvider} from "utils/contexts";
import EnrollmentsContext from "contexts/Enrollments";
import LecturesDigestContext from "contexts/LecturesDigest";
import Paper from "@mui/material/Paper";
import userTypes from "consts/userTypes";
import StudentAssignmentList from "./StudentAssignmentList";
import AdminAssignmentList from "./AdminAssignmentList";

const {student, child, principal, teacher} = userTypes;

const AssignmentContainer = styled.div`
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const NoAssignmentMessage = styled.div`
    padding: 1rem;
    color: #8b8b8b;
`;

const CardSpace = styled.div`
    padding: 0.5rem;
    width: 50vw;
    ${mediaQuery.phone`
     width: 50vw;
`};
    @media screen and (max-width: 2560px) {
        width: 40vw;
    }
    @media screen and (max-width: 1496px) {
        width: 50vw;
    }
    @media screen and (max-width: 1440px) {
        width: 55vw;
    }
    @media screen and (max-width: 1024px) {
        width: 76vw;
    }
    @media screen and (max-width: 768px) {
        width: 100vw;
    }
`;

function AssignmentList(props) {
    const {
        assignments,
        isLectureOwner,
        setOpenedAssignment,
        currentUser,
        enterAssignment,
        showPersonalResult,
        lecture,
        getLectureData,
        usersAttendance,
        history,
        updateAssignments,
        t,
        updateAssignment,
        updateSubmission,
        deleteSubmission,
        replaceSubmissionFromCache
    } = props;

    const isStudentOrChild = currentUser.role === student || currentUser.role === child;
    const isTeacherOrPrincipal = currentUser.role === teacher || currentUser.role === principal;

    if (isStudentOrChild) {
        return (
            <AssignmentContainer>
                <ListContainer>
                    {assignments && assignments.length > 0 ? (
                        assignments.map((assignment, index) => {
                            const submittedAssignment = assignment?.results.find(
                                submission => submission.student === currentUser.id && submission.status !== "deleted"
                            );

                            return (
                                <Paper elevation={4}>
                                    <CardSpace>
                                        <StudentAssignmentList
                                            key={assignment.id}
                                            submittedAssignment={submittedAssignment}
                                            assignment={assignment}
                                            setOpenedAssignment={setOpenedAssignment}
                                            currentUser={currentUser}
                                            enterAssignment={enterAssignment}
                                            showPersonalResult={showPersonalResult}
                                            lecture={lecture}
                                            getLectureData={getLectureData}
                                            usersAttendance={usersAttendance}
                                            history={history}
                                            index={index}
                                            updateSubmission={updateSubmission}
                                            deleteSubmission={deleteSubmission}
                                            replaceSubmissionFromCache={replaceSubmissionFromCache}
                                        />
                                    </CardSpace>
                                </Paper>
                            );
                        })
                    ) : (
                        <NoAssignmentMessage>{t("lecture.digest.noAssignmentsAssigned")}</NoAssignmentMessage>
                    )}
                </ListContainer>
            </AssignmentContainer>
        );
    }

    // Teacher or Principal
    if (isTeacherOrPrincipal) {
        return (
            <AssignmentContainer>
                <ListContainer>
                    {/* <Paper elevation={4}> */}
                    <CardSpace>
                        <AdminAssignmentList
                            assignments={assignments}
                            updateAssignments={updateAssignments}
                            isLectureOwner={isLectureOwner}
                            setOpenedAssignment={setOpenedAssignment}
                            currentUser={currentUser}
                            showPersonalResult={showPersonalResult}
                            lecture={lecture}
                            getLectureData={getLectureData}
                            usersAttendance={usersAttendance}
                            history={history}
                            updateAssignment={updateAssignment}
                        />
                    </CardSpace>
                    {/* </Paper> */}
                </ListContainer>
            </AssignmentContainer>
        );
    }

    return null;
}

export default compose(
    withNamespaces("translation"),
    withContextConsumer(EnrollmentsContext.Consumer),
    withContextConsumer(LecturesDigestContext.Consumer),
    withContextProvider(AssignmentSubmissionContext.Provider)
)(AssignmentList);
