import React, {Component} from "react";
import styled from "styled-components";
import FileIconDefault from "components/common/icons/library/FileIconDefault";
import FileIconImage from "components/common/icons/library/FileIconImage";
import FileIconVideo from "components/common/icons/library/FileIconVideo";
import FolderSmall from "components/common/icons/library/FolderSmall";

const FileIconContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    font-weight: 600;
    font-size: 0.8125rem;
    color: #bcc3ca;
    text-transform: uppercase;
    margin-top: 3px;

    max-width: 2.25rem;
    min-width: 2.25rem;
    svg {
        width: 0.875rem;
        margin-bottom: 0.35rem;
    }
`;

class FilesIcon extends Component {
    resolveFileIcon(extension, mimeType) {
        if (!mimeType) {
            return <FolderSmall />;
        }
        if (mimeType.match(/image\/*/i)) {
            return <FileIconImage />;
        }
        if (mimeType.match(/video\/*/i)) {
            return <FileIconVideo />;
        }
        return <FileIconDefault />;
    }

    render() {
        const {file} = this.props;
        if (!file.name) {
            return (
                <FileIconContainer>
                    <FileIconDefault />
                    &nbsp;
                </FileIconContainer>
            );
        }
        const extension = file.name.split(".").slice(-1)[0];

        return (
            <FileIconContainer>
                {this.resolveFileIcon(extension, file.type)}
                {extension ? extension : null}
            </FileIconContainer>
        );
    }
}

export default FilesIcon;
