import i18n from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {reactI18nextModule} from "react-i18next";

i18n.use(Backend)
    .use(intervalPlural)
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        fallbackLng: "en",
        debug: process.env.NODE_ENV !== "production",
        react: {
            wait: true,
            withRef: true
        },
        backend: {
            loadPath: "/locales/{{ns}}/{{lng}}.json"
        }
    });

export default i18n;
