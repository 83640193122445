import React from "react";

export default props => (
    <svg viewBox="0 0 102 102" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#D5DADF"
                fillRule="nonzero"
                d="M61 0l7.95 31.434a1 1 0 0 0 .705.72L102 41v57a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h57z"
            />
            <path fill="#BCC3CA" d="M60.929 0L102 41.071H64.929a4 4 0 0 1-4-4V0z" />
        </g>
    </svg>
);
