import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import {Scrollbars} from "react-custom-scrollbars-rtl";

@withNamespaces("translation")
export default class extends Component {
    render() {
        const {i18n, t, reportNS, defaultNS, i18nOptions, tReady, scrollRef, children, ...rest} = this.props;
        return (
            <Scrollbars ref={scrollRef} isRTL={i18n.dir() === "rtl"} {...rest}>
                {children}
            </Scrollbars>
        );
    }
}
