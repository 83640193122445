import React, {Component} from "react";
import Scrollbars from "components/common/Scrollbars";
import styled from "styled-components";
import File from "./File";
import {mediaQuery} from "components/common/styled/mediaQuery";

const FilesGridContainer = styled.div`
    flex: 1;
    margin-top: 1rem;
    ${mediaQuery.phone`margin-top: 1.5rem;`}
`;

const FilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${mediaQuery.phone`justify-content: center;`}
`;

class FilesGrid extends Component {
    getItems() {
        const {items, ...rest} = this.props;
        return (
            <FilesWrapper>
                {items.map((item) => (
                    <File key={item.id} item={item} {...rest} />
                ))}
            </FilesWrapper>
        );
    }

    render() {
        const {isPhoneResolution} = this.props;
        return (
            <FilesGridContainer>
                {isPhoneResolution ? this.getItems() : <Scrollbars>{this.getItems()}</Scrollbars>}
            </FilesGridContainer>
        );
    }
}

export default FilesGrid;
