import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import TawkChat from "components/TawkChat";
import {withContextConsumer} from "utils/contexts";
import NetworkController from "controllers/Network";
import CurrentUserContext from "./CurrentUser";
import throttle from "lodash/throttle";

const LoginContext = React.createContext("login");

@withRouter
@withContextConsumer(CurrentUserContext.Consumer)
class LoginProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginErrors: {},
            needSpecifyUserName: false,
            isLoadingPrepareInfo: false,
            login: this.login.bind(this),
            prepareLogin: throttle(this.prepareLogin.bind(this), 1000)
        };
    }

    async prepareLogin(data) {
        this.setState({isLoadingPrepareInfo: true});
        const {response} = await NetworkController.get("/users/prepare-login", data);
        const {needSpecifyUserName} = response;
        this.setState({needSpecifyUserName, isLoadingPrepareInfo: false});
    }

    async login(redirectLink, data) {
        const {error, response} = await NetworkController.post("/users/login", data);
        if (error) {
            return this.setState({loginErrors: response});
        }
        this.setState({loginErrors: {}});
        const {user} = response;
        const link = redirectLink || sessionStorage.redirectAfterAuth || "/";
        const {hash, search} = window.location;
        await this.props.setCurrentUser(user);
        TawkChat.checkDone(TawkChat.addEmailPhone, user);

        const urlSearchParams = new URLSearchParams(search);
        urlSearchParams.delete("redirectFrom");

        this.props.history.push({pathname: link, hash, search: urlSearchParams.toString()});
    }

    render() {
        return <LoginContext.Provider value={this.state}>{this.props.children}</LoginContext.Provider>;
    }
}

export default {
    Provider: LoginProvider,
    Consumer: LoginContext.Consumer
};
