import React, {Component} from "react";
import {detect} from "detect-browser";

const BrowserContext = React.createContext("browser");

class BrowserProvider extends Component {
    constructor(props) {
        super(props);
        const browser = detect();
        // https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
        const isIpad = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
        browser.isMobile = ["iOS", "Android OS", "BlackBerry OS"].includes(browser.os) || isIpad;
        this.state = {browser};
    }

    render() {
        return <BrowserContext.Provider value={this.state}>{this.props.children}</BrowserContext.Provider>;
    }
}

export default {Provider: BrowserProvider, Consumer: BrowserContext.Consumer, Context: BrowserContext};
