import React from "react";

export default ({fill, ...props}) => (
    <svg viewBox="0 0 14 14" {...props}>
        <defs>
            <path id="plus-a" d="M14 8H8v6H6V8H0V6h6V0h2v6h6z" />
        </defs>
        <use fill={fill || "var(--main-theme-color)"} fillRule="nonzero" xlinkHref="#plus-a" />
    </svg>
);
