import React from "react";
import styled from "styled-components";

const CircleBackground = styled.circle`
    fill: none;
    stroke: ${props => props.background || "#ddd"};
    stroke-width: ${props => props.strokeWidth};
`;
const CircleProgress = styled.circle`
    fill: none;
    stroke: ${props => props.color};
    stroke-linecap: ${props => (props.buttLinecup ? "butt" : "round")};
    stroke-linejoin: round;
`;

export default ({indicatorOptions, percentage, secondaryPercentage, ...rest}) => {
    const {radius, strokeWidth, backgroundStrokeWidth, color, background, secondaryColor, buttLinecup} = indicatorOptions;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const innerRadius = (radius - strokeWidth) / 2;
    // Enclose circle in a circumscribing square
    const viewBox = `0 0 ${radius} ${radius}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = innerRadius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = percentage > 0 ? dashArray - (dashArray * percentage) / 100 : dashArray;
    const secondaryDashOffset =
        secondaryPercentage > 0 ? secondaryPercentage && dashArray - (dashArray * secondaryPercentage) / 100 : dashArray;
    return (
        <svg width={radius} height={radius} viewBox={viewBox} {...rest}>
            <CircleBackground
                cx={radius / 2}
                cy={radius / 2}
                r={innerRadius}
                strokeWidth={`${backgroundStrokeWidth}px`}
                background={background}
            />
            <CircleProgress
                cx={radius / 2}
                cy={radius / 2}
                r={innerRadius}
                strokeWidth={`${strokeWidth}px`}
                color={color}
                // Start progress marker at 12 O'Clock
                transform={`rotate(-90 ${radius / 2} ${radius / 2})`}
                style={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset
                }}
                buttLinecup={buttLinecup}
            />
            {secondaryPercentage && (
                <CircleProgress
                    cx={radius / 2}
                    cy={radius / 2}
                    r={innerRadius}
                    strokeWidth={`${strokeWidth}px`}
                    color={secondaryColor}
                    // Start progress marker at the end of previous circle
                    transform={`rotate(-${90 + (dashOffset / dashArray) * 360} ${radius / 2} ${radius / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: secondaryDashOffset
                    }}
                    buttLinecup={buttLinecup}
                />
            )}
        </svg>
    );
};
