import React, {Component} from "react";
import styled from "styled-components";
import FolderSmallIcon from "components/common/icons/library/FolderSmall";
import dropdownArrow from "assets/icons/dropdownArrow.svg";

const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
    ${props => props.isOpen && `box-shadow: none;`}
    img {
        width: 10px;
        ${props => props.isOpen && "transform: rotate(180deg);"}
    }
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% + 15rem);
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
    z-index: -1;
`;

const SelectedWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 1rem;
`;

const SelectedFolder = styled.div`
    svg {
        height: 0.875rem;
        margin-right: 0.5rem;
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 15rem;
    z-index: 1000;
`;

class FoldersDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    toggleOpened() {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const {isOpen} = this.state;
        const {selectedFolderName, content} = this.props;

        return (
            <DropdownWrapper isOpen={isOpen}>
                <SelectedWrapper onClick={this.toggleOpened.bind(this)}>
                    <SelectedFolder>
                        <FolderSmallIcon />
                        {selectedFolderName}
                    </SelectedFolder>
                    <img src={dropdownArrow} alt={"arrow"} />
                </SelectedWrapper>
                {isOpen && <DropdownContent>{content}</DropdownContent>}
                {isOpen && <Background />}
            </DropdownWrapper>
        );
    }
}

export default FoldersDropdown;
