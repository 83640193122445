import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import AssignmentsListContext from "contexts/assignment/AssignmentList";
import AssignmentsList from "./AssignmentsList";
import AttachForm from "./AttachForm";
import {mediaQuery} from "components/common/styled/mediaQuery";

const ModalContent = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 0 2rem 0;
    height: 100%;
    ${mediaQuery.phone`
        padding: 1rem 0 0 0;
    `}
`;

const AttachAssignmentTitle = styled.div`
    line-height: 1.5;
    padding: 1rem;
    width: 100%;
    ${mediaQuery.phone`
        padding: 1rem;
    `}
`;

const Bold = styled.span`
    font-weight: bold;
`;

@withNamespaces("translation")
@withContextProvider(AssignmentsListContext.Provider)
@withContextConsumer(AssignmentsListContext.Consumer)
class OwnersContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignment: null,
            subject: null,
            competencies: [],
            assignmentGame: null
        };
    }

    async componentDidMount() {
        // const {getAssignments, getAssignmentMeta, getAssignmentGames} = this.props;
        // await getAssignmentMeta();
        // await getAssignments();
        // await getAssignmentGames();
    }

    onFieldChange(field, value) {
        this.setState({[field]: value});
    }

    setAssignment(assignment) {
        const {lectureData} = this.props;
        const {competencies = [], subject = null, grade = null} = lectureData.course || {};
        this.setState({
            assignment,
            competencies,
            subject,
            grade
        });
    }

    async attachAssignments(course, attachedAssignment) {
        const {
            attachAssignment,
            getAssignments,
            getLectureData,
            lectureData,
            attachViaSelfStudyStructure,
            attachSelfStudyAssignment
        } = this.props;
        const {assignment, subject, competencies, assignmentGame} = this.state;

        const attachForm = [
            {
                courseId: course ? course.id : null,
                lectureId: lectureData.id,
                subject: course ? course.subject : subject,
                competencies,
                grade: course ? course.grade : null,
                assignmentGame
            }
        ];

        if (attachViaSelfStudyStructure) {
            await attachSelfStudyAssignment(attachedAssignment);
        }

        await attachAssignment(attachedAssignment?.id, attachForm);
        // await getAssignments();

        let lectureId = lectureData?.[0]?.id || lectureData?.id;

        await getLectureData(lectureId);
        this.setState({assignment: null, subject: null, competencies: []});
    }

    renderContentTitle(course) {
        const {lectureData, isListTitleHidden, t} = this.props;
        const {assignment} = this.state;
        if (assignment) {
            const lectureName = course ? `${course.subject}, ${course.grade}, ${course.language}` : lectureData.name;
            return (
                <AttachAssignmentTitle>
                    {t("lecture.notifications.attachAssignment") + " "}
                    {/*  {t("lecture.notifications.attachQuiz") + " "} */}
                    <Bold>{assignment.name}</Bold>
                    {t("lecture.notifications.toLecture")}
                    <Bold>{lectureName}</Bold>
                </AttachAssignmentTitle>
            );
        }
        if (isListTitleHidden) {
            return null;
        }
        return <AttachAssignmentTitle>{t("lecture.notifications.attachAssignment")}</AttachAssignmentTitle>;
    }

    render() {
        const {onClose, assignmentMeta, lectureData, assignments, closeTitle, hideAttached} = this.props;
        const {assignment} = this.state;

        if (!assignmentMeta) {
            return null;
        }

        const course = this.props.course || assignmentMeta.courses.find(c => c.id === lectureData.course);

        return (
            <ModalContent>
                {this.renderContentTitle(course)}
                {assignment ? (
                    <AttachForm
                        assignmentMeta={assignmentMeta}
                        assignment={assignment}
                        // assignmentGames={assignmentGames}
                        lectureData={lectureData}
                        onFieldChange={this.onFieldChange.bind(this)}
                        attachAssignment={this.attachAssignments.bind(this)}
                        course={course}
                        {...this.state}
                    />
                ) : (
                    <AssignmentsList
                        assignments={assignments}
                        setAssignment={this.setAssignment.bind(this)}
                        lectureData={lectureData}
                        onClose={onClose}
                        closeTitle={closeTitle}
                        hideAttached={hideAttached}
                    />
                )}
            </ModalContent>
        );
    }
}

export default OwnersContent;
