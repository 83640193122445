import styled from "styled-components";

const Circle = styled.div`
    position: absolute;
    right: 0.125rem;
    top: -0.125rem;
    width: 0.675rem;
    height: 0.675rem;
    border-radius: 100%;
    background: orange;
`;
export default Circle;
