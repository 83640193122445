import React from "react";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";

const AssignmentsListContext = React.createContext();

const CONFIG = {
    headers: {"content-type": "multipart/form-data"}
};
class AssignmentsListProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            assignments: [], // list of assignments need to be shown
            assignment: {},
            isLoading: false,
            assignmentMeta: null,
            assignmentGames: [],
            isConfirmDeleteModalOpen: false,

            createAssignment: this.createAssignment.bind(this),
            updateAssignment: this.updateAssignment.bind(this),
            getAssignments: this.getAssignments.bind(this),
            getAssignment: this.getAssignment.bind(this),
            getAssignmentMeta: this.getAssignmentMeta.bind(this),

            attachAssignment: this.attachAssignment.bind(this),
            deleteAssignment: this.deleteAssignment.bind(this),
            openConfirmDeleteModal: this.openConfirmDeleteModal.bind(this),
            toggleConfirmDeleteModal: this.toggleConfirmDeleteModal.bind(this)
        };
    }

    showLoader = () => {
        this.setState({isLoading: true});
    };

    openConfirmDeleteModal(assignmentId) {
        this.setState({assignmentToDeleteId: assignmentId}, this.toggleConfirmDeleteModal);
    }

    toggleConfirmDeleteModal() {
        this.setState({isConfirmDeleteModalOpen: !this.state.isConfirmDeleteModalOpen});
    }

    async createAssignment(assignment, files) {
        const {response} = await NetworkController.formData(`/assignments`, assignment, files, CONFIG);

        return response;
    }

    async updateAssignment(assignmentId, assignment, files) {
        const {response} = await NetworkController.formData(`/assignments/${assignmentId}`, assignment, files, CONFIG);

        return response;
    }

    async getAssignments() {
        this.showLoader();
        const {response} = await NetworkController.get("/assignments");
        const {assignments} = response;

        this.setState({assignments: assignments, isLoading: false});
    }

    async getAssignment(id) {
        this.showLoader();
        const {response} = await NetworkController.get(`/assignments/${id}`);
        const {assignment} = response;
        this.setState({assignment, isLoading: false});
    }

    async getAssignmentMeta() {
        const {response} = await NetworkController.get("/assignments/meta");
        this.setState({assignmentMeta: response.assignmentMeta});
    }

    async attachAssignment(assignmentId, attachForms) {
        await NetworkController.put("/assignments/attach", {assignmentId, attachForms});
    }

    async deleteAssignment(id) {
        this.setState({assignments: this.state.assignments.filter(assignment => assignment.id !== id)});
        await NetworkController.delete(`/assignments/${id}`);
    }

    render() {
        return <AssignmentsListContext.Provider value={this.state}>{this.props.children}</AssignmentsListContext.Provider>;
    }
}

export default {Provider: withRouter(AssignmentsListProvider), Consumer: AssignmentsListContext.Consumer};
