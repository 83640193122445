import validator from "validator";

export function isAlphaAvailable(str) {
    for (const u of validator.isAlphaLocales) {
        if (validator.isAlpha(str, u)) {
            return true;
        }
    }
    return false;
}
