const StudentsMoreThanSupportPlan = "StudentsMoreThanSupportPlan";
const NotEnoughLibrarySize = "NotEnoughLibrarySize";

export const getAmountData = ({plan, currency, isAdmin}) => {
    if (isAdmin) {
        return plan.amount["usd"]
            ? {
                  value: plan.amount["usd"],
                  currencySign: "$",
                  currencyName: "usd"
              }
            : {
                  value: Object.values(plan.amount)[0],
                  currencySign: Object.keys(plan.amount)[0],
                  currencyName: Object.keys(plan.amount)[0]
              };
    }
    const userCurrency = currency.name.toLowerCase();
    if ([userCurrency] in plan.amount) {
        return {
            value: plan.amount[userCurrency],
            currencySign: currency.sign,
            currencyName: currency.name
        };
    }
    if (plan.amount["usd"]) {
        return {
            value: plan.amount["usd"],
            currencySign: "$",
            currencyName: "usd"
        };
    }
    const prices = Object.values(plan.amount);
    if (prices[0]) {
        const currencyName = Object.keys(plan.amount)[0];
        return {
            value: prices[0],
            currencySign: currencyName,
            currencyName
        };
    }
    return {
        value: 0
    };
};

export const isPlanError = error => {
    return [StudentsMoreThanSupportPlan, NotEnoughLibrarySize].includes(error);
};
