import React, {Component} from "react";
import styled from "styled-components";
import {Manager, Popper, Reference} from "react-popper";
import OutsideClickHandler from "react-outside-click-handler";
import {mediaQuery} from "../styled/mediaQuery";

const PopperElement = styled.div`
    opacity: ${props => (props.isShown ? "1" : "0")};
    pointer-events: ${props => (props.isShown ? "all" : "none")};
    transition: opacity 200ms ease;
    z-index: 1000;
`;

const MenuContainer = styled.div`
    border-radius: 4px;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    padding: 1.3rem 2.1rem;
    margin-top: 1rem;

    > div:last-child {
        margin-bottom: 0;
    }
    ${mediaQuery.phone`
        margin-top: 0.5rem;
    `}
`;

class PopperMenu extends Component {
    render() {
        const {isShown, refElement, content, onRequestClose, placement, containerStyles} = this.props;

        return (
            <Manager>
                <Reference>{({ref}) => <div ref={ref}>{refElement}</div>}</Reference>
                <Popper placement={placement || "bottom-end"}>
                    {({ref, style}) => (
                        <PopperElement ref={ref} style={style} isShown={isShown}>
                            <OutsideClickHandler disabled={!isShown} onOutsideClick={() => isShown && onRequestClose()}>
                                <MenuContainer style={containerStyles}>{content}</MenuContainer>
                            </OutsideClickHandler>
                        </PopperElement>
                    )}
                </Popper>
            </Manager>
        );
    }
}

export default PopperMenu;
