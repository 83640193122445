import React, {PureComponent} from "react";
import styled from "styled-components";
import formatChatTitle from "utils/formatChatTitle";
import Avatar from "components/common/Avatar";
import File from "components/library/files/FilesGrid/File";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import StackedGroupIcon from "components/common/icons/chat/StackedGroup";
import {Link} from "react-router-dom";

const Container = styled.div`
    display: flex;
    margin: 0.1rem 0.25rem;
    margin-bottom: ${({sameMessageBlock}) => (sameMessageBlock ? "0.1" : "1.75rem")};
    align-items: flex-end;
    justify-content: ${({isOwnMessage}) => (isOwnMessage ? "flex-end" : "flex-start")};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0.5rem;
    border-bottom-right-radius: ${({isLastMessageInBlock, isOwnMessage}) =>
        isOwnMessage && !isLastMessageInBlock ? "0" : "0.5rem"};
    border-bottom-left-radius: ${({isLastMessageInBlock, isOwnMessage}) =>
        !isOwnMessage && !isLastMessageInBlock ? "0" : "0.5rem"};
    padding: 0.5rem 0.8rem;
    background-color: ${({isOwnMessage}) => (isOwnMessage ? "var(--main-theme-color)" : "#F4F5F7")};
`;

const AdditionalInfoContainer = styled.span`
    font-size: 0.8rem;
    margin: -0.5rem -0.8rem 0.5rem;
    padding: 0.5rem;
    color: #5c6977;
    background-color: #ebecee;
`;

const AuthorName = styled.div`
    font-size: 0.85rem;
    margin-bottom: 0.3rem;
    font-weight: 600;
    color: ${({color}) => color};
    display: ${({isVisible}) => (isVisible ? "block" : "none")};
`;

const MessageBody = styled.div`
    ${FlexMarginBetween(0.5)};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const MessageAvatar = styled(Avatar)`
    min-width: 1.3rem;
    max-width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.7rem;
    display: ${({display}) => display};
    visibility: ${({visibility}) => visibility};
`;

const MessageDate = styled.div`
    font-family: Almarai, OpenSans, sans-serif;
    font-size: 0.7rem;
    color: ${({isOwnMessage}) => (isOwnMessage ? "var(--light-blue)" : "#CBD1D6")};
`;

const Text = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    word-break: break-word;
    color: ${({isOwnMessage}) => (isOwnMessage ? "var(--white)" : "#5c6977")};
`;

const FilesContainer = styled.div`
    display: ${({isVisible}) => (isVisible ? "flex" : "none")};
    flex-wrap: wrap;
    margin-top: 0.5rem;
`;

const OptionsButton = styled.div`
    cursor: pointer;
    margin: 0 0.3rem 0.3rem;
    svg {
        fill: #93aec5;
        width: 1rem;
        height: 1rem;
    }
`;

const authorNameColorList = ["#26c6aa", "#363636", "#977298", "#2654c6", "#f5af26", "#c62659"];
class Message extends PureComponent {
    getAuthorNameColor(author) {
        return authorNameColorList[author.length % authorNameColorList.length];
    }

    renderAdditionalInfo() {
        const {t, additionalInfo} = this.props;
        if (!additionalInfo) return null;
        return (
            <AdditionalInfoContainer>
                {`${t("chatWidget.messageAdditionalInfo.questionAbout")} `}
                <Link to={`/content/${additionalInfo.id}`}>{t(`chatWidget.messageAdditionalInfo.${additionalInfo.type}`)}</Link>
            </AdditionalInfoContainer>
        );
    }

    render() {
        const {
            author,
            text,
            createdAt,
            files,
            onFileClick,
            isOwnMessage,
            t,
            isGroup,
            isLastMessageInBlock,
            isFirstMessageInBlock,
            isLastMessageInList,
            onMessageClick
        } = this.props;
        const isFilesExist = files && files.length !== 0;
        const isAvatarVisible = !isOwnMessage && !isLastMessageInBlock;
        const authorName = formatChatTitle(author, t);
        const optionsButtonVisible = onMessageClick && isGroup && isAvatarVisible;

        const messageAvatarVisibility = isAvatarVisible ? "block" : "none";
        const messageAvatarDisplay = isGroup ? "block" : "none";

        return (
            <Container isOwnMessage={isOwnMessage} sameMessageBlock={isLastMessageInBlock || isLastMessageInList}>
                <MessageAvatar src={author.userPic} display={messageAvatarDisplay} visibility={messageAvatarVisibility} />
                <Content isOwnMessage={isOwnMessage} isLastMessageInBlock={isLastMessageInBlock}>
                    <AuthorName
                        isVisible={isGroup && !isOwnMessage && isFirstMessageInBlock}
                        color={this.getAuthorNameColor(authorName)}>
                        {authorName}
                    </AuthorName>
                    {this.renderAdditionalInfo()}
                    <MessageBody>
                        <Text isOwnMessage={isOwnMessage}>{text}</Text>
                        <MessageDate isOwnMessage={isOwnMessage}>{createdAt}</MessageDate>
                    </MessageBody>
                    <FilesContainer isVisible={isFilesExist}>
                        {files.map(file => (
                            <File size="small" key={file.id} item={file} selectItem={() => onFileClick(file)} />
                        ))}
                    </FilesContainer>
                </Content>
                {optionsButtonVisible && (
                    <OptionsButton onClick={() => onMessageClick(author)}>
                        <StackedGroupIcon />
                    </OptionsButton>
                )}
            </Container>
        );
    }
}

export default Message;
