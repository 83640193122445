import React from "react";

export default props => (
    <svg viewBox="0 0 95 40" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H94V40H0z" transform="translate(.882)" />
            <g fill="#FFF" fillRule="nonzero">
                <path
                    d="M3.215 3.715c.392 0 .726.285.787.673l.625 3.964c.06.388.395.673.787.673h1.814c3.776 0 5.955 1.827 6.524 5.448.257 1.584.011 2.829-.73 3.7-.815.958-2.26 1.464-4.179 1.464H3.111c-.392 0-.726-.285-.787-.673L.006 4.266c-.046-.29.179-.551.473-.551h2.736zm3.037 8.497H5.235l.607 3.84c.036.233.237.404.472.404h.396c1.035 0 2.012 0 2.516-.59.301-.352.393-.875.279-1.598-.303-1.988-1.782-2.054-3.253-2.056zM17.556 3.46c2.444 0 3.8 1.571 3.8 1.571l-.123-.762c-.046-.292.179-.553.471-.553h2.473c.393 0 .725.285.787.673l1.483 9.394c.047.29-.177.552-.47.552h-2.745c-.234 0-.436-.171-.472-.404l-.121-.767-.192.278c-.594.862-1.92 1.15-3.242 1.15-3.033 0-5.624-2.297-6.128-5.52-.263-1.607.11-3.144 1.022-4.216.837-.985 2.033-1.396 3.457-1.396zM31.16 0c.262 0 .507.127.655.342l9.174 13.242c.22.317-.006.75-.392.75h-2.759c-.263 0-.51-.13-.66-.349l-3.803-5.604-1.613 5.385c-.101.337-.412.569-.764.569h-2.71c-.33 0-.559-.322-.454-.632l3.038-8.916L28.015.754c-.225-.317.001-.754.39-.754h2.754zM19.054 6.372c-.797 0-1.434.255-1.843.74-.406.48-.56 1.165-.43 1.928.247 1.555 1.512 2.642 3.075 2.642.78 0 1.413-.259 1.83-.748.42-.493.585-1.182.465-1.942-.265-1.568-1.51-2.62-3.097-2.62z"
                    transform="translate(.882) matrix(1 0 0 -1 10.196 29.702)"
                />
                <path
                    d="M70.58 3.715c.394 0 .727.285.788.673l2.32 14.697c.046.29-.179.552-.471.552h-2.649c-.234 0-.435-.171-.47-.403L67.743 4.266c-.046-.29.179-.551.471-.551h2.366zm-26.276 0c.273 0 .507.2.55.47l.657 4.167c.06.388.395.673.787.673h1.813c3.777 0 5.955 1.827 6.525 5.448.257 1.584.01 2.829-.732 3.7-.813.958-2.258 1.464-4.176 1.464h-5.733c-.391 0-.725-.285-.786-.673L40.891 4.266c-.046-.29.178-.551.47-.551h2.943zm2.833 8.497h-1.016l.607 3.84c.036.233.236.404.471.404h.397c1.034 0 2.011 0 2.516-.59.3-.352.392-.875.277-1.598-.302-1.988-1.78-2.054-3.252-2.056zM58.443 3.46c2.444 0 3.8 1.571 3.8 1.571l-.123-.762c-.046-.292.178-.553.473-.553h2.471c.392 0 .725.285.786.673l1.485 9.394c.045.29-.18.552-.474.552h-2.743c-.236 0-.436-.171-.471-.404l-.122-.767-.192.278c-.595.862-1.919 1.15-3.241 1.15-3.034 0-5.623-2.297-6.128-5.52-.262-1.607.11-3.144 1.022-4.216.838-.985 2.033-1.396 3.457-1.396zm1.495 2.912c-.795 0-1.434.255-1.843.74-.406.48-.558 1.165-.43 1.928.248 1.555 1.512 2.642 3.075 2.642.78 0 1.413-.259 1.83-.748.42-.493.586-1.182.465-1.942-.263-1.568-1.51-2.62-3.097-2.62z"
                    opacity=".2"
                    transform="translate(.882) matrix(1 0 0 -1 10.196 29.702)"
                />
            </g>
        </g>
    </svg>
);
