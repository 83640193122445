import React from "react";
import Modal from "./Modal";

export default props => (
    <Modal
        borderRadius={"5px"}
        style={{color: "var(--light-black)", ...props.style}}
        headerStyle={{
            padding: "1rem 1rem 0 0",
            margin: 0,
            justifyContent: "flex-start",
            fontSize: "1.5rem"
        }}
        titleStyle={{margin: 0}}
        closeIconStyle={{top: "10px"}}
        {...props}
    />
);
