import React, {Component} from "react";
import {Redirect, withRouter} from "react-router-dom";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import PageWrapper from "../PageWrapper";
import LectureDigestContext from "contexts/LecturesDigest";
import EnrollmentsContext from "contexts/Enrollments";
import LectureDigestComponent from "components/lectures/digest/LectureDigest";
import CurrentUserContext from "contexts/CurrentUser";
import PageLoadingSpinner from "components/common/PageLoadingSpinner";
import routes from "consts/routes";

const USER_ATTENDANCE_LIMIT = 10;

@withRouter
@withContextProvider(LectureDigestContext.Provider)
@withContextConsumer(LectureDigestContext.Consumer)
@withContextConsumer(EnrollmentsContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
class LecturesDigest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersPage: 1
        };
    }

    async componentDidMount() {
        const {getLectureData, getLectureReviews, getCurrentUserEnrollments} = this.props;

        const id = this.props.match.params.id;
        await getLectureData(id);
        await getLectureReviews({lectureId: id});
        await getCurrentUserEnrollments();
    }

    async loadUsersAttendance(page) {
        const {getUsersLectureAttendance} = this.props;
        const id = this.props.match.params.id;
        await getUsersLectureAttendance(id, {offset: USER_ATTENDANCE_LIMIT * (page - 1), limit: USER_ATTENDANCE_LIMIT});
        this.setState({usersPage: page});
    }

    render() {
        const {
            lecture,
            usersAttendance,
            quizzes,
            enterQuiz,
            showOverallResult,
            openedQuiz,
            setOpenedQuiz,
            showPersonalResult,
            quizzesToken,
            isLectureOwner,
            totalUsers,
            getLectureReviews,
            lectureReviews,
            getLectureData,
            currentUser,
            location,
            assignments,
            updateLectureAssignments,
            updateAssignment,
            updateAssessment,
            updateSubmission,
            assessments
        } = this.props;

        if (!currentUser) {
            return (
                <Redirect
                    to={{
                        pathname: routes.signin.path,
                        search: `?redirectFrom=${location.pathname}`
                    }}
                />
            );
        }

        const {usersPage} = this.state;
        return (
            <PageWrapper>
                {lecture ? (
                    <LectureDigestComponent
                        lecture={lecture}
                        title="Meeting"
                        quizzes={quizzes}
                        quizzesToken={quizzesToken}
                        enterQuiz={enterQuiz}
                        showOverallResult={showOverallResult}
                        showPersonalResult={showPersonalResult}
                        openedQuiz={openedQuiz}
                        setOpenedQuiz={setOpenedQuiz}
                        isLectureOwner={isLectureOwner}
                        usersAttendance={usersAttendance}
                        totalUsers={totalUsers}
                        loadAttendance={this.loadUsersAttendance.bind(this)}
                        usersPage={usersPage}
                        pageSize={USER_ATTENDANCE_LIMIT}
                        getLectureReviews={getLectureReviews}
                        lectureReviews={lectureReviews}
                        getLectureData={getLectureData.bind(null, lecture.id)}
                        assignments={assignments}
                        updateLectureAssignments={updateLectureAssignments}
                        updateAssignment={updateAssignment}
                        updateAssessment={updateAssessment}
                        updateSubmission={updateSubmission}
                        assessments={assessments}
                    />
                ) : (
                    <PageLoadingSpinner />
                )}
            </PageWrapper>
        );
    }
}

export default LecturesDigest;
