import React, {useState, useContext, useEffect, useRef} from "react";
import {withRouter} from "react-router-dom";
import Scrollbar from "react-custom-scrollbars-rtl";
import SearchIcon from "components/common/icons/Search";
import OutsideClickHandler from "react-outside-click-handler";
import {LandingContext} from "contexts/Landing";
import Menu from "components/common/icons/landing/Menu";
import Cross from "components/common/icons/Cross";
import {
    MenuList,
    MenuIconContainer,
    SearchInput,
    SearchIconContainer,
    LinkContainer,
    CloseButton,
    Gradient,
    MobileContainer,
    SearchMenuList,
    SearchResult
} from "./styles";
import SearchedItem from "./SearchedItem";
import landingHeaderLinks from "consts/landingHeaderLinks";

const FETCH_EDGE_PX = 100;

function MarketSearchMobile({history, t, scrollbarRef, ...props}) {
    const {withSearch} = props;
    const [searchValue, setSearchValue] = useState("");
    const [searchActive, setSearchActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const headerRef = useRef(null);
    const {search: searchItems, onSearch, onScrollToAnchor, onSetHeaderRef} = useContext(LandingContext);
    onSetHeaderRef(headerRef);
    const onInputChange = ({target: {value}}) => setSearchValue(value);
    useEffect(() => {
        onSearch(searchValue);
    }, [searchValue]);
    const onScroll = e => {
        const {
            target: {offsetHeight, scrollHeight, scrollTop}
        } = e;
        const offset = scrollHeight - offsetHeight - scrollTop;
        const needToFetch = offset < FETCH_EDGE_PX;
        if (needToFetch) onSearch(searchValue, true);
    };
    return (
        <MobileContainer ref={headerRef}>
            <OutsideClickHandler onOutsideClick={() => setSearchActive(false)}>
                <MenuIconContainer onClick={() => setMenuActive(true)}>
                    <Menu />
                </MenuIconContainer>
                {withSearch && (
                    <React.Fragment>
                        <SearchIconContainer onClick={() => setSearchActive(a => !a)}>
                            <SearchIcon />
                        </SearchIconContainer>
                        <SearchMenuList active={searchActive}>
                            <SearchInput
                                value={searchValue}
                                onChange={onInputChange}
                                placeholder={t("landing.header.searchPlaceholder")}
                                visible={true}
                            />
                            <SearchResult visible={true}>
                                <Scrollbar autoHeight autoHeightMax={"80vh"} onScroll={onScroll}>
                                    {searchItems.list.map(SearchedItem)}
                                </Scrollbar>
                                <Gradient />
                            </SearchResult>
                            <CloseButton onClick={() => setSearchActive(false)}>
                                <Cross />
                            </CloseButton>
                        </SearchMenuList>
                    </React.Fragment>
                )}
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={() => setMenuActive(false)}>
                <MenuList active={menuActive}>
                    {landingHeaderLinks.map((link, i) => (
                        <LinkContainer
                            key={i}
                            onClick={() =>
                                onScrollToAnchor({
                                    link,
                                    scrollbarRef,
                                    headerRef
                                })
                            }>
                            {t(link.label)}
                        </LinkContainer>
                    ))}
                    <CloseButton onClick={() => setMenuActive(false)}>
                        <Cross />
                    </CloseButton>
                </MenuList>
            </OutsideClickHandler>
        </MobileContainer>
    );
}

export default withRouter(MarketSearchMobile);
