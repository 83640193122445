import React, {Component} from "react";
import styled from "styled-components";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import UserAvatar from "./UserAvatar";

const OptionsContainer = styled.div`
    position: relative;
`;

const OptionsPanel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ${FlexMarginBetween(0.5)};
    padding: 1.25rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    position: absolute;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    right: 1rem;
`;

const ContactName = styled.div`
    margin-bottom: 0.5rem;
    color: #363636;
`;

const OptionsPanelButton = styled.div`
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.875rem;
    color: var(--red);
    &:first-child {
        margin-bottom: 1rem;
    }
`;

class BlockUserFromGroupPanel extends Component {
    render() {
        const {t, user, handlerBlock, handlerUnblock, blocked} = this.props;
        if (!user) return null;
        const blockOptionTitle = blocked ? t("chatsList.unblockUser") : t("chatsList.blockUser");
        const blockOptionFunc = blocked ? handlerUnblock : handlerBlock;
        return (
            <OptionsContainer>
                <OptionsPanel>
                    <UserAvatar src={user.userPic} blocked={blocked} size="2.5rem" />
                    <div>
                        <ContactName>{`${user.firstName} ${user.lastName}`}</ContactName>
                        <OptionsPanelButton onClick={blockOptionFunc}>{blockOptionTitle}</OptionsPanelButton>
                    </div>
                </OptionsPanel>
            </OptionsContainer>
        );
    }
}

export default BlockUserFromGroupPanel;
