import React, {Component} from "react";
import styled from "styled-components";
import {compose} from "recompose";
import {withNamespaces} from "react-i18next";
import moment from "moment";
import Button from "components/form/Button.js";
import {mediaQuery} from "components/common/styled/mediaQuery";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import EnrollmentsContext from "contexts/Enrollments";
import AssignmentSubmissionContext from "contexts/assignment/AssignmentSubmission";
import AssessmentAddEditContext from "contexts/assessment/AssessmentAddEdit";
import NetworkController from "controllers/Network";

import AssessmentInfo from "../assessment/AssessmentInfo";

const AssessmentContainer = styled.div`
    margin: 0.5rem 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    ${mediaQuery.phone`
        width: 100%;
    `}
`;

const ListContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    ${mediaQuery.phone`
        order: 2;
    `}
`;

const MainContainer = styled.div`
    padding: ${props => props.padding || "0 0 0 0"};
    margin: ${props => props.margin || "0 0 0 0"};
`;

class StudentAssessmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        const {assessment, getStudentsWork, lecture} = this.props;

        const qs = {
            assessmentId: assessment?.id,
            lectureId: lecture?.id
        };

        await getStudentsWork(assessment, qs);
    }

    render() {
        const {submittedAssessment, assessment, studentAssessments, t} = this.props;

        const isAssessmentChecked = submittedAssessment?.status == "checked";

        return (
            <AssessmentContainer>
                <ListContainer>
                    <MainContainer>
                        <AssessmentInfo assessment={assessment} studentAssessments={studentAssessments} t={t} />
                    </MainContainer>
                </ListContainer>
            </AssessmentContainer>
        );
    }
}

export default compose(
    withNamespaces("translation"),
    withContextProvider(AssessmentAddEditContext.Provider),
    withContextConsumer(AssessmentAddEditContext.Consumer),
    withContextConsumer(EnrollmentsContext.Consumer),
    withContextConsumer(AssignmentSubmissionContext.Consumer)
)(StudentAssessmentList);
