import React, {Component} from "react";
import PropsTypes from "prop-types";
import {withNamespaces} from "react-i18next";
import styled from "styled-components";
import LibraryContext from "contexts/Library";
import {withContextConsumer} from "utils/contexts";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import {mediaQuery} from "components/common/styled/mediaQuery";
import AddFilesButton from "../AddFilesButton";
import Files from "../files/Files";
import LibrarySpace from "../LibrarySpace";
import Folders from "../folders/Folders";
import AttachFilesButton from "../AttachFilesButton";

const LibraryViewHolder = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    ${FlexMarginBetween(0.5)}
    ${props => props.inSharingModal && "height: 38rem;margin-bottom: 0;"}
    ${mediaQuery.phone`
        flex-flow: column;
        > div{margin: 0 !important;}
        flex: none;
        ${props => props.inSharingModal && "height:auto"}
    `}
`;

const FoldersSection = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-flow: column;
    height: 100%;
    width: 15rem;
    ${mediaQuery.phone`width: 100%; height: auto;`}
`;

const FilesSection = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    ${mediaQuery.phone`width: 100%; height: auto;`}
`;

@withNamespaces("translation")
@withContextConsumer(LibraryContext.Consumer)
class LibraryView extends Component {
    componentDidMount() {
        if (this.props.inSharingModal) {
            this.props.setItemFilter(this.props.filterType);
        }
    }

    render() {
        const {
            t,
            i18n,
            meta,
            items,
            selectedItems,
            isIndicatorOpen,
            itemFilter,
            setItemFilter,
            gotItems,
            itemsMode,
            setItemsMode,
            onSelectFolder,
            expendedFolderIds,
            toggleExpended,
            canDeleteFolder,
            selectItem,
            unselectItem,
            folderTree,
            extraFolderTrees,
            selectedFolderId,
            itemSort,
            setItemSort,
            inSharingModal,
            onInputFiles,
            onAttachFiles,
            total,
            totalAvailable,
            percentage,
            isSingleItemPickerMode,
            currentUser,
            originalItems,
            findFolder,
            viewOnly,
            inCourseAttachment,
            addedItems,
            onAttach,
            hideAddButton
        } = this.props;

        const setDeleteFolderModalIsOpen = this.props.setDeleteFolderModalIsOpen || (() => null);
        const setCreateFolderIsOpen = this.props.setCreateFolderIsOpen || (() => null);
        const setEditItemsModalIsOpen = this.props.setEditItemsModalIsOpen || (() => null);
        const setDeleteItemsModalIsOpen = this.props.setDeleteItemsModalIsOpen || (() => null);
        const setMoveItemsModalIsOpen = this.props.setMoveItemsModalIsOpen || (() => null);
        return (
            <LibraryViewHolder dir={i18n.dir()} inSharingModal={inSharingModal}>
                <FoldersSection>
                    {isIndicatorOpen && (
                        <LibrarySpace t={t} total={total} totalAvailable={totalAvailable} percentage={percentage} />
                    )}
                    <Folders
                        t={t}
                        folderTree={folderTree}
                        selectedFolderId={selectedFolderId}
                        onSelectFolder={onSelectFolder}
                        expendedFolderIds={expendedFolderIds}
                        toggleExpended={toggleExpended}
                        canDeleteFolder={canDeleteFolder}
                        onDeleteFolder={setDeleteFolderModalIsOpen.bind(null, true)}
                        inSharingModal={inSharingModal}
                        extraFolderTrees={extraFolderTrees}
                        findFolder={findFolder}
                    />

                    {/* {viewOnly ? null : (
                        <AddFilesButton
                            t={t}
                            meta={meta}
                            inSharingModal={inSharingModal}
                            onInputFiles={onInputFiles}
                            invisible={isSingleItemPickerMode}
                            hideOnPhoneResolution
                        />
                    )} */}
                </FoldersSection>
                <FilesSection>
                    <Files
                        t={t}
                        i18n={i18n}
                        inSharingModal={inSharingModal}
                        itemsMode={itemsMode}
                        setItemsMode={setItemsMode}
                        gotItems={gotItems}
                        items={items}
                        selectedItems={selectedItems}
                        selectItem={selectItem}
                        unselectItem={unselectItem}
                        itemFilter={itemFilter}
                        setItemFilter={setItemFilter}
                        itemSort={itemSort}
                        setItemSort={setItemSort}
                        onCreateNewFolder={setCreateFolderIsOpen.bind(this, true)}
                        onEditItems={setEditItemsModalIsOpen.bind(this, true)}
                        onDeleteItems={setDeleteItemsModalIsOpen.bind(this, true)}
                        onMoveItems={setMoveItemsModalIsOpen.bind(this, true)}
                        onSelectFolder={onSelectFolder}
                        currentUser={currentUser}
                        originalItems={originalItems}
                        viewOnly={viewOnly}
                        inCourseAttachment={inCourseAttachment}
                        onAttach={onAttach}
                        meta={meta}
                        onInputFiles={onInputFiles}
                        invisible={isSingleItemPickerMode}
                        hideAddButton={hideAddButton}
                        hideOnPhoneResolution
                    />
                    {viewOnly ? null : <AttachFilesButton t={t} onAttachFiles={onAttachFiles} hideOnPhoneResolution />}
                </FilesSection>
            </LibraryViewHolder>
        );
    }
}

LibraryView.defaultProps = {
    viewOnly: false
};

LibraryView.propsTypes = {
    viewOnly: PropsTypes.bool
};

export default LibraryView;
