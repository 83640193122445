import React from "react";
import {getUserTime} from "utils/time";
import styled from "styled-components";
import {mediaQuery} from "../../../common/styled/mediaQuery";
import Input from "components/form/Input";
import Button from "components/form/Button";

const InfoContainer = styled.div`
    justify-content: center;
`;

const MainAssessmentInfoContainer = styled.div`
    justify-content: center;
`;

const Title = styled.div`
    font-weight: bold;
    min-width: 0;
    word-break: break-word;
    height: fit-content;
    ${mediaQuery.phone`
        padding: 0.3 0.7;
    `}
`;

const StudentAssignmentInfoContainer = styled.div`
    justify-content: center;
`;

const GradesContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const Space = styled.div`
    padding: 0.2rem;
    margin: 0.2rem;
`;

const ShowStudentAssessment = (studentAssessment, assessment, t) => {
    return (
        <>
            <StudentAssignmentInfoContainer>
                <Title>{t("lecture.digest.checkAssignment.workhistory")}</Title>

                <GradesContainer>
                    <h5>{t("lecture.digest.checkAssignment.status")}:</h5>
                    <p style={{float: "left"}}>{studentAssessment?.submissionStatus}</p>
                </GradesContainer>
                <GradesContainer>
                    <h5>Obtained Marks:</h5>
                    <p style={{float: "left", padding: "10px"}}>{studentAssessment.results?.obtainScores}</p>

                    <h5>{t("lecture.digest.checkAssignment.totalScores")}:</h5>
                    <p style={{float: "right", padding: "10px"}}>{assessment.totalScores}</p>
                    {/* <p style={{ marginLeft: 8 }}> */}
                </GradesContainer>
            </StudentAssignmentInfoContainer>
        </>
    );
};

function AssessmentInfo(props) {
    const {assessment, studentAssessments, t} = props;
    let ShowStudentAssessmentComponent = null;
    if (studentAssessments && studentAssessments.length > 0) {
        ShowStudentAssessmentComponent = studentAssessments.map(studentAssessment =>
            ShowStudentAssessment(studentAssessment, assessment, t)
        );
    }

    return (
        <MainAssessmentInfoContainer>
            <InfoContainer>
                <Title>{assessment.name}</Title>
                <p>{assessment.description}</p>
                <p>
                    {" "}
                    {t("lecture.digest.assessmentsList.totalScores")}:{assessment.totalScores}
                </p>
                {/* <p>
                    {t("lecture.digest.assessmentsList.status")}:{assessment.status}
                </p> */}
                {/* <p>Attached: {getUserTime(assessment.availableFrom)}</p> */}

                {/* <p>Due Date: {getUserTime(assessment.dueDate)}</p> */}
            </InfoContainer>
            {ShowStudentAssessmentComponent}
            {/* <FileContainer>
                {assessment.attachments.map(attachment => {
                    return (
                        <File>
                            <div>{attachment.name}</div>
                            <DownloadButton target={"_blank"} href={attachment.url} download={attachment.name}>
                                Download
                            </DownloadButton>
                        </File>
                    );
                })}
            </FileContainer> */}
        </MainAssessmentInfoContainer>
    );
}

export default AssessmentInfo;
