import React, {useState} from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";
import FlexCenter from "components/common/styled/FlexCenter";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import GlobeIcon from "components/common/icons/landing/GlobeIcon";
import UpDownIcon from "components/common/icons/landing/UpDownArrow";

const DropdownMenu = styled.div`
    display: flex;
    position: relative;
    height: 2.5rem;
    width: ${p => (p.isLanding ? "9rem" : "4rem")};
    justify-content: center;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.6px;
    text-align: center;
    color: ${p => (p.opened ? "var(--black);" : "var(--white);")};
    margin: 0 1rem;

    ${mediaQuery.phone`
         width: 3rem;
    `}
`;

const WrapperLang = styled.div`
    position: absolute;
    z-index: 10;
    border-radius: 6px;
    background-color: ${p => p.backgroundColor || "rgba(255, 255, 255, 0.2)"};
    box-shadow: unset;
    ${props =>
        props.above &&
        `
    bottom: 100%;
    `}

    ${p =>
        p.opened &&
        `
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background-color: var(--white);
    `}
`;

const SelectedLanguage = styled.div`
    display: flex;
    align-items: center;
    height: 2.5rem;
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    padding: 0 0.5rem;
`;

const LanguageOptions = styled.div`
    display: flex;
    flex-direction: column;
    height: ${p => (p.opened ? "auto" : "0")};
    overflow: hidden;
    border-radius: 0.25rem;
`;

const LanguageOption = styled.div`
    ${FlexCenter};
    height: 100%;
    width: 100%;
    cursor: pointer;
    padding: 0.3rem 0;

    :hover {
        background-color: ${p => p.hoverColor || "rgba(150, 150, 150, 0.2)"};
    }
`;

const languages = {
    en: "En",
    "ru-RU": "Ru",
    "ar-SA": "Ar",
    tr: "Tr",
    so: "So",
    fr: "Fr"
};

function LanguageDropDownContainer({i18n, isLanding, wrapperStyle, listStyle, above}) {
    const [opened, setOpened] = useState(false);
    const selectLanguage = language => {
        i18n.changeLanguage(language);
        setOpened(false);
    };

    const globeIconStyle = {margin: "0 0.2rem"};

    return (
        <OutsideClickHandler onOutsideClick={() => setOpened(false)}>
            <DropdownMenu opened={opened} isLanding={isLanding}>
                <WrapperLang above={above} opened={opened} {...wrapperStyle}>
                    <SelectedLanguage id="languageDropDown" onClick={() => setOpened(opened => !opened)}>
                        <GlobeIcon fillColor={opened && "#000"} width={21} height={21} opacity={0.65} style={globeIconStyle} />
                        {languages[i18n.language] || "En"}
                        <UpDownIcon fillColor={opened && "#000"} width={21} height={21} opacity={0.65} />
                    </SelectedLanguage>

                    <LanguageOptions opened={opened} dir={i18n.dir()}>
                        {Object.keys(languages)
                            .filter(l => l !== i18n.language)
                            .map((lang, i) => (
                                <LanguageOption
                                    id={`languageDropDownOption-${i}`}
                                    onClick={selectLanguage.bind(null, lang)}
                                    key={i}
                                    {...listStyle}>
                                    {languages[lang]}
                                </LanguageOption>
                            ))}
                    </LanguageOptions>
                </WrapperLang>
            </DropdownMenu>
        </OutsideClickHandler>
    );
}

export default LanguageDropDownContainer;
