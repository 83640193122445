import throttle from "lodash/throttle";
import React, {Component} from "react";
import resolutions from "../consts/resolutions";

export const WindowRectContext = React.createContext("windowRect");

class WindowRectProvider extends Component {
    constructor(props) {
        super(props);
        this.state = this.getWindowState();

        this.onResizeListener = throttle(() => setTimeout(() => this.onResize(), 0), 300);
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResizeListener);
        window.addEventListener("orientationchange", this.onResizeListener);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeListener);
        window.removeEventListener("orientationchange", this.onResizeListener);
    }

    getWindowState() {
        const isPhoneResolution = window.innerWidth <= resolutions.phone;
        const isDesktopResolution = !isPhoneResolution;
        return {
            windowRect: {height: window.innerHeight, width: window.innerWidth},
            isPhoneResolution,
            isDesktopResolution
        };
    }

    onResize() {
        this.setState(this.getWindowState());
    }

    render() {
        return <WindowRectContext.Provider value={this.state}>{this.props.children}</WindowRectContext.Provider>;
    }
}

export default {Provider: WindowRectProvider, Consumer: WindowRectContext.Consumer};
