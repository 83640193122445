import React, {Component} from "react";
import styled from "styled-components";
import Spinner from "components/common/Spinner";

function getButtonColor(type) {
    switch (type) {
        case "default":
            return "#9E9E9E";
        case "light-gray":
            return "#EEF2F5";
        case "danger":
            return "#f83a3a";
        case "action":
            return "var(--main-theme-color)";
        case "cancel":
            return "transparent";
        case "light":
            return "#f5f5f5";
        case "light-blue":
            return "#6aa8e6";
        case "white":
            return "var(--white)";
        case "blue":
            return "var(--main-theme-color)";
        case "green":
            return "#289005";
        case "light-black":
            return "var(--light-black)";
        default:
            return "var(--main-theme-color)";
    }
}

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${props => props.width || "10rem"};
    min-width: ${props => props.minWidth || "unset"};
    height: ${props => props.height || "2.5rem"};
    font-size: ${props => props.fontSize || "1rem"};
    font-weight: ${props => props.fontWeight || "normal"};
    padding: ${props => props.padding || "0 0 0 0"};
    margin: ${props => props.margin || "0 0 0 0"};

    border-radius: ${props => props.borderRadius || "5px"};
    background-color: ${props => getButtonColor(props.type)};
    color: ${props =>
        props.color ||
        (props.type === "light" || props.type === "white" || props.type === "light-gray"
            ? "var(--main-theme-color)"
            : "var(--white)")};
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    pointer-events: ${props => (props.disabled ? "none" : "all")};
    ${props => props.disabled && "opacity: 0.5;"}

    user-select: none;
    text-align: center;
`;

class Button extends Component {
    render() {
        const {loading, children, ...rest} = this.props;
        return <Container {...rest}>{loading ? <Spinner height="1.1rem" /> : children}</Container>;
    }
}

export default Button;
