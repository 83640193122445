import React, {Component} from "react";
import styled from "styled-components";
import Button from "../form/Button";

const ControlsContainer = styled.div`
    flex: 0.8;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const defaultButtonsStyle = {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: "0.8px",
    height: "3rem",
    margin: "0.3rem"
};

class AcceptDeclineButtonsGroup extends Component {
    getCancelStyle(type) {
        switch (type) {
            case "danger":
                return "action";
            case "save":
                return "danger";
            case "confirm":
            default:
                return "light";
        }
    }

    getConfirmStyle(type) {
        switch (type) {
            case "danger":
                return "danger";
            case "save":
            case "confirm":
            default:
                return "action";
        }
    }

    render() {
        const {
            style,
            confirmTitle,
            cancelTitle,
            controlButtonsStyle,
            onConfirmClick,
            onCancelClick,
            type,
            acceptDisabled,
            idPrefix
        } = this.props;

        const confirmButtonStyle = {...defaultButtonsStyle, ...controlButtonsStyle};
        const cancelButtonStyle = {...defaultButtonsStyle, ...controlButtonsStyle};
        return (
            <ControlsContainer style={style}>
                <Button
                    id={idPrefix && `${idPrefix}CancelButton`}
                    style={cancelButtonStyle}
                    onClick={onCancelClick}
                    type={this.getCancelStyle(type)}
                >
                    {cancelTitle}
                </Button>
                <Button
                    id={idPrefix && `${idPrefix}ConfirmButton`}
                    style={confirmButtonStyle}
                    onClick={onConfirmClick}
                    type={this.getConfirmStyle(type)}
                    disabled={acceptDisabled}>
                    {confirmTitle}
                </Button>
            </ControlsContainer>
        );
    }
}

export default AcceptDeclineButtonsGroup;
