import React, {Component} from "react";
import styled from "styled-components";
import HeaderContent from "./HeaderContent";
import HeaderWrapper from "./HeaderWrapper";
import Logo from "./Logo";
import MainNavigationLinks from "./MainNavigationLinks";
import ProfileDropdown from "./ProfileDropdown";
import MobileMenu from "./MobileMenu";
import ChatNotifications from "components/common/icons/notifications/ChatNotifications";
import GlobalNotifications from "components/common/icons/notifications/GlobalNotifications";
import EnrollmentsContext from "contexts/Enrollments";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import NotificationsContext from "contexts/Notifications";
import PopUpNotification from "components/notification/PopUp/PopUpNotification";
import notificationsType from "consts/notificationsType";
import userTypes from "consts/userTypes";
import {withRouter} from "react-router-dom";
import PopUpChatNotification from "../notification/PopUp/PopUpChatNotification";
import FileUploadProgress from "components/fileUploadProgress/FileUploadProgress";
import MetaTags from "components/common/metatags/MetaTags";
import LanguageDropDownContainer from "components/common/LanguageDropdown";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import Sidebar from "components/common/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";

const NOTIFICATION_TYPES = {
    chatNotifications: "isOpenChatNotifications",
    allNotifications: "isOpenGlobalNotifications"
};

const LeftContainerWithLogo = styled.div`
    display: flex;
    align-items: center;
    min-width: 37rem;

    ${mediaQuery.phone`
        min-width: unset;
    `}
`;

const RightContainerWithNotifications = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    ${p =>
        p.dir === "ltr"
            ? `
        margin-left: auto;
        margin-right: 0;
    `
            : `
        margin-left: 0;
        margin-right: auto;
    `}

    ${mediaQuery.phone`
        ${p =>
            p.dir === "ltr"
                ? `
            margin-right: 2rem;
        `
                : `
            margin-left: 2rem;
        `}
    `}

    width: 8rem;

    svg {
        height: 1.2rem;
        cursor: pointer;
    }
`;

const ChatNotificationsContainer = styled.div`
    position: relative;
`;

const ChatNotificationCount = styled.div`
    position: absolute;
    width: 1.125rem;
    height: 1.125rem;
    background: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: -0.25rem;
    right: -0.125rem;
    font-size: 0.75rem;
`;

const ProfileAndLang = styled.div`
    display: flex;
    align-items: center;
`;

const TopRightCorner = styled.div`
    display: flex;
`;

const MenuIconContainer = styled.span`
    display: none;

    align-self: center;

    @media (max-width: 1220px) and (min-width: 1033px) {
        display: inline;
    }
`;

@withRouter
@withContextConsumer(NotificationsContext.Consumer)
@withContextProvider(EnrollmentsContext.Provider)
@withContextConsumer(EnrollmentsContext.Consumer)
@withNamespaces("translation")
class Registered extends Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.handlerOpenNotifications = this.handlerOpenNotifications.bind(this);
        this.handlerChatIconClick = this.handlerChatIconClick.bind(this);
        this.openSidebar = this.openSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
    }

    componentDidMount() {
        // const mediaListener = window.matchMedia("(max-width: 1220px) and (min-width:1033px)");
        // const mediaListener2 = window.matchMedia("(min-width: 1221px)");
        // mediaListener.addEventListener("change", this.closeSidebar); // Attach listener function on state changes
        // mediaListener2.addEventListener("change", this.openSidebar); // Attach listener function on state changes
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.enrollmentsTabIsVisited && this.props.history.location.hash === "#enrollments") {
            this.setState({enrollmentsTabIsVisited: true});
        }
    }

    initState() {
        return {
            isOpenGlobalNotifications: false,
            isOpenChatNotifications: false,
            chatNotificationCount: 0,
            enrollmentsTabIsVisited: false,
            toggleSidebar: false
        };
    }

    openSidebar() {
        this.setState({toggleSidebar: true});
    }

    closeSidebar() {
        this.setState({toggleSidebar: false});
    }

    setViewedGroupOfNotifications(notifications) {
        const {setViewed} = this.props;
        const identificators = notifications.filter(notification => !notification.isViewed).map(u => u.id);

        setViewed(identificators);
    }

    handlerOpenNotifications(type) {
        const newState = this.initState();
        const isOpen = NOTIFICATION_TYPES[type];
        newState[isOpen] = true;
        this.setState(newState);
    }

    handlerCloseNotifications(type) {
        const notifications = this.props[type];
        this.setState(this.initState());
        this.setViewedGroupOfNotifications(notifications.headerNotifications);
    }

    handlerChatIconClick() {
        this.props.history.push("/chat/");
    }

    handleToggleSidebar() {
        this.setState(prevState => {
            return {toggleSidebar: !prevState.toggleSidebar};
        });
    }

    render() {
        const {
            history,
            currentUser,
            logout,
            allNotifications,
            newGlobalNotification,
            newChatNotification,
            clearAllByType,
            clearByTypeAndIds,
            chatNotifications,
            enrollRequestsCount,
            i18n
        } = this.props;

        const {enrollmentsTabIsVisited, toggleSidebar} = this.state;

        const isAdmin = currentUser.role === userTypes.admin;
        const isTeacher = currentUser.role === userTypes.teacher;
        const isPrincipal = currentUser.role === userTypes.principal;
        const {totalNotifications} = chatNotifications;

        const enrollmentsCourseTab = history.location.hash === "#enrollments";
        const myCoursesNotification = isTeacher && !!enrollRequestsCount && !enrollmentsTabIsVisited && !enrollmentsCourseTab;

        const isSchoolTeacher = isTeacher && currentUser.isSchoolAttendee;

        return (
            <>
                {/* <Sidebar /> */}
                <HeaderWrapper>
                    <MetaTags path={"/"} />
                    <HeaderContent>
                        <LeftContainerWithLogo>
                            <Logo />

                            {/* <MainNavigationLinks currentUser={currentUser} myCoursesNotification={myCoursesNotification} /> */}
                            <Sidebar
                                toggleSidebar={toggleSidebar}
                                currentUser={currentUser}
                                logout={logout}
                                i18n={i18n}
                                dir={i18n.dir()}
                            />
                        </LeftContainerWithLogo>

                        <TopRightCorner>
                            <MenuIconContainer>
                                <MenuIcon onClick={this.handleToggleSidebar.bind(this)} />
                            </MenuIconContainer>
                            {!isAdmin && (
                                <RightContainerWithNotifications dir={i18n.dir()}>
                                    <FileUploadProgress />
                                    <ChatNotificationsContainer id="registeredHeaderChatIcon" onClick={this.handlerChatIconClick}>
                                        <PopUpChatNotification newNotification={newChatNotification}>
                                            <ChatNotifications />
                                            {totalNotifications ? (
                                                <ChatNotificationCount>{totalNotifications}</ChatNotificationCount>
                                            ) : null}
                                        </PopUpChatNotification>
                                    </ChatNotificationsContainer>
                                    <PopUpNotification
                                        handlerOpen={this.handlerOpenNotifications.bind(this)}
                                        handlerClose={this.handlerCloseNotifications.bind(this)}
                                        isOpen={this.state.isOpenGlobalNotifications}
                                        headerNotifications={allNotifications.headerNotifications}
                                        newNotification={newGlobalNotification}
                                        clearAllByType={clearAllByType}
                                        clearByTypeAndIds={clearByTypeAndIds}
                                        type={notificationsType.allNotifications}
                                        arrow={i18n.dir() === "ltr" ? "end" : "absoluteStart"}>
                                        <GlobalNotifications
                                            active={allNotifications.headerNotifications.some(u => !u.isViewed)}
                                        />
                                    </PopUpNotification>
                                </RightContainerWithNotifications>
                            )}
                            <ProfileAndLang>
                                <ProfileDropdown id="registeredHeaderProfileDropdown" user={currentUser} logout={logout} />
                                {/* <LanguageDropDownContainer
                                    i18n={i18n}
                                    wrapperStyle={{backgroundColor: ""}}
                                    listStyle={{hoverColor: "rgba(100,100,100,0.1)"}}
                                /> */}
                            </ProfileAndLang>
                            <MobileMenu currentUser={currentUser} logout={logout} myCoursesNotification={myCoursesNotification} />
                        </TopRightCorner>
                    </HeaderContent>
                </HeaderWrapper>
            </>
        );
    }
}

export default Registered;
