import React from "react";

export default props => (
    <svg viewBox="0 0 16 16" {...props}>
        <defs>
            <path
                id="aListActive"
                d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.398 6.398 0 0 1 1.6 8c0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 3.536-2.864 6.4-6.4 6.4z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path stroke="var(--main-theme-color)" strokeWidth="2" d="M4.56 8.241L7.113 10.8l4.647-5.6" />
            <use fill="var(--main-theme-color)" fillRule="nonzero" xlinkHref="#aListActive" />
        </g>
    </svg>
);
