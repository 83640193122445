import React, {Component} from "react";
import styled from "styled-components";
import WindowRectContext from "contexts/WindowRect";
import {withContextConsumer} from "utils/contexts";
import Header from "./Header";
import FilesGrid from "./FilesGrid/FilesGrid";
import FilesList from "./FilesList/FilesList";
import userTypes from "consts/userTypes";

const FilesContainer = styled.div`
    flex: 1;
    min-width: 0;
    display: flex;
    flex-flow: column;
`;

@withContextConsumer(WindowRectContext.Consumer)
class Files extends Component {
    renderFileGrid() {
        const {itemsMode, ...rest} = this.props;
        return (
            <React.Fragment>
                {itemsMode === "grid" && <FilesGrid {...rest} />}
                {itemsMode === "list" && <FilesList {...rest} />}
            </React.Fragment>
        );
    }

    checkIfExtraneousSelected() {
        const {currentUser, originalItems, selectedItems} = this.props;

        if (currentUser.role === userTypes.principal) {
            return false;
        }

        return selectedItems.some(id => {
            const item = originalItems.find(o => o.id === id);
            return item.isExtraneous;
        });
    }

    render() {
        const {
            t,
            i18n,
            selectedItems,
            gotItems,
            onCreateNewFolder,
            itemsMode,
            setItemsMode,
            onMoveItems,
            onEditItems,
            onDeleteItems,
            itemFilter,
            setItemFilter,
            itemSort,
            setItemSort,
            inSharingModal,
            viewOnly,
            inCourseAttachment,
            onAttach,
            onInputFiles,
            meta,
            invisible,
            hideAddButton,
            hideOnPhoneResolution
        } = this.props;

        const showEditItemsControls = selectedItems.length > 0;

        const hasExtraneousSelected = this.checkIfExtraneousSelected();

        return (
            <FilesContainer>
                <Header
                    t={t}
                    i18n={i18n}
                    inSharingModal={inSharingModal}
                    itemsMode={itemsMode}
                    onEditItems={onEditItems}
                    setItemsMode={setItemsMode}
                    onMoveItems={onMoveItems}
                    onCreateNewFolder={onCreateNewFolder}
                    onDeleteItems={onDeleteItems}
                    showEditItemsControls={showEditItemsControls}
                    itemFilter={itemFilter}
                    setItemFilter={setItemFilter}
                    itemSort={itemSort}
                    setItemSort={setItemSort}
                    hasExtraneousSelected={hasExtraneousSelected}
                    viewOnly={viewOnly}
                    inCourseAttachment={inCourseAttachment}
                    onAttach={onAttach}
                    meta={meta}
                    onInputFiles={onInputFiles}
                    invisible={invisible}
                    hideAddButton={hideAddButton}
                    hideOnPhoneResolution
                />
                {gotItems && this.renderFileGrid()}
            </FilesContainer>
        );
    }
}

export default Files;
