import React, {Component} from "react";
import styled from "styled-components";
import formatChatTitle from "utils/formatChatTitle";
import {withNamespaces} from "react-i18next";
import MessageInput from "./MessageInput";
import MessageList from "../chat/MessageList";
import CrossIcon from "components/common/icons/chatWidget/Cross";
import {HeaderButton} from "./styled";
import userTypes from "consts/userTypes";
import SupportIcon from "components/common/icons/chatWidget/Support";
import ConfirmClearMessages from "../chat/ConfirmClearMessages";
import ChatOptionsPanel from "./ChatOptionsPanel";
import StackedGroupIcon from "components/common/icons/chat/StackedGroup";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import BlockUserFromGroupPanel from "./BlockUserFromGroupPanel";
import OutsideClickHandler from "react-outside-click-handler";
import UserAvatar from "./UserAvatar";

const Container = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 6px 34px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--white);
    ${props => (props.dir === "rtl" ? "margin-left" : "margin-right")}: 1rem;
`;

const Delimiter = styled.div`
    height: 1px;
    background: #f5f5f5;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 0.9rem 1.2rem;
`;

const MainContainer = styled.div`
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
`;

const Name = styled.div`
    line-height: 1rem;
    max-height: 2rem;
    display: inline-block;
    font-size: 0.875rem;
    color: #5c6977;
    margin: 0 0.7rem;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
`;

const ActionContainer = styled.div`
    display: flex;
    ${FlexMarginBetween(0.3)};
`;

const MessagesContainer = styled.div`
    height: 50vh;
    display: flex;
    flex-direction: column;
`;

@withNamespaces("translation")
class ChatItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsPanelIsOpen: false,
            confirmClearMessagesIsOpen: false,
            userForBlockInGroup: null
        };
    }

    toggleDialogOptionOpenClick() {
        this.setState({optionsPanelIsOpen: !this.state.optionsPanelIsOpen});
    }

    closeDialogOptionOpenClick() {
        this.setState({optionsPanelIsOpen: false});
    }

    toggleClearMessagesConfirmation() {
        this.setState({confirmClearMessagesIsOpen: !this.state.confirmClearMessagesIsOpen});
    }

    async handleClearMessagesConfirm() {
        const {dialogState, clearMessages} = this.props;
        this.toggleDialogOptionOpenClick();
        this.toggleClearMessagesConfirmation();
        await clearMessages(dialogState.dialogId);
    }

    handleLoadNextMessages() {
        const {messages, dialogId} = this.props.dialogState;
        if (messages && messages.length !== 0) {
            const lastMessage = messages[messages.length - 1];
            this.props.loadNextMessages(dialogId, lastMessage.createdAt);
        }
    }

    renderClearConfirmModal() {
        const {t} = this.props;
        const {confirmClearMessagesIsOpen} = this.state;
        return (
            <React.Fragment>
                <ConfirmClearMessages
                    isOpen={confirmClearMessagesIsOpen}
                    t={t}
                    onConfirm={this.handleClearMessagesConfirm.bind(this)}
                    onRequestClose={this.toggleClearMessagesConfirmation.bind(this)}
                />
            </React.Fragment>
        );
    }

    renderHeader() {
        const {t, dialogState, onClose, blockUser, unblockUser} = this.props;
        const {optionsPanelIsOpen} = this.state;
        const {user, group, blockState} = dialogState;
        const isAdmin = user.role === userTypes.admin;
        const dialogOptionsIsVisible = !group && !isAdmin;
        const blocked = dialogState.blockState && dialogState.blockState.contactBlocked;

        return (
            <Header>
                {this.renderClearConfirmModal()}
                <MainContainer>
                    {isAdmin ? <SupportIcon /> : <UserAvatar src={user.userPic} blocked={blocked} size="1.8rem" />}
                    <Name>{formatChatTitle(user, t, dialogState)}</Name>
                </MainContainer>
                <OutsideClickHandler onOutsideClick={this.closeDialogOptionOpenClick.bind(this)}>
                    <ChatOptionsPanel
                        t={t}
                        isOpen={optionsPanelIsOpen}
                        blocked={blockState && blockState.contactBlocked}
                        handlerBlock={() => blockUser(dialogState)}
                        handlerUnblock={() => unblockUser(dialogState)}
                        handlerClearMessages={this.toggleClearMessagesConfirmation.bind(this)}
                    />
                </OutsideClickHandler>
                <ActionContainer>
                    {dialogOptionsIsVisible && (
                        <HeaderButton
                            height="1rem"
                            transform="rotate(90deg)"
                            onClick={this.toggleDialogOptionOpenClick.bind(this)}>
                            <StackedGroupIcon />
                        </HeaderButton>
                    )}
                    <HeaderButton width="1.2rem" onClick={onClose}>
                        <CrossIcon />
                    </HeaderButton>
                </ActionContainer>
            </Header>
        );
    }

    handlerMessageClick(messageAuthor) {
        this.setState({userForBlockInGroup: messageAuthor});
    }

    handlerBlockUserFromGroupPanelClose() {
        this.setState({userForBlockInGroup: null});
    }

    render() {
        const {
            t,
            currentUser,
            meta,
            dialogState,
            additionalInfo,
            sendMessage,
            blockUserGroup,
            unblockUserGroup,
            i18n
        } = this.props;
        const {user, blockState, group, parent, blockedUsers} = dialogState;
        const isGroup = group || parent;
        const {userForBlockInGroup} = this.state;
        const dir = i18n.dir();
        const blocked = userForBlockInGroup && dialogState.blockedUsers.includes(userForBlockInGroup.id);
        const {isDeleted, role} = user;

        const blockStudentsFromChatting =
            (currentUser.role === userTypes.student || currentUser.role === userTypes.child) &&
            (user.role === userTypes.student || user.role === userTypes.child);

        const blockFromChatting =
            (currentUser.blockedFromChat && !(role === userTypes.admin || role === userTypes.parent)) ||
            blockStudentsFromChatting;
        const inputBlocked =
            blockFromChatting ||
            isDeleted ||
            (blockState && blockState.userBlocked) ||
            (blockedUsers && blockedUsers.includes(currentUser.id));
        const isCurrentUserGroupOwner = currentUser.id === user.owner;
        return (
            <Container dir={dir}>
                {this.renderHeader()}
                <Delimiter />
                <MessagesContainer>
                    <OutsideClickHandler onOutsideClick={this.handlerBlockUserFromGroupPanelClose.bind(this)}>
                        <BlockUserFromGroupPanel
                            t={t}
                            user={userForBlockInGroup}
                            blocked={blocked}
                            handlerBlock={() => blockUserGroup(userForBlockInGroup, dialogState)}
                            handlerUnblock={() => unblockUserGroup(userForBlockInGroup, dialogState)}
                        />
                    </OutsideClickHandler>
                    <MessageList
                        type="widget"
                        user={currentUser}
                        group={isGroup}
                        users={dialogState.users}
                        messages={dialogState.messages}
                        allMessagesLoaded={dialogState.allMessagesLoaded}
                        loadNextMessages={this.handleLoadNextMessages.bind(this)}
                        downloadFile={this.props.downloadFile}
                        onMessageClick={isCurrentUserGroupOwner && this.handlerMessageClick.bind(this)}
                        t={t}
                    />
                    <MessageInput
                        t={t}
                        blocked={inputBlocked}
                        dialogState={dialogState}
                        onSubmit={sendMessage}
                        meta={meta}
                        userRole={currentUser.role}
                        additionalInfo={additionalInfo}
                    />
                </MessagesContainer>
            </Container>
        );
    }
}

export default ChatItem;
