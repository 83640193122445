import React from "react";

export default props => (
    <svg viewBox="0 0 30 18" {...props}>
        <g fill="#FFF" fillRule="evenodd">
            <rect width="20" height="2" x="10" rx="1" />
            <rect width="30" height="2" y="8" rx="1" />
            <rect width="20" height="2" x="10" y="16" rx="1" />
        </g>
    </svg>
);
