import styled from "styled-components";
export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: ${props => (props.isPopUp ? 0.5 : 0)}rem 0;
    padding: 1rem;
    width: 100%;
`;
