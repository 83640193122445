import {sha256} from "js-sha256";

const TAWK_SRC = process.env.REACT_APP_TAWK_SRC;
const API_KEY = process.env.REACT_APP_TAWK_API_KEY;

class TawkChat {
    init = currentUser => {
        if (!TAWK_SRC) return null;
        this.start(TAWK_SRC, currentUser, this.addEmailPhone);
    };

    start = (src, currentUser, cb) => {
        if (!src) {
            throw new Error("TawkTo id is missing");
        }
        const tawkToScript = document.getElementById("tawkToScript");
        if (tawkToScript) {
            return window.Tawk_API;
        }
        const s1 = document.createElement("script");
        s1.id = "tawkToScript";
        s1.async = true;
        s1.src = src;
        s1.setAttribute("crossorigin", "*");
        const s0 = document.getElementsByTagName("script")[0];
        if (!s0 || !s0.parentNode) {
            throw new Error("DOM is missing");
        }
        s0.parentNode.insertBefore(s1, s0);

        document.body.appendChild(s0);
        document.body.appendChild(s1);

        this.checkDone(cb, currentUser);
    };

    checkDone = (callback, currentUser) => {
        if (window && window.Tawk_API && window.Tawk_API.getStatus() !== undefined) {
            callback(window.Tawk_API, currentUser);
            return;
        }
        setTimeout(() => this.checkDone(callback, currentUser), 0);
    };

    hide = () => {
        const Tawk_API = window.Tawk_API;
        Tawk_API && Tawk_API.hideWidget();
    };

    show = () => {
        const Tawk_API = window.Tawk_API;
        Tawk_API && Tawk_API.showWidget();
    };

    addEmailPhone = (Tawk_API, currentUser) => {
        if (!currentUser) return;

        const email = currentUser.email || "no@email.data";
        const userData = {
            name: `${currentUser.firstName} ${currentUser.lastName} (${currentUser.role}) ${currentUser.phoneNumber}`,
            hash: sha256.hmac(API_KEY, email),
            email
        };

        Tawk_API.setAttributes(userData, this.errorHandler);
    };

    logout = () => {
        if (!window.Tawk_API) return;
        window.Tawk_API.endChat();
    };

    errorHandler = error => {
        if (error) {
            console.error("[TAWK IO CHAT]", error);
        }
    };
}

export default new TawkChat();
