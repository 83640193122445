import React, {Component} from "react";
import styled from "styled-components";
import Paginator from "components/common/Paginator";
import {Link} from "react-router-dom";
import NetworkController from "controllers/Network";
import {isSelfMode} from "consts/courseMode";
import {isScheduleMode} from "../../../consts/lectureMode";

const Container = styled.div``;
const VideoContainer = styled.div`
    position: relative;
    padding-top: 56.25%;
    margin-top: 1rem;
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const DownloadVideoLink = styled(Link)`
    font-weight: 600;
    text-decoration: none;
    color: var(--main-theme-color);
    cursor: pointer;
`;

class Recordings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRecording: 1
        };
    }

    setActiveRecording(activeRecording) {
        this.video.pause();
        this.setState({activeRecording}, () => this.video.load());
    }

    getDownloadLink(src) {
        const params = src.split("/").slice(-2);
        const lectureId = params[0];
        const recordName = params[1];
        return `${NetworkController.getApiRoot()}/lectures/record/${lectureId}/${recordName}`;
    }

    render() {
        const {t, recordings, disableDownload, lecture} = this.props;
        const {activeRecording} = this.state;
        const isSelfLecture = isSelfMode(lecture.mode);
        const isScheduleLecture = isScheduleMode(lecture.mode);

        if (isScheduleLecture && !recordings.length) {
            return null;
        }

        let src = recordings[activeRecording - 1];

        if (isSelfLecture) {
            const videos = lecture.actions.filter(action => action.contentType === "video");
            src = videos.length > 0 ? videos[videos.length - 1].fileUrl : "";
        }

        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        const showDownloadBtn = !isChrome && !disableDownload;

        const disableDownloadRecordingProps = disableDownload
            ? {
                  controlsList: "nodownload",
                  onContextMenu: e => e.preventDefault()
              }
            : {};

        return (
            <Container>
                {showDownloadBtn && (
                    <DownloadVideoLink to={{pathname: this.getDownloadLink(src)}} target={"_blank"}>
                        {t("lecture.digest.downloadVideo")}
                    </DownloadVideoLink>
                )}
                <VideoContainer>
                    <video
                        ref={ref => (this.video = ref)}
                        key={src}
                        src={src}
                        playsInline
                        controls
                        {...disableDownloadRecordingProps}
                    />
                </VideoContainer>
                {recordings.length > 1 && (
                    <Paginator
                        pageSize={1}
                        current={activeRecording}
                        total={recordings.length}
                        onChange={this.setActiveRecording.bind(this)}
                    />
                )}
            </Container>
        );
    }
}

export default Recordings;
