import React, {Component} from "react";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";
import FileUploadContext from "../common/FileUploadProgress";
import {withContextConsumer} from "utils/contexts";

const AssessmentsAddEditContext = React.createContext("AssessmentsAddEdit");

@withRouter
@withContextConsumer(FileUploadContext.Consumer)
class AssessmentsAddEditProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assessment: [],
            submissions: [],
            studentAssessments: [],
            propsdata: null,
            createAssessment: this.createAssessment.bind(this),
            updateAssessment: this.updateAssessment.bind(this),
            cancelSaveAssessment: this.cancelSaveAssessment.bind(this),
            getAssessment: this.getAssessment.bind(this),
            getAssessments: this.getAssessments.bind(this),
            getStudentsWork: this.getStudentsWork.bind(this),
            getSubmissions: this.getSubmissions.bind(this),
            InMeetingLecturecreateAssessment: this.InMeetingLecturecreateAssessment.bind(this),
            saveAssessment: this.saveAssessment.bind(this),
            getLectureAssessments: this.getLectureAssessments.bind(this),
            PassAssessmentAddEditData: this.PassAssessmentAddEditData.bind(this)
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state == null) return true;

        if (this.state.propsdata !== null) return false;

        return true;
    }

    async getStudentsWork(assignment, qs) {
        const {response, error} = await NetworkController.get(`/assessments/studentsWork/${assignment?._id}`, qs);
        if (error) {
        } else {
            const submittedAssessment = response.assessments ? response.assessments : [];
            return this.setState({studentAssessments: submittedAssessment});
        }
    }

    async getAssessment(assessmentId) {
        const {response} = await NetworkController.get(`/assessments/${assessmentId}`);
        return this.setState({assessment: response.assessment});
    }

    async getAssessments() {
        const {response} = await NetworkController.get(`/assessments`);

        return await this.setState({assessments: response.assessments});
    }

    async getSubmissions() {
        const {response} = await NetworkController.get(`/assessments/submissions`);
        return await this.setState({assessments: response.assessments, submissions: response.submissions});
    }

    async getLectureAssessments(lectureId) {
        let qs = {lectureId: lectureId};

        const {response, error} = await NetworkController.get(`/assessments/inlecture/${lectureId}`, qs);

        return this.setState({assessments: response.assessments, isLoading: false});
    }

    async createAssessment(assessment, files) {
        const config = {
            headers: {"content-type": "multipart/form-data"}
        };

        const {response} = await NetworkController.formData(`/assessments`, assessment, files, config);
        return this.setState({assessment: response.assessment});
        // await this.sendAssessmentData("/assessments", assessment);
    }

    async InMeetingLecturecreateAssessment(assessment, files) {
        const config = {
            headers: {"content-type": "multipart/form-data"}
        };
        const {response} = await NetworkController.formData(`/assessments`, assessment, files, config);
        this.setState({assessment: response.assessment});
        return response.assessment;
    }

    async updateAssessment(assessmentId, assessment) {
        const config = {
            headers: {"content-type": "multipart/form-data"}
        };
        const {response} = await NetworkController.formData(`/assessments/${assessmentId}`, assessment, config);
        return response;
    }

    async saveAssessment(assessmentId, assessment, files) {
        await this.UpdateAssessmentData(`/assessments/${assessmentId}`, assessment, files);
        // const config = {
        //     headers: {"content-type": "application/json"}
        // };
        // const {response} = await NetworkController.post(`/assessments/edit/${assessment.id}`, assessment, config);
        // return this.setState({assessment: response.assessment});
    }

    async sendAssessmentData(url, assessment) {
        const files = assessment.assessmentFile;

        const config = {
            headers: {"content-type": "multipart/form-data"}
        };
        const params = {url, body: assessment, files};
        const {error, response} = await NetworkController.formData("/assessments", assessment, files, config);

        if (error) {
            return error;
        } else {
            this.setState({assessment: response});
        }
        // const current = this.props.location.pathname;
        // this.props.history.replace(`/`);
        // setTimeout(() => {
        //     return this.props.history.replace(current);
        // });
        //const params = {url, body: assessment, files};
        //await this.props.uploadFilesWithProgressToApi(params);
        return this.props.history.goBack();
    }

    async UpdateAssessmentData(url, assessment, files) {
        const config = {
            headers: {"content-type": "multipart/form-data"}
        };
        // const {error, response} = await NetworkController.formData("/assessments", assessment, files, config);
        const {error, response} = await NetworkController.formData(url, assessment, files, config);
        if (error) {
            return error;
        } else {
            this.setState({assessment: response.UpdatedAssessment});
        }

        //const params = {url, body: assessment, files};
        //await this.props.uploadFilesWithProgressToApi(params);
        return this.props.history.goBack();
    }

    cancelSaveAssessment() {
        return this.props.history.goBack();
    }

    PassAssessmentAddEditData = async data => {
        // const { keyindex, lectureData, course, assessment, title } = data;
        //  this.setState({ propsdata: data })
        return this.setState({propsdata: data}, () => {
            this.props.history.push("/assessments/create");
        });

        // return (<AddEditAssessment lectureData={lectureData} course={course} />)
    };

    render() {
        return <AssessmentsAddEditContext.Provider value={this.state}>{this.props.children}</AssessmentsAddEditContext.Provider>;
    }
}

export default {
    Provider: withRouter(AssessmentsAddEditProvider),
    Consumer: AssessmentsAddEditContext.Consumer
};
