import styled from "styled-components";

export default styled.div`
    top: ${props => (props.reversePadding ? "-4px" : "4px")};
    right: ${props => (props.reversePadding ? "-4px" : "8px")};
    width: 0.675rem;
    height: 0.675rem;
    position: absolute;
    background: var(--orange);
    border-radius: 10px;
    visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

export const CourseNotificationDot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 1.125rem;
    height: 1.125rem;
    background: var(--main-theme-color);
    border-radius: 10px;
    border: 3px var(--white) solid;
    visibility: ${props => (props.visible ? "visible" : "hidden")};
`;
