import React from "react";

export default props => (
    <svg viewBox="0 0 14 2" {...props}>
        <g fill="#5C6977" fillRule="evenodd" transform="rotate(90 7 7)">
            <circle cx="1" cy="1" r="1" />
            <circle cx="1" cy="7" r="1" />
            <circle cx="1" cy="13" r="1" />
        </g>
    </svg>
);
