import React from "react";

export default props => (
    <svg width="38" height="38" viewBox="0 0 38 38" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFF"
                d="M25.864 23.855c.848 0 1.536.687 1.536 1.535 0 .848-.688 1.536-1.536 1.536H12.136c-.848 0-1.536-.688-1.536-1.536 0-.848.688-1.535 1.536-1.535h13.728zm-7.35-6.628c.848 0 1.536.688 1.536 1.536 0 .848-.688 1.535-1.536 1.535h-6.378c-.848 0-1.536-.687-1.536-1.535 0-.848.688-1.536 1.536-1.536h6.378zm7.35-6.627c.848 0 1.536.688 1.536 1.536 0 .848-.688 1.535-1.536 1.535H12.136c-.848 0-1.536-.687-1.536-1.535 0-.848.688-1.536 1.536-1.536h13.728z"
            />
        </g>
    </svg>
);
