import React from "react";

export default props => (
    <svg viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd" stroke={props.fill ? props.fill : "#757575"} strokeWidth="2" transform="translate(1 1)">
            <circle cx="4.615" cy="4.615" r="4.615" />
            <path d="M8.302 7.62l3.748 3.749" />
        </g>
    </svg>
);
