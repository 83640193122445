import React, {Component} from "react";
import styled, {css} from "styled-components";
import {withNamespaces} from "react-i18next";
import {withContextConsumer} from "utils/contexts";
import {mediaQuery} from "../common/styled/mediaQuery";
import GlobalHintsContext from "contexts/GlobalHints";
import fadeInBottom from "components/common/styled/keyframes/fadeInBottom";
import fadeOutTop from "components/common/styled/keyframes/fadeOutTop";

const HintsContainer = styled.div`
    position: fixed;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    ${mediaQuery.phone`
        bottom: 6rem;
        left: 0;
        right: 0;
        transform: none;
    `}
`;

const animation = props =>
    props.state === "shown"
        ? css`
              ${fadeInBottom} 300ms ease forwards
          `
        : css`
              ${fadeOutTop} 300ms ease forwards
          `;

const HintContainer = styled.div`
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    max-width: 20rem;
    background-color: ${props => props.background || "#313131"};
    color: var(--white);
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    user-select: none;
    pointer-events: none;
    animation: ${animation};

    ${mediaQuery.phone`
        margin: 0;
        box-shadow: none;
        max-width: none;
    `}
`;

@withNamespaces("translation")
@withContextConsumer(GlobalHintsContext.Consumer)
class GlobalHints extends Component {
    renderHint(hint, index) {
        const {t} = this.props;
        return (
            <HintContainer key={index} state={hint.state} {...hint}>
                {t(`globalHints.${hint.text}`)}
            </HintContainer>
        );
    }

    render() {
        const {hints} = this.props;
        return <HintsContainer>{hints.map((h, i) => this.renderHint(h, i))}</HintsContainer>;
    }
}

export default GlobalHints;
