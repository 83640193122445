import React, {Component} from "react";
import styled from "styled-components";
import Spinner from "components/common/Spinner";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
`;

class Callback extends Component {
    componentDidMount() {
        const status = location.href.split("/").pop();
        window.parent.postMessage(`callback-${status}`);
    }

    render() {
        return (
            <Container>
                <Spinner height="4rem" />
            </Container>
        );
    }
}

export default Callback;
