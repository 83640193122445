import React, {PureComponent} from "react";
import styled from "styled-components";
import Modal from "components/common/modals/Modal";
import OwnersContent from "components/common/ownersQuizModalContent/OwnersContent";
// import AssignmentOwnersContent from "components/common/ownersAssignmentModalContent/OwnersContent";
import StudentsContent from "./StudentsContent";
import CurrentUserContext from "contexts/CurrentUser";
import {withContextConsumer} from "utils/contexts";
import userTypes from "consts/userTypes";
import {withNamespaces} from "react-i18next";
// import MaterialTabs from "components/common/tabs/MaterialTabs";

const headerStyle = {fontWeight: "bold", fontSize: "1.5rem", padding: "0 0 1.5 0"};
// const TabsContainer = styled.div`
//     width: 50rem;
//     max-width: 100%;
//     padding: 1rem 0;
// `;

@withNamespaces("translation")
@withContextConsumer(CurrentUserContext.Consumer)
class LectureDidFinishedModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedPage: "quizzes"
        };
    }

    getPagesData() {
        const {t} = this.props;
        return [{id: "quizzes", title: t(`lecture.digest.quizzes`)}];
    }
    // getSelectedComponent() {
    //     const {disconnectFromLecture, lectureState, t} = this.props;

    //     const {selectedPage} = this.state;
    //     if (selectedPage === "quizzes") {
    //         return (
    //             <OwnersContent
    //                 onClose={disconnectFromLecture.bind(null, "/meetings")}
    //                 lectureData={lectureState}
    //                 closeTitle={t("lecture.notifications.backToLectures")}
    //             />
    //         );
    //     }
    //     if (selectedPage === "assignments") {
    //         const assignments = this.state.assignments;

    //         return (
    //             <AssignmentOwnersContent
    //                 onClose={disconnectFromLecture.bind(null, "/meetings")}
    //                 lectureData={lectureState}
    //                 closeTitle={t("lecture.notifications.backToLectures")}
    //             />
    //         );
    //     }
    // }

    render() {
        const {
            isOpen,
            disconnectFromLecture,
            currentUser,
            isLectureOwner,
            lectureState,
            onLeaveLectureReview,
            onLeaveCourseReview,
            heading,
            t,
            onRequestClose
        } = this.props;

        const canAssignQuiz = isLectureOwner && currentUser.role === userTypes.teacher;
        const title = heading ? heading : "lecture.notifications.lectureDidFinished";
        return (
            <Modal
                isOpen={isOpen}
                headerStyle={headerStyle}
                header={t(title)}
                style={{borderRadius: "4px"}}
                contentWrapperStyle={{padding: 0}}
                fullHeightContent
                onRequestClose={onRequestClose}>
                {canAssignQuiz ? (
                    <>
                        {/* <TabsContainer>
                            <MaterialTabs
                                data={this.getPagesData()}
                                selected={this.state.selectedPage}
                                onChange={selectedPage => this.setState({selectedPage})}
                            />
                        </TabsContainer> */}
                        {/* {this.getSelectedComponent()} */}
                        <OwnersContent
                            onClose={disconnectFromLecture.bind(null, "/meetings")}
                            lectureData={lectureState}
                            closeTitle={t("lecture.notifications.backToLectures")}
                        />
                    </>
                ) : (
                    <StudentsContent
                        disconnectFromLecture={disconnectFromLecture.bind(null, `/lectures/${lectureState.id}/digest`)}
                        onLeaveLectureReview={onLeaveLectureReview}
                        onLeaveCourseReview={onLeaveCourseReview}
                        isAdHocLecture={lectureState.isAdHocLecture}
                        isLastCourseLecture={lectureState.isLast}
                    />
                )}
            </Modal>
        );
    }
}

export default LectureDidFinishedModal;
