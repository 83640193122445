import React from "react";
import {withRouter} from "react-router-dom";
import {validateCreateChildData} from "../validations/createChild";
import AsyncStateComponent from "./common/AsyncStateComponent";
import NetworkController from "../controllers/Network";
import GlobalHintsContext from "./GlobalHints";
import ChatContext from "./chat/Chat";
import {withContextConsumer} from "utils/contexts";
import childrenConfig from "../consts/childrenList";
import {validatePasswordData} from "../validations/profile";
import CurrentUser from "./CurrentUser";
import {isParent} from "consts/userTypes";

const ChildrenContext = React.createContext("children");

@withContextConsumer(GlobalHintsContext.Consumer)
@withContextConsumer(CurrentUser.Consumer)
@withContextConsumer(ChatContext.Consumer)
class ChildrenProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(window.location.search);
        this.state = {
            childrenErrors: {},

            // Show
            myChildren: [],
            myChildrenCount: 0,
            childrenListCurrentPage: 1,
            getChildren: this.getChildren.bind(this),
            areChildrenLoading: false,
            isChildPageReady: false,
            // Create
            registerChildData: null,
            createChildConfirmationToken: null,
            createChildConfirmationIsOpen: false,
            createChildIsOpen: false,
            createChild: this.createChild.bind(this),
            createChildConfirmPhone: this.createChildConfirmPhone.bind(this),
            openCreateChild: this.openCreateChild.bind(this),
            createChildResendCode: this.createChildResendCode.bind(this),
            closeCreateChild: this.closeCreateChild.bind(this),
            closeCreateChildConfirmCode: this.closeCreateChildConfirmCode.bind(this),

            // Delete
            deleteChildModalIsOpen: false,
            childToDeleteId: null,
            requestDeleteChild: this.requestDeleteChild.bind(this),
            deleteChild: this.deleteChild.bind(this),
            abortDeleteChild: this.abortDeleteChild.bind(this),

            // Edit profile
            editChildModalIsOpen: query.get("isCreateChildModalOpen") || false,
            childToEdit: null,
            requestEditChild: this.requestEditChild.bind(this),
            editChild: this.editChild.bind(this),
            abortEditChild: this.abortEditChild.bind(this),

            // edit child spending
            childToEditForSpending: null,
            childSpendingModalIsOpen: false,
            requestEditChildSpending: this.requestEditChildSpending.bind(this),
            editChildSpending: this.editChildSpending.bind(this),
            abortEditChildSpending: this.abortEditChildSpending.bind(this),

            // Edit phone
            changePhoneModalIsOpen: false,
            requestChangePhone: this.requestChangePhone.bind(this),
            abortChangePhone: this.abortChangePhone.bind(this),
            changePhone: this.changePhone.bind(this),
            editChildPhoneConfirmationIsOpen: false,
            abortEditChildPhoneConfirmation: this.abortEditChildPhoneConfirmation.bind(this),
            editChildPhoneResendCode: this.editChildPhoneResendCode.bind(this),
            editChildPhoneConfirmPhone: this.editChildPhoneConfirmPhone.bind(this),
            editChildPhoneConfirmationToken: null,
            editChildPhoneData: null,

            // Edit password
            changePasswordModalIsOpen: false,
            requestChangePassword: this.requestChangePassword.bind(this),
            abortChangePassword: this.abortChangePassword.bind(this),
            changePassword: this.changePassword.bind(this)
        };
    }

    async componentDidMount() {
        await this.getChildrenForParent(this.props.currentUser);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentUser} = this.props;
        const didUserAppear = !prevProps.currentUser && currentUser;
        if (didUserAppear) {
            const isUserParent = isParent(currentUser.role);
            if (isUserParent) {
                await this.getChildrenForParent(this.props.currentUser);
            }
        }
    }

    async getChildrenForParent(currentUser) {
        if (isParent(currentUser)) {
            await this.getChildren();
        }
    }

    async getChildren(page = 1, search) {
        if (!this.state.isChildPageReady) {
            await this.setStatePromise({areChildrenLoading: true});
        }
        const query = {
            limit: childrenConfig.maxChildrenPerPage,
            skip: childrenConfig.maxChildrenPerPage * (page - 1)
        };
        if (search) {
            query.search = search;
        }
        const {error, response} = await NetworkController.get("/users/children", query);
        if (error) {
            return this.setState({childrenErrors: response, areChildrenLoading: false, isChildPageReady: true});
        }
        this.setState({
            myChildren: response.children,
            myChildrenCount: response.childrenCount,
            childrenListCurrentPage: page,
            areChildrenLoading: false,
            isChildPageReady: true
        });
    }

    async createChild(data, userPic) {
        await this.setStatePromise({childrenErrors: {}});
        const errors = validateCreateChildData(data);
        const hasErrors = !!Object.keys(errors).length;
        if (hasErrors) {
            return this.setState({childrenErrors: errors});
        }
        const {error, response} = await NetworkController.formData("/users/children", data, {userPic});
        if (error) {
            if (response.maxChildCountExceeded) {
                this.setState({createChildIsOpen: false});
                return this.props.addHint({text: "You no longer can create children", timeout: 3000});
            }
            return this.setState({childrenErrors: response});
        }
        if (response.needToConfirmPhone) {
            return this.setState({
                createChildConfirmationToken: response.confirmationToken,
                createChildConfirmationIsOpen: true,
                createChildIsOpen: false,
                registerChildData: {data, userPic}
            });
        }
        await this.onChildrenCreated();
    }

    async onChildrenCreated() {
        this.setState({
            childrenErrors: {},
            createChildIsOpen: false,
            editChildModalIsOpen: false,
            createChildConfirmationIsOpen: false,
            registerChildData: null,
            createChildConfirmationToken: null
        });
        await this.getChildren();
        await this.props.reloadChatState();
    }

    async createChildConfirmPhone(code) {
        const {registerChildData, createChildConfirmationToken} = this.state;
        const {error, response} = await NetworkController.formData(
            "/users/children",
            {
                ...registerChildData.data,
                confirmationToken: createChildConfirmationToken,
                confirmationCode: code
            },
            {userPic: registerChildData.userPic}
        );
        if (error) {
            return this.setState({childrenErrors: response});
        }
        await this.onChildrenCreated();
    }

    openCreateChild() {
        this.setState({createChildIsOpen: true});
    }

    async createChildResendCode() {
        const {error, response} = await NetworkController.post("/users/resendCode", {
            confirmationToken: this.state.createChildConfirmationToken
        });
        if (error) {
            return console.log(error);
        }
        this.setState({createChildConfirmationToken: response.confirmationToken});
    }

    closeCreateChild() {
        this.setState({createChildIsOpen: false});
    }

    closeCreateChildConfirmCode() {
        this.setState({createChildConfirmationIsOpen: false});
    }

    requestDeleteChild(id) {
        this.setState({deleteChildModalIsOpen: true, childToDeleteId: id});
    }

    async deleteChild() {
        await this.setStatePromise({childrenErrors: {}});
        const id = this.state.childToDeleteId;
        const {error, response} = await NetworkController.delete(`/users/children/${id}`);
        if (error) {
            return this.setState({childrenErrors: response});
        }
        this.setState({deleteChildModalIsOpen: false, childToDeleteId: null});
        await this.getChildren();
    }

    abortDeleteChild() {
        this.setState({
            deleteChildModalIsOpen: false,
            childToDeleteId: null
        });
    }

    // edit child spending functions
    async requestEditChildSpending(child) {
        // await this.setStatePromise({childrenErrors: {}});
        this.setState({
            childToEditForSpending: child,
            childSpendingModalIsOpen: true
        });
    }

    async editChildSpending(childId, data) {
        const {error, response} = await NetworkController.put(`/users/children/edit/child-spending/${childId}`, data);
        if (error) {
            return this.setState({childrenErrors: response});
        }
        this.setState({
            childToEditForSpending: null,
            childSpendingModalIsOpen: false
        });
        await this.getChildren();
    }

    abortEditChildSpending() {
        this.setState({
            childToEditForSpending: null,
            childSpendingModalIsOpen: false
        });
    }

    // edit child
    async requestEditChild(child) {
        await this.setStatePromise({childrenErrors: {}});
        this.setState({
            childToEdit: child,
            editChildModalIsOpen: true
        });
    }

    async editChild(data, userPic) {
        const errors = validateCreateChildData(data, true);
        const hasErrors = !!Object.keys(errors).length;
        if (hasErrors) {
            return this.setState({childrenErrors: errors});
        }
        const {error, response} = await NetworkController.formData(`/users/children/${this.state.childToEdit.id}`, data, {
            userPic
        });
        if (error) {
            return this.setState({childrenErrors: response});
        }
        this.setState({
            childrenErrors: {},
            childToEdit: null,
            editChildModalIsOpen: false
        });
        await this.getChildren();
    }

    abortEditChild() {
        this.setState({
            childToEdit: null,
            editChildModalIsOpen: false
        });
    }

    abortEditChildPhoneConfirmation() {
        this.setState({editChildPhoneConfirmationIsOpen: false});
    }

    async requestChangePhone() {
        await this.setStatePromise({childrenErrors: {}});
        this.setState({changePhoneModalIsOpen: true});
    }

    abortChangePhone() {
        this.setState({changePhoneModalIsOpen: false});
    }

    async changePhone(data) {
        await this.setStatePromise({childrenErrors: {}});

        const newChildPhoneData = {...data, childId: this.state.childToEdit.id};
        const {error, response} = await NetworkController.post("/users/edit/phone", newChildPhoneData);

        if (error) {
            return this.setState({childrenErrors: response});
        }
        this.setState({
            changePhoneModalIsOpen: false,
            editChildPhoneConfirmationIsOpen: true,
            editChildPhoneConfirmationToken: response.confirmationToken,
            editChildPhoneData: newChildPhoneData
        });
    }

    async editChildPhoneConfirmPhone(code) {
        const {error, response} = await NetworkController.post("/users/edit/phone", {
            confirmationCode: code,
            confirmationToken: this.state.editChildPhoneConfirmationToken,
            ...this.state.editChildPhoneData
        });
        if (error) {
            return this.setState({childrenErrors: response});
        }

        this.props.addHint({
            text: "Phone number was changed successfully",
            timeout: 3000
        });
        this.setState({editChildPhoneConfirmationIsOpen: false});
        await this.getChildren();
    }

    async editChildPhoneResendCode() {
        const {error, response} = await NetworkController.post("/users/resendCode", {
            confirmationToken: this.state.editChildPhoneConfirmationToken
        });
        if (error) {
            return console.log(error);
        }
        this.setState({
            editChildPhoneConfirmationToken: response.confirmationToken
        });
    }

    async requestChangePassword() {
        await this.setStatePromise({childrenErrors: {}});
        this.setState({changePasswordModalIsOpen: true});
    }

    abortChangePassword() {
        this.setState({changePasswordModalIsOpen: false});
    }

    async changePassword(data) {
        await this.setStatePromise({childrenErrors: {}});
        const errors = validatePasswordData(data);
        const hasErrors = !!Object.keys(errors).length;
        if (hasErrors) {
            return this.setState({childrenErrors: errors});
        }
        const childNewPasswordData = {
            ...data,
            childId: this.state.childToEdit.id
        };
        const {error, response} = await NetworkController.post("/users/edit/password", childNewPasswordData);
        if (error) {
            return this.setState({childrenErrors: response});
        }
        this.props.addHint({
            text: "Password was changed successfully",
            timeout: 3000
        });
        this.setState({changePasswordModalIsOpen: false});
        await this.getChildren();
    }

    render() {
        return <ChildrenContext.Provider value={this.state}>{this.props.children}</ChildrenContext.Provider>;
    }
}

export default {
    Provider: withRouter(ChildrenProvider),
    Consumer: ChildrenContext.Consumer,
    Context: ChildrenContext
};
