import React from "react";
import {Detail, Name, SearchedItemContainer, Avatar, Type} from "./styles";

export default function SearchedItem(item, i) {
    return (
        <SearchedItemContainer key={i}>
            <Name>{item.name}</Name>
            <Avatar src={item.avatar || item.cover} />
            <Detail>{item.author}</Detail>
            <Type>
                <div>{item.type}</div>
            </Type>
        </SearchedItemContainer>
    );
}
