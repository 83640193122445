import React, {Component} from "react";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";
import {withContextConsumer} from "utils/contexts";
import userTypes from "consts/userTypes";
import routes from "consts/routes";
import CurrentUserContext from "./CurrentUser";

const LecturesDigestContext = React.createContext("lecturesDigest");

const REVIEWS_PER_PAGE = 10;

@withRouter
@withContextConsumer(CurrentUserContext.Consumer)
class LecturesDigestProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lecture: null,
            usersAttendance: [],
            groupedUserenrolledUsers: [],
            totalUsers: 0,
            getLectureData: this.getLectureData.bind(this),
            getSelfStudyLectureData: this.getSelfStudyLectureData.bind(this),
            getUsersLectureAttendance: this.getUsersLectureAttendance.bind(this),
            enterQuiz: this.enterQuiz.bind(this),
            showOverallResult: this.showOverallResult.bind(this),
            openedQuiz: null,
            setOpenedQuiz: this.setOpenedQuiz.bind(this),
            showPersonalResult: this.showPersonalResult.bind(this),
            quizzes: null,
            quizzesToken: null,
            assignments: null,
            isLectureOwner: false,
            getLectureReviews: this.getLectureReviews.bind(this),
            lectureReviews: [],
            reviewsCount: 0,
            updateLectureAssignments: this.updateLectureAssignments.bind(this),
            updateAssignment: this.updateAssignment.bind(this),
            updateSubmission: this.updateSubmission.bind(this),
            deleteSubmission: this.deleteSubmission.bind(this),
            replaceSubmissionFromCache: this.replaceSubmissionFromCache.bind(this),
            updateAssessment: this.updateAssessment.bind(this),
            updateAssessmentSubmission: this.updateAssessmentSubmission.bind(this),
            deleteAssessmentSubmission: this.deleteAssessmentSubmission.bind(this)
        };
    }

    async getLectureReviews({lectureId, offset = 0, limit = REVIEWS_PER_PAGE}) {
        const {currentUser} = this.props;
        const isTeacher = currentUser && currentUser.role === userTypes.teacher;
        if (isTeacher) {
            const {response} = await NetworkController.get(`/reviews/lectures`, {lectureId, offset, limit});
            this.setState({lectureReviews: response.reviews, reviewsCount: response.reviewsCount});
        }
    }

    async getLectureData(id) {
        const {history, currentUser} = this.props;
        if (!currentUser) {
            return history.push(routes.home.path);
        }
        const {error, response} = await NetworkController.get(`/lectures/${id}`);

        const {lecture} = response;

        if (error || !lecture) {
            return history.push(routes.lectures.path);
        }
        this.setState({
            lecture,
            quizzes: response.quizzes,
            isLectureOwner: currentUser.id === lecture.owner.id,
            quizzesToken: response.quizzesToken,
            assignments: response.assignments,
            assessments: response.assessments
        });

        const isCurrentUserLectureOwner = lecture.owner.id === currentUser.id;
        const isParent = currentUser.role === userTypes.parent;
        const isPrincipal = currentUser.role === userTypes.principal;
        if (isCurrentUserLectureOwner || isParent || isPrincipal) {
            await this.getUsersLectureAttendance(id, {offset: 0, limit: 10});
        }
    }

    async getSelfStudyLectureData(id) {
        const {history, currentUser} = this.props;
        if (!currentUser) {
            return history.push(routes.home.path);
        }
        const {error, response} = await NetworkController.get(`/lectures/selfStudy/${id}`);
        const {lecture} = response;

        if (error || !lecture) {
            return history.push(routes.lectures.path);
        }
        this.setState({
            lecture,
            quizzes: response.quizzes,
            isLectureOwner: currentUser.id === lecture.owner.id,
            quizzesToken: response.quizzesToken,
            assignments: response.assignments,
            assessments: response.assessments
        });

        const isCurrentUserLectureOwner = lecture.owner.id === currentUser.id;
        const isParent = currentUser.role === userTypes.parent;
        const isPrincipal = currentUser.role === userTypes.principal;
        if (isCurrentUserLectureOwner || isParent || isPrincipal) {
            await this.getUsersLectureAttendance(id, {offset: 0, limit: 10});
        }
    }

    async getUsersLectureAttendance(id, data) {
        const {error, response} = await NetworkController.get(`/lectures/attendances/${id}`, data);
        const {usersAttendance, groupedUserenrolledUsers, count} = response;

        if (!error) {
            this.setState({usersAttendance, groupedUserenrolledUsers, totalUsers: count});
        }
    }

    enterQuiz(quizId) {
        const {lecture, quizzesToken} = this.state;
        this.props.history.push(`/quizzes/pass?quizId=${quizId}&lectureId=${lecture.id}&quizzesToken=${quizzesToken}`);
    }

    showOverallResult(quizId) {
        const {lecture} = this.state;
        this.props.history.push(`/quizzes/result?quizId=${quizId}&lectureId=${lecture.id}`);
    }

    showPersonalResult(passDataId) {
        this.props.history.push(`/quizzes/result/${passDataId}`);
    }

    setOpenedQuiz(quiz) {
        this.setState({openedQuiz: quiz});
    }

    updateLectureAssignments(assignmentId) {
        this.setState({assignments: this.state.assignments.filter(a => a.id !== assignmentId)});
    }

    updateAssignment(assignment) {
        const {assignments} = this.state;
        const newAssignments = [...assignments];
        const index = newAssignments.findIndex(a => a.id === assignment.id);
        newAssignments[index] = assignment;

        this.setState({assignments: newAssignments});
    }

    updateAssessment(assessment) {
        const {assessments} = this.state;
        const newAssessments = [...assessments];
        const index = newAssessments.findIndex(a => a.id === assessment.id);
        newAssessments[index] = assessment;

        this.setState({assessments: newAssessments});
    }

    updateSubmission(assignmentSubmission) {
        const {assignments} = this.state;
        const newAssignments = [...assignments];
        const index = newAssignments.findIndex(a => a.id === assignmentSubmission.assignment);
        newAssignments[index].results.push(assignmentSubmission);

        this.setState({assignments: newAssignments});
    }

    updateAssessmentSubmission(assessmentSubmission) {
        const {assessments} = this.state;
        const newAssessments = [...assessments];
        const index = newAssessments.findIndex(a => a.id === assessmentSubmission);
        newAssessments[index].results.push(assessmentSubmission);

        this.setState({assessments: newAssessments});
    }

    replaceSubmissionFromCache(assignmentSubmission) {
        const {assignments} = this.state;
        const newAssignments = [...assignments];
        const index = newAssignments.findIndex(a => a.id === assignmentSubmission.assignment);
        newAssignments[index].results.map(r => (r.status = "deleted"));
        newAssignments[index].results.push(assignmentSubmission);

        this.setState({assignments: newAssignments});
    }

    deleteSubmission(assignmentSubmission) {
        const {assignments} = this.state;
        const newAssignments = [...assignments];
        const index = newAssignments.findIndex(a => a.id === assignmentSubmission.assignment);

        newAssignments.at(index).results.map(r => {
            if (r.id === assignmentSubmission.id) {
                r.status = "deleted";
            }
        });
        this.setState({assignments: newAssignments});
    }

    deleteAssessmentSubmission(assessmentSubmission) {
        const {assessments} = this.state;
        const newAssessments = [...assessments];
        const index = newAssessments.findIndex(a => a.id === assessmentSubmission.assignment);

        newAssessments.at(index).results.map(r => {
            if (r.id === assessmentSubmission.id) {
                r.status = "deleted";
            }
        });
        this.setState({assessments: newAssessments});
    }

    render() {
        return <LecturesDigestContext.Provider value={this.state}>{this.props.children}</LecturesDigestContext.Provider>;
    }
}

export default {Provider: LecturesDigestProvider, Consumer: LecturesDigestContext.Consumer};
