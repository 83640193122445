import React from "react";

export const withContextConsumer = Consumer => WrappedComponent =>
    React.forwardRef((props, ref) => (
        <Consumer>{contextState => <WrappedComponent ref={ref} {...contextState} {...props} />}</Consumer>
    ));

export const withContextProvider = Provider => WrappedComponent =>
    React.forwardRef((props, ref) => (
        <Provider>
            <WrappedComponent ref={ref} {...props} />
        </Provider>
    ));
