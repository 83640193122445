import React from "react";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";
import {withContextConsumer} from "utils/contexts";
import GlobalHintsContext from "../GlobalHints";

const ChecklistContext = React.createContext("checklist");

@withContextConsumer(GlobalHintsContext.Consumer)
class ChecklistProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            lectureReview: [],
            isLoading: false,
            getChecklist: this.getChecklist.bind(this),
            setLectureReview: this.setLectureReview.bind(this),
            onLectureObservation: this.onLectureObservation.bind(this),
            onLeaveLectureReview: this.onLeaveLectureReview.bind(this)
        };
    }

    async getChecklist(lectureId) {
        await this.setStatePromise({isLoading: true});
        let apiResponse = {};
        const {response: lectureResponse} = await NetworkController.get(`/lectures/review/${lectureId}`);

        if (lectureResponse) {
            apiResponse = lectureResponse.reviews;
        } else {
            const {response} = await NetworkController.get("/checklist");
            response.map(element => {
                return element.checklist.map(option => (option.value = "none"));
            });
            apiResponse = response;
        }

        await this.setStatePromise({lectureReview: apiResponse, isLoading: false});
    }

    async setLectureReview(categoryIndex, optionIndex, newReview) {
        let newLectureReview = this.state.lectureReview;
        newLectureReview[categoryIndex]["checklist"][optionIndex] = newReview;
        await this.setStatePromise({lectureReview: newLectureReview});
    }

    // lecture observation leave by principal
    async onLectureObservation(teacherId, courseId, lectureId, reviews) {
        await this.setStatePromise({isLoading: true});
        const {error, response} = await NetworkController.post("/lectures/review", {
            teacherId,
            courseId,
            lectureId,
            reviews
        });

        await this.setStatePromise({isLoading: false});
        this.props.addHint({
            text: "Review added successfully",
            timeout: 3000
        });
    }

    // lecture review leave by student
    async onLeaveLectureReview({lectureId, rating, text}) {
        if (!rating && !text) {
            return;
        }

        if (!lectureId) {
            return;
        }

        const {error} = await NetworkController.post("/reviews/lectures", {rating, text, lectureId: lectureId});

        return error;
    }

    render() {
        return <ChecklistContext.Provider value={this.state}>{this.props.children}</ChecklistContext.Provider>;
    }
}

export default {Provider: withRouter(ChecklistProvider), Consumer: ChecklistContext.Consumer};
