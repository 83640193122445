import React from "react";
import styled from "styled-components";
import RadioChecked from "components/common/icons/RadioChecked";
import RadioUnchecked from "components/common/icons/RadioUnchecked";
import RadioError from "components/common/icons/RedCrossInCircle";
import RadioSuccess from "components/common/icons/RadioSuccess";
import iconTypes from "consts/iconTypes";
import FlexMarginBetween from "../styled/FlexMarginBetween";

const RadioItemWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    ${FlexMarginBetween(0.25)};
    svg {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
        min-height: 1rem;
    }
`;

const SvgContainer = styled.div`
    display: flex;
    align-items: center;
    > svg {
        min-width: 1rem;
        min-height: 1rem;
        width: ${props => (props.width ? props.width : "1rem")};
        height: ${props => (props.height ? props.height : "1rem")};
    }
`;

const renderIcon = (type, selected, iconStyle, disabled) => {
    if (type === iconTypes.wrong) {
        return <RadioError viewBox={iconStyle.viewBox || "0 0 20 20"} />;
    }
    if (type === iconTypes.success) {
        return <RadioSuccess viewBox={iconStyle.viewBox || "0 0 20 20"} />;
    }

    return selected ? (
        <RadioChecked color={type === iconTypes.gray && "#747474"} viewBox={iconStyle.viewBox || "0 0 16 16"} />
    ) : (
        <RadioUnchecked viewBox={iconStyle.viewBox || "0 0 16 16"} />
    );
};

const RadioSwitchButton = ({
    id,
    selected,
    title,
    titleStyle,
    onClick,
    style,
    className,
    iconType,
    iconStyle = {},
    disabled = false
}) => {
    return (
        <RadioItemWrapper className={className} style={style}>
            <SvgContainer
                id={id}
                onClick={e => {
                    if (disabled) {
                    } else {
                        if (typeof onClick === "function") {
                            onClick(e);
                        }
                    }
                }}
                width={iconStyle.width}
                height={iconStyle.height}>
                {renderIcon(iconType, selected, iconStyle, disabled)}
            </SvgContainer>
            <div onClick={selected ? null : onClick} style={titleStyle}>
                {title}
            </div>
        </RadioItemWrapper>
    );
};

export default RadioSwitchButton;
