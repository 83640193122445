import {css} from "styled-components";

export default css`
    input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;

        max-width: 100%;
        max-height: 100%;

        width: 100%;

        color: transparent;
        ::-webkit-file-upload-button {
            cursor: pointer;
        }
    }
`;
