import React from "react";
import styled from "styled-components";
import FlexCenter from "components/common/styled/FlexCenter";

const ProgressBarContainer = styled.div`
    border-radius: 2px;
    height: 10px;
    width: 100%;
    min-width: 0;
    flex: 1;
    background-color: ${props => props.color};
    ${FlexCenter}
`;

const ProgressContainer = styled.div`
    border-radius: 2px;
    height: ${props => props.height || "2px"};
    width: 98%;
    background-color: ${props => props.color || "#e3e5ea"};
    ${props => props.onClick && "cursor: pointer;"}
`;

const Progress = styled.div.attrs(props => ({
    style: {
        width: props.progress ? props.progress + "%" : 0,
        backgroundColor: props.color
    }
}))`
    border-radius: 2px;
    height: 100%;
    min-width: 0;
    pointer-events: none;
    overflow: hidden;
`;

export default ({progress, colors = {}, height, onChange, isRTL}) => {
    const onClick = e => {
        const rect = e.target.getBoundingClientRect();
        const targetX = e.clientX - rect.left;
        const percent = targetX / rect.width;
        const correctedPercent = isRTL ? 1 - percent : percent;
        onChange(correctedPercent);
    };

    return (
        <ProgressBarContainer>
            <ProgressContainer height={height} color={colors.progressContainer} onClick={onChange && onClick}>
                <Progress progress={progress} color={colors.mainColor} />
            </ProgressContainer>
        </ProgressBarContainer>
    );
};
