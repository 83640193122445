import React, {Component} from "react";
import styled from "styled-components";
import {withRouter} from "react-router-dom";
import ChatContext from "contexts/chat/Chat";
import LibraryContext from "contexts/Library";
import WindowRectContext from "contexts/WindowRect";
import {withContextConsumer, withContextProvider} from "utils/contexts";
import NotificationsContext from "contexts/Notifications";
import {mediaQuery} from "components/common/styled/mediaQuery";
import ChatButton from "./ChatButton";
import ContactList from "./ContactList";
import ChatItem from "./ChatItem";
import {withNamespaces} from "react-i18next";
import {checkIfLocationPathStartsWith} from "utils/location";
import routes from "consts/routes";

const Wrapper = styled.div`
    position: fixed;
    z-index: 10;
    bottom: 8vh;
    ${props => (props.dir === "rtl" ? "left" : "right")}: 3rem;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    ${mediaQuery.phone`
        bottom: 2vh;
    `}
`;

const Container = styled.div`
    display: flex;
    visibility: ${props => (props.visible ? "visible" : "hidden")};
    opacity: ${props => (props.visible ? "1" : "0")};
    align-items: flex-end;
    transition: 0.2s;
    position: fixed;
    bottom: 10vh;
    margin-bottom: 4rem;
`;

const DIALOG_WIDTH = 300;

@withRouter
@withNamespaces("translation")
@withContextProvider(LibraryContext.Provider)
@withContextConsumer(ChatContext.Consumer)
@withContextConsumer(NotificationsContext.Consumer)
@withContextConsumer(WindowRectContext.Consumer)
class ChatWidget extends Component {
    async componentDidMount() {
        await this.props.prepareConnection();
    }

    async componentDidUpdate(prevProps) {
        const {dialogs, chatNotifications, hideChatNotification, isPhoneResolution, widgetIsOpen, toggleWidget} = this.props;
        dialogs.forEach(({dialogId}) => {
            const {notifications} = chatNotifications;
            const notification = notifications.find(n => n.dialogId === dialogId);
            if (notification) {
                hideChatNotification(notification.id);
            }
        });
        const didMobileModeChange = isPhoneResolution !== prevProps.isPhoneResolution;
        const shouldToggleWidget = didMobileModeChange && widgetIsOpen;
        if (shouldToggleWidget) {
            toggleWidget();
        }
    }

    componentWillUnmount() {
        this.props.disconnect();
    }

    async handleContactClick(id) {
        const {openDialogWith} = this.props;
        await openDialogWith(id);
    }

    handleCloseDialog(dialogId) {
        const {closeDialog} = this.props;
        closeDialog(dialogId);
    }

    updateDialogListDependsOnScreenWidth() {
        const {dialogs, windowRect, closeDialog} = this.props;
        const {width} = windowRect;
        let widthSum = DIALOG_WIDTH;
        [...dialogs.values()].reverse().forEach(({dialogId}) => {
            if (widthSum + DIALOG_WIDTH * 1.5 > width) closeDialog(dialogId);
            else widthSum += DIALOG_WIDTH;
        });
    }

    handleChatButtonClick() {
        const {history, isPhoneResolution, toggleWidget} = this.props;

        if (isPhoneResolution) {
            history.push("/chat");
        } else {
            toggleWidget();
        }
    }

    render() {
        const {
            widgetIsOpen,
            toggleWidget,
            additionalInfoMessages,
            downloadFile,
            location,
            meta,
            chatState,
            currentUser,
            sendMessage,
            dialogs,
            loadNextMessages,
            supportUser,
            blockUser,
            unblockUser,
            blockUserGroup,
            unblockUserGroup,
            clearMessages,
            searchInMessages,
            isPhoneResolution,
            i18n
        } = this.props;

        const shouldHideChatWidget = checkIfLocationPathStartsWith(routes.chats.path);
        if (shouldHideChatWidget) {
            return null;
        }

        this.updateDialogListDependsOnScreenWidth();
        const dir = i18n.dir();
        const isUnreadMessagesExist = !!chatState.reduce(
            (unreadMessageNumber, {unreadMessageCount}) => unreadMessageNumber + unreadMessageCount,
            0
        );

        return (
            <Wrapper dir={dir}>
                {!isPhoneResolution && (
                    <Container visible={widgetIsOpen}>
                        {[...dialogs.values()].map(dialog => (
                            <ChatItem
                                key={dialog.dialogId}
                                dialogState={dialog}
                                currentUser={currentUser}
                                additionalInfo={additionalInfoMessages.get(dialog.user.id)}
                                meta={meta}
                                sendMessage={sendMessage}
                                loadNextMessages={loadNextMessages}
                                downloadFile={downloadFile}
                                blockUser={blockUser}
                                unblockUser={unblockUser}
                                blockUserGroup={blockUserGroup}
                                unblockUserGroup={unblockUserGroup}
                                clearMessages={clearMessages}
                                onClose={this.handleCloseDialog.bind(this, dialog.dialogId)}
                            />
                        ))}
                        {/*<ContactList*/}
                        {/*    supportUser={supportUser}*/}
                        {/*    currentUser={currentUser}*/}
                        {/*    chatState={chatState}*/}
                        {/*    onToggle={toggleWidget}*/}
                        {/*    searchInMessages={searchInMessages}*/}
                        {/*    onContactClick={this.handleContactClick.bind(this)}*/}
                        {/*/>*/}
                    </Container>
                )}
                {/*<ChatButton*/}
                {/*    onToggle={this.handleChatButtonClick.bind(this)}*/}
                {/*    isOpen={widgetIsOpen && !isPhoneResolution}*/}
                {/*    isUnreadMessagesExist={isUnreadMessagesExist}*/}
                {/*/>*/}
            </Wrapper>
        );
    }
}

export default ChatWidget;
