import React from "react";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import NetworkController from "controllers/Network";
import {withRouter} from "react-router-dom";

const QuizzesListContext = React.createContext("quizzesList");

class QuizzesListProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            quizzes: [],
            isLoading: false,
            quizMeta: null,
            quizGames: [],
            getQuizzes: this.getQuizzes.bind(this),
            getQuizMeta: this.getQuizMeta.bind(this),
            getQuizGames: this.getQuizGames.bind(this),
            attachQuiz: this.attachQuiz.bind(this),
            deleteQuiz: this.deleteQuiz.bind(this),
            openConfirmDeleteModal: this.openConfirmDeleteModal.bind(this),
            toggleConfirmDeleteModal: this.toggleConfirmDeleteModal.bind(this),
            isConfirmDeleteModalOpen: false,
            editQuiz: this.editQuiz.bind(this)
        };
    }

    openConfirmDeleteModal(quizId) {
        this.setState({quizToDeleteId: quizId}, this.toggleConfirmDeleteModal);
    }

    toggleConfirmDeleteModal() {
        this.setState({isConfirmDeleteModalOpen: !this.state.isConfirmDeleteModalOpen});
    }

    editQuiz(quiz) {
        this.props.history.push(`quizzes/${quiz.id}`);
    }

    async getQuizzes() {
        await this.setStatePromise({isLoading: true});
        const {response} = await NetworkController.get("/quizzes");
        this.setState({quizzes: response.quizzes, isLoading: false});
    }

    async getQuizMeta() {
        const {response} = await NetworkController.get("/quizzes/meta");
        this.setState({quizMeta: response.quizMeta});
    }

    async getQuizGames() {
        const {response} = await NetworkController.get("/quizgames");
        this.setState({quizGames: response.games});
    }

    async attachQuiz(quizId, attachForms) {
        await NetworkController.put("/quizzes/attach", {quizId, attachForms});
    }

    async deleteQuiz(id) {
        this.setState({quizzes: this.state.quizzes.filter(quiz => quiz.id !== id)});
        await NetworkController.delete(`/quizzes/${id}`);
    }

    render() {
        return <QuizzesListContext.Provider value={this.state}>{this.props.children}</QuizzesListContext.Provider>;
    }
}

export default {Provider: withRouter(QuizzesListProvider), Consumer: QuizzesListContext.Consumer};
