export const landingContentTabs = {
    books: "books",
    games: "games",
    multimedia: "multimedia",
    combos: "combos"
};

export const landingContentPictureTabsList = [
    {
        value: landingContentTabs.books,
        pic: "images/eBooksTab.png"
    },
    {
        value: landingContentTabs.games,
        pic: "images/gamesTab.png"
    },
    {
        value: landingContentTabs.multimedia,
        pic: "images/multimediaTab.png"
    },
    {
        value: landingContentTabs.combos,
        pic: "images/combosTab.png"
    }
];
