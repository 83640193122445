import React from "react";

export default props => {
    return (
        <svg {...props} viewBox="0 0 700 550">
            <g fill="currentColor">
                <path
                    fill="currentColor"
                    d="m195.39 155.84c-6.7539 0-13.23-2.6797-18.004-7.457-4.7734-4.7734-7.457-11.25-7.457-18.004s2.6836-13.23 7.457-18.004c4.7734-4.7773 11.25-7.457 18.004-7.457 6.7539 0 13.23 2.6797 18.004 7.457 4.7773 4.7734 7.4609 11.25 7.4609 18.004s-2.6836 13.23-7.4609 18.004c-4.7734 4.7773-11.25 7.457-18.004 7.457zm0-33.336c-4.0508 0.42578-7.1289 3.8438-7.1289 7.918s3.0781 7.4922 7.1289 7.918c2.2461 0.23828 4.4844-0.48828 6.1602-2 1.6797-1.5078 2.6367-3.6602 2.6367-5.918 0-2.2578-0.95703-4.4062-2.6367-5.918-1.6758-1.5078-3.9141-2.2344-6.1602-2z"
                />
                <path
                    fill="currentColor"
                    d="m224.61 201.25c-4.832 0-8.75-3.918-8.75-8.75v-16.625c-0.45703-6.9805-4.4492-13.246-10.582-16.613-6.1367-3.3672-13.562-3.3672-19.699 0-6.1328 3.3672-10.125 9.6328-10.582 16.613v16.625c0 4.832-3.918 8.75-8.75 8.75s-8.75-3.918-8.75-8.75v-16.625c0.62891-13.117 7.9961-24.98 19.473-31.363 11.477-6.3867 25.441-6.3867 36.918 0 11.477 6.3828 18.84 18.246 19.473 31.363v16.625c0 2.3203-0.92188 4.5469-2.5625 6.1875s-3.8672 2.5625-6.1875 2.5625z"
                />
                <path
                    fill="currentColor"
                    d="m195.39 287.09c-6.7539 0-13.23-2.6797-18.004-7.457-4.7734-4.7734-7.457-11.25-7.457-18.004s2.6836-13.23 7.457-18.004c4.7734-4.7773 11.25-7.457 18.004-7.457 6.7539 0 13.23 2.6797 18.004 7.457 4.7773 4.7734 7.4609 11.25 7.4609 18.004s-2.6836 13.23-7.4609 18.004c-4.7734 4.7773-11.25 7.457-18.004 7.457zm0-33.336c-4.0508 0.42578-7.1289 3.8438-7.1289 7.918s3.0781 7.4922 7.1289 7.918c2.2461 0.23828 4.4844-0.48828 6.1602-2 1.6797-1.5078 2.6367-3.6602 2.6367-5.918 0-2.2578-0.95703-4.4062-2.6367-5.918-1.6758-1.5078-3.9141-2.2344-6.1602-2z"
                />
                <path
                    fill="currentColor"
                    d="m224.61 332.5c-4.832 0-8.75-3.918-8.75-8.75v-16.625c-0.45703-6.9805-4.4492-13.246-10.582-16.613-6.1367-3.3672-13.562-3.3672-19.699 0-6.1328 3.3672-10.125 9.6328-10.582 16.613v16.625c0 4.832-3.918 8.75-8.75 8.75s-8.75-3.918-8.75-8.75v-16.625c0.62891-13.117 7.9961-24.98 19.473-31.363 11.477-6.3867 25.441-6.3867 36.918 0 11.477 6.3828 18.84 18.246 19.473 31.363v16.625c0 2.3203-0.92188 4.5469-2.5625 6.1875s-3.8672 2.5625-6.1875 2.5625z"
                />
                <path
                    fill="currentColor"
                    d="m396.2 385h-264.95c-6.9609 0-13.641-2.7656-18.562-7.6875s-7.6875-11.602-7.6875-18.562v-297.5c0-6.9609 2.7656-13.641 7.6875-18.562s11.602-7.6875 18.562-7.6875h437.5c6.9609 0 13.641 2.7656 18.562 7.6875s7.6875 11.602 7.6875 18.562v297.5c0.003906 2.6719-0.41016 5.3281-1.2266 7.875-0.65234 2.2852-2.207 4.207-4.3047 5.3242-2.0977 1.1172-4.5625 1.332-6.8203 0.59375-2.2617-0.73828-4.125-2.3633-5.1602-4.5039-1.0391-2.1406-1.1602-4.6094-0.33984-6.8398 0.25781-0.78906 0.37891-1.6172 0.35156-2.4492v-297.5c0-2.3203-0.92188-4.5469-2.5625-6.1875s-3.8672-2.5625-6.1875-2.5625h-437.5c-4.832 0-8.75 3.918-8.75 8.75v297.5c0 2.3203 0.92188 4.5469 2.5625 6.1875s3.8672 2.5625 6.1875 2.5625h264.95c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m577.5 87.5h-332.5c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h332.5c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path d="m148.75 87.5c-1.1445-0.015625-2.2734-0.25-3.3242-0.69922-1.1055-0.39844-2.0938-1.0586-2.8867-1.9258-1.6172-1.6289-2.5312-3.8281-2.5391-6.125-0.011719-2.3242 0.89844-4.5625 2.5391-6.2109 1.6406-1.6562 3.8789-2.5898 6.2109-2.5898s4.5703 0.93359 6.2109 2.5898c2.5273 2.5 3.2852 6.2852 1.9219 9.5664-1.3633 3.2812-4.5781 5.4141-8.1328 5.3945z" />
                <path
                    fill="currentColor"
                    d="m175 87.5c-4.832 0-8.75-3.918-8.75-8.75-0.011719-2.3242 0.89844-4.5625 2.5391-6.2109 1.6406-1.6562 3.8789-2.5898 6.2109-2.5898s4.5703 0.93359 6.2109 2.5898c2.5273 2.5 3.2852 6.2852 1.9219 9.5664-1.3633 3.2812-4.5781 5.4141-8.1328 5.3945z"
                />
                <path
                    fill="currentColor"
                    d="m201.25 87.5c-4.832 0-8.75-3.918-8.75-8.75-0.011719-2.3242 0.89844-4.5625 2.5391-6.2109 2.0664-2.0508 5.0195-2.9375 7.875-2.3633l1.6641 0.4375 1.4883 0.78906 1.3984 1.1367h-0.003906c1.6406 1.6484 2.5508 3.8867 2.5391 6.2109 0 2.3203-0.92188 4.5469-2.5625 6.1875s-3.8672 2.5625-6.1875 2.5625z"
                />
                <path
                    fill="currentColor"
                    d="m311.5 140h-49c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h49c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m323.75 175h-61.25c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h61.25c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m311.5 271.25h-49c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h49c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m323.75 306.25h-61.25c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h61.25c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m414.14 155.84c-6.7539 0-13.23-2.6797-18.004-7.457-4.7734-4.7734-7.457-11.25-7.457-18.004s2.6836-13.23 7.457-18.004c4.7734-4.7773 11.25-7.457 18.004-7.457 6.7539 0 13.23 2.6797 18.004 7.457 4.7773 4.7734 7.4609 11.25 7.4609 18.004s-2.6836 13.23-7.4609 18.004c-4.7734 4.7773-11.25 7.457-18.004 7.457zm0-33.336c-4.0508 0.42578-7.1289 3.8438-7.1289 7.918s3.0781 7.4922 7.1289 7.918c2.2461 0.23828 4.4844-0.48828 6.1602-2 1.6797-1.5078 2.6367-3.6602 2.6367-5.918 0-2.2578-0.95703-4.4062-2.6367-5.918-1.6758-1.5078-3.9141-2.2344-6.1602-2z"
                />
                <path
                    fill="currentColor"
                    d="m443.36 201.25c-4.832 0-8.75-3.918-8.75-8.75v-16.625c-0.45703-6.9805-4.4492-13.246-10.582-16.613-6.1367-3.3672-13.562-3.3672-19.699 0-6.1328 3.3672-10.125 9.6328-10.582 16.613v16.625c0 4.832-3.918 8.75-8.75 8.75s-8.75-3.918-8.75-8.75v-16.625c0.62891-13.117 7.9961-24.98 19.473-31.363 11.477-6.3867 25.441-6.3867 36.918 0 11.477 6.3828 18.84 18.246 19.473 31.363v16.625c0 2.3203-0.92188 4.5469-2.5625 6.1875s-3.8672 2.5625-6.1875 2.5625z"
                />
                <path
                    fill="currentColor"
                    d="m414.14 287.09c-6.7539 0-13.23-2.6797-18.004-7.457-4.7734-4.7734-7.457-11.25-7.457-18.004s2.6836-13.23 7.457-18.004c4.7734-4.7773 11.25-7.457 18.004-7.457 6.7539 0 13.23 2.6797 18.004 7.457 4.7773 4.7734 7.4609 11.25 7.4609 18.004s-2.6836 13.23-7.4609 18.004c-4.7734 4.7773-11.25 7.457-18.004 7.457zm0-33.336c-4.0508 0.42578-7.1289 3.8438-7.1289 7.918s3.0781 7.4922 7.1289 7.918c2.2461 0.23828 4.4844-0.48828 6.1602-2 1.6797-1.5078 2.6367-3.6602 2.6367-5.918 0-2.2578-0.95703-4.4062-2.6367-5.918-1.6758-1.5078-3.9141-2.2344-6.1602-2z"
                />
                <path
                    fill="currentColor"
                    d="m443.36 332.5c-4.832 0-8.75-3.918-8.75-8.75v-16.625c-0.45703-6.9805-4.4492-13.246-10.582-16.613-6.1367-3.3672-13.562-3.3672-19.699 0-6.1328 3.3672-10.125 9.6328-10.582 16.613v16.625c0 4.832-3.918 8.75-8.75 8.75s-8.75-3.918-8.75-8.75v-16.625c0.62891-13.117 7.9961-24.98 19.473-31.363 11.477-6.3867 25.441-6.3867 36.918 0 11.477 6.3828 18.84 18.246 19.473 31.363v16.625c0 2.3203-0.92188 4.5469-2.5625 6.1875s-3.8672 2.5625-6.1875 2.5625z"
                />
                <path
                    fill="currentColor"
                    d="m530.25 140h-49c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h49c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m542.5 175h-61.25c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h61.25c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m530.25 271.25h-49c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h49c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m542.5 306.25h-61.25c-4.832 0-8.75-3.918-8.75-8.75s3.918-8.75 8.75-8.75h61.25c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75z"
                />
                <path
                    fill="currentColor"
                    d="m494.38 507.5c-22.047 0-43.188-8.7578-58.777-24.348s-24.348-36.73-24.348-58.777 8.7578-43.188 24.348-58.777 36.73-24.348 58.777-24.348 43.188 8.7578 58.777 24.348 24.348 36.73 24.348 58.777c-0.023438 22.039-8.7891 43.168-24.371 58.754-15.586 15.582-36.715 24.348-58.754 24.371zm0-148.75c-17.406 0-34.098 6.9141-46.402 19.223-12.309 12.305-19.223 28.996-19.223 46.402s6.9141 34.098 19.223 46.402c12.305 12.309 28.996 19.223 46.402 19.223s34.098-6.9141 46.402-19.223c12.309-12.305 19.223-28.996 19.223-46.402s-6.9141-34.098-19.223-46.402c-12.305-12.309-28.996-19.223-46.402-19.223z"
                />
                <path
                    fill="currentColor"
                    d="m586.25 525c-2.3242 0.011719-4.5625-0.89844-6.2109-2.5391l-35-35c-3.4336-3.4297-3.4336-8.9922 0-12.422 3.4297-3.4336 8.9922-3.4336 12.422 0l35 35c1.6562 1.6406 2.5898 3.8789 2.5898 6.2109s-0.93359 4.5703-2.5898 6.2109c-1.6484 1.6406-3.8867 2.5508-6.2109 2.5391z"
                />
            </g>
        </svg>
    );
};
