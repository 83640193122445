import React, {Component} from "react";
import styled from "styled-components";
import {withContextConsumer} from "utils/contexts";
import LocaleContext from "contexts/common/LocaleContext";
import {mediaQuery} from "components/common/styled/mediaQuery";
import RoundIndicator from "components/common/RoundIndicator";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.13);
    margin-bottom: 1.4rem;
    ${mediaQuery.phone`width: 100%; height: auto;`}
`;

const Text = styled.div`
    font-size: 0.8rem;
    line-height: 1.25;
    margin-bottom: 0.5rem;
    color: #97a5b4;
`;
const InfoContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

const Available = styled.span`
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 1.6px;
`;

const Total = styled.span`
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.65px;
`;

@withContextConsumer(LocaleContext.Consumer)
class LibrarySpace extends Component {
    renderTotalAvailable(totalAvailable) {
        const {numberFormatter} = this.props;
        let available = totalAvailable / 1024;
        available = available < 0 ? 0 : available.toFixed(1);
        return numberFormatter.format(available);
    }

    renderTotal(total) {
        const {numberFormatter} = this.props;
        return numberFormatter.format((total / 1024).toFixed(1));
    }

    render() {
        const {t, total, totalAvailable, percentage} = this.props;
        return (
            <Container>
                <div>
                    <Text>{t("library.availableStorage")}</Text>
                    <InfoContainer>
                        <Available>{`${this.renderTotalAvailable(totalAvailable)} ${t("wallet.subscriptions.GB")}`} </Available>
                        {" / "}
                        <Total>{`${this.renderTotal(total)} ${t("wallet.subscriptions.GB")}`}</Total>
                    </InfoContainer>
                </div>
                <RoundIndicator
                    indicatorOptions={{
                        radius: 40,
                        strokeWidth: 9,
                        backgroundStrokeWidth: 9,
                        color: "var(--main-theme-color)",
                        buttLinecup: true
                    }}
                    percentage={percentage || 0}
                />
            </Container>
        );
    }
}

export default LibrarySpace;
