import React, {useState} from "react";
import styled from "styled-components";
import Tooltip from "components/common/Tooltip";
import {withNamespaces} from "react-i18next";

const TooltipContainer = styled.div`
  position: relative;
`;

const TooltipAbsolute = styled(Tooltip)`
    position: absolute;
    left: 50%;
`;

export default (withNamespaces("translation"))(({children, tooltip, tooltipAlignLeft, isHidden, i18n}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
        <TooltipContainer onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
            {children}
            {!isHidden && <TooltipAbsolute
                tooltipIsOpen={isTooltipOpen}
                tooltipAlignLeft={i18n.dir() === "rtl" ? !tooltipAlignLeft : tooltipAlignLeft}
            >
                {tooltip}
            </TooltipAbsolute>}
        </TooltipContainer>
    )
})