import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import Modal from "components/common/modals/Modal";
import logoMobileMenu from "assets/logo/logo.png";
import UserPicContainer from "components/common/styled/UserPicContainer";
import NotificationDot from "components/common/NotificationDot";
import {isAdmin, isParent, isPrincipal, isTeacher} from "consts/userTypes";
import routes from "consts/routes";
import MenuItemsData from "./MenuItemsData";

const LogoContainer = styled.div`
    position: fixed;
    top: 0.5rem;
    left: 0.8rem;
`;

const Content = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    max-height: 100%;
`;

const ProfileContent = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
`;

const Avatar = styled.div`
    width: 90px;
    height: 90px;
    ${UserPicContainer}
`;

const ProfileName = styled.div`
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--light-black);
    margin-top: 0.5rem;
    max-width: 100%;

    word-break: break-word;
`;

const ProfileLink = styled.div`
    font-weight: 600;
    color: var(--main-theme-color);
    font-size: 1.1rem;
    margin-top: 0.5rem;
`;

const NavContent = styled.div`
    margin-bottom: 5rem;
`;

const NavLink = styled.div`
    font-weight: 600;
    font-size: 1.2rem;
    color: ${props => (props.active ? "var(--light-black)" : "#848f97")};
    margin-top: 0.9rem;
    div {
        display: inline;
        ${props => props.active && "border-bottom: 2px solid var(--main-theme-color);"}
    }
`;

const NavTitle = styled.div`
    position: relative;
`;

const LogoutContent = styled.div`
    padding-bottom: 1rem;
`;

@withRouter
@withNamespaces("translation")
class MobileMenuModal extends Component {
    onGoTo(link) {
        const {onRequestClose, history} = this.props;
        onRequestClose();
        history.push(link);
    }

    render() {
        const {currentUser, t, history, logout, myCoursesNotification, ...rest} = this.props;

        return (
            <Modal backgroundColor={"#f8f8fa"} {...rest} fullHeightContent>
                <LogoContainer>
                    <img src={logoMobileMenu} alt={"logo"} />
                </LogoContainer>
                <Content>
                    {!isAdmin(currentUser) && (
                        <ProfileContent>
                            <Avatar>{currentUser.userPic && <img src={currentUser.userPic} alt={"avatar"} />}</Avatar>
                            <ProfileName>
                                {currentUser.firstName} {currentUser.lastName}
                            </ProfileName>
                            <ProfileLink onClick={() => this.onGoTo(routes.profile.path)}>
                                {t("navigation.userMenu.profile")}
                            </ProfileLink>
                            <ProfileLink onClick={() => this.onGoTo(routes.wallet.path)}>
                                {t("navigation.userMenu.wallet")}
                            </ProfileLink>
                            <ProfileLink onClick={() => this.onGoTo(routes.content.path)}>
                                {t("navigation.userMenu.myContent")}
                            </ProfileLink>
                            {isTeacher(currentUser) && (
                                <>
                                    <ProfileLink onClick={() => this.onGoTo(routes.quizzes.path)}>
                                        {t("navigation.userMenu.quizzes")}
                                    </ProfileLink>
                                    <ProfileLink onClick={() => this.onGoTo(routes.library.path)}>
                                        {t("navigation.userMenu.library")}
                                    </ProfileLink>
                                </>
                            )}

                            {isTeacher(currentUser) && (
                                <>
                                    <ProfileLink onClick={() => this.onGoTo(routes.assignments.path)}>
                                        {t("navigation.userMenu.assignments")}
                                    </ProfileLink>
                                    <ProfileLink onClick={() => this.onGoTo(routes.assessments.path)}>
                                        {t("navigation.userMenu.assessments")}
                                    </ProfileLink>
                                    <ProfileLink onClick={() => this.onGoTo(routes.weightageCategory.path)}>
                                        {t("navigation.userMenu.weightageCategory")}
                                    </ProfileLink>
                                </>
                            )}

                            {isTeacher(currentUser) && (
                                <>
                                    <ProfileLink onClick={() => this.onGoTo(routes.quizzes.path)}>
                                        {t("navigation.userMenu.quizzes")}
                                    </ProfileLink>
                                    <ProfileLink onClick={() => this.onGoTo(routes.library.path)}>
                                        {t("navigation.userMenu.library")}
                                    </ProfileLink>
                                </>
                            )}

                            {(isTeacher(currentUser) || isPrincipal(currentUser)) && (
                                <ProfileLink onClick={() => this.onGoTo(routes.invitations.path)}>
                                    {t("navigation.userMenu.invitations")}
                                </ProfileLink>
                            )}
                            {isParent(currentUser) && (
                                <ProfileLink onClick={() => this.onGoTo(routes.children.path)}>
                                    {t("navigation.userMenu.children")}
                                </ProfileLink>
                            )}
                        </ProfileContent>
                    )}
                    <NavContent>
                        {currentUser &&
                            MenuItemsData({currentUser}).map((d, i) => {
                                const isAvailable = d.availableForUsers.includes(currentUser.role);
                                if (!isAvailable) {
                                    return null;
                                }
                                const isCourseLink = d.link === "/courses";
                                return (
                                    <NavLink
                                        key={i}
                                        active={history.location.pathname === d.link}
                                        onClick={() => this.onGoTo(d.link)}>
                                        <NavTitle>
                                            {t(d.title)}
                                            <NotificationDot visible={isCourseLink && myCoursesNotification} reversePadding />
                                        </NavTitle>
                                    </NavLink>
                                );
                            })}
                    </NavContent>
                    <LogoutContent>
                        <ProfileLink onClick={logout}>{t("navigation.userMenu.logout")}</ProfileLink>
                    </LogoutContent>
                </Content>
            </Modal>
        );
    }
}

export default MobileMenuModal;
