import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
        flex: 1;
    }
    ${mediaQuery.phone`
        flex-flow: column;
    `}
`;

export const Title = styled.div`
    margin: 1rem 0;
    font-size: 1.5rem;

    font-weight: 600;
    line-height: 1.5;
    color: var(--black);
`;

export const NotificationRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    ${mediaQuery.phone`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const CellOfRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40rem;
`;

export const TitleOfNotification = styled.div`
    margin-bottom: 1rem;
    ${mediaQuery.phone`
        margin-bottom: 0.5rem;
    `}
`;

export const CreatedAt = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    color: #8b8b8b;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    ${mediaQuery.phone`
        margin-bottom: 0.8rem;
    `}
`;

export const Event = styled.div`
    border-radius: 2px;
    background-color: #f4f4f4;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    padding: 0.5rem 1rem;
    color: #2e2e2e;
    ${mediaQuery.phone`
        margin-left: 2rem;
        margin-bottom: 0.8rem;
    `}
`;

export const Link = styled.div`
    font-size: 13px;
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: var(--main-theme-color);
    cursor: pointer;
    width: 10rem;
    ${mediaQuery.phone`
        margin-left: 2rem;
        width: unset;
    `}
`;

export const ControlRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
