import styled from "styled-components";
import Button from "../../form/Button";
import {mediaQuery} from "components/common/styled/mediaQuery";

export const Field = styled.div`
    margin-bottom: 1.5rem;
    font-size: 0.93rem;
`;

export const FieldHeader = styled.div`
    display: flex;
    justify-content: space-between;
    ${props => !props.black && "color: #8b8b8b;"}
    margin-bottom: 0.3rem;
`;

export const FieldText = styled.div`
    font-weight: 600;
    color: var(--light-black);
`;

export const BalanceText = styled(FieldText)`
    font-size: 2.6rem;
    ${mediaQuery.phone`
        font-size: 2rem;
        margin: 0 0 1rem 0;
    `}
`;

export const BalanceButton = styled(Button)`
    font-weight: 600;
    width: 100%;
    height: 2.8rem;
    margin-top: 3rem;
    ${mediaQuery.phone`
        margin-top: 0;
        height: 3.5rem;
    `}
`;

export const ErrorContainer = styled.div`
    text-align: center;
    font-size: 0.8rem;
    color: #fd0f0f;
`;
