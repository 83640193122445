import React, {Component} from "react";
import styled from "styled-components";
import {libraryItemTypes} from "consts/library";
import FlexMarginBetween from "components/common/styled/FlexMarginBetween";
import EllipsisText from "components/common/styled/EllipsisText";
import Scrollbars from "components/common/Scrollbars";
import LocaleContext from "contexts/common/LocaleContext";
import {withContextConsumer} from "utils/contexts";
import {mediaQuery} from "components/common/styled/mediaQuery";
import Checkmark from "./Checkmark";

import FolderSmall from "components/common/icons/library/FolderSmall";
import DocumentSmall from "components/common/icons/library/DocumentSmall";
import ImageSmall from "components/common/icons/library/ImageSmall";
import RestFileInfo from "components/common/icons/library/RestFileInfo";

const FilesListContainer = styled.div`
    flex: 1;
    ${mediaQuery.phone`margin-top: 1.5rem;`}
`;

const FileContainer = styled.div`
    ${props => props.disabled && "opacity: 0.5; pointer-events: none;"}
`;

const ListRow = styled.div`
    display: flex;
    align-items: center;
    height: 2.4rem;
    ${props => props.withBorder && "border-bottom: 2px solid #f8f8f8;"}
    ${FlexMarginBetween(0.5)}
    ${mediaQuery.phone`
        border-bottom: none;
        ${props => props.withBorder && "border-top: 2px solid #f8f8f8;"}
        &:last-child{border-bottom: 2px solid #f8f8f8;}
    `}
`;

const RestInfoRow = styled.div`
    display: flex;
    font-size: 0.8125rem;
    margin-bottom: 0.3rem;
    ${FlexMarginBetween(0.5)}
`;

const HeaderRow = styled.div`
    font-size: 0.7rem;
    font-weight: 600;
    color: #ababab;
`;

const RestTitle = styled.div`
    color: #ababab;
    font-weight: 600;
`;

const CheckmarkCol = styled.div`
    width: 16px;
    flex-shrink: 0;
`;

const NameCol = styled.div`
    flex: 1 1 auto;
    min-width: 0;
`;

const FileName = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    svg {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
    }
    span {
        margin: 0 0.6rem;
    }

    ${EllipsisText}
`;

const AuthorCol = styled.div`
    flex-basis: 9rem;
    text-align: right;
    flex-shrink: 0;
    min-width: 0;
    ${EllipsisText}
    ${mediaQuery.phone`display: none;`}
`;

const SizeCol = styled.div`
    flex-basis: 3.75rem;
    text-align: right;
    flex-shrink: 0;
    min-width: 0;
    ${EllipsisText}
    ${mediaQuery.phone`display: none;`}
`;

const DateCol = styled.div`
    flex-basis: 7.5rem;
    text-align: right;
    flex-shrink: 0;
    min-width: 0;
    ${EllipsisText}
`;

const RestCol = styled.div`
    flex-basis: 1rem;
    display: none;
    align-items: center;
    svg {
        height: 2px;
    }
    ${mediaQuery.phone`display: flex;`}
`;

const RegularInfo = styled.div`
    font-size: 0.8125rem;
    color: #b4b4b4;
`;

@withContextConsumer(LocaleContext.Consumer)
class FilesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedItems: []
        };
    }

    toggleExpandedItem(itemId) {
        const {expandedItems} = this.state;
        if (expandedItems.includes(itemId)) {
            return this.setState({expandedItems: expandedItems.filter(i => i !== itemId)});
        }
        this.setState({expandedItems: [...expandedItems, itemId]});
    }

    getFileIcon(item) {
        if (item.type === libraryItemTypes.folder) {
            return <FolderSmall />;
        }
        if (item.type === libraryItemTypes.image) {
            return <ImageSmall />;
        }
        if (item.type === libraryItemTypes.video) {
            return <DocumentSmall />;
        }
        if (item.type === libraryItemTypes.audio) {
            return <DocumentSmall />;
        }
        return <DocumentSmall />;
    }

    getSizeForItem(item) {
        const {t, numberFormatter} = this.props;
        if (!item.size) {
            return null;
        }
        return (
            <RegularInfo>
                {numberFormatter.format(item.size)}
                {t("library.filesList.MB")}
            </RegularInfo>
        );
    }

    renderItem(item, i) {
        const {t, i18n, dateFormatter, selectedItems, selectItem, unselectItem, inSharingModal} = this.props;
        const {expandedItems} = this.state;

        if (inSharingModal && item.isMarkedForDeletion) {
            return null;
        }

        const isFolder = item.type === libraryItemTypes.folder;
        const isExpanded = expandedItems.includes(item.id);
        const isSelected = selectedItems.includes(item.id);

        const disabled = !inSharingModal && item.isMarkedForDeletion;
        return (
            <FileContainer key={i} disabled={disabled}>
                <ListRow dir={i18n.dir()} withBorder>
                    <CheckmarkCol>
                        <Checkmark
                            checked={isSelected}
                            onClick={isSelected ? unselectItem.bind(null, item.id) : selectItem.bind(null, item.id)}
                        />
                    </CheckmarkCol>
                    <NameCol withBorder>
                        <FileName>
                            {this.getFileIcon(item)} <span>{item.name}</span>
                        </FileName>
                    </NameCol>
                    {!inSharingModal && (
                        <AuthorCol withBorder>
                            <RegularInfo>{item.author}</RegularInfo>
                        </AuthorCol>
                    )}
                    {!inSharingModal && <SizeCol withBorder>{this.getSizeForItem(item)}</SizeCol>}
                    <DateCol withBorder>
                        <RegularInfo>{dateFormatter.format(new Date(item.createdAt))}</RegularInfo>
                    </DateCol>
                    <RestCol onClick={!isFolder ? this.toggleExpandedItem.bind(this, item.id) : () => null}>
                        {!isFolder && <RestFileInfo />}
                    </RestCol>
                </ListRow>
                {isExpanded && (
                    <React.Fragment>
                        <RestInfoRow>
                            <CheckmarkCol />
                            <NameCol>
                                <RestTitle>{t("library.filesList.author")}</RestTitle>
                            </NameCol>
                            <DateCol>
                                <RegularInfo>{item.author}</RegularInfo>
                            </DateCol>
                            <RestCol />
                        </RestInfoRow>
                        <RestInfoRow>
                            <CheckmarkCol />
                            <NameCol>
                                <RestTitle>{t("library.filesList.size")}</RestTitle>
                            </NameCol>
                            <DateCol>
                                <RegularInfo>{this.getSizeForItem(item)}</RegularInfo>
                            </DateCol>
                            <RestCol />
                        </RestInfoRow>
                    </React.Fragment>
                )}
            </FileContainer>
        );
    }

    renderReaderRow() {
        const {t} = this.props;

        return (
            <HeaderRow>
                <ListRow withBorder>
                    <CheckmarkCol />
                    <NameCol>{t("library.filesList.name")}</NameCol>
                    <AuthorCol>{t("library.filesList.author")}</AuthorCol>
                    <SizeCol>{t("library.filesList.size")}</SizeCol>
                    <DateCol>{t("library.filesList.date")}</DateCol>
                    <RestCol />
                </ListRow>
            </HeaderRow>
        );
    }

    renderItems() {
        const {items} = this.props;
        return items.map(this.renderItem.bind(this));
    }

    render() {
        const {isPhoneResolution, inSharingModal} = this.props;
        return (
            <FilesListContainer>
                {isPhoneResolution ? (
                    this.renderItems()
                ) : (
                    <Scrollbars>
                        {!inSharingModal && this.renderReaderRow()}
                        {this.renderItems()}
                    </Scrollbars>
                )}
            </FilesListContainer>
        );
    }
}

export default FilesList;
