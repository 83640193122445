import moment from "moment-timezone";
import {leadZeros} from "./date";

export function GetTimeZone(currentUser) {
    return currentUser.timezone || moment.tz.guess();
}

export function getUserTime(time) {
    return moment.tz(moment(time), moment.tz.guess()).format("MMMM D, YYYY h:mm A");
}

export function convertTime24to12(time24h) {
    return moment(time24h, ["HH:mm"]).format("hh:mm A");
}

export function msToTime(ms) {
    if (ms === 0 || ms === NaN) {
        return 0;
    } else {
        let seconds = (ms / 1000).toFixed(1);
        let minutes = (ms / (1000 * 60)).toFixed(1);
        let hours = (ms / (1000 * 60 * 60)).toFixed(1);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
        if (seconds < 60) return seconds + " Sec";
        else if (minutes < 60) return minutes + " Min";
        else if (hours < 24) return hours + " Hrs";
        else return days + " Days";
    }
}

export function convertTime12to24(time12h) {
    return moment(time12h.toUpperCase(), ["hh:mm A"]).format("HH:mm");
}

export function isValidTime(time, format) {
    if (format === "12") {
        let [hours, right] = time.split(":");
        if (!right) {
            return false;
        }
        let [minutes, modifier] = right.split(" ");
        return hours && hours.length === 2 && minutes && minutes.length === 2 && modifier;
    }
}

export function human24TimeToMinutes(time24h) {
    const [hours, minutes] = time24h.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
}

export function minutesToHumanDate(minutes) {
    return `${leadZeros(Math.floor(minutes / 60))}:${leadZeros(minutes % 60)}`;
}

export function secondsToHHmm(seconds) {
    const minutes = seconds / 60;
    return minutesToHumanDate(minutes);
}

export function secondsToHumanDate(minutes) {
    return `${leadZeros(Math.floor(minutes))}:${leadZeros(minutes * 60)}`;
}

export function secondsToMMss(seconds) {
    const minutes = seconds / 60;
    return secondsToHumanDate(minutes);
}

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
}

export function getDifferenceBetweenTimezones(zone1, zone2) {
    const now = moment.utc();
    const first = moment.tz.zone(zone1).utcOffset(now);
    const second = moment.tz.zone(zone2).utcOffset(now);
    return first - second;
}

export function toHHmmFromMillis(millis, {numberFormatter} = {}) {
    let hours = Math.floor(millis / 3600000);
    hours = hours < 10 ? "0" + hours : hours;

    let minutes = Math.floor((millis - hours * 3600000) / 60000);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    if (numberFormatter) {
        return numberFormatter.format(hours) + ":" + numberFormatter.format(minutes);
    }
    return hours + " : " + minutes;
}

export function HHmmDurationToDate(duration) {
    const durationArray = duration.split(":");
    return moment
        .utc(0)
        .set({hour: durationArray[0], minute: durationArray[1]})
        .toDate();
}

export function getDifferenceInDaysFromNow(date) {
    const now = new Date();
    const momentDate = moment(date);
    return momentDate.diff(now, "days");
}

export function getTimePeriodBetweenNowAndDate(date) {
    const now = new Date();
    const momentNow = moment(now);
    const momentDateToCheck = moment(date);
    const timePeriods = ["day", "year"];
    for (const period of timePeriods) {
        if (momentNow.isSame(momentDateToCheck, period)) return period;
    }
    return "lastyear";
}
