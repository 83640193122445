import {css} from "styled-components";
import userPicPlaceholder from "assets/icons/profilePlaceholder.png";
import {mediaQuery} from "./mediaQuery";

export default css`
    display: flex;
    border-radius: 50%;
    ${props => !props.borderless && "border: 5px solid var(--light-blue);"}
    box-sizing: border-box;

    background-image: url(${props => props.placeholder || userPicPlaceholder});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% auto;

    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-drag: none;
    }
    ${mediaQuery.phone`
        border: none;
    `}
`;
