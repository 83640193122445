import React from "react";

export default ({fillColor, ...props}) => (
    <svg viewBox="0 0 21 21" {...props}>
        <path
            fill={fillColor || "#FFF"}
            fillRule="evenodd"
            d="M12.075 12.044c.29 0 .525.277.525.618 0 .133-.037.263-.105.37l-1.575 2.47c-.174.274-.503.33-.735.124-.04-.035-.075-.076-.105-.123l-1.575-2.47c-.174-.274-.127-.66.105-.865.09-.08.201-.124.315-.124h3.15zm-1.26-6.67c.04.035.075.076.105.123l1.575 2.47c.174.274.127.66-.105.865-.09.08-.201.124-.315.124h-3.15c-.29 0-.525-.277-.525-.618 0-.133.037-.263.105-.37l1.575-2.47c.174-.274.503-.33.735-.124z"
        />
    </svg>
);
